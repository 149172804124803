import React from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  type: "text",
  parse: true,
  onChange: noop,
};

function InputPeso({
  parse,
  className,
  maxLength,
  onChange,
  options,
  negative,
  ...props
}) {
  function formatInputValue(e) {
    let value = e.target.value.replace(/[^0-9.]/g, "");

    value = value.replace(/^0+/, "");
    let floatValue = parseFloat(value);

    let parts = value.split(".");
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? "." + parts[1] : "";

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    value = integerPart + decimalPart;

    onChange(floatValue);
    e.target.value = value;
  }

  return (
    <div
      className="position-relative d-flex align-items-center"
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <i class="position-absolute opacity-75 " style={{ left: "1rem" }}>
        {negative ? "- ₱" : "₱"}
      </i>
      <input
        className={classNames("form-control pe-3 ps-5", className)}
        onInput={formatInputValue}
        style={{
          textAlign: "end",
        }}
        placeholder="0.00"
        {...props}
      />
    </div>
  );
}

InputPeso.defaultProps = defaultProps;

export default InputPeso;
