import BaseListPresenter from "../../base/BaseListPresenter";
import { aggregateUseCase } from "../../usecases/object";

class CashInPositionPresenter extends BaseListPresenter {
  componentDidMount() {
    this.init();
    return this.getObjects();
  }

  init() {
    this.where = {};
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.filterDate = {};
    this.reset();
  }

  reset() {
    this.objects = [];
    this.count = 0;
    this.current = 1;
    this.view.setObjects([]);
    this.view.setSelected([]);
    this.view.setCount(0);
    this.view.setTotal(0);
  }

  async getObjects() {
    this.reset();
    await this.countObjects();
    this.findObjects();
    this.totalCashBank();
    this.totalCashDisbursement();
    this.totalPaidOut();
  }
  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = {
      include: "all",
      where: { ...this.filterDate },
    };
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  // async totalCashReceipts() {
  //   const collection = "transaction";
  //   try {
  //     this.showProgress();
  //     this.findObjectUseCase.abort();

  //     const [transaction] = await aggregateUseCase().execute(collection, [
  //       {
  //         $group: {
  //           _id: null,
  //           total: {
  //             $sum: { $toDouble: "$amounts" },
  //           },
  //         },
  //       },
  //     ]);

  //     console.log("T", transaction);
  //     this.view.setState({ totalCashReceipt: transaction.total });

  //     this.hideProgress();
  //   } catch (error) {
  //     this.hideProgress();
  //     this.view.showError(error);
  //   }
  //   this.progress = false;
  // }

  async totalCashBank() {
    const collection = "gmd_accounts";
    const query = {
      include: "all",
    };
    try {
      this.showProgress();
      this.findObjectUseCase.abort();

      const [accounts] = await aggregateUseCase().execute(collection, [
        {
          $group: {
            _id: null,
            total: {
              $sum: "$balance",
            },
          },
        },
      ]);

      this.view.setTotalBank(accounts.total);

      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  async totalCashDisbursement() {
    const collectExpense = "expense";
    const collectPurchase = "purchase_orders";
    const query = {
      include: "all",
      where: { ...this.filterDate },
    };
    try {
      const totalExpense = await this.findObjectUseCase.execute(
        collectExpense,
        query
      );

      const totalPurchase = await this.findObjectUseCase.execute(
        collectPurchase,
        query
      );

      const weekOne = "Workforce  - Week 1";
      const weekOneObjects = totalExpense.filter(
        (item) => item.chart_accounts?.name === weekOne
      );
      const weekOneTotalAmount = weekOneObjects.reduce(
        (total, item) => total + item.amount,
        0
      );

      const weekTwo = "Workforce  - Week 2";
      const weekTwoObjects = totalExpense.filter(
        (item) => item.chart_accounts?.name === weekTwo
      );
      const weekTwoTotalAmount = weekTwoObjects.reduce(
        (total, item) => total + item.amount,
        0
      );

      const weekThree = "Workforce  - Week 3";
      const weekThreeObjects = totalExpense.filter(
        (item) => item.chart_accounts?.name === weekThree
      );
      const weekThreeTotalAmount = weekThreeObjects.reduce(
        (total, item) => total + item.amount,
        0
      );

      const weekFour = "Workforce  - Week 4";
      const weekFourObjects = totalExpense.filter(
        (item) => item.chart_accounts?.name === weekFour
      );
      const weekFourTotalAmount = weekFourObjects.reduce(
        (total, item) => total + item.amount,
        0
      );

      const weekFive = "Workforce  - Week 5";
      const weekFiveObjects = totalExpense.filter(
        (item) => item.chart_accounts?.name === weekFive
      );
      const weekFiveTotalAmount = weekFiveObjects.reduce(
        (total, item) => total + item.amount,
        0
      );

      const workforce =
        weekOneTotalAmount +
        weekTwoTotalAmount +
        weekThreeTotalAmount +
        weekFourTotalAmount +
        weekFiveTotalAmount;

      const officeTeam20th = "Office Team (20th)";
      const officeTeam20thObjects = totalExpense.filter(
        (item) => item.chart_accounts?.name === officeTeam20th
      );
      const officeTeam20thTotalAmount = officeTeam20thObjects.reduce(
        (total, item) => total + item.amount,
        0
      );

      const officeTeam5th = "Office Team (5th)";
      const officeTeam5thObjects = totalExpense.filter(
        (item) => item.chart_accounts?.name === officeTeam5th
      );
      const officeTeam5thTotalAmount = officeTeam5thObjects.reduce(
        (total, item) => total + item.amount,
        0
      );

      const officeTeam = officeTeam5thTotalAmount + officeTeam20thTotalAmount;
      const salariesWages = workforce + officeTeam;
      const totalExpenseWithRequestNumber = totalExpense.filter((obj) =>
        obj.hasOwnProperty("requestNumber")
      );
      const sortedTotalExpense = totalExpenseWithRequestNumber.sort(
        (a, b) => b.amount - a.amount
      );
      const totalAmount = sortedTotalExpense.reduce(
        (total, obj) => total + obj.amount,
        0
      );

      const opExpense = "OPEX -";
      const opExpenseObjects = totalExpense.filter((item) =>
        item.chart_accounts?.name
          .toLowerCase()
          .includes(opExpense.toLowerCase())
      );

      const combinedOpExpenseData = opExpenseObjects.reduce((result, obj) => {
        const name = obj.chart_accounts?.name;
        if (!result[name]) {
          result[name] = { amount: obj.amount };
        } else {
          result[name].amount += obj.amount;
        }
        return result;
      }, {});

      const totalOpEx = Object.values(combinedOpExpenseData).reduce(
        (total, data) => total + data.amount,
        0
      );

      const creditCardBill = "Credit Card Bill";
      const creditCardBillObjects = totalExpense.filter(
        (item) => item.chart_accounts?.name === creditCardBill
      );
      const creditCardBillTotalAmount = creditCardBillObjects.reduce(
        (total, item) => total + item.amount,
        0
      );

      const purchaseOrder = "Sent";
      const purchaseOrderArray = Object.values(totalPurchase);

      const purchaseOrderObjects = purchaseOrderArray.filter(
        (item) => item.statuses === purchaseOrder
      );

      const vendorTotalMap = new Map();

      purchaseOrderObjects.forEach((item) => {
        const vendorName = item.vendor_name?.name;
        const totalAmount = item.total;

        if (vendorName && totalAmount) {
          if (!vendorTotalMap.has(vendorName)) {
            vendorTotalMap.set(vendorName, totalAmount);
          } else {
            vendorTotalMap.set(
              vendorName,
              vendorTotalMap.get(vendorName) + totalAmount
            );
          }
        }
      });

      const purchaseOrderTotalAmount = purchaseOrderObjects.reduce(
        (total, item) => total + item.total,
        0
      );

      const totalDisburse =
        salariesWages +
        officeTeam +
        workforce +
        creditCardBillTotalAmount +
        purchaseOrderTotalAmount +
        totalAmount +
        totalOpEx;
      this.view.setDisburse(totalDisburse);
    } catch (error) {
      console.log("errttt", error);
    }
  }

  async totalPaidOut() {
    const collection = "expense";
    const query = {
      include: "all",
      where: { ...this.filterDate },
    };
    try {
      const objects = await this.findObjectUseCase.execute(collection, query);
      const cashPaidOut = "CPO -";
      const cashPaidOutObjects = objects.filter((item) =>
        item.chart_accounts?.name
          .toLowerCase()
          .includes(cashPaidOut.toLowerCase())
      );

      const cashPaidOutData = cashPaidOutObjects.reduce((result, obj) => {
        const name = obj.chart_accounts?.name;
        const displayName =
          name && name.startsWith("CPO -") ? name.slice(5) : name;

        if (!result[displayName]) {
          result[displayName] = { amount: obj.amount };
        } else {
          result[displayName].amount += obj.amount;
        }
        return result;
      }, {});

      const cashPaidOutValues = Object.values(cashPaidOutData);

      const totalCashPaid = cashPaidOutValues.reduce(
        (total, item) => total + item.amount,
        0
      );

      this.view.setCashPaid(totalCashPaid);
    } catch (error) {
      this.view.showError(error);
    }
  }
}

export default CashInPositionPresenter;
