import React from "react";
import classNames from "../../../classNames";

const MarkDoneDialogContent = ({ onDone, onCancel }) => (
  <div className="text-end">
    <div className="text-center">
      <i
        className={classNames("bi bi-file-earmark-check", "text-success")}
        style={{ fontSize: "5rem" }}
      ></i>
      <h4 className="fw-bold">Mark Project as Done</h4>
      <p className="m-0">Are you sure you want to mark this project as done?</p>
      <button
        className="btn text-white mb-2 mt-2 me-2"
        style={{ backgroundColor: "#4aab73" }}
        onClick={onDone}
      >
        Mark as Done
      </button>
      <button className="btn mb-2 mt-2 btn-light" onClick={onCancel}>
        Cancel
      </button>
    </div>
  </div>
);

export default MarkDoneDialogContent;
