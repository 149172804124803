import React from "react";
import { formatCurrency } from "../../../../../../formatCurrency";

export default function OtherDeductions({ deductions }) {
  console.log("DEDUCTIONS", deductions);
  return (
    <div
      className="rounded position-relative m-0 p-3 flex-1 w-100"
      style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)", fontSize: ".9rem" }}
    >
      <div className="d-flex fw-semibold opacity-75 justify-content-between">
        <p>Other Deductions</p>
        <p>
          {formatCurrency(
            deductions.reduce((acc, current) => (acc += current.value), 0)
          )}
        </p>
      </div>
      <div
        className="d-flex p-2 m-0 rounded flex-column"
        style={{ background: "#e6e6e6", fontSize: ".8rem" }}
      >
        <div className="d-flex p-2 m-0 rounded justify-content-between">
          <p className=" flex-grow w-100 fw-semibold">Description</p>
          <p className="text-end flex-grow w-100 fw-semibold">Amount</p>
        </div>
        {deductions.map((deduction) => {
          return (
            <div
              key={deduction.label}
              className="d-flex ps-2 pe-2 p-0 m-0 rounded justify-content-between"
              style={{ fontSize: ".8rem" }}
            >
              <p className=" flex-grow w-100">{deduction.label}</p>
              <p className="text-end flex-grow w-100">
                {formatCurrency(deduction.value)}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
