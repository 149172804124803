import React from "react";
import { InputJson } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../../usecases/object";
import withRouter from "../../../../withRouter";
import BaseFormPage from "../../../../base/BaseFormPage";
import NavBar from "../../../../components/navbar";
import { restUseCase } from "../../../../usecases/rest";
import ProjectTabFormPresenter from "./ProjectTabFormPresenter";
import InputFactory from "../../../../components/InputFactory";
import Drop from "../../../../components/DropdownFilter";
import FormFactory from "../../../../components/FormFactory";

class ProjectTabFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      filtered: [],
    };
    this.presenter = new ProjectTabFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  onClickAdvance() {
    this.setState({
      advanced: !this.state.advanced,
    });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:Designer", "role:masterAdmin"],
      write: [user.id, "role:Designer", "role:masterAdmin"],
    };
  }

  render() {
    const object = this.state.object;
    const currentDate = this.getCurrentDateFormatted();
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    const tabCollection = this.props.params.name;
    const { pid, cid, id } = this.props.params;
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />

        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <div className="d-flex align-items-center ">
                <button
                  type="button"
                  className="btn btn-link"
                  style={{ padding: "0" }}
                >
                  <i
                    className="bi bi-arrow-left fs-4"
                    onClick={this.onClickBack.bind(this)}
                    style={{ color: "#DAB451", margin: "0" }}
                  ></i>
                </button>
                <h1 className="fw-bold mt-3 text-capitalize">
                  {label} {tabCollection === "project_designs" && "Design"}
                  {tabCollection === "expense" && "Expense"}
                  {tabCollection === "estimate_forms" && "Estimate"}
                  {tabCollection === "invoices" && "Invoice"}
                </h1>
              </div>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  {/* Project Design */}
                  {tabCollection === "project_designs" && (
                    <div className="row g-3 py-4">
                      <div className="mt-3 mb-3">
                        <label className="form-label">Link</label>
                        <InputFactory
                          type="String"
                          field="link"
                          object={object}
                          placeholder="Enter Design Link"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <InputFactory
                          type="String"
                          field="description"
                          object={object}
                          placeholder="Enter Description"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    </div>
                  )}

                  {/* Estimates */}
                  {tabCollection === "estimate_forms" && (
                    <div className="row g-3 py-4">
                      <div className="mt-3 mb-3">
                        <label className="form-label">Link</label>
                        <InputFactory
                          type="String"
                          field="link"
                          object={object}
                          placeholder="Enter Design Link"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <InputFactory
                          type="String"
                          field="description"
                          object={object}
                          placeholder="Enter Description"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    </div>
                  )}

                  {/* Invoices */}
                  {tabCollection === "invoices" && (
                    <div className="row g-3 py-4">
                      <div className="mt-3 mb-3">
                        <label className="form-label">Link</label>
                        <InputFactory
                          type="String"
                          field="link"
                          object={object}
                          placeholder="Enter Design Link"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <InputFactory
                          type="String"
                          field="description"
                          object={object}
                          placeholder="Enter Description"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    </div>
                  )}

                  <>
                    <p>PID:{pid}</p>
                    <p>CID:{cid}</p>
                    <p>ID:{id}</p>
                  </>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary fs-sm me-3"
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProjectTabFormPage);
