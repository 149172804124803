export default function AddOvertime({ isEditing }) {
  return (
    <div className="col">
      <div className="row">
        <p
          className="fw-semibold"
          style={{ fontSize: "1.3rem", color: "#DAB44E" }}
        >
          {isEditing ? "Edit Overtime" : "Add Overtime"}
        </p>
        <p className="" style={{ fontSize: "1rem" }}>
          {`Are you sure you want to ${
            isEditing ? "edit" : "add"
          } this overtime?`}
        </p>
      </div>
    </div>
  );
}
