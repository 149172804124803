import React from "react";
import ModdedFormDialog from "../ModdedFormDialog";

const defaultProps = {};

function ModdedRelationDialog({ schema, onClickItem, onCancel, findObject, saveObject, saveImage, saveFile, schemas }) {

    return (
        <div className="py-4 px-2">
            <h4 className="fw-bold">Add {schema.label || schema.collection}</h4>

            <div className="mt-3">
                <ModdedFormDialog
                    onSave={onClickItem}
                    schema={schema}
                    findObject={findObject}
                    saveObject={saveObject}
                    saveFile={saveFile}
                    saveImage={saveImage}
                    schemas={schemas}
                    onCancel={onCancel}
                />
            </div>
        </div>
    )
}

ModdedRelationDialog.defaultProps = defaultProps;
export default ModdedRelationDialog;
