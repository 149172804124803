import { dialog } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";
import { getDateRange } from "../payroll/utils/getDateRange";
class LeaveRequestViewPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    if (id) {
      const params = { include: ["all"] };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        const [user] = await findObjectUseCase().execute("users", {
          where: { id: this.object.name.id },
        });
        this.view.setState((prev) => ({ ...prev, userInfo: user }));
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  async onClickStatus(status) {
    const collection = "leave_request";
    this.object.statuses = status;

    try {
      const data = await this.upsertUseCase.execute(collection, this.object);
      if (data.statuses === this.object.statuses) {
        this.view.setObject(this.object);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }

  async checkAndModifyPayroll(startDate, endDate, userId, isHalfday) {
    function computeWorkDayByHours(hours) {
      hours = parseFloat(hours);
      let day = Math.floor(hours / 8);

      return day;
    }

    const employeePayrolls = await findObjectUseCase().execute(
      "employee_payroll_info",
      {
        where: { userId },
      }
    );

    const [user] = await findObjectUseCase().execute("users", {
      where: {
        id: userId,
      },
    });

    const filteredEmployeePayroll = await Promise.all(
      employeePayrolls.map(async (employeePayroll) => {
        let newObj = {};
        const payrolls = await findObjectUseCase().execute("payroll", {
          where: {
            id: employeePayroll.payrollId,
            type: "Weekly",
          },
        });

        payrolls.forEach(async (payroll) => {
          const payrollDateRange = getDateRange(
            payroll.startDate,
            payroll.endDate
          ).slice(0, 7);

          let total = 0;
          payrollDateRange.forEach((date) => {
            const currentDate = new Date(date);
            const leaveStartDate = new Date(startDate);
            const leaveEndDate = new Date(endDate);

            if (currentDate >= leaveStartDate && currentDate <= leaveEndDate) {
              console.log(
                `${payroll.name} - ${currentDate} :: ${leaveStartDate} | ${leaveEndDate}`
              );
              total += isHalfday ? 4 : 8;
            }
          });

          newObj = {
            ...employeePayroll,
            total:
              (employeePayroll.total || 0) +
              total * ((user.dailyRate || 0) / 8),
            subtotal:
              (employeePayroll.total || 0) +
              total * ((user.dailyRate || 0) / 8),
            deductions: 0,
            // POTENTIAL ISSUE:: If the total hours is 4, it won't be counted as 1 day. Later on if there is another total hours of 4, it should be then treated as 1 day, adding the previous 4.
            daysWorked:
              (employeePayroll.daysWorked || 0) + computeWorkDayByHours(total),
          };

          await this.upsertUseCase.execute("employee_payroll_info", newObj);
        });

        return newObj;
      })
    );
    console.log("Filtered Employee Payroll", filteredEmployeePayroll);
  }

  async onClickMarkPaid(currentCreds, creditsDay) {
    const { startDate, endDate, name, isHalfday } = this.view.state.object;
    const query = {
      where: {
        id: this.object.name.id,
      },
    };

    try {
      const user = await findObjectUseCase().execute("users", query);

      console.log("Current User Credits", currentCreds);
      const userCreds = currentCreds.replace(/,/g, "");
      const credsDay = creditsDay.replace(/,/g, "");
      const newCreds = parseFloat(userCreds) - parseFloat(credsDay);

      user[0].credits = newCreds.toLocaleString();

      // Update user's credits in the database
      await this.upsertUseCase.execute("users", user[0]);

      // Update employee payroll info
      await this.checkAndModifyPayroll(startDate, endDate, name.id, isHalfday);

      await this.save();

      console.log("User credits updated successfully:", user[0]);
    } catch (error) {
      console.error("Error updating user credits:", error);
    }
  }
}

export default LeaveRequestViewPresenter;
