import React from 'react';
import NavBar from "../../components/navbar";
import { InfiniteScroll, Progress } from "nq-component";
import Table from "../../components/Table";
import BaseListPage from '../../base/BaseListPage';
import {
    countObjectUseCase,
    deleteObjectUseCase,
    findObjectUseCase,
    upsertUseCase,
    getObjectUseCase, updateObjectUseCase
} from "../../usecases/object";
import EditEstimatePresenter from './EditEstimatePresenter';
import BaseFormPage from '../../base/BaseFormPage';
import { restUseCase } from '../../usecases/rest';
import InputFactory from '../../components/InputFactory';
import Drop from '../../components/DropdownFilter';
import InputFactory2 from '../../components/InputFactory2';
import withRouter from '../../withRouter';


class EditEstimatePage extends BaseFormPage {
    constructor(props) {
        super(props);
        this.state = { object: {}, 
                      advanced: false, 
                      selectedOption: '', 
                      selectedProject: null, 
                      filtered: [], 
                      totalAmount: 0, 
                      discountType: 'percent', };

        this.presenter = new EditEstimatePresenter(
          this,
          getObjectUseCase(),
          upsertUseCase(),
          restUseCase()
        );
      }
    
      getCollectionName() {
        return "estimate_forms";
    
      }
    
      onChangeObject(object) {
        this.presenter.onChangeObject(object);
      }
    
      onChangeSelect(event) {
        const selectedOption = event.target.value;
        console.log("Selected Option:", selectedOption);
        this.setState({ selectedOption });
      }
    
      onChangeSelect2(event) {
        const selectedOption2 = event.target.value;
        console.log("Selected Option:", selectedOption2);
        this.setState({ selectedOption2 });
      }
    
    
      getCurrentDateFormatted() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
    
        return `${year}-${month}-${day}`;
      }
    
    
      async onChange(value, field) {
        console.log("Field:", field);
        console.log("Value:", value);
    
        if (field === "client_name") {
          const clientName = value.id;
          const projects = await findObjectUseCase().execute("projects");
          console.log("projects", projects);
    
          const filteredProjects = projects
            .filter((project) => project.client.id === clientName)
            .map((project) => ({
              name: project.name,
              id: project.id,
            }));
          console.log("filteredProjects", filteredProjects);
    
          this.setState({
            filtered: filteredProjects,
            selectedProject: filteredProjects.length > 0 ? filteredProjects[0] : null
          }, () => {
            const estimate = "event"; 
    
            console.log("Setting estimate_type to:", estimate);
    
            this.setState({
                estimate_type: estimate,
            }, () => {
                this.onChange(estimate, 'estimate_type');
            });
          });
        } else if (field === "project_name") {
          console.log("Project name changed:", value);
          // Here you can perform additional logic if needed
        }
        // else if (Array.isArray(value)) {
        //   const { quantity, rate } = value[0];
        //   this.setState({ quantity: quantity, rate: rate });
    
    
        //   const qty = parseFloat(quantity);
        //   const rateValue = rate ? parseFloat(rate.replace(/,/g, '')) : 0;
    
        //   console.log("Quantity:", qty);
        //   console.log("Rate:", rateValue);
    
        //   const amount = Math.round((qty * rateValue) * 100) / 100;
        //   console.log("Amount:", amount);
    
        //   this.setState({ amount: amount });
    
        //   const taxRate = 0.12;
        //   const taxAmount = amount * taxRate;
        //   const roundedTaxAmount = Math.round(taxAmount * 100) / 100;
    
        //   // Update state with tax amount
        //   this.setState({ taxAmount: roundedTaxAmount.toString() });
    
        //   // Calculate total amount (including tax)
        //   const totalAmount = amount + taxAmount;
        //   console.log("Total Amount:", totalAmount);
    
        //   // Update state and push to data object
        //   this.setState({ totalAmount: totalAmount.toString() });
        //   // Assuming your data object is named 'data'
        //   this.setState(prevState => ({
        //     data: {
        //       ...prevState.data,
        //       total: totalAmount.toString()
        //     }
        //   }));
    
        // } 
        else if (Array.isArray(value)) {
          let totalAmount = 0; // Initialize total amount
    
          value.forEach(item => {
            const { quantity, rate } = item;
            const parsedQuantity = parseFloat(quantity);
            const parsedRate = rate ? parseFloat(rate.replace(/,/g, '')) : 0;
    
            // Calculate amount for the current data item
            const amount = parsedQuantity * parsedRate;
    
            // Add the amount to the total
            totalAmount += amount;
          });
    
          console.log("Total Amount:", totalAmount);
    
          // Update state with total amount
          this.setState({
            amount: totalAmount,
            taxAmount: (totalAmount * 0.12).toFixed(2), // Calculate tax amount (assuming 12% tax rate)
            totalAmount: (totalAmount * 1.12).toFixed(2), // Calculate total amount including tax
          });
    
          // Assuming your data object is named 'data'
          this.setState(prevState => ({
            data: {
              ...prevState.data,
              total: (totalAmount * 1.12).toFixed(2), // Update total in data object including tax
            }
          }));
        }
        
        // else if (field === "ASF" ) {
        //   const discountValue = parseFloat(value) || 0; // Convert discount value to a number
        //   const { amount, taxAmount } = this.state; // Get current amount and tax amount
    
        //   const discountedAmount = amount - discountValue;
    
        //   const totalAmount = discountedAmount + parseFloat(taxAmount);
        //   this.setState({
        //     totalAmount: totalAmount.toFixed(2),
        //   });
    
        //   console.log("Discount Value:", discountValue);
        //   console.log("Amount (Before Discount):", amount);
        //   console.log("Tax Amount:", taxAmount);
        //   console.log("Discounted Amount:", discountedAmount);
        //   console.log("Total Amount (After Discount):", totalAmount);
        // }
    
        else if (field === "discount") {
          const discountValue = parseFloat(value) || 0; // Convert discount value to a number
          const { amount, taxAmount } = this.state; // Get current amount and tax amount
    
          const discountedAmount = amount - discountValue;
    
          const totalAmount = discountedAmount + parseFloat(taxAmount);
          this.setState({
            totalAmount: totalAmount.toFixed(2),
          });
    
          console.log("Discount Value:", discountValue);
          console.log("Amount (Before Discount):", amount);
          console.log("Tax Amount:", taxAmount);
          console.log("Discounted Amount:", discountedAmount);
          console.log("Total Amount (After Discount):", totalAmount);
        }
        else if (field === "estimate_type") {
          const estimate = "event"; // Set your desired static value here
      
          console.log("Setting estimate_type to:", estimate);
      
          this.setState({
              estimate_type: estimate,
          });
      }
      
        else {
         
          this.setState({ [field]: value });
          
          
        }
        this.presenter.onChange(value, field);
      }
    
    
    
      handleProjectChange = (selectedProjectId) => {
        console.log("handleProjectChange - selected project ID:", selectedProjectId);
    
        // Find the selected project from the list of all projects
        const selectedProject = this.state.filtered.find(project => project.id === selectedProjectId);
    
        console.log("handleProjectChange - selected project:", selectedProject);
    
        // Update the selected project if found, or handle the case when no project is found
        if (selectedProject) {
          // Do something with the selected project, e.g., update state
          this.setState({ selectedProject }); // Set the selected project
          console.log("Project name selected:", selectedProject.name);
          this.onChange(selectedProject.name, "project_name"); // Pass the project name to onChange
        } else {
          // Handle the case where no project is found
          console.log("No project found with ID:", selectedProjectId);
          this.setState({ selectedProject: null });
        }
      };
    
    
      handleDiscountType(type) {
        this.setState({ discountType: type });
      }

    render() {
        const formatCurrency = (amount) => {
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "PHP",
            }).format(amount);
          }
      
      
          const object = this.state.object;
          const user = this.getCurrentRoles();
          const schema = this.getSchema(this.getCollectionName());
          const { selectedOption, selectedOption2, selectedProject, discountType } = this.state;
          if (!schema) return <Progress />;
          console.log( "schema" ,schema);
          console.log( "object" ,object);
          const currentDate = this.getCurrentDateFormatted();

          const { estimate_type } = this.state.object;

        return (
            <>
            <NavBar />
            <div className="overflow-auto" >
              <div className="p-3 p-lg-4">
                <div className="row align-items-center">
                  <div className="col-auto d-flex align-items-center">
                    <button type="button" className="btn btn-link" style={{ padding: "0" }}>
                      <i className="bi bi-arrow-left fs-4" onClick={this.onClickBack.bind(this)} style={{ color: "#DAB451", margin: "0" }}></i>
                    </button>
                  </div>
                  <div className="col">
                    <h1 className="fw-bold mt-3 text-capitalize">{estimate_type}</h1>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-auto d-flex align-items-center">
                    <h6 className="fw-normal mt-3 text-sm" style={{ paddingLeft: '50px', color: '#434343', opacity: '0.5' }}>Select service type and enter data to the required fields.</h6>
                  </div>
                </div>
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row align-items-center" style={{ paddingLeft: '50px', paddingTop: "10px", width: "50%" }}>
                    {/* <div className=" col mb-3">
                    <label className="form-label">Select Client</label>
                    <InputFactory
                      type="UserInputPointer"
                      dynamic={false}
                      target="client"
                      field="client_name"
                      object={object}
                      placeholder="Select Client"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="col mb-3">
                    <label className="form-label">Select Project</label>
                    <InputFactory
                      type="UserInputPointer"
                      dynamic={false}
                      target="projects"
                      field="project_name"
                      object={object}
                      placeholder="Select Project"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div> */}
                  </div>
    
    
    
                  <div className="row align-items-center" style={{ paddingLeft: '50px', paddingTop: "10px", width: "100%" }}>
    
                    {/* <div className="mb-3">
                          <label className="form-label">Items</label>
                          <InputFactory2
                            type="Relation"
                            _type="Related"
                            field="items"
                            target="items"
                            object={object}
                            onChange={this.onChange.bind(this)}
                            required={true}
                            disabled={
                              object.statuses === "Approved" ||
                              object.statuses === "Sent" ||
                              object.statuses === "Paid"
                            }
                            excludeFields={["supplier", "tin#", "or#","Chart of Accounts"]}
                          />
                        </div> */}
                    {/* <div className="mb-3">
                          <label className="form-label">Chart of Accounts</label>
                          <InputFactory
                            type="ModdedInputPointer"
                            target="chart_of_accounts"
                            field="chart_of_accounts"
                            object={object}
                            placeholder="Select Chart of Accounts"
                            onChange={this.onChange.bind(this)}
                            required={true}
                            disabled={
                              object.statuses === "Approved" ||
                              object.statuses === "Sent" ||
                              object.statuses === "Paid"
                            }
                          />
                        </div> */}
    
    
    
    
                    {/* Client to Services */}
    
                    <div className="row" style={{ width: "50%" }}>
                      <div className="col">
                        <label className="form-label">Client Name</label>
                        <InputFactory
                          type="UserInputPointer"
                          dynamic={false}
                          target="clients"
                          field="client_name"
                          object={object}
                          placeholder="Select Client Name"
                          onChange={this.onChange.bind(this)}
                          required={true}
                          disabled={
                            object.statuses === "Approved" ||
                            object.statuses === "Sent" ||
                            object.statuses === "Paid"
                          }
                        />
                      </div>
                      <div className="col">
                        <label className="form-label">Project Name</label>
                        <Drop
                          placeholder={selectedProject ? "Select a project" : object.project_name?.name}
                          value={selectedProject ? selectedProject : null}
                          options={this.state.filtered}
                          onChange={(selectedValue) => this.handleProjectChange(selectedValue)} // Pass the selected value directly
                        />
                      </div>
                    </div>
    
                      <InputFactory2
                        type="Relation"
                        _type="Related"
                        field="estimate_data"
                        target="estimate_data"
                        object={object}
                        onChange={this.onChange.bind(this)}
                        required={true}
                        excludeFields={[
                          "estimate_type"
                          // "amount"
                        ]}
                    />
                    {/* <label className="form-label">AMOUNT</label>
                      <InputFactory
                      type="String"
                      field="amount"
                      target="estimate_data"
                      object={object}
                      onChange={this.onChange.bind(this)}
                      required={true}
                      value={this.state.totalAmount}
                      excludeFields={[
                      ]}
                    /> */}
    
    
    
    
                    {/* <div className="mb-3">
                        <label className="form-label">Total: </label>
                        {formatCurrency(this.state.amount)}
                    </div> */}
    
    
    
                  </div>
    
    
                  <div className='container d-flex justify-content-end' style={{ width: "100%" }}>
                    <div className="card" style={{ width: "75%" }} >
                      <div className='row d-flex justify-content-between' style={{ padding: "20px" }}>
                        <div className='col' style={{ width: "50%" }}>
                          <ul className="list-unstyled ms-1 text-truncate" >
                            <li style={{ padding: "5px" }}>
                              <span className="ms-2 fw-light fw-bold">Exclusive Items</span>
                              <InputFactory
                                type="String"
                                dynamic={false}
                                target="estimate_data"
                                field="exclusive_item"
                                object={object}
                                placeholder="Enter Exclusive Items..."
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </li>
                            <li style={{ padding: "5px" }}>
                              <span className="ms-2 fw-light fw-bold">Notes</span>
                              <InputFactory
                                type="String"
                                dynamic={false}
                                target="estimate_data"
                                field="notes"
                                object={object}
                                placeholder="Enter Notes..."
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </li>
                            <li style={{ padding: "5px" }}>
                              <span className="ms-2 fw-light fw-bold">ASF</span>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <select
                                  className="form-control"
                                  value={selectedOption2}
                                  onChange={this.onChangeSelect2.bind(this)}
                                  required={true}
                                >
                                  <option value="">Select</option>
                                  <option field="type_of_discount" value="Percent">Percent</option>
                                  <option field="type_of_discount" value="Value">Value</option>
                                </select>
    
                                {/* Render InputFactory based on selected option */}
                                {selectedOption2 === "Percent" && (
                                  <InputFactory
                                    type="Number"
                                    dynamic={false}
                                    target="estimate_data"
                                    field="ASF"
                                    object={object}
                                    placeholder="%"
                                    onChange={this.onChange.bind(this)}
                                    required={true}
                                  />
                                )}
                                {selectedOption2 === "Value" && (
                                  <InputFactory
                                    type="Number"
                                    dynamic={false}
                                    target="estimate_data"
                                    field="ASF"
                                    object={object}
                                    placeholder="Enter Value..."
                                    onChange={this.onChange.bind(this)}
                                    required={true}
                                  />
                                )}
    
                                {/* <input className="form-control" type="number" placeholder='%' style={{ marginLeft: "5px" }} /> */}
                              </div>
    
                            </li>
                          </ul>
                        </div>
                        {/* <div className='col' style={{ width: "50%" }}>
                        <ul className="list-unstyled ms-1 text-truncate">
                          <li style={{ paddingBottom: "100px" }}>
                            <span className="ms-2 fw-light fw-bold">Date</span>
                            <InputFactory
                              type="Date"
                              field="due_date"
                              object={object}
                              onChange={this.onChange.bind(this)}
                              required={true}
                              min={currentDate}
                            />
                          </li>
                        </ul>
                      </div> */}
                        <div className='col' >
                          <ul className="list-unstyled ms-1 text-truncate">
    
                            <li style={{ padding: "5px" }}>
                              <span className="ms-2 fw-light fw-bold">Discount</span>
                              <div>
                                <select
                                  className="form-control"
                                  value={selectedOption}
                                  onChange={this.onChangeSelect.bind(this)}
                                  required={true}
                                >
                                  <option value="">Select Discount</option>
                                  <option field="type_of_discount" value="Discount Percent">Discount Percent</option>
                                  <option field="type_of_discount" value="Discount Value">Discount Value</option>
                                </select>
                                <br />
    
    
                                {selectedOption === "Discount Percent" && (
                                  <InputFactory
                                    type="Number"
                                    dynamic={false}
                                    target="estimate_data"
                                    field="discount"
                                    object={object}
                                    placeholder="Enter Discount Percentage..."
                                    onChange={this.onChange.bind(this)}
                                    required={true}
                                  />
                                )}
                                {selectedOption === "Discount Value" && (
                                  <InputFactory
                                    type="Number"
                                    dynamic={false}
                                    target="estimate_data"
                                    field="discount"
                                    object={object}
                                    placeholder="Enter Discount Value..."
                                    onChange={this.onChange.bind(this)}
                                    required={true}
                                  />
                                )}
    
    
                              </div>
    
    
                            </li>
                            <ul className="list-unstyled ms-1 text-truncate" style={{ width: "100%" }}>
                              <li style={{ padding: "5px" }}>
                                <div className="d-flex justify-content-between align-items-center" >
                                  <span className="bold" style={{ paddingRight: "20px" }}>Subtotal</span>
                                  <span className="bold" style={{ color: "#D8AE3F" }}>{formatCurrency(parseFloat(this.state.amount))}</span>
    
                                </div>
                              </li>
                              <li style={{ padding: "5px" }}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="bold">Tax %</span>
                                  <span className="bold" style={{ color: "#D8AE3F" }}>{formatCurrency(parseFloat(this.state.taxAmount))}</span>
                                </div>
                              </li>
                              <li style={{ padding: "5px" }}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="bold">Total</span>
                                  <span className="bold" style={{ color: "#D8AE3F" }}> {formatCurrency(parseFloat(this.state.totalAmount))} </span>
    
                                </div>
                              </li>
    
                            </ul>
                            <div style={{ paddingTop: "10px" }}>
                              <button
                                className="btn btn-main ms-1"
                                type="submit"
                                style={{ backgroundColor: "#E8E8E8", height: "40px", width: "140px", border: "none", fontSize: "18px", borderRadius: "5px", marginRight: "10px" }}>
                                Cancel
                              </button>
                              <button
                                className="btn btn-main ms-1"
                                type="submit"
                                style={{ backgroundColor: "#DAB451", height: "40px", width: "140px", border: "none", fontSize: "18px", borderRadius: "5px" }}>
                                Save
                              </button>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        );
    }
}

export default withRouter(EditEstimatePage);


