import BaseListPresenter from "../../../base/BaseListPresenter";
import Papa from "papaparse";

class TransactionTablePresenter extends BaseListPresenter {
  constructor(view, findObjectUseCase, countObjectUseCase, upsertUseCase) {
    super(view, findObjectUseCase, countObjectUseCase, upsertUseCase);
    this.upsertUseCase = upsertUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: 5,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  onClick() {
    this.view.navigateTo("/accounts/transactions");
  }
}

export default TransactionTablePresenter;
