import { dialog } from "nq-component";
import BaseFormPresenter from "../../../../base/BaseFormPresenter";
import classNames from "../../../../classNames";
import { findObjectUseCase } from "../../../../usecases/object";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};
class ProjectPrintPresenter extends BaseFormPresenter {
  onClickExport() {
    this.view.exportPDF();
  }
}

export default ProjectPrintPresenter;
