import React from "react";
import InputFactory from "../../../components/InputFactory";

export default function Adjustments({
  object,
  onIncreasesAndDeductionsChange,
  setEmployeeIncreasesAndDeductsObject,
}) {
  return (
    <>
      <div className="row">
        <div className="col">
          <p
            className="fw-semibold mt-5 col"
            style={{ fontSize: "1.25rem", color: "#434343" }}
          >
            Adjustments
            <span className="ms-2 text-success ">(+)</span>
          </p>
        </div>
        <div className="col d-flex justify-content-end align-align-items-center">
          <p
            className="fw-semibold mt-5"
            onClick={() => {
              const newObject = {
                ...object,
                adjustments: [
                  ...(object?.adjustments || []),
                  { adjustmentDescription: "", adjustmentAmount: "" },
                ],
              };

              setEmployeeIncreasesAndDeductsObject(newObject);
            }}
            style={{
              fontSize: "1rem",
              color: "#DAB44E",
              textAlign: "end",
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            Add
          </p>
        </div>
      </div>
      <div
        className="col rounded m-0 p-3 ps-5 pe-5"
        style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)" }}
      >
        {object?.adjustments?.map((item, i) => {
          return (
            <div key={item} className="row mt-3 mb-4 align-items-center ">
              <div className="col-5">
                <InputFactory
                  type="String"
                  object={item}
                  field="adjustmentDescription"
                  onChange={onIncreasesAndDeductionsChange}
                  placeholder="Description..."
                />
              </div>
              <div className="col-5">
                <InputFactory
                  type="Peso"
                  object={item}
                  field="adjustmentAmount"
                  onChange={onIncreasesAndDeductionsChange}
                />
              </div>
              <div className="col-2">
                <i
                  onClick={() => {
                    const newObject = {
                      ...object,
                      subtotal:
                        parseInt(object.subtotal) -
                        object.adjustments[i].adjustmentAmount,
                      total:
                        object.total - object.adjustments[i].adjustmentAmount,
                      adjustments: object.adjustments.filter(
                        (_, index) => index !== i
                      ),
                    };

                    setEmployeeIncreasesAndDeductsObject(newObject);
                  }}
                  class="bi bi-trash3-fill text-danger"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
