import BaseListPresenter from "../../base/BaseListPresenter";
import { dialog } from "nq-component";
import ModdedFormDialog from "../../components/ModdedFormDialog";

class InvoicesEditPaymentPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    deleteObjectUseCase,
    upsertUseCase,
    exportCSVUseCase,
    addSchemaUseCase,
    updateSchemaUseCase,
    deleteSchemaUseCase
  ) {
    super(view, findObjectUseCase, countObjectUseCase, deleteObjectUseCase);
    this.upsertUseCase = upsertUseCase;
    this.exportCSVUseCase = exportCSVUseCase;
    this.addSchemaUseCase = addSchemaUseCase;
    this.updateSchemaUseCase = updateSchemaUseCase;
    this.deleteSchemaUseCase = deleteSchemaUseCase;
  }

  componentDidUpdate(prevProps) {
    const prevClassName = prevProps.params.name;
    const newClassName = this.view.getCollectionName();
    //if collection change
    if (prevClassName !== newClassName) {
      this.init();
      this.getObjects();
    }
  }

  formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
    }).format(amount);
  };
  handleRefund(index, filteredObjects) {
    dialog.fire({
      html: (
        <div className="p-4">
          <h3 style={{ color: "#BB1C1C" }}>Refund Payment</h3>
          <div className="border border-light mb-4 p-4 rounded d-flex align-items-center justify-content-center">
            <p className="fs-3" style={{ marginRight: "7px" }}>
              {filteredObjects[index].reference_number}
            </p>
            <p className="fs-3" style={{ marginRight: "6px" }}>
              (
              <span style={{ color: "#D8AE3F" }}>
                {this.formatCurrency(filteredObjects[index].amount)}
              </span>
              )
            </p>
            <p className="fs-3" style={{ marginRight: "5px" }}>
              to [
              <span style={{ color: "#CF6F00" }}>
                {filteredObjects[index].account?.name}
              </span>
              ]
            </p>
          </div>
          <p className="m-0 fs-5">
            Are you sure you want to refund this payment?
          </p>
          <p className="m-0 text-danger">This action cannot be undone</p>
          <div className="d-flex justify-content-center mt-4">
            <button
              style={{ background: "#E8E8E8" }}
              className="btn btn-secondary fs-5 me-2 p-2"
              onClick={() => dialog.close()}
            >
              Cancel
            </button>
            <button
              style={{ background: "#DAB451", color: "black" }}
              className="btn btn-primary fs-5 p-2s"
              onClick={() => this.handleSubmit(index, filteredObjects)}
            >
              Refund
            </button>
          </div>
        </div>
      ),
      size: "xl",
      footer: false,
    });
  }

  async handleSubmit(index, filteredObjects) {
    const change = filteredObjects[index];
    const cashInBank = await this.findObjectUseCase.execute("gmd_accounts");
    const combineBal = cashInBank.reduce(
      (total, account) => total + account.balance,
      0
    );

    const invoices = await this.findObjectUseCase.execute("invoices");
    const invNum = invoices.find(
      (inv) => inv.invoice_num === change.invoice_num
    );

    const mio = {
      id: "f3a5c4d2-cbd0-4305-9835-5c8c75de59c4",
      type: "Money Out",
    };

    const transaction = {
      account_balance: combineBal - change.amount,
      types: mio,
      account: change.account,
      amounts: String(change.amount),
      note: "Refund_Reference No." + change.reference_number,
      //   project_name: change.project_name,
    };
    const gmd = {
      name: change.account?.name,
      balance: change.account.balance - change.amount,
      id: change.account.id,
    };

    const invo = invNum;
    invo.received = invo.received - change.amount;
    invo.collectibles = invo.collectibles + change.amount;

    this.upsertUseCase.execute("invoices", invo);
    this.upsertUseCase.execute("transaction", transaction);
    this.upsertUseCase.execute("gmd_accounts", gmd);
    this.deleteObjectUseCase.execute("payvoice", change.id);

    dialog.close();
    this.view.submitting();

    this.view.submissionSuccess();
    await this.view.showSuccess("", "Refund Successful");
    await this.view.navigateTo("/invoices");
  }
}

export default InvoicesEditPaymentPresenter;
