import React from "react";

export default function PayslipEmployeeInfo({
  employeeName,
  employeeID,
  position,
}) {
  return (
    <div
      style={{ flex: 3, border: "1px solid #e9983d", height: "fit-content" }}
    >
      <div style={{ background: "#e9983d", padding: ".1rem 1rem" }}>
        <p style={{ fontWeight: "bold" }}>Employee</p>
      </div>
      <div
        style={{
          padding: ".1rem 1rem",
        }}
      >
        <div>
          <p style={{ fontWeight: "bold" }}>{employeeName || "None"}</p>
          <p>{employeeID || "None"}</p>
          <p>{position || "None"}</p>
        </div>
      </div>
    </div>
  );
}
