class SignInPresenter {
  constructor(view, signInUseCase) {
    this.view = view;
    this.signInUseCase = signInUseCase;
    this.change = {};
  }

  onChange(value, field) {
    this.change[field] = value;
  }

  submit() {
    // const masterKey =
    //   this.view.getMasterKey() || "0e5ac421-a045-49cb-98aa-2f772f0781a0";
    const masterKey = "0e5ac421-a045-49cb-98aa-2f772f0781a0";
    const user = {
      ...this.change,
      masterKey,
    };
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.signInUseCase.execute(user))
      .then((user) => {
        const roles = user.roles.map((role) => role.name);
        if (roles.includes("masterAdmin") || roles.includes("admin")) {
          this.view.navigateTo("/accounts");
        } else {
          this.view.navigateTo("/budget-request");
        }
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }
}

export default SignInPresenter;
