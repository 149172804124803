import React, { useState, useEffect } from "react";
import InputFactory from "../../components/InputFactory";

const InvoTable = ({ object, disc, amount, onChange, onUpdateServices, onDeleteService }) => {
  const [services, setServices] = useState([{ service: "", name: "", description: "", quantity:"", rate:""}]);
console.log(object)
  useEffect(() => {
    if (object?.services) {
      const activeServices = Object.values(object.services).filter(service => !service.deleted);
      setServices(activeServices);
    }
  }, [object?.services]);

  const handleAddService = () => {
    const newService = { service: "", name: "", description: "", quantity: 0, rate: 0 };
    const updatedServices = [...services, { ...newService }];
    setServices(updatedServices);
    onUpdateServices(updatedServices); 
  };

  const handleDeleteService = (index) => {
    const updatedServices = [...services];
    updatedServices[index].deleted = true; 
    const updatedObject = {
      ...object,
      services: updatedServices.filter(service => !service.deleted)
    };
    console.warn(updatedServices, updatedObject)
    setServices(updatedServices.filter(service => !service.deleted)); // Update state
    onUpdateServices(updatedObject);
  };

  const handleChange = (index, field, value) => {
    const updatedServices = services.map((service, i) => {
      if (i === index) {
        return { ...service, [field]: value };
      }
      return service;
    });
  
    const updatedObject = {
      ...object,
      services: updatedServices
    };
  
    setServices(updatedServices); 
    onUpdateServices(updatedObject); 
  };

  const renderServiceRows = () => {
    return services.map((service, index) => (
      <tr key={index}>
        <td className="border-end">
          <div className="d-flex align-items-center">
              {services.length > 1 && !(object.statuses === "Approved" || object.statuses === "Paid" || object.statuses === "Partially Paid") && (
                <span
                  className="bi bi-trash me-2"
                  onClick={() => handleDeleteService(index)}
                  style={{ cursor: "pointer" }}
                ></span>
              )}

            <InputFactory
              type="ModdedInputService"
              dynamic={false}
              target="services_list"
              field="service"
              object={service}
              placeholder="Enter Service"
              required={true}
              onChange={(value) => handleChange(index, "service", value)}
              disabled={
                object.statuses === "Approved" ||
                object.statuses === "Paid" ||
                object.statuses === "Partially Paid"
              }
            />
          </div>
        </td>
        <td className="border-end">
          <InputFactory
            type="String"
            dynamic={false}
            field="name"
            object={service}
            placeholder="Enter Name"
            onChange={(value) => handleChange(index, "name", value)}
            disabled={
              object.statuses === "Approved" ||
              object.statuses === "Paid" ||
              object.statuses === "Partially Paid"
            }
          />
        </td>
        <td className="border-end">
          <InputFactory
            type="String"
            dynamic={false}
            field="description"
            object={service}
            placeholder="Enter Description"
            onChange={(value) => handleChange(index, "description", value)}
            disabled={
              object.statuses === "Approved" ||
              object.statuses === "Paid" ||
              object.statuses === "Partially Paid"
            }
          />
        </td>
        <td className="border-end">
          <InputFactory
            type="Number"
            dynamic={false}
            field="quantity"
            object={service}
            placeholder="Enter Qty."
            onChange={(value) => handleChange(index, "quantity", value)}
            disabled={
              object.statuses === "Approved" ||
              object.statuses === "Paid" ||
              object.statuses === "Partially Paid"
            }
          />
        </td>
        <td className="border-end">
          <InputFactory
            _type="parseNumber"
            type="String"
            dynamic={false}
            field="rate"
            object={service}
            placeholder="Enter Rate"
            onChange={(value) => handleChange(index, "rate", value)}
            disabled={
              object.statuses === "Approved" ||
              object.statuses === "Paid" ||
              object.statuses === "Partially Paid"
            }
          />
        </td>
        <td className="border-end">
          <InputFactory
            type="parseNumber"
            dynamic={false}
            field="amount"
            value={(parseFloat(service?.quantity?.toString().replace(/,/g, '')) * parseFloat(service?.rate?.toString().replace(/,/g, ''))) || ''}
            object={service}
            placeholder="Enter Amount"
            disabled={true}
          />
        </td>
      </tr>
    ));
  };

  return (
    <>
      {services.length > 0 && (
        <table className="table mt-3">
          <thead style={{ textAlign: "center" }}>
            <tr>
              <th style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>SERVICE</th>
              <th style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>NAME</th>
              <th style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>DESCRIPTION</th>
              <th style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>QTY</th>
              <th style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>RATE</th>
              <th style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>AMOUNT</th>
            </tr>
          </thead>
          <tbody>{renderServiceRows()}</tbody>
        </table>
      )}
      {!(object.statuses === "Approved" || object.statuses === "Paid" || object.statuses === "Partially Paid") && (
        <button
          className="btn d-flex justify-content-center align-items-center"
          type="button"
          onClick={handleAddService}
        >
          <span className="bi bi-plus-circle-fill text-danger me-1 ms-2"></span>
          <span className="text-danger ms-2">Add Service</span>
        </button>
      )}

    </>
  );
};

export default InvoTable;
