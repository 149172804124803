import React from "react";
import InputFactory from "../../../components/InputFactory";

export default function SearchFilter({ filter, onChangeFilter }) {
  const { type, ...options } = filter;

  return (
    <div className="position-relative d-flex align-items-center w-45">
      <i class="bi bi-search position-absolute" style={{ left: "1rem" }}></i>
      <InputFactory
        type={type}
        field="fullName"
        onChange={(value, field) => onChangeFilter(type, value, field)}
        className="rounded-pill ps-5 text-light-emphasis"
        {...options}
      />
    </div>
  );
}
