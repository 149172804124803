import React from "react";

export default function PayslipHeader() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 3,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontWeight: "bold" }}>GMD Productions Inc.</div>
          <div>32 Unit H Santan Street, Barangay Fortune, Marikina City</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>Mobile No: 0917 583 7654/</div>
          <div>Office No: 7092-8807/</div>
          <div>Website: https://www.gmd.ph/</div>
        </div>
      </div>
      <div style={{ flex: 1, display: "flex", justifyContent: "end" }}>
        <img src="/gmd-logo.png" alt="GMD Logo" style={{ width: "10rem" }} />
      </div>
    </div>
  );
}
