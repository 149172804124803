import toast, { Toaster } from "react-hot-toast";
import BaseFormPage from "../../../base/BaseFormPage";
import { getObjectUseCase, upsertUseCase } from "../../../usecases/object";
import { restUseCase } from "../../../usecases/rest";
import PayrollFormPresenter from "./PayrollFormPresenter";
import NavBar from "../../../components/navbar";
import CreatePayrollForm from "../components/CreatePayrollForm";
import withRouter from "../../../withRouter";
import createPromise from "../../../createPromise";
import dialog from "../../../components/Modal/dialog";
import AddPayroll from "../components/modals/AddPayroll";

class PayrollFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = { object: {} };
    this.presenter = new PayrollFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase()
    );
  }

  getCollectionName() {
    return "payroll";
  }

  getExcludedFields() {
    return [
      "id",
      "createdAt",
      "updatedAt",
      "acl",
      "total",
      "status",
      "chartOfAccounts",
      "bank",
      "dateCreated",
      "project_name",
    ];
  }

  showToast(message) {
    toast(message, {
      position: "bottom-right",
      style: {
        background: "#4FBC4C",
        color: "white",
        fontWeight: "600",
      },
    });
  }

  showAddPayrollDialog() {
    const promise = createPromise();
    dialog.fire({
      html: <AddPayroll />,
      positiveButton: "Add",
      negativeButton: "Cancel",
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
    });
    return promise;
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { object } = this.state;

    return (
      <>
        <NavBar />
        <Toaster />
        <div
          className="container-fluid h-100"
          style={{
            paddingInline: "3rem",
            paddingBlock: "2rem",
            background: "white",
          }}
        >
          <div className="row align-items-center ">
            <i
              class="bi bi-chevron-left col-auto btn btn-link"
              onClick={this.onClickBack.bind(this)}
            ></i>
            <p className="fs-3 fw-bold col-auto m-0">Add New Payroll</p>
          </div>
          <form onSubmit={this.onSubmitForm.bind(this)}>
            <CreatePayrollForm
              fields={schema.fields}
              object={object}
              onClickBack={this.onClickBack.bind(this)}
              onChange={this.onChange.bind(this)}
              excludedFields={this.getExcludedFields()}
            />
          </form>
        </div>
      </>
    );
  }
}

export default withRouter(PayrollFormPage);
