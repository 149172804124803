// import React from "react";
// import classNames from "../../classNames";

// const noop = () => {};
// const defaultProps = {
//   type: "text",
//   strict: false,
//   onChange: noop,
// };

// function Parse({ className, strict, onChange, uppercase, ...props }) {
//   function onInput(e) {
//     const value = e.target.value;
//     const character = value.charAt(value.length - 1);
//     // only string
//     if (strict && !/^[a-zA-Z]*$/.test(character)) {
//       return;
//     }
//     if (uppercase) {
//       e.target.value = value.toUpperCase();
//     }
//     onChange(e.target.value);
//   }
//   return (
//     <div>
//       <input
//         className={classNames("form-control")}
//         onInput={onInput}
//         {...props}
//       />
//       <br />
//     </div>
//   );
// }
// Parse.defaultProps = defaultProps;
// export default Parse;
import React from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  type: "text",
  strict: false,
  onChange: noop,
};

function Parse({ className, type, strict, onChange, uppercase, ...props }) {
  // Function to format numbers with commas
  const formatNumber = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  function onInput(e) {
    let value = e.target.value;

    // Handling for number type
    if ("number" === "number") {
      // Strip non-numeric characters except for the decimal point
      value = value.replace(/[^\d.]/g, "");
      value = formatNumber(value);
    } else {
      // Handling for string input
      const character = value.charAt(value.length - 1);
      if (strict && !/^[a-zA-Z]*$/.test(character)) {
        return;
      }
    }

    if (uppercase) {
      value = value.toUpperCase();
    }

    e.target.value = value;
    onChange(value);
  }

  return (
    <div>
      <input
        className={classNames("form-control", className)}
        onInput={onInput}
        {...props}
      />
      <br />
    </div>
  );
}
Parse.defaultProps = defaultProps;
export default Parse;
