export default function DeleteButton({ selected, onDelete }) {
  if (selected?.length < 1) return null;

  return (
    <div className="col">
      <button className="btn btn-danger float-end ps-4 pe-4" onClick={onDelete}>
        <i class="bi bi-trash3"></i> Delete
      </button>
    </div>
  );
}
