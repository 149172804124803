import InputFactory from "../../../components/InputFactory";

export default function CreateTabForm({ fields, includedFields, object }) {
  return (
    <div className="w-100 mt-5">
      <div className="col p-0">
        {includedFields.map((field) => {
          const { type, ...options } = fields[field];

          return (
            <div className="row m-0 p-0 mb-4" key={field}>
              <label
                class="form-label fw-semibold"
                style={{ fontSize: "0.875rem", color: "#434343" }}
              >
                {options.label}
              </label>

              <div className="col">
                <InputFactory
                  field={field}
                  key={field}
                  object={object}
                  type={type}
                  disabled
                  {...options}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
