import React from "react";
import classNames from "../../../classNames";

const DeleteProjectDialogContent = ({ object, onDelete, onCancel }) => (
  <div className="text-end">
    <div className="text-center">
      <i
        className={classNames("bi bi-file-earmark-x", "text-danger")}
        style={{ fontSize: "5rem" }}
      ></i>
      <h4 className="fw-bold">Delete Project</h4>
      <p>
        {object.name} (
        <span style={{ color: "#D8AE3F" }}>{object.client.name}</span>)
      </p>
      <p className="m-0">
        Are you sure you want to delete this project? This action cannot be
        reverse.
      </p>
      <button className="btn btn-danger mb-2 mt-2 me-2" onClick={onDelete}>
        Delete
      </button>
      <button className="btn mb-2 mt-2 btn-light" onClick={onCancel}>
        Cancel
      </button>
    </div>
  </div>
);

export default DeleteProjectDialogContent;
