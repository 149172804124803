import { browseFile } from "nq";
import BaseListPresenter from "../../../base/BaseListPresenter";
import Papa from "papaparse";

class CashPaidOutPresenter extends BaseListPresenter {
    constructor(view, findObjectUseCase, countObjectUseCase, upsertUseCase) {
        super(view, findObjectUseCase, countObjectUseCase, upsertUseCase);
        this.upsertUseCase = upsertUseCase;
    }

    init() {
        this.limit = 20;
        this.where = {};
        this.search = {};
        this.filter = {};
        this.filter2 = {};
        this.filter3 = {};
        this.filterDate = {};
        this.filterList = {};
        this.filterEmployee = {};
        this.include = ["all"];
        this.keys = undefined; // if keys are specified, only those keys will be returned
        this.sort = { createdAt: -1 };
        this.progress = true;
        this.reset();
    }

    createQuery() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const bankNameFromURL = urlSearchParams.get("bank");
        const skip = (this.current - 1) * this.limit;
        const query = {
            limit: this.limit,
            skip: skip,
            where: {
                ...this.where,
                ...this.search,
                ...this.filter,
                ...this.filter2,
                ...this.filterDate,
                ...this.filter3,
                ...this.filterList,
                ...(bankNameFromURL ? { account: { name: bankNameFromURL } } : {}),
            },
            include: this.include,
        };
        if (this.sort) {
            query.sort = this.sort;
        }
        const keys = this.keys || this.view.getKeys() || [];
        if (keys.length > 0) {
            query.keys = keys;
        }
        return query;

    }

    filterListing(where) {
        this.reset();
        this.filterList = where;
        this.getObjects();
    }

    filterSubmit2(where) {
        this.reset();
        this.filter2 = where;

        this.getObjects();
    }

    filterSubmit3(where) {
        this.reset();
        this.filter3 = where;
        this.getObjects();
    }

    onChangeDate(where) {
        this.filterDate = where;
        this.getObjects();
    }

    onChangeEmployee(where) {
        this.filterEmployee = where;
        this.getObjects();
    }

    onClickAdd() {
        // const collection = this.view.getCollectionName();
        // this.view.navigateTo("/form/expenses/" + collection);
        this.view.navigateTo("/accounts/new-transaction");
    }

    onClickImport(file) {
        this.view.showProgress();
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const csvText = e.target.result;
                const data = this.parseCSV(csvText);
                console.log("type", data);
                // this.saveObjects(data);
            } catch (error) {
                console.error("Error processing the CSV file:", error);
                alert("An error occurred while reading the CSV file.");
            }
        };
        reader.readAsText(file);
    }

    createCSVQuery() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const bankNameFromURL = urlSearchParams.get("bank");
        const skip = (this.current - 1);
        const query = {
            skip: skip,
            where: {
                ...(bankNameFromURL ? { account: { name: bankNameFromURL } } : {}),
                ...this.filterDate
            },
            include: "all",
        };
        return query;

    }

    convertToCSV(objArray) {
        const array =
            typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
        let str =
            `${Object.keys(array[0])
                .map((value) => `"${value}"`)
                .join(",")}` + "\r\n";

        for (let i = 0; i < array.length; i++) {
            let line = "";
            for (let index in array[i]) {
                if (line !== "") line += ",";

                line += `"${array[i][index]}"`;
            }

            str += line + "\r\n";
        }

        return str;
    }

    exportToCSV = async () => {
        try {
            const dataName = "TotalCashPaidOut"
            const collection = "expense";
            const query = this.createCSVQuery()
            const objectCSV = await this.findObjectUseCase.execute(collection, query);

            const cashPaidOut = "CPO -";
            const cashPaidOutObjects = objectCSV.filter(item =>
                item.chart_accounts?.name.toLowerCase().includes(cashPaidOut.toLowerCase())
            );

            const cashPaidOutData = cashPaidOutObjects.reduce((result, obj) => {
                const name = obj.chart_accounts?.name;
                const displayName = name && name.startsWith("CPO -") ? name.slice(5) : name;

                if (!result[displayName]) {
                    result[displayName] = { amount: obj.amount };
                } else {
                    result[displayName].amount += obj.amount;
                }
                return result;
            }, {});


            const data = Object.entries(cashPaidOutData).map(([name, data]) => ({
                A: name,
                B: data.amount
            }));

            const csvData = data.map(item => ({
                "": item.A,
                "Date": item.B
            }));

            const csvString = this.convertToCSV(csvData);
            const blob = new Blob([csvString], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = dataName + ".csv";
            a.click();

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error exporting to CSV:", error);
        }
    };

    onClick() {
        this.view.navigateTo("/cash-in-position");
    }
}

export default CashPaidOutPresenter;