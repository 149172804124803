import React from "react";
import {
  addSchemaUseCase,
  updateSchemaUseCase,
  deleteSchemaUseCase,
} from "../../usecases/schema/usecases";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import { exportCSVUseCase } from "../../usecases/csv/usecases";
import { Progress, InfiniteScroll, dialog } from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import InvoicesEditPaymentPresenter from "./InvoicesEditPaymentPresenter";
import Table from "../../components/Table";

class InvoicesEditPayment extends BaseListPage {
  constructor(props) {
    super(props);

    this.presenter = new InvoicesEditPaymentPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase(),
      upsertUseCase(),
      exportCSVUseCase(),
      addSchemaUseCase(),
      updateSchemaUseCase(),
      deleteSchemaUseCase()
    );
    this.state = {
      selected: [],
      objects: [],
      isFullyPaid: false,
      schema: [],
    };

    this.findObjectUseCase = findObjectUseCase();
    this.contractPDF = React.createRef();
  }

  getCollectionName() {
    return "payvoice";
  }
  handleRefund(index, filteredObjects) {
    this.presenter.handleRefund(index, filteredObjects);
  }

  render() {
    const { objects, selected, progress, isOpen } = this.state;
    const praps = this.props;

    const actionsList = [
      {
        label: "Refund",
        onClick: (index) => {
          this.handleRefund(index, filteredObjects);
        },
      },
    ];
    const schema = this.getSchema(this.getCollectionName());
    const filterObjects = (objects) => {
      return objects.filter((object) => object.invoice_num === praps.inv);
    };

    const filteredObjects = filterObjects(objects);
    console.log("filt", filteredObjects);
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    return (
      <>
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">Payments</h1>
              </div>
              <Table
                fields={schema.fields}
                objects={filteredObjects}
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "estimates",
                    "client_email",
                    "client_address",
                    "notes",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                // actionsList={actionsList}
                // actions={actions}
                onClickItem={this.onClickItem.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default InvoicesEditPayment;
