import React from "react";
import NavBar from "../../../components/navbar";
import { Button, InfiniteScroll, Progress } from "nq-component";
import BaseListPage from "../../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import InputFactory from "../../../components/InputFactory";
import DateRange from "../../../components/DateRange";

import Table from "../../../components/Table";
import TransactionPresenter from "./TransactionPresenter";
import printComponent from "../../../printComponent";
import { browseFile } from "nq";

class TransactionPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TransactionPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
    };
    this.contractPDF = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }

  setSelected(selected) {
    this.setState({ selected });
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (
      role.some((role) => role.name === "admin" || role.name === "masterAdmin")
    ) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({ created_by: { id: user?.id } });
    }
  }
  getCollectionName() {
    return "transaction";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeEmployee(where) {
    this.presenter.onChangeEmployee(where);
  }

  filterSubmit2(where) {
    this.presenter.filterSubmit2(where);
  }

  filterSubmit3(where) {
    this.presenter.filterSubmit3(where);
  }

  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    //console.log("filterSubmit2")
    this.filterSubmit2(where);
  }

  onChangeFilter3(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit3(where);
    //console.log("filterSubmit3")
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  // onClickImport() {
  //   this.presenter.onClickImport();
  // }

  onClickImport() {
    browseFile("*").then((files) => {
      if (files.length > 0) {
        const file = files[0];
        this.presenter.onClickImport(file);
      }
    });
  }
  onClick() {
    this.presenter.onClick();
  }

  onCollapse(index, object) {
    const role = this.getCurrentRoles().map((item) => item.name);
    // const formatCurrency = (amount) => {
    //   return new Intl.NumberFormat("en-US", {
    //     style: "currency",
    //     currency: "PHP",
    //   }).format(amount);
    // };
    const formatCurrency = (amount) => {
      if (typeof amount === "number") {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "PHP",
          minimumFractionDigits: 2, // Ensures two decimal places
          maximumFractionDigits: 2,
        }).format(amount);
      }
      return amount; // If it's not a number, return as is
    };

    //console.log("collap", object);
    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Note: </span>
              <span className="text-nowrap">{object.note}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">
                Money In/Money Out:{object?.types?.type}
              </span>
              <span className="text-nowrap">{object.type}</span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">Account Balance: </span>
              <span className="text-nowrap">
                {role.includes("masterAdmin")
                  ? formatCurrency(object.account_balance)
                  : "****"}
              </span>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
    );
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };
  exportPDF() {
    const objectCSV = this.state.objects;
    this.presenter.exportToCSV(objectCSV);
  }
  onClickExport() {
    const role = this.getCurrentRoles().map((item) => item.name);
    this.presenter.exportToCSV(role);
  }

  handleBankSelectionChange = (value) => {
    const selectedBank = value;
    const banker = "bank=" + selectedBank;
    switch (selectedBank) {
      case selectedBank:
        this.navigateTo("/accounts/history", banker);
        break;
      default:
    }
  };
  onChangeFilter6(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit6(where);
  }
  filterSubmit5(where) {
    this.presenter.filterSubmit5(where);
  }
  handleTypeChange = (value) => {
    const selectedBank = value;
    const banker = "bank=" + selectedBank;
    switch (selectedBank) {
      case selectedBank:
        this.navigateTo("/accounts/transactions", banker);
        break;
      default:
    }
  };

  filterSubmit10(where) {
    this.presenter.filterSubmit10(where);
  }

  onChangeFilter10(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit10(where);
  }
  onSelect(index) {
    console.log("index", index);
    this.presenter.onSelect(index);
  }

  loadMore() {
    this.presenter.loadMore();
  }

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const role = this.getCurrentRoles().map((item) => item.name);
    const { objects, selected, count, progress } = this.state;
    console.log("transaction selected", selected);
    console.log("transactions", objects);
    const formatCurrency = (amount) => {
      if (typeof amount === "number") {
        return (
          "₱" +
          new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(amount)
        );
      }
      return "₱" + amount;
    };

    // Format account_balance during the render phase
    const updatedObjects = objects.map((object) => ({
      ...object,
      account_balance: formatCurrency(object.account_balance),
    }));
    console.log("updatedObjects", objects);

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="d-none">
          <div className="" ref={this.contractPDF}>
            <Table
              fields={schema.fields}
              groups={schema.groups}
              objects={objects}
              collapsable
              excludeFields={Object.keys(schema.fields).reduce(
                (acc, key) => {
                  const options = schema.fields[key];
                  if (options.read === false) {
                    acc.push(key);
                  }
                  switch (options._type || options.type) {
                    case "Relation":
                    case "Array":
                    case "Object":
                    case "File":
                      acc.push(key);
                      break;
                    default:
                  }
                  return acc;
                },
                [
                  "acl",
                  "password",
                  "br",
                  "or",
                  "items",
                  "id",
                  "vendor_email",
                  "vendor_address",
                  "clients",
                ]
              )}
              selected={selected}
              onSelect={this.onSelect.bind(this)}
              onSelectAll={this.onSelectAll.bind(this)}
              progress={progress}
              onClickItem={this.onClickItem.bind(this)}
              onCollapse={this.onCollapse.bind(this)}
              className="mt-3"
            />
          </div>
        </div>
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="p-lg-4 d-flex">
                  <h1 className="fw-bold text-capitalize">
                    <span onClick={this.onClick.bind(this)}>
                      <i class="bi bi-chevron-left"></i>
                    </span>
                    {schema.label || this.getCollectionName()}
                  </h1>
                </div>

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#EBBD2F" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                    <div className="dropdown">
                      <button
                        className="border-0"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-three-dots"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          className="dropdown-item"
                          role="button"
                          onClick={this.onClickExport.bind(this)}
                        >
                          <i className="bi bi-filetype-csv"></i> Export CSV
                        </li>
                        <li
                          className="dropdown-item"
                          role="button"
                          onClick={this.onClickDeleteSelected.bind(this)}
                        >
                          <i className="bi bi-filetype-pdf"></i> Delete Selected
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters2 || {}).map((field) => {
                          let { type, ...options } = schema.filters2[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter2.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters5[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter5.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters3 || {}).map((field) => {
                          let { type, ...options } = schema.filters3[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1 "
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter3.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="createdAt"
                        />
                        <InputFactory
                          type="String"
                          _type="Enum"
                          options={[
                            "GMD Revolving Fund",
                            "PJG Abono",
                            "GMD Fund",
                            "Metrobank",
                            "BPI",
                            "BDO Main",
                          ]}
                          placeholder="Select Bank"
                          onChange={this.handleBankSelectionChange.bind(this)}
                        />

                        {Object.keys(schema.filters10 || {}).map((field) => {
                          let { type, ...options } = schema.filters10[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter10.bind(
                                  this,
                                  type
                                )}
                                {...options}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters2 || {}).map((field) => {
                    let { type, ...options } = schema.filters2[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter2.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  {Object.keys(schema.filters3 || {}).map((field) => {
                    let { type, ...options } = schema.filters3[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1 "
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter3.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  <InputFactory
                    type="String"
                    _type="Enum"
                    options={[
                      "GMD Revolving Fund",
                      "PJG Abono",
                      "GMD Fund",
                      "Metrobank",
                      "BPI",
                      "BDO Main",
                    ]}
                    placeholder="Select Bank"
                    onChange={this.handleBankSelectionChange.bind(this)}
                  />
                  {/* <InputFactory
                    type="String"
                    _type="Enum"
                    options={[
                      
                      "Money In",
                      "Money Out"
                    ]}
                    placeholder="Select Type"
                    onChange={this.handleTypeChange.bind(this)}
                  /> */}
                  {Object.keys(schema.filters10 || {}).map((field) => {
                    let { type, ...options } = schema.filters10[field];

                    return (
                      <div className="mb-2">
                        <InputFactory
                          key={field}
                          className="ms-1"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter10.bind(this, type)}
                          {...options}
                        />
                      </div>
                    );
                  })}

                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>

                {selected.length > 0 && (
                  <div className="dropdown">
                    <button
                      className="border-0"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-three-dots"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li
                        className="dropdown-item"
                        role="button"
                        onClick={this.onClickExport.bind(this)}
                      >
                        <i className="bi bi-filetype-pdf"></i> Export CSV
                      </li>
                      <li
                        className="dropdown-item"
                        role="button"
                        onClick={this.onClickDeleteSelected.bind(this)}
                      >
                        <i className="bi bi-filetype-pdf"></i> Delete Selected
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <Table
                fields={schema.fields}
                groups={schema.groups}
                objects={updatedObjects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "id",
                    "updatedAt",
                    "note",
                    "payee",
                    "clients",
                    "chart_of_accounts",
                    "type",
                    "amount",
                    "is_revolving",
                    "fund_source",
                    "reciept",
                    "remarks",
                    "types",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
                role={role}
              />
            </div>
            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#EBBD2F",
                }}
              >
                <i className="bi bi-plus" />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default TransactionPage;
