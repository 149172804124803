import BaseListPresenter from "../../base/BaseListPresenter";
import toast from "react-hot-toast";

class ExpensePresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase,
    getObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase,
      getObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
    this.getObjectUseCase = getObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filter2 = {};
    this.filter3 = {};
    this.filterDate = {};
    this.filterEmployee = {};

    this.include = ["items.all", "project_name", "accounts", "client_name"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;

    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filter2,
        ...this.filterDate,
        ...this.filter3,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  async findObjects() {
    const collection = "expense";
    const query = this.createQuery();
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  filterSubmit2(where) {
    this.reset();
    this.filter2 = where;
    this.getObjects();
  }

  // exportToCSV = async () => {
  //   try {
  //     const urlSearchParams = new URLSearchParams(window.location.search);
  //     const bankNameFromURL = urlSearchParams.get("bank");
  //     const specified = bankNameFromURL ? bankNameFromURL + "-" : ""
  //     const collection = "expense";
  //     const selected = this.view.state.selected
  //     const query = {
  //       include: ["all"],
  //     };
  //     const objectCSV = await this.findObjectUseCase.execute(collection, query);
  //     const selectedId = selected ? selected.map(item => item.id) : [];
  //     const filteredData = objectCSV.filter(expense => selectedId.includes(expense.id));

  //     const csvData = [];
  //     for (const expense of filteredData) {
  //       const commonData = {
  //         'Date': expense.createdAt,
  //         'Client Name': expense.client_name?.name || "n/a",
  //         'Project Name': expense.project_name?.name || "n/a",
  //         'BR #': expense.requestNumber || "n/a",
  //       };
  //       for (const item of expense.items) {
  //         const chartOfAccountsId = item["chart_of_accounts"]?.id || "";
  //         const findCoa = await this.getObjectUseCase.execute("chart_of_accounts", chartOfAccountsId);
  //         csvData.push({
  //           ...commonData,
  //           'Item Supplier': item.supplier || "n/a",
  //           'Item Tin #': item['tin#'] || "n/a",
  //           'Item Name': item.name || "n/a",
  //           'Item Amount': item.amounts || "n/a",
  //           'Chart of Accounts': findCoa?.name || "n/a"
  //         });
  //       }
  //     }

  //     const csvString = this.convertToCSV(csvData);
  //     const blob = new Blob([csvString], { type: "text/csv" });
  //     const url = window.URL.createObjectURL(blob);

  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = specified.trim() + collection + ".csv";
  //     a.click();

  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error exporting to CSV:", error);
  //   }
  // };

  async exportToCSV() {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const bankNameFromURL = urlSearchParams.get("bank");
      const specifiedPrefix = bankNameFromURL ? bankNameFromURL + "-" : "";
      const collection = "expense";

      const expenses = await this.findObjectUseCase.execute("expense", {
        include: ["items.all", "project_name", "accounts", "client_name"],
        where: { ...this.view.state.filter },
      });

      const expensesDetails = expenses.map((expense) => {
        return this.getExpenseDetails(expense);
      });

      const csvData = [];
      expensesDetails.forEach((details) => {
        if (details) {
          csvData.push(...details);
        }
      });

      const csvString = this.convertToCSV(csvData);
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const downloadFileName = specifiedPrefix.trim() + collection + ".csv";
      this.downloadFile(url, downloadFileName);

      window.URL.revokeObjectURL(url);

      return expenses;
    } catch (error) {
      console.error("Error exporting to CSV:", error);
      return error;
    }
  }

  getExpenseDetails = (expense) => {
    const csvData = [];
    const commonData = {
      Date: expense?.createdAt,
      "Client Name": expense?.client_name?.name || "n/a",
      "Project Name": expense?.project_name?.name || "n/a",
      "BR #": expense?.requestNumber || "n/a",
    };

    for (const item of expense?.items) {
      csvData.push({
        ...commonData,
        "Item Supplier": item?.supplier || "n/a",
        "Item Tin #": item["tin#"] || "n/a",
        "Item Name": item.name || "n/a",
        "Item Amount":
          parseFloat(item.amounts?.split(",").join("")) * item.quantity ||
          "n/a",
        "Chart of Accounts": item.chart_of_accounts?.name || "n/a",
      });
    }

    return csvData;
  };

  downloadFile = (url, fileName) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
  };

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        ?.map((value) => `"${value}"`)
        ?.join(",")}` + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += `"${array[i][index]}"`;
      }

      str += line + "\r\n";
    }

    return str;
  }
  filterSubmit3(where) {
    this.reset();
    this.filter3 = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.view.setState({ filter: where });
    this.getObjects();
  }

  onChangeEmployee(where) {
    this.filterEmployee = where;
    this.getObjects();
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    // this.view.navigateTo("/form/expenses/" + collection);
    this.view.navigateTo("/forms/expenses");
  }

  async onClickStatus(index, status) {
    const collection = "budget_request";
    const object = this.objects[index];

    object.statuses =
      status === "approved"
        ? "approved"
        : status === "paid"
        ? "paid"
        : status === "disapproved"
        ? "disapproved"
        : "liquidation";

    try {
      const data = await this.upsertUseCase.execute(collection, object);

      if (data.statuses === object.statuses) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }

  onClickLiquidate(index) {
    const object = this.objects[index];
    this.view.navigateTo("/liquidate/form/" + object.id);
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    // this.view.navigateTo("/collection/" + collection + "/form/" + object.id);
    this.view.navigateTo("/forms/expenses/" + object.id);
  }
}

export default ExpensePresenter;

// 06c67bd6898647e13e913583adbe26ec70dfc162
