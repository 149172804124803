import React from "react";
import { formatDate } from "./Utils";

const ListItem = ({ label, content }) => (
  <li>
    <span className="ms-2 fw-light fw-bold">{label}: </span>
    <span className="text-nowrap">{content}</span>
  </li>
);

const ListItems = ({ object }) => {
  console.log("list", object);
  const getTotalAmount = () =>
    object.items_list.reduce(
      (acc, item) => acc + Number(item.amounts?.replace(/,/g, "")),
      0
    );

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
    }).format(amount);
  };
  return (
    <div className="d-flex">
      <ul className="list-unstyled ms-1 text-truncate">
        <ListItem label="REQ #" content={object?.requestNumber} />
        <ListItem label="DATE" content={formatDate(object?.createdAt)} />
        <ListItem label="CLIENT NAME" content={object.client_name?.name} />
        <ListItem label="PROJECT NAME" content={object.project_name?.name} />
        <ListItem label="REQUESTEE" content={object.creditedTo?.fullName} />
        {object.items_list.map((item, index) => (
          <ListItem
            key={index}
            label="Item"
            content={`${item.name} - ${formatCurrency(
              Number(item.amounts?.replace(/,/g, ""))
            )}`}
          />
        ))}
        <ListItem
          label="AMOUNT"
          content={`${formatCurrency(getTotalAmount())}`}
        />
        {/* <ListItem label="EXPENSES" content={object["mobile"]} />
        <ListItem label="REMAINING" content={object["mobile"]} /> */}
      </ul>
    </div>
  );
};

export default ListItems;
