import React, { useState } from "react";
import InputFactory from "../../../components/InputFactory";
import { useEffect } from "react";

const separateTime = (string) => {
  let numericPart = parseInt(string.match(/\d+/)[0]);
  const alphabeticPart = string.match(/[a-zA-Z]+/)[0];

  if (alphabeticPart === "pm") {
    numericPart = 12 + numericPart;
  }

  if (alphabeticPart === "am" && numericPart === 12) {
    numericPart = 0;
  }

  return numericPart;
};

function formatTime(hours) {
  return hours.map((hour) => {
    if (hour === 0 || hour === 24) {
      return "12:00 am";
    } else if (hour === 12) {
      return "12:00 pm";
    } else if (hour < 12) {
      return hour + ":00 am";
    } else {
      return hour - 12 + ":00 pm";
    }
  });
}

function generateTimeArray(start, end) {
  const timeArray = [];

  if (start > end) {
    end += 24;
  }

  for (let i = start; i <= end; i++) {
    const hour = i % 24;
    timeArray.push(hour);
  }
  return timeArray;
}

function elementsAfterNumber(arr, num) {
  const index = arr.indexOf(num);

  if (index === -1 || index === arr.length - 1) {
    return [];
  } else {
    return arr.slice(index + 1);
  }
}

function elementsBeforeNumber(arr, num) {
  const index = arr.indexOf(num);

  if (index === -1 || index === 0) {
    return [];
  } else {
    return arr.slice(0, index);
  }
}

export default function TimeSelect({
  start,
  end,
  field,
  onChange,
  object,
  view,
  ...options
}) {
  const startTime = separateTime(start);
  const endTime = separateTime(end);
  const [fromTimeOptions, setFromTimeOptions] = useState(
    generateTimeArray(startTime, endTime)
  );
  const [toTimeOptions, setToTimeOptions] = useState(
    generateTimeArray(startTime, endTime)
  );

  const handleFromChange = (e) => {
    if (!e) {
      setToTimeOptions(generateTimeArray(startTime, endTime));
      return;
    }

    const from = separateTime(e);
    const newTo = elementsAfterNumber(
      generateTimeArray(startTime, endTime),
      from
    );
    setToTimeOptions(newTo);
  };

  const handleToChange = (e) => {
    if (!e) {
      setFromTimeOptions(generateTimeArray(startTime, endTime));

      return;
    }

    const to = separateTime(e);
    const newFrom = elementsBeforeNumber(
      generateTimeArray(startTime, endTime),
      to
    );
    setFromTimeOptions(newFrom);
  };

  return (
    <div className="row g-2">
      <div className="col">
        <p
          className="opacity-50 m-1"
          style={{ color: "#434343", fontSize: ".75rem" }}
        >
          From
        </p>
        <InputFactory
          options={formatTime(fromTimeOptions)}
          type={view ? "String" : "Enum"}
          onChange={(e) => {
            onChange(e, `${field}_from`);
            handleFromChange(e);
          }}
          field={`${field}_from`}
          object={object}
          disabled
          placeholder="Select Time"
          {...options}
        />
      </div>
      <div className="col">
        <p
          className="opacity-50 m-1"
          style={{ color: "#434343", fontSize: ".75rem" }}
        >
          To
        </p>
        <InputFactory
          options={formatTime(toTimeOptions)}
          type={view ? "String" : "Enum"}
          onChange={(e) => {
            onChange(e, `${field}_to`);
            handleToChange(e);
          }}
          disabled
          field={`${field}_to`}
          object={object}
          placeholder="Select Time"
          {...options}
        />
      </div>
    </div>
  );
}
