// import React from "react";
// import classNames from "../../classNames";

// const noop = () => {};
// const defaultProps = {
//   type: "tel",
//   parse: true,
//   onChange: noop,
// };

// function InputNumber({
//   parse,
//   className,
//   maxLength,
//   onChange,
//   options,
//   ...props
// }) {
//   function onInput(e) {
//     let value = e.target.value;

//     // Allow only digits and '.'
//     value = value.replace(/[^\d.]/gi, "");

//     // Allow only one '.'
//     if ((value.match(/\./g) || []).length > 1) {
//       value = value.replace(/\.+$/, "");
//     }

//     // Check if value is valid number or number.5
//     const validNumber = /^(\d+(\.5?)?)?$/.test(value);

//     if (validNumber) {
//       // Ensure maxLength is respected
//       if (maxLength && value.replace(".", "").length > maxLength) {
//         value = value.slice(0, maxLength);
//       }

//       e.target.value = value;
//       onChange(parse ? (value === "" ? "" : parseFloat(value)) : value);
//     } else {
//       // If invalid, revert to the previous valid state
//       e.target.value = e.target.value.slice(0, -1);
//     }
//   }

//   return (
//     <input
//       className={classNames("form-control", className)}
//       onInput={onInput}
//       {...props}
//     />
//   );
// }

// InputNumber.defaultProps = defaultProps;

// export default InputNumber;

import React from "react";
import classNames from "../../classNames";
const noop = () => {};
const defaultProps = {
  type: "tel",
  parse: true,
  onChange: noop,
};
function InputNumber({
  parse,
  className,
  maxLength,
  onChange,
  options,
  ...props
}) {
  function onInput(e) {
    let value = e.target.value;
    // Allow only digits and '.'
    value = value.replace(/[^\d.]/gi, "");
    // Allow only one '.'
    if ((value.match(/\./g) || []).length > 1) {
      value = value.replace(/\.+$/, "");
    }

    // Ensure maxLength is respected
    if (maxLength && value.replace(".", "").length > maxLength) {
      // Keep the part before the decimal and the decimal itself
      const [integerPart, decimalPart] = value.split(".");
      const allowedIntegerPart = integerPart.slice(0, maxLength);

      if (decimalPart) {
        value = `${allowedIntegerPart}.${decimalPart}`;
      } else {
        value = allowedIntegerPart;
      }
    }

    // Set the input's value
    e.target.value = value;

    // Call onChange handler with parsed or raw value
    onChange(parse ? (value === "" ? "" : parseFloat(value)) : value);
  }

  return (
    <input
      className={classNames("form-control", className)}
      onInput={onInput}
      {...props}
    />
  );
}
InputNumber.defaultProps = defaultProps;
export default InputNumber;
