import React from "react";
import { InputJson } from "nq-component";
import { getObjectUseCase, upsertUseCase } from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory from "../../components/FormFactory";
import { restUseCase } from "../../usecases/rest";
import InvoicesFormPresenter from "./InvoicesFormPresenter";
import InputFactory from "../../components/InputFactory";
import CustomDate from "./CustomDate";
import InvoTable from "./InvoTable";
import InvoicesEditPresenter from "./InvoicesEditPresenter";
import InvoicesEditPayment from "./InvoicesEditPayment";

class InvoicesEditPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      subtotal: 0,
      totalAmount: 0,
      tax: 0,
      discountedAmount: 0,
      due_date: "",
      advanced: false,
      discountType: "percent",
      asfType: "percent",
      activeTab: 1,
    };
    this.presenter = new InvoicesEditPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase()
    );
  }

  getCollectionName() {
    return "invoices_final";
  }

  onSubmitForm(e) {
    this.presenter.handleSubmit(e);
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChange(value, field) {
    const { object, discountType, asfType } = this.state;
    let amounts = object.qty * object.rate;
    this.setState({ amounts: amounts });
    if (amounts !== undefined && amounts !== null) {
      const amount = parseFloat(amounts);

      if (!isNaN(amounts)) {
        let discountedAmount = amount;
        if (discountType === "percent") {
          discountedAmount = parseFloat(
            amount - (amount * object.discount) / 100
          );
          this.setState({ amounts: discountedAmount });
        } else if (discountType === "value") {
          discountedAmount = parseFloat(amount - object.discount);
          this.setState({ amounts: discountedAmount });
        }
        let newAmountWithASF = discountedAmount;
        if (asfType === "percent") {
          newAmountWithASF = parseFloat(
            discountedAmount + (amount * object.asf) / 100
          );
          this.setState({ amounts: newAmountWithASF });
        } else if (asfType === "value") {
          newAmountWithASF = parseFloat(
            discountedAmount + parseFloat(object.asf)
          );
          this.setState({ amounts: newAmountWithASF });
        }

        // Update the state with the new discounted amount and amount with ASF
        this.setState({
          discountedAmount: discountedAmount,
          discountedAmountWithASF: newAmountWithASF,
        });
      }
    }
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  calculateNewDueDate(days) {
    const currentDate = new Date();
    const newDueDate = new Date(
      currentDate.setDate(currentDate.getDate() + days)
    );
    const year = newDueDate.getFullYear();
    const month = String(newDueDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDueDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  handleTabClick(tabNumber) {
    this.setState({ activeTab: tabNumber });
  }

  handleDiscountType(type) {
    this.setState({ discountType: type }, () => {
      this.onChange();
    });
  }

  handleASFType(type) {
    this.setState({ asfType: type }, () => {
      this.onChange();
    });
  }

  calculateTotalAmount() {
    const { subtotal, tax, discountType, asfType, object, totalAmount } =
      this.state;
    let discountedAmount = object.subtotal;
    if (discountType === "percent") {
      discountedAmount -=
        (totalAmount * (parseFloat(object.discount) || 0)) / 100;
    } else if (discountType === "value") {
      discountedAmount -= parseFloat(object.discount) || 0;
    }
    if (asfType === "percent") {
      discountedAmount += (subtotal * (parseFloat(object.asf) || 0)) / 100;
    } else if (asfType === "value") {
      discountedAmount += parseFloat(object.asf) || 0;
    }
    const total = discountedAmount + tax;

    this.setState({ totalAmount: total });
  }

  onUpdateServices = (updatedServices) => {
    const { discountType, asfType, object } = this.state;
    if (
      !updatedServices ||
      Object.keys(updatedServices).length === 0 ||
      !Array.isArray(updatedServices.services)
    ) {
      console.log("empty or invalid updatedServices");
      return;
    }
    console.log(updatedServices);
    let subtotal = updatedServices.services.reduce((acc, service) => {
      let rate = parseFloat(String(service.rate || "").replace(/,/g, ""));
      return acc + service.qty * rate;
    }, 0);

    let discountValue =
      parseFloat((object.discount || "").toString().replace(/,/g, "")) || 0;

    if (discountType === "percent") {
      discountValue = (subtotal * discountValue) / 100;
    }
    discountValue = subtotal - discountValue;

    let asfValue =
      parseFloat((object.asf || "").toString().replace(/,/g, "")) || 0;
    if (asfType === "percent") {
      asfValue = (discountValue * asfValue) / 100;
    }
    discountValue = asfValue + discountValue;

    let tax = discountValue * 0.12;
    let totalAmount = discountValue + tax;

    this.setState((prevState) => ({
      object: {
        ...prevState.object,
        services: updatedServices.services,
        subtotal: subtotal,
      },
      tax: tax,
      totalAmount: totalAmount,
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    const { object, discountType, asfType, hasUpdated } = this.state;

    // Check if object and object.disc_type exist, if discountType needs updating, and if it hasn't already been updated
    if (
      object &&
      object.disc_type &&
      discountType !== object.disc_type &&
      !hasUpdated
    ) {
      // Update discountType based on object.disc_type and set hasUpdated flag to true
      this.setState({ discountType: object.disc_type, hasUpdated: true });
    }

    // Check if object and object.asf_type exist, if asfType needs updating, and if it hasn't already been updated
    if (
      object &&
      object.asf_type &&
      asfType !== object.asf_type &&
      !hasUpdated
    ) {
      // Update asfType based on object.asf_type and set hasUpdated flag to true
      this.setState({ asfType: object.asf_type, hasUpdated: true });
    }
  }

  render() {
    const {
      object,
      tax,
      due_date,
      discountedAmount,
      subtotal,
      asfType,
      discountType,
      activeTab,
    } = this.state;
    const currentDate = this.getCurrentDateFormatted();
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Create " : "Edit ";

    console.warn(object);
    return (
      <>
        <NavBar />
        <div className="overflow-x-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label + (schema.label || this.getCollectionName())}
              </h1>
              <div className="d-flex mb-3">
                <div
                  className={`tab ${activeTab === 1 ? "active" : ""}`}
                  onClick={() => this.handleTabClick(1)}
                >
                  Overview
                </div>
                <div
                  className={`tab ${activeTab === 2 ? "active" : ""}`}
                  onClick={() => this.handleTabClick(2)}
                >
                  Payments
                </div>
              </div>

              {activeTab === 1 && (
                <div className="mt-3 bg-white shadow rounded p-3 px-4 py-lg-4">
                  <div>
                    <div className="row">
                      {/* First Column */}
                      <div className="col-lg-6">
                        <label className="form-label pt-3">Client Name</label>
                        <InputFactory
                          type="ModdedInputService"
                          dynamic={false}
                          target="clients"
                          field="client_name"
                          object={object}
                          placeholder="Select Client Name"
                          onChange={this.onChange.bind(this)}
                          disabled={
                            object.statuses == "Approved" ||
                            object.statuses === "Paid" ||
                            object.statuses === "Partially Paid"
                          }
                          required={true}
                        />
                        <label className="form-label pt-3">Client Email</label>
                        <InputFactory
                          type="String"
                          dynamic={false}
                          field="client_email"
                          object={object}
                          placeholder="Enter Client Email"
                          onChange={this.onChange.bind(this)}
                          required={true}
                          disabled={
                            object.statuses === "Approved" ||
                            object.statuses === "Paid" ||
                            object.statuses === "Partially Paid"
                          }
                        />
                        <label className="form-label pt-3">
                          Client Address
                        </label>
                        <InputFactory
                          type="String"
                          dynamic={false}
                          field="client_address"
                          object={object}
                          placeholder="Enter Client Address"
                          onChange={this.onChange.bind(this)}
                          required={true}
                          disabled={
                            object.statuses === "Approved" ||
                            object.statuses === "Paid" ||
                            object.statuses === "Partially Paid"
                          }
                        />
                        <label className="form-label pt-3">Due Date</label>
                        <CustomDate
                          defaultDate={
                            object.due_date
                              ? object.due_date.split("T")[0]
                              : null
                          }
                          onDateChange={(newDate) =>
                            this.setState({ due_date: newDate })
                          }
                        />
                      </div>

                      {/* Second Column */}
                      <div className="col-lg-6">
                        <div className="row"></div>
                        <div className="row pt-2">
                          <div className="col-md-6">
                            <label
                              className="form-label"
                              style={{ marginRight: "10px" }}
                            >
                              Discount
                            </label>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Discount Type"
                            >
                              <button
                                type="button"
                                className={`btn ${
                                  this.state.discountType === "percent"
                                    ? "btn-primary"
                                    : "btn-secondary"
                                } m-2`}
                                style={{
                                  padding: "0.15rem 0.5rem",
                                  fontSize: "0.75rem",
                                }}
                                onClick={() => {
                                  this.handleDiscountType("percent");
                                  this.onUpdateServices(this.state.services); // Call onUpdateServices when discount type changes
                                }}
                              >
                                %
                              </button>
                              <button
                                type="button"
                                className={`btn ${
                                  this.state.discountType === "value"
                                    ? "btn-primary"
                                    : "btn-secondary"
                                } m-2`}
                                style={{
                                  padding: "0.15rem 0.5rem",
                                  fontSize: "0.75rem",
                                }}
                                onClick={() => {
                                  this.handleDiscountType("value");
                                  this.onUpdateServices(this.state.services); // Call onUpdateServices when discount type changes
                                }}
                              >
                                ₱
                              </button>
                            </div>
                            <InputFactory
                              type="parseNumber"
                              field="discount"
                              object={object}
                              placeholder={
                                this.state.discountType === "percent"
                                  ? "Enter Discount Percentage"
                                  : "Enter Discount Value"
                              }
                              onChange={(value) => {
                                this.setState(
                                  { object: { ...object, discount: value } },
                                  () => {
                                    this.onChange(value, "discount");
                                    this.calculateTotalAmount();
                                  }
                                );
                              }}
                              required={true}
                              disabled={
                                object.statuses === "Approved" ||
                                object.statuses === "Paid" ||
                                object.statuses === "Partially Paid"
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <label
                              className="form-label"
                              style={{ marginRight: "10px" }}
                            >
                              ASF
                            </label>
                            <div className="btn-group" role="group">
                              <button
                                type="button"
                                className={`btn ${
                                  this.state.asfType === "percent"
                                    ? "btn-primary"
                                    : "btn-secondary"
                                } m-2`}
                                style={{
                                  padding: "0.15rem 0.5rem",
                                  fontSize: "0.75rem",
                                }}
                                onClick={() => this.handleASFType("percent")}
                              >
                                %
                              </button>
                              <button
                                type="button"
                                className={`btn ${
                                  this.state.asfType === "value"
                                    ? "btn-primary"
                                    : "btn-secondary"
                                } m-2`}
                                style={{
                                  padding: "0.15rem 0.5rem",
                                  fontSize: "0.75rem",
                                }}
                                onClick={() => this.handleASFType("value")}
                              >
                                ₱
                              </button>
                            </div>
                            <InputFactory
                              type="parseNumber"
                              field="asf"
                              object={object}
                              placeholder={
                                this.state.asfType === "percent"
                                  ? "Enter ASF Percentage"
                                  : "Enter ASF Value"
                              }
                              onChange={(value) => {
                                this.setState(
                                  { object: { ...object, asf: value } },
                                  () => {
                                    this.onChange(value, "asf");
                                    this.calculateTotalAmount();
                                  }
                                );
                              }}
                              disabled={
                                object.statuses === "Approved" ||
                                object.statuses === "Paid" ||
                                object.statuses === "Partially Paid"
                              }
                              required={true}
                            />
                          </div>
                          <div style={{ marginTop: "-10px" }}>
                            <label className="form-label">
                              Purchase Order Number
                            </label>
                            <InputFactory
                              type="Number"
                              dynamic={false}
                              field="po_num"
                              object={object}
                              placeholder="Enter Purchase Order Number"
                              onChange={this.onChange.bind(this)}
                              disabled={
                                object.statuses === "Approved" ||
                                object.statuses === "Paid" ||
                                object.statuses === "Partially Paid"
                              }
                              required={true}
                            />
                          </div>
                          <div>
                            <label
                              className="form-label"
                              style={{ marginTop: "18px" }}
                            >
                              Notes
                            </label>
                            <InputFactory
                              type="String"
                              dynamic={false}
                              field="notes"
                              object={object}
                              placeholder="Enter Note"
                              onChange={this.onChange.bind(this)}
                              required={true}
                              disabled={
                                object.statuses === "Approved" ||
                                object.statuses === "Paid" ||
                                object.statuses === "Partially Paid"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive mt-2">
                      <InvoTable
                        object={object}
                        onUpdateServices={this.onUpdateServices}
                        onChange={this.onChange.bind(this)}
                      />
                    </div>
                    <div className="mt-2">
                      <div className="row justify-content-end">
                        <div className="col-md-4">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>Subtotal</td>
                                <td className="text-center">
                                  {"₱" + object.subtotal}
                                </td>
                              </tr>
                              <tr>
                                <td>Discount</td>
                                <td className="text-center">
                                  {discountType === "percent"
                                    ? `₱${(
                                        (object.subtotal *
                                          (parseFloat(object.discount) || 0)) /
                                        100
                                      ).toFixed(2)}`
                                    : "₱" + (object.discount || 0)}
                                </td>
                              </tr>
                              <tr>
                                <td>ASF</td>
                                <td className="text-center">
                                  {asfType === "percent"
                                    ? `₱${(
                                        (object.subtotal *
                                          (parseFloat(object.asf) || 0)) /
                                        100
                                      ).toFixed(2)}`
                                    : `₱${(parseFloat(object.asf) || 0).toFixed(
                                        2
                                      )}`}
                                </td>
                              </tr>
                              <tr>
                                <td>Tax 12%</td>
                                <td className="text-center">
                                  ₱
                                  {(tax
                                    ? tax
                                    : object && object.tax
                                    ? object.tax
                                    : 0
                                  ).toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Total Amount</strong>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  <strong>
                                    {"₱" +
                                      (this.state.totalAmount
                                        ? this.state.totalAmount
                                        : object.amount)}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-light fs-sm me-1"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                    {!(
                      object.statuses === "Approved" ||
                      object.statuses === "Paid" ||
                      object.statuses === "Partially Paid"
                    ) && (
                      <button
                        onClick={this.onSubmitForm.bind(this)}
                        className="btn ms-1"
                        style={{ backgroundColor: "#EBBD2F" }}
                      >
                        SAVE
                      </button>
                    )}
                  </div>
                </div>
              )}

              {activeTab === 2 && (
                <InvoicesEditPayment inv={this.state.object.invoice_num} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(InvoicesEditPage);
