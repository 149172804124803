import React from "react";
import classNames from "../../../classNames";

const ApproveDialogContent = ({ onApprove, onCancel }) => (
  <div className="text-end">
    <div className="text-center">
      <i
        className={classNames("bi bi-file-earmark-check", "text-warning")}
        style={{ fontSize: "5rem" }}
      ></i>
      <h4 className="fw-bold">Approve Budget Request!</h4>
      <p className="m-0">
        Are you sure you want to approve this budget request?
      </p>
      <button
        className="btn mb-2 mt-2 me-2"
        style={{ backgroundColor: "#EBBD2F" }}
        onClick={onApprove}
      >
        Approve
      </button>
      <button
        className="btn mb-2 mt-2"
        style={{ backgroundColor: "#EBBD2F" }}
        onClick={onCancel}
      >
        Cancel
      </button>
    </div>
  </div>
);

export default ApproveDialogContent;
