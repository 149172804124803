import React from "react";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import { restUseCase } from "../../usecases/rest";
import InputFactory from "../../components/InputFactory";
import InputFactory2 from "../../components/InputFactory2";
import DesignFormPresenter from "./DesignFormPresenter";
import Drop from "../../components/DropdownFilter";
import { emailUseCase } from "../../usecases/emails";
class DesignFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      selectedProject: null,
      filtered: [],
    };
    this.presenter = new DesignFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return "project_designs";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }
  onChangeFilter(value) {
    this.presenter.onChangeFilter(value);
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:Designer", "role:masterAdmin"],
      write: [user.id, "role:Designer", "role:masterAdmin"],
    };
  }

  handleProjectChange = (value) => {
    const selectedProject = this.state.filtered.find(
      (project) => project.id === value
    );
    this.setState({ selectedProject: selectedProject || null });
  };

  async onChange(value, field) {
    if (field === "client_name") {
      const clientName = value.id;
      const proj = await findObjectUseCase().execute("projects");
      const filteredProjects = proj
        .filter((project) => project.client?.id === clientName)
        .map((project) => ({
          name: project.name,
          id: project.id,
        }));
      this.setState({
        filtered: filteredProjects,
        selectedProject:
          filteredProjects.length > 0 ? filteredProjects[0] : null,
      });
    } else if (field === "project" && !value) {
      this.setState({ filtered: [], selectedProject: null });
    } else if (Array.isArray(value)) {
      this.setState({ itemsAmount: value });
    }

    this.presenter.onChange(value, field);
  }
  getOptions() {}

  render() {
    console.log("args", this.props.params.pid);
    const { object } = this.state;
    const role = this.getCurrentRoles();
    const user = this.getCurrentUser();
    const schema = this.getSchema(this.getCollectionName());
    const selectedProject = this.state.selectedProject;
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />

        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">{label} Design</h1>

              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3 py-4">
                    {!this.props.params?.cid && (
                      <div className="mt-3 mb-3">
                        <label className="form-label">Client Name</label>
                        <InputFactory
                          type="UserInputPointer"
                          target="clients"
                          dynamic={false}
                          field="client_name"
                          object={object}
                          placeholder="Select Client"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {!this.props.params?.pid && (
                      <div className="mb-3">
                        <label className="form-label">Project Name</label>
                        {/* {this.state.filtered.length > 0 ||
                        selectedProject ||
                        object.project?.name ? (
                          <> */}
                        <Drop
                          placeholder={
                            selectedProject
                              ? "Select a project"
                              : object.project_name?.name
                          }
                          value={
                            selectedProject
                              ? selectedProject
                              : object.project_name?.name
                          }
                          options={this.state.filtered}
                          onChange={this.handleProjectChange}
                        />
                        {/* </>
                        ) : null} */}
                      </div>
                    )}

                    <div className="mt-3 mb-3">
                      <label className="form-label">Link</label>
                      <InputFactory
                        type="String"
                        field="link"
                        object={object}
                        placeholder="Enter Design Link"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <InputFactory
                        type="String"
                        field="description"
                        object={object}
                        placeholder="Enter Description"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <button
                      type="submit"
                      className="btn  fs-sm me-3"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      CANCEL
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(DesignFormPage);
