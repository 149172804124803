import { dialog } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";
class PurchaseOrderFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async save() {
    const collection = this.view.getCollectionName();
    const user = this.view.getCurrentUser();
    const payables = {};
    const orders = await findObjectUseCase().execute("purchase_orders");

    const budgetRequest = this.change;
    const itemsName1 = budgetRequest.items[0]?.name || "";
    const itemsAmount1 = budgetRequest.items[0]?.amount || "";
    const itemsName2 = budgetRequest.items[1]?.name || "";
    const itemsAmount2 = budgetRequest.items[1]?.amount || "";
    const itemsName3 = budgetRequest.items[2]?.name || "";
    const itemsAmount3 = budgetRequest.items[2]?.amount || "";
    const itemsName4 = budgetRequest.items[3]?.name || "";
    const itemsAmount4 = budgetRequest.items[3]?.amount || "";
    const itemsName5 = budgetRequest.items[4]?.name || "";
    const itemsAmount5 = budgetRequest.items[4]?.amount || "";
    const itemsName6 = budgetRequest.items[5]?.name || "";
    const itemsAmount6 = budgetRequest.items[5]?.amount || "";
    const itemsName7 = budgetRequest.items[6]?.name || "";
    const itemsAmount7 = budgetRequest.items[6]?.amount || "";
    const itemsName8 = budgetRequest.items[7]?.name || "";
    const itemsAmount8 = budgetRequest.items[7]?.amount || "";
    const itemsName9 = budgetRequest.items[8]?.name || "";
    const itemsAmount9 = budgetRequest.items[8]?.amount || "";
    const itemsName10 = budgetRequest.items[9]?.name || "";
    const itemsAmount10 = budgetRequest.items[9]?.amount || "";

    let highestPoNum = 0;
    orders.forEach((item) => {
      if (item.po_num && item.po_num > highestPoNum) {
        highestPoNum = item.po_num;
      }
    });

    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
      payables.project_name = this.view.state.selectedProject;
      payables.items = this.object.items;
      payables.purchaseOrderNumber = highestPoNum + 1;
      payables.accounts = this.object.account;
      payables.due_date = this.object.due_date;
      payables.amount = this.object.totalAmount;
      payables.client_name = this.object.client_name;
      payables.vendor_name = this.object.vendor_name;
      //payables.pending_balance = this.object.totalAmount
      console.log(this.object);
      // await this.upsertUseCase.execute("payables", payables);
      console.log("this.change.payables", payables);
    }
    if (this.object.created_by) {
      this.change.created_by = this.object.created_by;
    } else {
      this.change.created_by = user;
    }
    this.change.statuses = "Pending";
    if (this.object.po_num == undefined && this.change.po_num == undefined) {
      this.change.po_num = highestPoNum + 1;
    }
    this.change.vendor_address = this.object.vendor_name?.current_address;
    this.change.vendor_email = this.object.vendor_name?.email;
    this.change.total = this.object.totalAmount;
    const project_name = this.view.state.selectedProject;
    this.change.project_name = project_name;
    this.change.requesteeString = user.fullName;
    this.change.pending_balance = this.object.totalAmount;
    this.change.vendorString = this.change.vendor_name.name;
    this.change.items1 = `${"->"} ${itemsName1} : ${itemsAmount1}` || "";
    this.change.items2 = itemsName2
      ? `${"->"} ${itemsName2} : ${itemsAmount2}`
      : "";
    this.change.items3 = itemsName3
      ? `${"->"} ${itemsName3} : ${itemsAmount3}`
      : "";
    this.change.items4 = itemsName4
      ? `${"->"} ${itemsName4} : ${itemsAmount4}`
      : "";
    this.change.items5 = itemsName5
      ? `${"->"} ${itemsName5} : ${itemsAmount5}`
      : "";
    this.change.items6 = itemsName6
      ? `${"->"} ${itemsName6} : ${itemsAmount6}`
      : "";
    this.change.items7 = itemsName7
      ? `${"->"} ${itemsName7} : ${itemsAmount7}`
      : "";
    this.change.items8 = itemsName8
      ? `${"->"} ${itemsName8} : ${itemsAmount8}`
      : "";
    this.change.items9 = itemsName9
      ? `${"->"} ${itemsName9} : ${itemsAmount9}`
      : "";
    this.change.items10 = itemsName10
      ? `${"->"} ${itemsName10} : ${itemsAmount10}`
      : "";
    // this.change.created_by = user;

    console.log("this.change.po", this.change);

    try {
      await this.upsertUseCase.execute(collection, this.change);
      this.change = {};
    } catch (error) {
      throw error;
    }
  }

  async submit() {
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      // this.view.showSuccessSnackbar("Successfully saved!");
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Data has been successfully saved.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo("/purchase-orders");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
  async approved() {
    const collection = this.view.getCollectionName();
    const payables = {};
    const orders = await findObjectUseCase().execute("purchase_orders");

    let highestPoNum = 0;
    orders.forEach((item) => {
      if (item.po_num && item.po_num > highestPoNum) {
        highestPoNum = item.po_num;
      }
    });
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }

    this.change.statuses = "Approved";

    try {
      this.view.submitting();
      console.warn(payables);
      payables.project_name = this.object.project_name;
      payables.items = this.object.items;
      payables.purchaseOrderNumber = highestPoNum;
      payables.accounts = this.object.account;
      payables.due_date = this.object.due_date;
      payables.amount = this.object.totalAmount;
      payables.client_name = this.object.client_name;
      payables.vendor_name = this.object.vendor_name;
      payables.statuses = this.object.statuses;
      payables.id = this.object.id;
      await this.upsertUseCase.execute(collection, this.change);
      await this.upsertUseCase.execute("payables", payables);

      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully Approved!");
      await this.view.navigateTo("/purchase-orders");

      this.change = {};
    } catch (error) {
      throw error;
    }
  }

  async disapproved() {
    const collection = this.view.getCollectionName();

    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }

    this.change.statuses = "Cancelled";

    try {
      this.view.submitting();
      await this.upsertUseCase.execute(collection, this.change);
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully Cancelled!");
      await this.view.navigateTo("/purchase-orders");

      this.change = {};
    } catch (error) {
      throw error;
    }
  }

  async send() {
    const collection = this.view.getCollectionName();
    const method = "POST";
    const path = "/send-email/fd499c79-5d21-4697-a04b-853d1553c31d";
    const obj = {
      to: this.object.vendor_email,
      body: `Hi ${this.object.vendor_name?.name}, Please see attached PO #${this.object?.po_num}. for your reference. Regards, GMD Productions Inc`,
    };

    // const payables = {};
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }

    this.change.statuses = "Sent";

    try {
      this.view.submitting();
      await this.upsertUseCase.execute(collection, this.change);
      await this.restUseCase.execute(method, path, {
        body: obj,
      });

      this.view.submissionSuccess();

      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Email Successfully Sent!</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo("/purchase-orders");

      this.change = {};
    } catch (error) {
      throw error;
    }
  }

  // async paid() {
  //   const collection = this.view.getCollectionName();

  //   if (this.object.id) {
  //     this.change.id = this.object.id;
  //   } else {
  //     this.change.acl = this.view.getAcl();
  //   }

  //   this.change.statuses = "Paid";

  //   try {
  //     this.view.submitting();
  //     await this.upsertUseCase.execute(collection, this.change);
  //     this.view.submissionSuccess();
  //     this.view.showSuccessSnackbar("Paid Successfully!");
  //     await this.view.navigateTo("/purchase-orders");

  //     this.change = {};
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  onClickExport() {
    this.view.exportPDF();
  }
}

export default PurchaseOrderFormPresenter;
