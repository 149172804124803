import BaseListPresenter from "../../base/BaseListPresenter";
import saveAs from "../../saveAs";
import { deleteObjectUseCase } from "../../usecases/object";

class EstimatePresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    exportCSVUseCase,
    addSchemaUseCase,
    updateSchemaUseCase,
    deleteSchemaUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.exportCSVUseCase = exportCSVUseCase;
    this.addSchemaUseCase = addSchemaUseCase;
    this.updateSchemaUseCase = updateSchemaUseCase;
    this.findObjectUseCase = findObjectUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.include = [
      "client_name",
      "services",
      "services.questions",
      "services.questions.service",
      "project_name.client",
    ];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }
  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    console.log("path", object);

    // Check the value of estimate_type
    if (object.estimate_type === "Event") {
      this.view.navigateTo("/forms/event/" + object.id);
    } else if (object.estimate_type === "Construction") {
      this.view.navigateTo("/forms/construction/" + object.id);
    } else if (object.estimate_type === "Fabrication") {
      this.view.navigateTo("/forms/fabrication/" + object.id);
    } else {
      console.log("Unknown estimate_type: " + object.estimate_type);
    }
  }

  async onClickItemDelete(index) {
    const object = this.objects[index].id;
    const collection = this.view.getCollectionName();
    await deleteObjectUseCase().execute(collection, object);
    this.objects.splice(index, 1);

    this.view.setObjects(this.objects);
  }

  onClickExport(index) {
    if (
      !this.objects ||
      !Array.isArray(this.objects) ||
      index < 0 ||
      index >= this.objects.length
    ) {
      console.error("Invalid objects array or index.");
      return;
    }

    const object = this.objects[index];
    if (!object || typeof object.id !== "number") {
      console.error("Invalid object or object ID.");
      return;
    }

    const defaultFilename = `estimate_${object.id}.pdf`;
    console.log("Default Filename:", defaultFilename);

    this.view.exportPDF(object.id).then((pdf) => {
      const blob = new Blob([pdf], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = defaultFilename;
      link.click();
    });
  }

  async saveObjects(objects) {
    const collection = this.view.getCollectionName();
    this.view.setCount(objects.length);
    const size = 20;
    let i = 0;
    while (i < objects.length) {
      const batch = objects.slice(i, i + size);
      // Process each batch
      await Promise.all(
        batch.map(async (object, index) => {
          try {
            await this.upsertUseCase.execute(collection, object);
            // Update the total count in the view
            this.view.setTotal(i + index + 1);
          } catch (error) {
            // Log and ignore errors
            console.log(object);
            console.log(error);
          }
        })
      );
      // Move to the next batch
      i += size;
    }
    // Hide the progress indicator
    this.view.hideProgress();
    this.init();
    await this.getObjects();
  }

  handleEdit(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();

    if (object.estimate_type === "Event") {
      this.view.navigateTo("/forms/event/" + object.id);
    } else if (object.estimate_type === "Construction") {
      this.view.navigateTo("/forms/construction/" + object.id);
    } else if (object.estimate_type === "Fabrication") {
      this.view.navigateTo("/forms/fabrication/" + object.id);
    } else {
      console.log("Unknown estimate_type: " + object.estimate_type);
    }
  }

  async handleCreate(objects, index) {
    const invoice = await this.findObjectUseCase.execute("invoices");
    let change = { ...objects[index] };
    console.log(objects[index]);

    let highestInvNo = 0;
    invoice.forEach((item) => {
      if (item.invoice_num && item.invoice_num > highestInvNo) {
        highestInvNo = item.invoice_num;
      }
    });

    change.invoice_num = highestInvNo + 1;
    change.statuses = "Pending";
    change.due_date = change.createdAt;
    change.amount = change.total;
    change.received = 0;
    change.collectibles = change.total;
    change.asf = change.ASF;
    change.services = objects[index].services;
    delete change.ASF;
    delete change.approveAt;

    console.log(change);
    await this.upsertUseCase.execute("invoices", change);
  }

  async handleDelete(index) {
    const collection = this.view.getCollectionName();
    console.log("collection", collection);

    try {
      await this.view.showDialog({
        title: "Delete Data?",
        message: "Are you sure you want to delete?",
      });

      const objectId = this.objects[index].id;

      await this.deleteObjectUseCase.execute(collection, objectId);
      // await this.view.deleteObjectUseCase
      console.log("objectId:", objectId);

      console.log("Object deleted from collection successfully.");

      this.objects.splice(index, 1);
      console.log("Object deleted from array successfully.");

      this.view.setObjects(this.objects);
    } catch (error) {
      console.error("Error deleting object:", error);
      this.view.hideProgress();
      this.view.showError(error);
    }
  }
}

export default EstimatePresenter;
