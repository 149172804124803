import React from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  type: "datetime-local",
  strict: false,
  onChange: noop,
};

function InputDateTime({ className, strict, onChange, uppercase, ...props }) {
  function onInput(e) {
    const value = e.target.value;
    const character = value.charAt(value.length - 1);
    // only string
    if (strict && !/^[a-zA-Z]*$/.test(character)) {
      return;
    }
    if (uppercase) {
      e.target.value = value.toUpperCase();
    }
    onChange(e.target.value);
  }

  return (
    <input
      {...props}
      type="datetime-local"
      className={classNames("form-control", className)}
      onInput={onInput}
    />
  );
}

InputDateTime.defaultProps = defaultProps;
export default InputDateTime;
