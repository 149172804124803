import React from "react";
import InputFactory from "../InputFactory";

function randomString(length = 10) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function DynamicRelatedFactory({
  schema,
  object,
  onChange,
  field,
  defaultValue,
  dynamic,
}) {
  const [relations, setRelations] = React.useState([]);

  // Set default value
  React.useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      setRelations(defaultValue);
    } else {
      setRelations([{ id: randomString() }]);
    }
  }, [defaultValue]);

  function onClickAdd() {
    setRelations([...relations, { id: randomString() }]);
  }

  function onChangeValue(index, value, field) {
    const updatedRelations = [...relations];
    updatedRelations[index][field] = value;
    setRelations(updatedRelations);
    onChange(updatedRelations);
  }

  return (
    <div className="col-12">
      <hr />
      {relations.map((relation, index) => (
        <div key={relation.id} className="row g-3">
          {Object.keys(schema.fields).map((key) => {
            const { type, label, ...options } = schema.fields[key];
            if (options.hasOwnProperty("write") && !options.write) return null;
            return (
              <div className="col-md-4" key={key}>
                <label className="form-label fs-sm mt-2">{label || key}</label>
                <InputFactory
                  object={relation}
                  field={key}
                  onChange={(value) => onChangeValue(index, value, key)}
                  type={type}
                  className="fs-sm"
                  {...options}
                />
              </div>
            );
          })}
        </div>
      ))}
      {dynamic && (
        <button
          onClick={onClickAdd}
          type="button"
          className="btn btn-light btn-sm fs-xs mt-3 text-uppercase"
        >
          <i className="bi bi-plus me-2"></i>ADD MORE {field}
        </button>
      )}
    </div>
  );
}

export default DynamicRelatedFactory;
