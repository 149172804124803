import { browseFile } from "nq";
import BaseListPresenter from "../../base/BaseListPresenter";
import Papa from "papaparse";

class CashAdvancePresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filter2 = {};
    this.filter3 = {};
    this.filterDate = {};
    this.filterList = {};
    this.include = ["all"];
    this.keys = [];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filter2,
        ...this.filterDate,
        ...this.filter3,
        ...this.filterList,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }
  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  filterSubmit2(where) {
    this.reset();
    this.filter2 = where;

    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/forms/cash-advance");
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/forms/cash-advance/" + object.id);
  }

  async onClickStatus(index, status, dp) {
    const collection = "cash_advance";
    const object = this.objects[index];

    object.statuses =
      status === "Approved"
        ? "Approved"
        : status === "Paid"
        ? "Paid"
        : status === "Disapproved"
        ? "Disapproved"
        : status === "Cancelled"
        ? "Cancelled"
        : status;

    object.amounts = dp;

    try {
      const data = await this.upsertUseCase.execute(collection, object);
      if (data.statuses === object.statuses) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }
}

export default CashAdvancePresenter;
