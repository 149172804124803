import React from "react";

export default function MonthlyTableBreakdown({ payroll }) {
  const {
    HRNormalDay,
    HRRegHoliday,
    HRSpeNonWorking,
    HRTimeOff,
    HRRegTimeOff,
    HRSpeTimeOff,
    OTNormalDay,
    OTRegHoliday,
    OTSpeNonWorking,
    OTTimeOff,
    OTRegTimeOff,
    OTSpeTimeOff,
    NDNormalDay,
    NDRegHoliday,
    NDSpeNonWorking,
    NDTimeOff,
    NDRegTimeOff,
    NDSpeTimeOff,
  } = payroll || {};

  const header = [
    "(Computed in hours)",
    "NORMAL DAY",
    "REG DAY",
    "SPE NON-WORING",
    "TIME OFF",
    "REG + TIME OFF",
    "SPE + TIME OFF",
  ];

  const data = [
    [
      "HR",
      HRNormalDay || 0,
      HRRegHoliday || 0,
      HRSpeNonWorking || 0,
      HRTimeOff || 0,
      HRRegTimeOff || 0,
      HRSpeTimeOff || 0,
    ],
    [
      "OT",
      OTNormalDay || 0,
      OTRegHoliday || 0,
      OTSpeNonWorking || 0,
      OTTimeOff || 0,
      OTRegTimeOff || 0,
      OTSpeTimeOff || 0,
    ],
    [
      "ND",
      NDNormalDay || 0,
      NDRegHoliday || 0,
      NDSpeNonWorking || 0,
      NDTimeOff || 0,
      NDRegTimeOff || 0,
      NDSpeTimeOff || 0,
    ],
  ];

  console.log("PAYROLL", payroll);

  return (
    <div
      className="rounded position-relative m-0"
      style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)" }}
    >
      <div
        className="d-flex p-3 m-0 rounded-top"
        style={{ background: "#e6e6e6" }}
      >
        {header.map((item, index) => (
          <p
            key={index}
            className="p-0 m-0 fw-semibold text-center"
            style={{ flex: "1", color: "#434343", fontSize: ".65rem" }}
          >
            {item}
          </p>
        ))}
      </div>
      <div className="d-flex flex-column">
        {data.map((item, rowIndex) => (
          <div className="d-flex flex-row p-3 m-0" key={rowIndex}>
            {item.map((i, colIndex) => (
              <p
                key={colIndex}
                className={`p-0 m-0 fw-semibold text-center ${
                  i === 0 ? "opacity-25" : "opacity-100"
                } `}
                style={{ flex: "1", color: "#434343", fontSize: ".75rem" }}
              >
                {i}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
