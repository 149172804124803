import React from "react";
import InputFactory from "../../../components/InputFactory";

export default function Deductions({ object, onIncreasesAndDeductionsChange }) {
  const deductions = ["CA Deductions", "TIN", "PHILHEALTH", "SSS", "PAGIBIG"];

  console.log("deductions", object);

  return (
    <div className="col-7">
      <p
        className="fw-semibold mt-5"
        style={{ fontSize: "1.25rem", color: "#434343" }}
      >
        Deductions
      </p>
      <div
        className="col rounded m-0 p-3"
        style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)" }}
      >
        {deductions.map((item) => {
          return (
            <div className="row mt-3 mb-3 align-items-center " key={item}>
              <div className="col-4">{item}</div>
              <div className="col-7">
                <InputFactory
                  type="Peso"
                  object={object}
                  field={item === "CA Deductions" ? "CADeductions" : item}
                  onChange={onIncreasesAndDeductionsChange}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
