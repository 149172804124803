import BaseFormPresenter from "../../../base/BaseFormPresenter";

class OvertimeFormPresenter extends BaseFormPresenter {
  separateTime(string) {
    let numericPart = parseInt(string.match(/\d+/)[0]);
    const alphabeticPart = string.match(/[a-zA-Z]+/)[0];

    if (alphabeticPart === "pm") {
      numericPart = 12 + numericPart;
    }

    if (alphabeticPart === "am" && numericPart === 12) {
      numericPart = 0;
    }

    return numericPart;
  }

  computeNightDifferential(object) {
    let totalNightDifferential = 0;

    if (
      object.hasOwnProperty("workedHoursFrom12amTo8am_from") &&
      object.hasOwnProperty("workedHoursFrom12amTo8am_to") &&
      object.workedHoursFrom12amTo8am_from.length > 0 &&
      object.workedHoursFrom12amTo8am_to.length > 0
    ) {
      const from = this.separateTime(object.workedHoursFrom12amTo8am_from);
      const to = this.separateTime(object.workedHoursFrom12amTo8am_to);

      const nightDifferential12amTo8am = to - from > 6 ? 6 : to - from;
      totalNightDifferential += nightDifferential12amTo8am;
    }

    if (
      object.hasOwnProperty("workedHoursFrom5pmTo12am_from") &&
      object.hasOwnProperty("workedHoursFrom5pmTo12am_to") &&
      object.workedHoursFrom5pmTo12am_from.length > 0 &&
      object.workedHoursFrom5pmTo12am_to.length > 0
    ) {
      const from = this.separateTime(object.workedHoursFrom5pmTo12am_from);
      const to =
        this.separateTime(object.workedHoursFrom5pmTo12am_to) === 0
          ? 24
          : this.separateTime(object.workedHoursFrom5pmTo12am_to);

      let nightDifferential5pmTo12am = 0;
      for (let i = from; i <= to; i++) {
        if (i > 22 && i <= 24) {
          nightDifferential5pmTo12am++;
        }
      }
      totalNightDifferential += nightDifferential5pmTo12am;
    }

    return totalNightDifferential;
  }
  computeTotalOvertime(object) {
    let overtime = 0;

    if (
      object.hasOwnProperty("workedHoursFrom12amTo8am_from") &&
      object.hasOwnProperty("workedHoursFrom12amTo8am_to") &&
      object.workedHoursFrom12amTo8am_from.length > 0 &&
      object.workedHoursFrom12amTo8am_to.length > 0
    ) {
      const from = this.separateTime(object.workedHoursFrom12amTo8am_from);
      const to = this.separateTime(object.workedHoursFrom12amTo8am_to);

      overtime += Math.abs(from - to);
    }

    if (
      object.hasOwnProperty("workedHoursFrom5pmTo12am_from") &&
      object.hasOwnProperty("workedHoursFrom5pmTo12am_to") &&
      object.workedHoursFrom5pmTo12am_from.length > 0 &&
      object.workedHoursFrom5pmTo12am_to.length > 0
    ) {
      const from = this.separateTime(object.workedHoursFrom5pmTo12am_from);
      const to = this.separateTime(object.workedHoursFrom5pmTo12am_to);

      overtime += Math.abs(from - (to === 0 ? 24 : to));
    }

    if (
      object.hasOwnProperty("workedHoursFrom8amTo5pm_from") &&
      object.hasOwnProperty("workedHoursFrom8amTo5pm_to") &&
      object.workedHoursFrom8amTo5pm_to.length > 0 &&
      object.workedHoursFrom8amTo5pm_from.length > 0
    ) {
      const from = this.separateTime(object.workedHoursFrom8amTo5pm_from);
      const to =
        this.separateTime(object.workedHoursFrom8amTo5pm_to) === 24
          ? 12
          : this.separateTime(object.workedHoursFrom8amTo5pm_to);

      // overtime += Math.abs(from - to);
    }

    if (
      object.hasOwnProperty("workedHoursFrom12pmTo1pm") &&
      object.workedHoursFrom12pmTo1pm
    ) {
      overtime++;
    }

    return overtime;
  }
  deleteObjects() {
    delete this.change.workedHoursFrom12amTo8am_from;
    delete this.change.workedHoursFrom12amTo8am_to;
    delete this.change.workedHoursFrom5pmTo12am_from;
    delete this.change.workedHoursFrom5pmTo12am_to;
    delete this.change.workedHoursFrom8amTo5pm_to;
    delete this.change.workedHoursFrom8amTo5pm_from;
    delete this.change.workedHoursFrom12pmTo1pm;
  }

  async submit() {
    const nightDifferential = this.computeNightDifferential(this.change);
    const totalOvertime = this.computeTotalOvertime(this.change);
    const roles = this.view.getCurrentRoles().map((item) => item.name);
    const isEditing = this.view.isEditing();

    this.deleteObjects();
    if (!isEditing) {
      if (roles.includes("masterAdmin")) {
        this.change = {
          ...this.change,
          totalOvertime,
          nightDifferential,
          employeeName: this.object.employee.fullName,
        };
      } else {
        const currentUser = this.view.getCurrentUser();

        this.change = {
          ...this.change,
          totalOvertime,
          nightDifferential,
          employee: currentUser,
          employeeName: currentUser.fullName,
        };
      }
    }

    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }

    try {
      this.view
        .showAddOvertimeDialog()
        .then(async () => {
          try {
            this.view.submitting();
            await this.save();
            this.view.submissionSuccess();

            return this.view.showProcessedOvertimeDialog();
          } catch (error) {
            throw new Error(`Error during submission: ${error}`);
          }
        })
        .then(() => {
          this.view.navigateBack();
        })
        .catch((error) => {
          this.view.submissionError(error);
          this.view.showError(error);
        });
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  // Change table label from the default schema label
  formatTableFieldsLabel(fields) {
    const view = this.view.getArgument();

    if (view) {
      return {
        ...fields,
        employeeName: { ...fields.employeeName },
        department: { type: "String", label: "Department" },
        employeeType: { type: "String", label: "Position" },
        projectName: { type: "String", label: "Project Name" },
        approver: { type: "String", label: "Approver" },
      };
    }

    return {
      ...fields,
      employeeName: { ...fields.employeeName },
      department: { type: "String", label: "Department" },
      employeeType: { type: "String", label: "Position" },
    };
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    if (id) {
      const params = { include: ["employee.roles", "projectName.name"] };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }
}

export default OvertimeFormPresenter;
