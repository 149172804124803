import React, { useState } from "react";
import InputFactory from "../InputFactory";

const newCategoryData = {
  questions: [
    {
      service: {},
      rate: 0,
      quantity: 0,
      amount: 0,
      name: "",
      description: "",
    },
  ],
};

function ExamQuestion({
  object,
  setObjectState,
  back,
  onSubtotalChange,
  onTaxChange,
  onDPChange,
  onTotalChange,
  onChangeService,
  onNameChange,
  onDescriptionChange,
}) {
  const [discountType, setDiscountType] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [asfType, setAsfType] = useState("");
  const [asfAmount, setAsfAmount] = useState(0);
  const [downpaymentType, setDownpaymentType] = useState("");
  const [
    downpaymentAmount,
    setDownpaymentAmount,
    downpaymentVal,
    setDownpaymentVal,
  ] = useState(0);

  function onAddCategory(e) {
    e.preventDefault();
    const newCategory = JSON.parse(JSON.stringify(newCategoryData));
    const newCategories = [...object.services, newCategory];
    const updatedExam = { ...object, services: newCategories };
    setObjectState(updatedExam);
  }

  // function onDeleteCategory(id, categoryIndex) {
  //   const updatedCategories = [...object.services];
  //   // if (updatedCategories[categoryIndex].id) {
  //   const updatedDesc = object.descriptionArray
  //     ? [...object.descriptionArray]
  //     : [];

  //   if (updatedCategories[categoryIndex]) {
  //     updatedCategories[categoryIndex].__operation = "REMOVE";
  //   } else {
  //     updatedCategories.splice(categoryIndex, 1);
  //   }
  //   if (updatedDesc[categoryIndex]) {
  //     console.log("DA", updatedDesc);
  //     // updatedDesc.splice(categoryIndex, 1);
  //     updatedDesc[categoryIndex].__operation = "REMOVE";
  //   }

  //   console.log("NEW OBJECT", updatedCategories);

  //   const updatedExam = { ...object, services: updatedCategories };

  //   setObjectState(updatedExam);

  //   let sessionDescriptions =
  //     JSON.parse(sessionStorage.getItem("updatedDescriptions")) || [];

  //   sessionDescriptions = sessionDescriptions.filter(
  //     (desc) => desc.categoryIndex !== categoryIndex
  //   );

  //   sessionDescriptions = sessionDescriptions.map((desc) => {
  //     if (desc.categoryIndex > categoryIndex) {
  //       desc.categoryIndex -= 1;
  //     }
  //     return desc;
  //   });

  //   sessionStorage.setItem(
  //     "updatedDescriptions",
  //     JSON.stringify(sessionDescriptions)
  //   );
  // }
  function onDeleteCategory(id, categoryIndex) {
    // Copy of services and descriptionArray
    const updatedCategories = [...object.services];
    const updatedDesc = object.descriptionArray
      ? [...object.descriptionArray]
      : [];

    // Find the service index based on its id (since indexes may not always match)
    const serviceIndex = updatedCategories.findIndex(
      (service) => service.id === id
    );

    if (serviceIndex === -1) {
      console.error("Service not found");
      return;
    }

    // Remove the corresponding service
    updatedCategories.splice(serviceIndex, 1);

    // Remove the corresponding description by matching categoryIndex
    const descriptionIndex = updatedDesc.findIndex(
      (desc) => desc.categoryIndex === categoryIndex
    );
    if (descriptionIndex !== -1) {
      updatedDesc.splice(descriptionIndex, 1);
    }

    // Adjust categoryIndex for remaining descriptions with higher indices
    const adjustedDesc = updatedDesc.map((desc) => {
      if (desc.categoryIndex > categoryIndex) {
        desc.categoryIndex -= 1; // Decrease the index to account for the removed item
      }
      return desc;
    });

    // Update the services and descriptions in the object and set the state
    const updatedExam = {
      ...object,
      services: updatedCategories,
      descriptionArray: adjustedDesc,
    };
    setObjectState(updatedExam);

    // Handle updatedDescriptions in sessionStorage (if required)
    let sessionDescriptions =
      JSON.parse(sessionStorage.getItem("updatedDescriptions")) || [];

    // Filter and remove the corresponding description in sessionStorage
    sessionDescriptions = sessionDescriptions.filter(
      (desc) => desc.categoryIndex !== categoryIndex
    );

    // Adjust indices in sessionStorage
    sessionDescriptions = sessionDescriptions.map((desc) => {
      if (desc.categoryIndex > categoryIndex) {
        desc.categoryIndex -= 1;
      }
      return desc;
    });

    // Save the updated descriptions back to sessionStorage
    sessionStorage.setItem(
      "updatedDescriptions",
      JSON.stringify(sessionDescriptions)
    );

    // Logging for debugging
    console.log("Updated Categories: ", updatedCategories);
    console.log(
      "Updated Descriptions in sessionStorage: ",
      sessionDescriptions
    );
  }

  // function onDeleteCategory(id, categoryIndex) {
  //   console.log("iiiiiii", categoryIndex);
  //   const updatedCategories = [...object.services];
  //   const updatedDesc = object.descriptionArray
  //     ? [...object.descriptionArray]
  //     : [];

  //   console.log("updatedDesc: ", updatedDesc);

  //   // Mark the category for removal in services (updatedCategories)
  //   if (updatedCategories[categoryIndex]) {
  //     updatedCategories[categoryIndex].__operation = "REMOVE";
  //   } else {
  //     updatedCategories.splice(categoryIndex, 1); // Remove the item if it doesn't have an id
  //   }

  //   // Mark the description for removal or handle it accordingly
  //   if (updatedDesc[categoryIndex]) {
  //     console.log("DA", updatedDesc[categoryIndex]);
  //     // updatedDesc.splice(categoryIndex, 1);
  //     updatedDesc[categoryIndex].__operation = "REMOVE";

  //     console.log("DA1", updatedDesc);
  //   } else {
  //     updatedDesc[categoryIndex].__operation = "REMOVE";
  //   }

  //   // Update the services in object and set the state
  //   const updatedExam = { ...object, services: updatedCategories };
  //   setObjectState(updatedExam);

  //   // Handle updatedDescriptions in sessionStorage
  //   let sessionDescriptions =
  //     JSON.parse(sessionStorage.getItem("updatedDescriptions")) || [];

  //   // Remove the corresponding description by filtering out the matching categoryIndex
  //   sessionDescriptions = sessionDescriptions.filter(
  //     (desc) => desc.categoryIndex === updatedDesc
  //   );

  //   // After deletion, adjust the categoryIndex for all descriptions with a higher index
  //   sessionDescriptions = sessionDescriptions.map((desc) => {
  //     if (desc.categoryIndex > categoryIndex) {
  //       desc.categoryIndex -= 1;
  //     }
  //     return desc;
  //   });

  //   // Save the updated descriptions back to sessionStorage
  //   sessionStorage.setItem("updatedDescriptions", JSON.stringify(updatedDesc));

  //   console.log("Updated Categories: ", updatedCategories);
  //   console.log(
  //     "Updated Descriptions in sessionStorage: ",
  //     sessionDescriptions
  //   );
  // }

  function handleChangeQuantity(categoryIndex, questionIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.services];
    const question = newCategories[categoryIndex].questions[questionIndex];
    question.quantity = parseFloat(value) || 0;
    question.amount = question.rate * question.quantity;
    setObjectState({ ...object, services: newCategories });
  }

  function handleChangeRate(categoryIndex, questionIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.services];
    const question = newCategories[categoryIndex].questions[questionIndex];
    question.rate = parseFloat(value) || 0;
    question.amount = question.rate * question.quantity;
    setObjectState({ ...object, services: newCategories });
  }

  function handleChangeName(categoryIndex, questionIndex, event) {
    onNameChange(event);
    sessionStorage.setItem("updatedName", JSON.stringify(event));
  }

  function handleChangeDescription(categoryIndex, questionIndex, event) {
    // Call the provided onDescriptionChange function
    onDescriptionChange(event);

    // Retrieve the existing descriptions from sessionStorage
    let updatedDescriptions =
      JSON.parse(sessionStorage.getItem("updatedDescriptions")) || [];

    // Create a new description object
    const newDescription = {
      categoryIndex,
      questionIndex,
      description: event,
    };

    // Find if there's already a description for this category and question
    const existingDescriptionIndex = updatedDescriptions?.findIndex(
      (desc) =>
        desc.categoryIndex === categoryIndex &&
        desc.questionIndex === questionIndex
    );

    if (existingDescriptionIndex !== -1) {
      // Update existing description
      updatedDescriptions[existingDescriptionIndex] = newDescription;
    } else {
      // Add new description
      updatedDescriptions.push(newDescription);
    }

    // Save the updated descriptions array to sessionStorage
    sessionStorage.setItem(
      "updatedDescriptions",
      JSON.stringify(updatedDescriptions)
    );
  }

  function handleServiceChange(categoryIndex, questionIndex, value) {
    console.log("vak", value);

    // Create a copy of the services array
    const newCategories = [...object.services];

    // Update the specific question with the new value
    newCategories[categoryIndex].questions[questionIndex].name = value.name;
    newCategories[categoryIndex].questions[questionIndex].description =
      value.description;
    newCategories[categoryIndex].questions[questionIndex].service = value;

    // Create a new updated object
    const updatedExam = { ...object, services: newCategories };

    // Update the state
    setObjectState(updatedExam);

    // Save the updated object in session storage
    // sessionStorage.setItem("updatedExam", JSON.stringify(updatedExam));
    handleChangeDescription(categoryIndex, questionIndex, value.description);
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
      minimumFractionDigits: 2, // Ensure at least 2 decimal places
      maximumFractionDigits: 2, // Limit to 2 decimal places
    }).format(amount);
  };

  function calculateTax(discount, dType, asf, aType) {
    const subtotal = calculateSubtotal();
    let total = subtotal;

    // DISCOUNT
    let discountValue = discount;
    if (dType === "percent") {
      discountValue = subtotal * (discount / 100);
    }
    total -= discountValue;

    // ASF
    let asfValue = asf;
    if (aType === "percent") {
      asfValue = subtotal * (asf / 100);
    }
    total += asfValue;

    const tax = total * 0.12;
    onTaxChange(tax);
    return tax;
  }

  function calculateTotal(discount, dType, asf, aType) {
    const subtotal = calculateSubtotal();
    let total = subtotal;
    console.log("Total", total);

    // DISCOUNT
    let discountValue = discount;
    if (dType === "percent") {
      discountValue = subtotal * (discount / 100);
    }
    total -= discountValue;
    console.log("Total After Discount", total);

    // ASF
    let asfValue = asf;
    if (aType === "percent") {
      asfValue = subtotal * (asf / 100);
    }
    total += asfValue;
    console.log("Total After ASF", total);

    // TAX
    const tax = total * 0.12;
    total += tax;
    console.log("Total After TAX", total);

    // DP
    // let dpValue = dp;
    // if (dpType === "percent") {
    //   dpValue = (subtotal - discount + asf + tax) * (dp / 100);
    // }

    total -= calculateDP(object.downpayment, object.downpaymentType) || 0;

    onTotalChange(total);
    return total;
  }

  function calculateTotalNoTax(discount, dType, asf, aType) {
    const subtotal = calculateSubtotal();
    let total = subtotal;

    // DISCOUNT
    let discountValue = discount;
    if (dType === "percent") {
      discountValue = subtotal * (discount / 100);
    }
    total -= discountValue;

    // ASF
    let asfValue = asf;
    if (aType === "percent") {
      asfValue = subtotal * (asf / 100);
    }
    total += asfValue;

    // DP
    // let dpValue = dp;
    // if (dpType === "percent") {
    //   dpValue = (subtotal - discount + asf) * (dp / 100);
    // }

    total -= calculateDP(object.downpayment, object.downpaymentType) || 0;

    onTotalChange(total);
    return total;
  }

  function calculateDiscount(discount, type) {
    const subtotal = calculateSubtotal();
    if (type === "percent") {
      return subtotal * (discount / 100);
    }

    return discount;
  }

  function calculateAsf(asf, type) {
    const subtotal = calculateSubtotal();
    if (type === "percent") {
      return subtotal * (parseFloat(asf) / 100);
    }

    return asf;
  }

  function calculateDP(dp, type) {
    const subtotal = calculateSubtotal() || 0;
    const discount = object.discount || 0;
    const asf = object.asf || 0;
    const tax =
      object.addVAT === true
        ? calculateTax(
            object.discount,
            object.discountType,
            object.asf,
            object.asfType
          )
        : 0;

    if (type === "percent") {
      return (subtotal - discount + asf + tax) * (dp / 100);
    }

    return dp;
  }

  function handleDiscountTypeChange(event) {
    setDiscountType(event.target.value);
    const { value } = event.target;

    setObjectState({ ...object, discountType: value });
  }

  function handleDiscount(value, field) {
    setDiscountAmount(parseFloat(value) || 0);
    setObjectState({ ...object, discount: parseFloat(value) || 0 });
  }

  function handleDownpaymentTypeChange(event) {
    setDownpaymentType(event.target.value);
    const { value } = event.target;
    setObjectState({ ...object, downpaymentType: value });
  }

  function handleDownpayment(value, field) {
    setDownpaymentAmount(parseFloat(value) || 0);
    // setDownpaymentVal(parseFloat(value) || 0);
    setObjectState({ ...object, downpaymentAmount: parseFloat(value) || 0 });
    setObjectState({
      ...object,
      downpaymentVal:
        parseFloat(calculateDP(downpaymentAmount, downpaymentType)) || 0,
    });
  }

  function calculateSubtotal() {
    const totalBeforeDiscount = object.services?.reduce((total, service) => {
      if (service.__operation === "REMOVE") return total;
      return (
        total +
        service.questions.reduce((serviceTotal, question) => {
          return serviceTotal + (question.amount || 0);
        }, 0)
      );
    }, 0);

    onSubtotalChange(Math.max(totalBeforeDiscount, 0));
    return Math.max(totalBeforeDiscount, 0);
  }

  function calculateSubtotalNoDiscAsf() {
    const totalBeforeDiscount = object.services?.reduce((total, service) => {
      if (service.__operation === "REMOVE") return total;
      return (
        total +
        service.questions.reduce((serviceTotal, question) => {
          return serviceTotal + (question.amount || 0);
        }, 0)
      );
    }, 0);

    return Math.max(totalBeforeDiscount, 0);
  }

  function handleExclusiveChange(value, field) {
    setObjectState({ ...object, exclusive: value });
  }

  function handleNotesChange(value, field) {
    setObjectState({ ...object, notes: value });
  }
  function handleVAT(value, field) {
    setObjectState({ ...object, addVAT: value });
  }

  function handleAsfTypeChange(event) {
    setAsfType(event.target.value);
    const { value } = event.target;

    setObjectState({ ...object, asfType: value });
  }

  function handleAsf(value, field) {
    setAsfAmount(parseFloat(value) || 0);
    setObjectState({ ...object, asf: parseFloat(value) || 0 });
  }

  console.log("OBJEFNJDNFNSDFIOEFEGEGSEGEG", object);

  return (
    <>
      <div className="row g-3 mb-3">
        <div>
          <hr className="dropdown-divider" />
        </div>

        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>SERVICE</th>
              <th>NAME</th>
              <th>DESCRIPTION</th>
              <th>QTY</th>
              <th>RATE</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {object?.services?.map((service, category_index) => {
              return (
                <>
                  {!service.__operation && (
                    <>
                      <tr key={service.id || category_index}>
                        {service?.questions.map((question, question_index) => {
                          return (
                            <React.Fragment key={question_index}>
                              <td>
                                <span
                                  className="ms-2"
                                  role="button"
                                  onClick={() =>
                                    onDeleteCategory(service.id, category_index)
                                  }
                                >
                                  <i className="bi bi-trash"></i>
                                </span>
                              </td>
                              <td>
                                <InputFactory
                                  type="ModdedInputPointer"
                                  target="services_list"
                                  field="name"
                                  placeholder={
                                    question.service?.name ?? "Add Service"
                                  }
                                  defaultValue={question.service?.name}
                                  object={object}
                                  required={true}
                                  onChange={(e) =>
                                    handleServiceChange(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <InputFactory
                                  type="String"
                                  field="nameService"
                                  object={object}
                                  placeholder="name"
                                  defaultValue={question.service?.name}
                                  required={true}
                                  disabled
                                  // disabled={[
                                  //   "Approved",
                                  //   "Sent",
                                  //   "Paid",
                                  // ].includes(object.statuses)}
                                  onChange={(e) =>
                                    handleChangeName(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <InputFactory
                                  type="TextArea"
                                  field="nameDescription"
                                  object={object}
                                  // placeholder={
                                  //   question.service?.description ??
                                  //   "description"
                                  // }
                                  defaultValue={
                                    object.descriptionArray &&
                                    object.descriptionArray.find(
                                      (desc) =>
                                        desc.categoryIndex === category_index
                                    )
                                      ? object.descriptionArray.find(
                                          (desc) =>
                                            desc.categoryIndex ===
                                            category_index
                                        ).description
                                      : question.service?.description
                                  }
                                  required={true}
                                  disabled={[
                                    "Approved",
                                    "Sent",
                                    "Paid",
                                  ].includes(object.statuses)}
                                  onChange={(e) =>
                                    handleChangeDescription(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={question.quantity}
                                  onChange={(e) =>
                                    handleChangeQuantity(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={question.rate}
                                  onChange={(e) =>
                                    handleChangeRate(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={question.amount}
                                  disabled
                                  required
                                />
                              </td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    </>
                  )}
                </>
              );
            })}
          </tbody>
        </table>

        <button
          className="btn d-flex justify-content-start align-items-left"
          type="button"
          onClick={onAddCategory}
          style={{ border: "none", width: "150px" }}
        >
          <span className="bi bi-plus-circle-fill text-danger me-1 ms-2"></span>
          <span className="text-danger ms-2">Add Service</span>
        </button>
      </div>
      <div className="row">
        <div className="col-3"></div>
        <div className="card col-9 p-3">
          <div className="row">
            <div className="col-6">
              <div className="col-md-6">
                <label className=" fw-bold">Exclusive Items</label>
                <InputFactory
                  type="TextArea"
                  field="exclusive"
                  object={object}
                  placeholder={"Enter exclusive items.."}
                  required={true}
                  onChange={handleExclusiveChange}
                />
              </div>
              <div className="col-md-6">
                <label className=" fw-bold">Notes</label>
                <InputFactory
                  type="TextArea"
                  field="name"
                  object={object}
                  placeholder={"Enter exclusive items.."}
                  defaultValue="1) Inclusive of Labor Fees (Inclusive of Night Differential) 2) Inclusive of Contingency Fee 3) Inclusive of Indirect Cost Fee 4) Inclusive of Value Added Taxes 5) Price Validity: 30 Days after Receiving 6) If the quotation is approved, Quotation Number must be indicated at Purchase Orders (PO) 7) Any alteration of the design and additional items not included in the contract will be billed accordingly. 8) GMD deserves the right to hold, pullout, suspend delivery if payments and other conditions are not met. - GMD Productions has a NO DP & NO Signed Contract = NO Production Policy - We request the following Payment Terms: 50% downpayment upon confirmation of project - Cost is based on specified requirements however additional requirements other than stated above shall be billed separately. - All permits must be c/o client Account Name: GMD PRODUCTIONS INC BDO CHECKING - 012758000370 BPI CHECKING - 6011 04 82 03"
                  required={true}
                  onChange={handleNotesChange}
                />
              </div>

              <div className="col-md-6">
                <label className="fw-bold">ASF</label>
                <select className="form-control" onChange={handleAsfTypeChange}>
                  <option value="">Select ASF</option>
                  <option value="percent">Percent</option>
                  <option value="value">Value</option>
                </select>
              </div>
              {asfType === "percent" && (
                <InputFactory
                  className="mt-2"
                  type="Number"
                  dynamic={false}
                  field="asf"
                  object={object}
                  placeholder="Enter ASF Percentage..."
                  onChange={handleAsf}
                />
              )}
              {asfType === "value" && (
                <InputFactory
                  className="mt-2"
                  type="Number"
                  dynamic={false}
                  field="asf"
                  object={object}
                  placeholder="Enter ASF Value..."
                  onChange={handleAsf}
                />
              )}
              <label className="form-label">Add VAT?</label>
              <InputFactory
                type="Boolean"
                field="addVAT"
                object={object}
                onChange={handleVAT}
                required={false}
              />
            </div>
            <div className="col-6">
              <div className="col-md-6">
                <label className="fw-bold">Discount</label>
                <select
                  className="form-control"
                  onChange={handleDiscountTypeChange}
                >
                  <option value="">Select Discount</option>
                  <option value="percent">Discount Percent</option>
                  <option value="value">Discount Value</option>
                </select>
              </div>
              {discountType === "percent" && (
                <InputFactory
                  className="mt-2"
                  type="Number"
                  dynamic={false}
                  field="discount"
                  object={object}
                  placeholder="Enter Discount Percentage..."
                  onChange={handleDiscount}
                />
              )}
              {discountType === "value" && (
                <InputFactory
                  className="mt-2"
                  type="Number"
                  dynamic={false}
                  field="discount"
                  object={object}
                  placeholder="Enter Discount Value..."
                  onChange={handleDiscount}
                />
              )}

              <div className="col-12">
                <ul
                  className="list-unstyled ms-1 text-truncate"
                  style={{ width: "100%" }}
                >
                  <li style={{ padding: "5px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="bold" style={{ paddingRight: "20px" }}>
                        Subtotal
                      </span>

                      <span className="bold" style={{ color: "#D8AE3F" }}>
                        {formatCurrency(calculateSubtotalNoDiscAsf())}
                      </span>
                    </div>
                  </li>
                  {object.addVAT && (
                    <li style={{ padding: "5px" }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="bold">Tax 12%</span>
                        <span className="bold" style={{ color: "#D8AE3F" }}>
                          {formatCurrency(
                            calculateTax(
                              object.discount,
                              object.discountType,
                              object.asf,
                              object.asfType
                            )
                          )}
                        </span>
                      </div>
                    </li>
                  )}
                  {object.discount && object.discount !== 0 ? (
                    <li style={{ padding: "5px" }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="bold">Discount</span>
                        <span className="bold" style={{ color: "#D8AE3F" }}>
                          {object.id
                            ? formatCurrency(
                                calculateDiscount(
                                  object.discount,
                                  object.discountType
                                )
                              )
                            : formatCurrency(
                                calculateDiscount(discountAmount, discountType)
                              )}
                        </span>
                      </div>
                    </li>
                  ) : null}
                  {object.asf && object.asf !== 0 ? (
                    <li style={{ padding: "5px" }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="bold">ASF</span>
                        <span className="bold" style={{ color: "#D8AE3F" }}>
                          {object.id
                            ? formatCurrency(
                                calculateAsf(object.asf, object.asfType)
                              )
                            : formatCurrency(calculateAsf(asfAmount, asfType))}
                        </span>
                      </div>
                    </li>
                  ) : null}
                  {object.downpayment && object.downpayment !== 0 ? (
                    <li style={{ padding: "5px" }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="bold">Downpayment</span>
                        <span className="bold" style={{ color: "#D8AE3F" }}>
                          {object.id
                            ? formatCurrency(
                                calculateDP(
                                  object.downpayment,
                                  object.downpaymentType
                                )
                              )
                            : formatCurrency(
                                calculateDP(downpaymentAmount, downpaymentType)
                              )}
                        </span>
                      </div>
                    </li>
                  ) : null}

                  <li style={{ padding: "5px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="bold">Total</span>
                      <span className="bold" style={{ color: "#D8AE3F" }}>
                        {object.addVAT
                          ? formatCurrency(
                              calculateTotal(
                                object.discount,
                                object.discountType,
                                object.asf,
                                object.asfType,
                                object.downpayment,
                                object.downpaymentType
                              )
                            )
                          : formatCurrency(
                              calculateTotalNoTax(
                                object.discount,
                                object.discountType,
                                object.asf,
                                object.asfType,
                                object.downpayment,
                                object.downpaymentType
                              )
                            )}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              {!object.hasOwnProperty("statuses") ||
              object.statuses === "Pending" ? (
                <div className="row text-center">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={back}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6 ">
                    <button
                      type="submit"
                      className="btn  fs-sm me-3"
                      style={{ backgroundColor: "#dfbe68" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExamQuestion;
