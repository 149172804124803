import React, { useState } from "react";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";

const noop = () => {};
const defaultProps = {
  type: "text",
  strict: false,
  onChange: noop,
};

function InputString({ className, strict, onChange, uppercase, ...props }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownOptions] = useState(["Option 1", "Option 2", "Option 3"]); // Example options
  const [inputValue, setInputValue] = useState("");
  const [fetchedStreets, setFetchedStreets] = React.useState([]);

  console.log("options", fetchedStreets);

  const fetchStreets = async () => {
    const query = {
      include: ["project_name"],
    };
    try {
      const data = await findObjectUseCase().execute("budget_request", query);
      setFetchedStreets(data);
    } catch (error) {
      console.error("Failed to fetch barangay streets:", error);
    }
  };

  React.useEffect(() => {
    fetchStreets();
  }, []);

  function onInput(e) {
    const value = e.target.value;
    const character = value.charAt(value.length - 1);
    if (strict && !/^[a-zA-Z]*$/.test(character)) {
      return;
    }
    if (uppercase) {
      e.target.value = value.toUpperCase();
    }
    setInputValue(e.target.value);
    onChange(e.target.value);
  }

  function toggleDropdown() {
    setShowDropdown(!showDropdown);
  }

  function handleOptionSelect(option) {
    const newValue = `#${option.br} ${option.project_name.name}`;
    setInputValue(newValue);
    setShowDropdown(false);
    onChange(newValue);
  }

  return (
    <div>
      <input
        className={classNames("form-control", className)}
        onInput={onInput}
        onClick={toggleDropdown}
        value={inputValue}
        {...props}
      />
      {showDropdown && (
        <ul className="dropdown-menu show">
          {fetchedStreets.map((option, index) => (
            <li key={index} onClick={() => handleOptionSelect(option)}>
              #{option.br} {option.project_name.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

InputString.defaultProps = defaultProps;
export default InputString;
