import { browseFile } from "nq";
import BaseListPresenter from "../../base/BaseListPresenter";
import Papa from "papaparse";

class HolidaysPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filter2 = {};
    this.filter3 = {};
    this.filterDate = {};
    this.filterList = {};
    this.filterEmployee = {};
    this.include = ["all"];
    this.keys = [];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filter2,
        ...this.filterDate,
        ...this.filter3,
        ...this.filterList,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }
  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  filterSubmit2(where) {
    this.reset();
    this.filter2 = where;

    this.getObjects();
  }

  filterSubmit3(where) {
    this.reset();
    this.filter3 = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeEmployee(where) {
    this.filterEmployee = where;
    this.getObjects();
  }
  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/forms/holidays");
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/forms/holidays/" + object.id);
  }

  parseCSV(csvText) {
    Papa.parse(csvText, {
      header: true,
      complete: (results) => {
        const dataArray = results.data;
        const dataObject = dataArray.reduce((obj, item) => {
          obj[item.name] = item;
          return item;
        }, {});
        this.upsertUseCase.execute("holidays", dataObject);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
        alert("An error occurred while parsing the CSV file.");
      },
    });
  }
}

export default HolidaysPresenter;
