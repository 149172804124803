import React from "react";
import logo from "../../../assets/gmd-logo.png";
import dateFormat from "../../../dateFormat";

export default function PurchaseOrderPrint({ object }) {
  console.log("Discount", object);
  const paper = {
    padding: "5mm",
    fontFamily: "Montserrat, sans-serif",
  };

  const logoStyle = {
    width: "30mm",
  };

  const header = {
    display: "flex",
    justifyContent: "space-between",
  };

  const styles = {
    table: {
      borderCollapse: "collapse",
      marginBottom: "10px",
      width: "100%",
      border: "1px solid #FFA500",
    },
    tableDesc: {
      borderCollapse: "collapse",
      marginBottom: "10px",
      width: "100%",
    },
    header: {
      backgroundColor: "#FFA500",
      color: "white",
      padding: "10px",
    },
    dataCell: {
      padding: "10px",
      verticalAlign: "top",
    },
    headerTotal: {
      backgroundColor: "#2e2e2e",
      color: "white",
      padding: "10px",
    },
    dataCellTotal: {
      backgroundColor: "#2e2e2e",
      color: "white",
      padding: "10px",
      verticalAlign: "top",
      textAlign: "right",
    },
    itemTable: {
      borderCollapse: "collapse",
      width: "100%",
      marginBottom: "10px",
    },
    summaryTable: {
      borderCollapse: "collapse",
      width: "100%",
    },
    headerCell: {
      backgroundColor: "#FFA500",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    itemCell: {
      padding: "10px",
      border: "1px solid #000",
      borderTop: "none",
    },
    summaryHeaderCell: {
      backgroundColor: "#000",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    summaryCell: {
      padding: "10px",
      border: "1px solid #000",
      backgroundColor: "#f2f2f2",
    },
  };
  const line = {
    border: "1px solid #FFA500",
  };

  const vendorShipping = {
    display: "flex",
    justifyContent: "space-between",
    gap: "2mm",
  };

  const subtotal = object.items
    ?.map((items) => Number(items.amounts?.replace(/,/g, "")) * items.quantity)
    .reduce((acc, prev) => acc + prev);

  console.log("SUBTOTAL", subtotal);

  const taxRate = 0.0;
  const tax = subtotal * taxRate;
  const shippingCost = 0;
  const total = subtotal + tax + shippingCost;
  const discount = object?.discount ? object?.discount : 0;
  const grandTotal = total - discount;

  const vendors = object.vendor_name;

  return (
    <div style={paper} className="">
      <img src={logo} alt="GMD Logo" style={logoStyle} />
      <div style={header}>
        <div>
          <p>
            <b>GMD Productions Inc</b>
          </p>
          <p>Mobile No: 0917 583 7654</p>
          <p>Office No: 7092-8807</p>
          <p>Website: https://www.gmd.ph/</p>
        </div>
        <div style={{ textAlign: "end" }}>
          <p>
            <b>Purchase Order</b>
          </p>
          <p>DATE: {dateFormat(object.updatedAt)}</p>
          <p>PO #: {object.po_num}</p>
        </div>
      </div>
      <div style={vendorShipping}>
        <table style={styles.table}>
          <tr>
            <th style={styles.header} colSpan="2">
              Vendor
            </th>
          </tr>
          <tr>
            <td style={styles.dataCell}>{vendors?.name}</td>
          </tr>
          <tr>
            <td style={styles.dataCell}>{object.vendor_address}</td>
          </tr>
          <tr>
            <td style={styles.dataCell}>Tel No: {vendors?.contact_number}</td>
          </tr>
          <tr>
            <td style={styles.dataCell}>Email: {object.email}</td>
          </tr>
        </table>

        <table style={styles.table}>
          <tr>
            <th style={styles.header} colSpan="2">
              Shipping Info
            </th>
          </tr>
          <tr>
            <td style={styles.dataCell}>Shipping Terms</td>
            <td style={styles.dataCell}>-</td>
          </tr>
          <tr>
            <td style={styles.dataCell}>Delivery Date</td>
            <td style={styles.dataCell}>-</td>
          </tr>
        </table>
      </div>
      <table style={styles.tableDesc}>
        <tr style={line}>
          <th style={styles.header} colSpan="9">
            Item Description
          </th>
          <th style={styles.header} colSpan="1">
            QTY
          </th>
          <th style={styles.header} colSpan="1">
            Unit Price
          </th>
          <th style={styles.headerTotal} colSpan="1">
            Total
          </th>
        </tr>

        {/* map this below */}
        {object.items?.map((item) => (
          <tr style={line} key={item.id}>
            <td style={styles.dataCell} colSpan="9">
              {item.name}
            </td>
            <td style={styles.dataCell} colSpan="1">
              {item.quantity}
            </td>
            <td style={styles.dataCell} colSpan="1">
              ₱ {item.amounts}
            </td>
            <td style={styles.dataCellTotal} colSpan="1">
              ₱{" "}
              {(
                Number(item.amounts?.replace(/,/g, "")) * Number(item.quantity)
              ).toLocaleString()}
            </td>
          </tr>
        ))}

        {/* DONT INCLUDE THE ROW BELOW IN BORDER */}

        <tr>
          <td style={styles.dataCell} colSpan="9"></td>
          <td style={styles.dataCell} colSpan="1"></td>
          <td style={styles.dataCell} colSpan="1">
            SUBTOTAL
          </td>
          <td style={styles.dataCellTotal} colSpan="1">
            ₱ {subtotal?.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={styles.dataCell} colSpan="9"></td>
          <td style={styles.dataCell} colSpan="1"></td>
          <td style={styles.dataCell} colSpan="1">
            DISCOUNT
          </td>
          <td style={styles.dataCellTotal} colSpan="1">
            ₱ {object.discount?.toLocaleString() || 0}
          </td>
        </tr>

        <tr>
          <td style={styles.dataCell} colSpan="9"></td>
          <td style={styles.dataCell} colSpan="1"></td>
          <td style={styles.dataCell} colSpan="1">
            TAX({taxRate * 100}%)
          </td>
          <td style={styles.dataCellTotal} colSpan="1">
            {/* ₱ {tax?.toFixed(2)} */}₱ {0}
          </td>
        </tr>

        <tr>
          <td style={styles.dataCell} colSpan="9"></td>
          <td style={styles.dataCell} colSpan="1"></td>
          <td style={styles.dataCell} colSpan="1">
            SHIPPING
          </td>
          <td style={styles.dataCellTotal} colSpan="1">
            ₱ {shippingCost.toFixed(2)}
          </td>
        </tr>

        <tr>
          <td style={styles.dataCell} colSpan="9"></td>
          <td style={styles.dataCell} colSpan="1"></td>
          <td style={styles.dataCell} colSpan="1">
            <b>TOTAL</b>
          </td>
          <td style={styles.dataCellTotal} colSpan="1">
            ₱ {grandTotal.toLocaleString()}
          </td>
        </tr>
      </table>
    </div>
  );
}
