import BaseFormPage from "../../../base/BaseFormPage";
import NavBar from "../../../components/navbar";
import { getObjectUseCase, upsertUseCase } from "../../../usecases/object";
import { restUseCase } from "../../../usecases/rest";
import withRouter from "../../../withRouter";
import Tabs from "../components/Tabs";
import SettingsFormPresenter from "./SettingsFormPresenter";

class SettingsFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = { object: {} };
    this.presenter = new SettingsFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase()
    );
  }

  getCollectionName() {
    return "users";
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { object } = this.state;

    return (
      <div className="h-100" style={{ background: "white" }}>
        <NavBar />
        {/* <Toaster /> */}
        <div
          className="container-fluid"
          style={{
            paddingInline: "2rem",
            paddingBlock: "2rem",
            background: "white",
          }}
        >
          <div className="row align-items-center ">
            <p className="fs-3 fw-bold col-auto m-0">Profile Settings</p>
          </div>
          <div className="mt-4">
            <Tabs schema={schema} object={object} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SettingsFormPage);
