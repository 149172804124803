import React from "react";
import { InputJson } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory2 from "../../components/FormFactory2";
import { restUseCase } from "../../usecases/rest";
import TaskFormPresenter from "./TaskFormPresenter";
import InputFactory from "../../components/InputFactory";
import Drop from "../../components/DropdownFilter";

class TaskFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      selectedProject: null,
      filtered: [],
    };
    this.presenter = new TaskFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "tasks";
  }

  onClickAdvance() {
    this.setState({
      advanced: !this.state.advanced,
    });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  handleProjectChange = (value) => {
    const selectedProject = this.state.filtered.find(
      (project) => project.id === value
    );
    this.setState({ selectedProject: selectedProject || null });
  };

  async onChange(value, field) {
    if (field === "client") {
      const clientName = value.id;
      const proj = await findObjectUseCase().execute("projects");
      console.warn("proj", proj);
      const filteredProjects = proj
        .filter((project) => project.client?.id === clientName)
        .map((project) => ({
          name: project.name,
          id: project.id,
        }));
      this.setState({
        filtered: filteredProjects,
        selectedProject:
          filteredProjects.length > 0 ? filteredProjects[0] : null,
      });
    } else if (field === "project" && !value) {
      this.setState({ filtered: [], selectedProject: null });
    } else if (Array.isArray(value)) {
      this.setState({ itemsAmount: value });
    }

    this.presenter.onChange(value, field);
  }

  render() {
    const selectedProject = this.state.selectedProject;
    const object = this.state.object;
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label + (schema.label || this.getCollectionName())}
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    {advanced ? (
                      <InputJson
                        defaultValue={object}
                        onChange={this.onChangeObject.bind(this)}
                        rows="15"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mt-3 mb-3">
                    <label className="form-label">Client Name</label>
                    <InputFactory
                      type="UserInputPointer"
                      target="clients"
                      dynamic={false}
                      field="client"
                      object={object}
                      placeholder="Select Client"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Project Name</label>
                    {this.state.filtered.length > 0 ||
                    selectedProject ||
                    object.project?.name ? (
                      <>
                        <Drop
                          placeholder={
                            selectedProject
                              ? "Select a project"
                              : object.project?.name
                          }
                          value={
                            selectedProject
                              ? selectedProject
                              : object.project?.name
                          }
                          options={this.state.filtered}
                          onChange={this.handleProjectChange}
                        />
                      </>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Task Type</label>
                    <InputFactory
                      type="UserInputPointer"
                      target="type_of_tasks"
                      dynamic={false}
                      field="type"
                      object={object}
                      placeholder="Select Task Type"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Task Name</label>
                    <InputFactory
                      type="String"
                      dynamic={false}
                      field="name"
                      object={object}
                      placeholder="Enter Task Name"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Location</label>
                    <InputFactory
                      type="String"
                      dynamic={false}
                      field="location"
                      object={object}
                      placeholder="Enter Location"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <InputFactory
                      type="String"
                      dynamic={false}
                      field="description"
                      object={object}
                      placeholder="Enter Description"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Assigned to?</label>
                    <InputFactory
                      type="Relation"
                      target="users"
                      dynamic={false}
                      field="employee"
                      label="Employee"
                      object={object}
                      placeholder="Select Assignee/s"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Start Date</label>
                    <InputFactory
                      type="Date"
                      dynamic={false}
                      field="startDate"
                      object={object}
                      placeholder="Choose Start Date"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">End Date</label>
                    <InputFactory
                      type="Date"
                      dynamic={false}
                      field="endDate"
                      object={object}
                      placeholder="Choose End Date"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary fs-sm me-3"
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(TaskFormPage);
