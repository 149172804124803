import React from "react";
import { InputJson } from "nq-component";

import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory from "../../components/FormFactory";
import { restUseCase } from "../../usecases/rest";
import InvoicesFormPresenter from "./InvoicesFormPresenter";
import InputFactory from "../../components/InputFactory";
import CustomDate from "./CustomDate";
import InvoTable from "./InvoTable";
import Drop from "../../components/DropdownFilter";
class InvoicesFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      due_date: {},
      advanced: false,
      discountType: "percent",
      amounts: 0,
      asfType: "percent",
      discountedAmount: 0,
      discountedAmountWithDiscount: 0,
      discountedAmountWithASF: 0,
      services: [],
      subtotal: 0,
      totalAmount: 0,
      tax: 0,
    };
    this.presenter = new InvoicesFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase()
    );
  }
  getCollectionName() {
    return "invoices";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }
  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  onChange(value, field) {
    const { object, discountType, asfType } = this.state;
    let amounts = object.qty * object.rate;
    this.setState({ amounts: amounts });
    if (amounts !== undefined && amounts !== null) {
      const amount = parseFloat(amounts);

      if (!isNaN(amounts)) {
        let discountedAmount = amount;
        if (discountType === "percent") {
          discountedAmount = parseFloat(
            amount - (amount * object.discount) / 100
          );
          this.setState({ amounts: discountedAmount });
        } else if (discountType === "value") {
          discountedAmount = parseFloat(amount - object.discount);
          this.setState({ amounts: discountedAmount });
        }
        let newAmountWithASF = discountedAmount;
        if (asfType === "percent") {
          newAmountWithASF = parseFloat(
            discountedAmount + (amount * object.asf) / 100
          );
          this.setState({ amounts: newAmountWithASF });
        } else if (asfType === "value") {
          newAmountWithASF = parseFloat(
            discountedAmount + parseFloat(object.asf)
          );
          this.setState({ amounts: newAmountWithASF });
        }

        // Update the state with the new discounted amount and amount with ASF
        this.setState({
          discountedAmount: discountedAmount,
          discountedAmountWithASF: newAmountWithASF,
        });
      }
    }
  }

  handleDiscountType(field) {
    this.setState({ discountType: field }, () => {
      this.onUpdateServices(this.state.services);
    });
  }

  handleASFType(field) {
    this.setState({ asfType: field }, () => {
      this.onUpdateServices(this.state.services);
    });
  }

  calculateNewDueDate(days) {
    const currentDate = new Date();
    const newDueDate = new Date(
      currentDate.setDate(currentDate.getDate() + days)
    );
    const year = newDueDate.getFullYear();
    const month = String(newDueDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDueDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  onUpdateServices = (updatedServices) => {
    this.setState({ services: updatedServices?.services }, () => {
      const { discountType, asfType, object } = this.state;
      let subtotal = 0;

      if (
        Array.isArray(updatedServices?.services) &&
        updatedServices.services.length > 0
      ) {
        subtotal = updatedServices.services.reduce((acc, service) => {
          let rate = parseFloat((service.rate || "").replace(/,/g, ""));
          return acc + service.qty * rate;
        }, 0);
      }

      let discountedAmount = subtotal;

      let discountValue =
        parseFloat((object.discount || "").replace(/,/g, "")) || 0;
      if (discountType === "percent") {
        discountValue = (subtotal * discountValue) / 100;
      }
      discountedAmount -= discountValue;

      let asfValue = parseFloat((object.asf || "").replace(/,/g, "")) || 0;
      if (asfType === "percent") {
        asfValue = (subtotal * asfValue) / 100;
      }
      discountedAmount += asfValue;

      let tax = discountedAmount * 0.12;
      let totalAmount = discountedAmount + tax;

      this.setState({ subtotal: subtotal, tax: tax, totalAmount: totalAmount });
    });
  };

  render() {
    const {
      object,
      tax,
      services,
      due_date,
      amounts,
      asfType,
      discountType,
      discountedAmount,
      discountedAmountWithASF,
    } = this.state;
    const currentDate = this.getCurrentDateFormatted();
    const schema = this.getSchema(this.getCollectionName());
    const selectedProject = this.state.selectedProject;
    console.log(object);

    console.log("HEREERERE");

    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Create " : "Edit ";
    return (
      <>
        <NavBar />
        <div className="overflow-x-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label + (schema.label || this.getCollectionName())}
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-4 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div>
                    <div className="row">
                      {/* First Column */}
                      <div className="col-lg-6">
                        <label className="form-label pt-3">Client Name</label>
                        <InputFactory
                          type="UserInputPointer"
                          dynamic={false}
                          target="clients"
                          field="client_name"
                          object={object}
                          placeholder="Select Client Name"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                        <label className="form-label pt-3">Client Email</label>
                        <InputFactory
                          type="String"
                          dynamic={false}
                          field="client_email"
                          defaultValue={object.client_name?.email}
                          object={object}
                          placeholder="Enter Client Email"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                        <label className="form-label pt-3">
                          Client Address
                        </label>
                        <InputFactory
                          type="String"
                          dynamic={false}
                          field="client_address"
                          defaultValue={object.client_name?.address}
                          object={object}
                          placeholder="Enter Client Address"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                        <label className="form-label pt-3 ">Due Date</label>
                        <CustomDate
                          defaultDate={currentDate}
                          onDateChange={(newDate) =>
                            this.setState({ due_date: newDate })
                          }
                        />
                      </div>

                      {/* Second Column */}
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-md-10"></div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-6">
                            <label
                              className="form-label"
                              style={{ marginRight: "10px" }}
                            >
                              Discount
                            </label>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Discount Type"
                            >
                              <button
                                type="button"
                                className={`btn ${
                                  this.state.discountType === "percent"
                                    ? "btn-primary"
                                    : "btn-secondary"
                                } m-2`}
                                style={{
                                  padding: "0.15rem 0.5rem",
                                  fontSize: "0.75rem",
                                }}
                                onClick={() =>
                                  this.handleDiscountType("percent")
                                }
                              >
                                %
                              </button>
                              <button
                                type="button"
                                className={`btn ${
                                  this.state.discountType === "value"
                                    ? "btn-primary"
                                    : "btn-secondary"
                                } m-2`}
                                style={{
                                  padding: "0.15rem 0.5rem",
                                  fontSize: "0.75rem",
                                }}
                                onClick={() => this.handleDiscountType("value")}
                              >
                                ₱
                              </button>
                            </div>
                            <InputFactory
                              type="parseNumber"
                              field="discount"
                              object={object}
                              placeholder={
                                this.state.discountType === "percent"
                                  ? "Enter Discount Percentage"
                                  : "Enter Discount Value"
                              }
                              onChange={(value) => {
                                this.setState(
                                  { object: { ...object, discount: value } },
                                  () => {
                                    this.onChange(value, "discount");
                                    this.onUpdateServices(this.state.services);
                                  }
                                );
                              }}
                              required={true}
                              disabled={
                                object.statuses === "Approved" ||
                                object.statuses === "Sent" ||
                                object.statuses === "Paid" ||
                                object.statuses === "Partially Paid"
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <label
                              className="form-label"
                              style={{ marginRight: "10px" }}
                            >
                              ASF
                            </label>
                            <div className="btn-group" role="group">
                              <button
                                type="button"
                                className={`btn ${
                                  this.state.asfType === "percent"
                                    ? "btn-primary"
                                    : "btn-secondary"
                                } m-2`}
                                style={{
                                  padding: "0.15rem 0.5rem",
                                  fontSize: "0.75rem",
                                }}
                                onClick={() => this.handleASFType("percent")}
                              >
                                %
                              </button>
                              <button
                                type="button"
                                className={`btn ${
                                  this.state.asfType === "value"
                                    ? "btn-primary"
                                    : "btn-secondary"
                                } m-2`}
                                style={{
                                  padding: "0.15rem 0.5rem",
                                  fontSize: "0.75rem",
                                }}
                                onClick={() => this.handleASFType("value")}
                              >
                                ₱
                              </button>
                            </div>
                            <InputFactory
                              type="parseNumber"
                              field="asf"
                              object={object}
                              placeholder={
                                this.state.asfType === "percent"
                                  ? "Enter ASF Percentage"
                                  : "Enter ASF Value"
                              }
                              onChange={(value) => {
                                this.setState(
                                  { object: { ...object, asf: value } },
                                  () => {
                                    this.onChange(value, "asf");
                                    this.onUpdateServices(this.state.services);
                                  }
                                );
                              }}
                              required={true}
                            />
                          </div>

                          <div className="mb-3 mt-3">
                            <label className="form-label">Notes</label>
                            <InputFactory
                              type="String"
                              dynamic={false}
                              field="notes"
                              object={object}
                              placeholder="Enter Note"
                              onChange={this.onChange.bind(this)}
                              required={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive mt-2">
                        <InvoTable
                          object={object}
                          disc={discountedAmount}
                          amount={this.state.discountedAmountWithASF}
                          onUpdateServices={this.onUpdateServices}
                          onChange={this.onChange.bind(this)}
                        />
                      </div>

                      <div className="mt-2">
                        <div className="row justify-content-end">
                          <div className="col-md-4">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td>Subtotal</td>
                                  <td className="text-center">
                                    {"₱" + this.state.subtotal}
                                  </td>
                                </tr>

                                <tr>
                                  <td>Discount</td>
                                  <td className="text-center">
                                    {discountType === "percent"
                                      ? `₱${(
                                          (this.state.subtotal *
                                            (parseFloat(object.discount) ||
                                              0)) /
                                          100
                                        ).toFixed(2)}`
                                      : "₱" + (object.discount || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td>ASF</td>
                                  <td className="text-center">
                                    {asfType === "percent"
                                      ? `₱${(
                                          (this.state.subtotal *
                                            (parseFloat(object.asf) || 0)) /
                                          100
                                        ).toFixed(2)}`
                                      : `₱${(
                                          parseFloat(object.asf) || 0
                                        ).toFixed(2)}`}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Tax 12%</td>
                                  <td className="text-center">
                                    ₱{tax.toFixed(2)}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Total Amount</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>
                                      {"₱" + this.state.totalAmount}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-light fs-sm me-1"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                    <button
                      type="submit"
                      className="btn ms-1"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      {" "}
                      SAVE
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(InvoicesFormPage);
