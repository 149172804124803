export const HRIncreases = Object.freeze({
  HRNormalDay: "HRNormalDay",
  HRRegHoliday: "HRRegHoliday",
  HRSpeNonWorking: "HRSpeNonWorking",
  HRTimeOff: "HRTimeOff",
  HRRegTimeOff: "HRRegTimeOff",
  HRSpeTimeOff: "HRSpeTimeOff",

  HRMonday: "HRMonday",
  HRTuesday: "HRTuesday",
  HRWednesday: "HRWednesday",
  HRThursday: "HRThursday",
  HRFriday: "HRFriday",
  HRSaturday: "HRSaturday",
  HRSunday: "HRSunday",
});
export const OTIncreases = Object.freeze({
  OTNormalDay: "OTNormalDay",
  OTRegHoliday: "OTRegHoliday",
  OTSpeNonWorking: "OTSpeNonWorking",
  OTTimeOff: "OTTimeOff",
  OTRegTimeOff: "OTRegTimeOff",
  OTSpeTimeOff: "OTSpeTimeOff",

  OTMonday: "OTMonday",
  OTTuesday: "OTTuesday",
  OTWednesday: "OTWednesday",
  OTThursday: "OTThursday",
  OTFriday: "OTFriday",
  OTSaturday: "OTSaturday",
  OTSunday: "OTSunday",
});
export const NDIncreases = Object.freeze({
  NDNormalDay: "NDNormalDay",
  NDRegHoliday: "NDRegHoliday",
  NDSpeNonWorking: "NDSpeNonWorking",
  NDTimeOff: "NDTimeOff",
  NDRegTimeOff: "NDRegTimeOff",
  NDSpeTimeOff: "NDSpeTimeOff",

  NDMonday: "NDMonday",
  NDTuesday: "NDTuesday",
  NDWednesday: "NDWednesday",
  NDThursday: "NDThursday",
  NDFriday: "NDFriday",
  NDSaturday: "NDSaturday",
  NDSunday: "NDSunday",
});
