import React from "react";
import InputFactory from "../../../components/InputFactory";
import { HRIncreases, NDIncreases, OTIncreases } from "../enums/payroll";

export default function Increases({ onIncreasesAndDeductionsChange, object }) {
  const header = [
    "",
    "Normal Day",
    "REG Holiday",
    "SPE Non-working",
    "Time-off",
    "REG + Time-off",
    "SPE + Time-off",
  ];

  const hrIncreaseFields = [
    HRIncreases.HRNormalDay,
    HRIncreases.HRRegHoliday,
    HRIncreases.HRSpeNonWorking,
    HRIncreases.HRTimeOff,
    HRIncreases.HRRegTimeOff,
    HRIncreases.HRSpeTimeOff,
  ];

  const otIncreaseFields = [
    OTIncreases.OTNormalDay,
    OTIncreases.OTRegHoliday,
    OTIncreases.OTSpeNonWorking,
    OTIncreases.OTTimeOff,
    OTIncreases.OTRegTimeOff,
    OTIncreases.OTSpeTimeOff,
  ];

  const ndIncreaseFields = [
    NDIncreases.NDNormalDay,
    NDIncreases.NDRegHoliday,
    NDIncreases.NDSpeNonWorking,
    NDIncreases.NDTimeOff,
    NDIncreases.NDRegTimeOff,
    NDIncreases.NDSpeTimeOff,
  ];

  return (
    <div className="mt-3">
      <p
        className="fw-semibold mt-5"
        style={{ fontSize: "1.25rem", color: "#434343" }}
      >
        Increases (Computed in hours)
      </p>
      <table
        class="table table-borderless"
        style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)" }}
      >
        <thead>
          <tr style={{ background: "#DAB44E", borderTopRadius: "1rem" }}>
            {header.map((item) => {
              return (
                <th
                  key={item}
                  style={{
                    background: "transparent",
                    fontSize: ".75rem",
                    height: "2.875rem",
                  }}
                  scope="col"
                >
                  <div
                    className="d-flex align-items-center justify-content-center m-0"
                    style={{ height: "100%" }}
                  >
                    {item}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th
              className="ps-3"
              scope="row"
              style={{ fontSize: ".75rem", height: "2.875rem" }}
            >
              <div
                className="d-flex align-items-center   m-0"
                style={{ height: "100%" }}
              >
                HR
              </div>
            </th>
            {hrIncreaseFields.map((field) => {
              return (
                <td key={field} style={{ fontSize: ".75rem" }}>
                  <InputFactory
                    object={object}
                    type="Number"
                    field={field}
                    disabled={field === "HRNormalDay"}
                    onChange={onIncreasesAndDeductionsChange}
                  />
                </td>
              );
            })}
          </tr>
          <tr>
            <th
              className="ps-3"
              scope="row"
              style={{ fontSize: ".75rem", height: "2.875rem" }}
            >
              <div
                className="d-flex align-items-center   m-0"
                style={{ height: "100%" }}
              >
                OT
              </div>
            </th>
            {otIncreaseFields.map((field) => {
              return (
                <td key={field} style={{ fontSize: ".75rem" }}>
                  <InputFactory
                    type="Number"
                    object={object}
                    field={field}
                    onChange={onIncreasesAndDeductionsChange}
                  />
                </td>
              );
            })}
          </tr>
          <tr>
            <th
              className="ps-3"
              scope="row"
              style={{ fontSize: ".75rem", height: "2.875rem" }}
            >
              <div
                className="d-flex align-items-center   m-0"
                style={{ height: "100%" }}
              >
                ND
              </div>
            </th>
            {ndIncreaseFields.map((field) => {
              return (
                <td key={field} style={{ fontSize: ".75rem" }}>
                  <InputFactory
                    type="Number"
                    object={object}
                    field={field}
                    onChange={onIncreasesAndDeductionsChange}
                  />
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
