import React from "react";

export default function CTA({ toggleModal }) {
  return (
    <div className="col d-flex gap-2 w-100 justify-content-end">
      <button
        className="btn"
        style={{ background: "#e6e6e6" }}
        onClick={toggleModal}
      >
        Close
      </button>
    </div>
  );
}
