import BaseFormPage from "../../../base/BaseFormPage";
import NavBar from "../../../components/navbar";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { restUseCase } from "../../../usecases/rest";
import withRouter from "../../../withRouter";
import AdditionalPayments from "../components/AdditionalPayments";
import Adjustments from "../components/Adjustments";
import Deductions from "../components/Deductions";
import EmployeeCard from "../components/EmployeeCard";
import Increases from "../components/Increases";
import OtherDeductions from "../components/OtherDeductions";
import TimeSheetDeductions from "../components/TimeSheetDeductions";
import EditPayrollActionButtons from "../components/EditPayrollActionButtons";
import EditIndividualPayrollPresenter from "./EditIndividualPayrollPresenter";
import LeavesFiled from "../components/LeavesFiled";
import WeeklyIncreases from "../components/WeeklyIncreases";
import createPromise from "../../../createPromise";
import dialog from "../../../components/Modal/dialog";
import EditPayroll from "./components/modal/EditPayroll";
import toast, { Toaster } from "react-hot-toast";
import CashAdvance from "../components/cashAdvance";

class EditIndividualPayrollPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      holidayDays: [],
      regularHolidayPay: 0,
      regularHolidayPayToSave: 0,
      userInfo: {},
      holidays: [],
      caRequest: false,
      leaveRequestInfo: [],
      payrollObject: {},
      cashAdvanceInfo: [],
      increases: {},
      object: {},
      employeeIncreasesAndDeductions: {},
      employeePayrollInfo: {
        daysWorked: 0,
        subtotal: 0,
        deductions: 0,
        total: 0,
      },
    };

    this.presenter = new EditIndividualPayrollPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "payroll";
  }

  setIncreasesObject(increases) {
    this.setState({ increases });
  }

  setEmployeeIncreasesAndDeductsObject(object) {
    this.setState({ employeeIncreasesAndDeductions: object });
  }

  onDeleteAdjustmentField(object, item) {
    this.presenter.onDeleteAdjustmentField(object, item);
  }

  onDeleteOtherDeductionsField(object, item) {
    this.presenter.onDeleteOtherDeductionsField(object, item);
  }

  onEmployeeIncreasesAndDeductionsChange(value, field, date) {
    this.presenter.onEmployeeIncreasesAndDeductionsChange(value, field, date);
  }

  countRegularHolidays() {
    this.presenter.countRegularHolidays();
  }

  showToast(message) {
    toast(message, {
      position: "bottom-right",
      style: {
        background: "#4FBC4C",
        color: "white",
        fontWeight: "600",
      },
    });
  }

  toggleCARequest() {
    this.setState((prev) => ({ ...prev, caRequest: !prev.caRequest }));
  }

  showEditPayrollDialog() {
    const promise = createPromise();
    dialog.fire({
      html: <EditPayroll />,
      positiveButton: "Add",
      negativeButton: "Cancel",
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
    });
    return promise;
  }

  render() {
    const {
      object,
      userInfo,
      cashAdvanceInfo,
      employeeIncreasesAndDeductions,
      leaveRequestInfo,
      increases,
    } = this.state;

    console.log("E", employeeIncreasesAndDeductions);

    return (
      <>
        <NavBar />
        <Toaster />
        <div
          className="container-fluid"
          style={{
            paddingInline: "3rem",
            paddingBlock: "2rem",
            background: "white",
          }}
        >
          <div className="row align-items-center ">
            <i
              class="bi bi-chevron-left col-auto btn btn-link"
              onClick={this.onClickBack.bind(this)}
            ></i>
            <p className="fs-3 fw-bold col-auto m-0">Edit Payroll</p>
          </div>
          <div className="row mt-4 gap-2 position-relative ">
            <div className="col-7">
              <form onSubmit={this.onSubmitForm.bind(this)}>
                <EmployeeCard
                  object={employeeIncreasesAndDeductions}
                  employeeName={userInfo?.fullName}
                  employeePosition={userInfo?.employeeType}
                  type={object.type}
                  monthlyRate={userInfo?.monthlyRate}
                  increases={increases}
                  startDate={this.getArgument().startDate}
                  endDate={this.getArgument().endDate}
                  regularHolidayPay={this.state.regularHolidayPay}
                  cashAdvanceInfo={cashAdvanceInfo}
                />
                {object.type === "Monthly" && (
                  <TimeSheetDeductions
                    object={employeeIncreasesAndDeductions}
                    onIncreasesAndDeductionsChange={this.onEmployeeIncreasesAndDeductionsChange.bind(
                      this
                    )}
                  />
                )}
                {object.type === "Monthly" ? (
                  <Increases
                    object={employeeIncreasesAndDeductions}
                    onIncreasesAndDeductionsChange={this.onEmployeeIncreasesAndDeductionsChange.bind(
                      this
                    )}
                  />
                ) : (
                  <WeeklyIncreases
                    startDate={this.getArgument().startDate}
                    endDate={this.getArgument().endDate}
                    object={employeeIncreasesAndDeductions}
                    onIncreasesAndDeductionsChange={this.onEmployeeIncreasesAndDeductionsChange.bind(
                      this
                    )}
                    leaveRequestInfo={leaveRequestInfo}
                    setEmployeeIncreasesAndDeductsObject={this.setEmployeeIncreasesAndDeductsObject.bind(
                      this
                    )}
                    holidays={this.state.holidays}
                  />
                )}
                <div className="row">
                  <Deductions
                    object={employeeIncreasesAndDeductions}
                    onIncreasesAndDeductionsChange={this.onEmployeeIncreasesAndDeductionsChange.bind(
                      this
                    )}
                  />
                  <CashAdvance
                    object={employeeIncreasesAndDeductions}
                    onIncreasesAndDeductionsChange={this.onEmployeeIncreasesAndDeductionsChange.bind(
                      this
                    )}
                    cashAdvanceInfo={cashAdvanceInfo}
                  />
                </div>
                <OtherDeductions
                  object={employeeIncreasesAndDeductions}
                  onIncreasesAndDeductionsChange={this.onEmployeeIncreasesAndDeductionsChange.bind(
                    this
                  )}
                  onDeleteOtherDeductionsField={this.onDeleteOtherDeductionsField.bind(
                    this
                  )}
                  setEmployeeIncreasesAndDeductsObject={this.setEmployeeIncreasesAndDeductsObject.bind(
                    this
                  )}
                />
                <AdditionalPayments
                  object={employeeIncreasesAndDeductions}
                  onIncreasesAndDeductionsChange={this.onEmployeeIncreasesAndDeductionsChange.bind(
                    this
                  )}
                  caRequest={this.state.caRequest}
                  startDate={this.getArgument().startDate}
                  endDate={this.getArgument().endDate}
                  toggleCARequest={this.toggleCARequest.bind(this)}
                  cashAdvanceInfo={this.state.cashAdvanceInfo}
                />
                <Adjustments
                  object={employeeIncreasesAndDeductions}
                  onIncreasesAndDeductionsChange={this.onEmployeeIncreasesAndDeductionsChange.bind(
                    this
                  )}
                  onDeleteAdjustmentField={this.onDeleteAdjustmentField.bind(
                    this
                  )}
                  setEmployeeIncreasesAndDeductsObject={this.setEmployeeIncreasesAndDeductsObject.bind(
                    this
                  )}
                />
                <EditPayrollActionButtons
                  onClickBack={this.onClickBack.bind(this)}
                />
              </form>
            </div>
            <div className="col position-sticky top-0 ">
              <LeavesFiled leaveRequestInfo={leaveRequestInfo} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EditIndividualPayrollPage);
