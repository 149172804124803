import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import Header from "./components/Header";
import OverviewCard from "./components/OverviewCard";
import TableBreakdown from "./components/TableBreakdown";
import Adjustments from "./components/Adjustments";
import OtherDeductions from "./components/OtherDeductions";
import CTA from "./components/CTA";
import MonthlyTableBreakdown from "./components/MontlyTableBreakdown";
import { findObjectUseCase } from "../../../../usecases/object";

export default function ViewPayslipModal({
  isOpen,
  toggleModal,
  onClickDownload,
  payroll,
  user,
  object,
  holidays,
}) {
  const [monthlyRate, setMonthlyRate] = useState();

  useEffect(() => {
    const getUser = async () => {
      const [user] = await findObjectUseCase().execute("users", {
        where: { id: payroll.userId },
      });

      setMonthlyRate(user.monthlyRate);
    };

    if (payroll) {
      getUser();
    }
  }, [payroll]);

  const isWeekly = object?.type === "Weekly";
  const totalOverviewData = [
    {
      label: isWeekly ? "Days Worked" : "Basic Salary",
      value: isWeekly ? payroll?.daysWorked || 0 : monthlyRate / 2 || 0,
    },
    { label: "Subtotal", value: payroll?.subtotal || monthlyRate / 2 || 0 },
    { label: "Total", value: payroll?.total || monthlyRate / 2 || 0 },
  ];

  const deductionsOverviewData = [
    { label: "Allowances", value: 0 },
    { label: "Cash Advance", value: 0 },
    { label: "Total Deduction", value: payroll?.deductions || 0 },
  ];

  const deductionDetailsData = [
    { label: "CA Deduction", value: payroll?.CADeductions || 0 },
    { label: "TIN", value: payroll?.TIN || 0 },
    { label: "PHILHEALTH", value: payroll?.PHILHEALTH || 0 },
    { label: "SSS", value: payroll?.SSS || 0 },
    { label: "PAGIBIG", value: payroll?.PAGIBIG || 0 },
    { label: "Attendance Deduction", value: 0 },
  ];

  const otherDeductions = [
    { label: "PAGIBIG LOAN", value: payroll?.PAGIBIGLOAN || 0 },
    { label: "SSS DELQ", value: payroll?.SSSDELQ || 0 },
    { label: "SSS CONDO", value: payroll?.SSSCONDO || 0 },
    { label: "SSS LOAN", value: payroll?.SSSLOAN || 0 },
    ...(payroll?.moreOtherDeductions ?? []).map((item) => ({
      label: item.deductionLabel,
      value: item.deductionAmount,
    })),
  ];

  const adjustments = [
    ...(payroll?.adjustments ?? []).map((item) => ({
      label: item.adjustmentDescription,
      value: item.adjustmentAmount,
    })),
  ];

  return (
    <Modal isOpen={isOpen} size="lg">
      <div className="p-4 d-flex flex-column gap-4">
        <Header
          name={user?.fullName}
          startDate={object?.startDate}
          endDate={object?.endDate}
        />
        <OverviewCard data={totalOverviewData} />
        <OverviewCard data={deductionsOverviewData} />
        {object?.type === "Weekly" ? (
          <TableBreakdown
            payroll={payroll}
            startDate={object?.startDate}
            endDate={object?.endDate}
            holidays={holidays}
          />
        ) : (
          <MonthlyTableBreakdown payroll={payroll} />
        )}
        <OverviewCard data={deductionDetailsData} />
        <div className="d-flex gap-4 ">
          <Adjustments adjustments={adjustments} />
          <OtherDeductions deductions={otherDeductions} />
        </div>
        <CTA toggleModal={toggleModal} onClickDownload={onClickDownload} />
      </div>
    </Modal>
  );
}
