"use strict";

var _interopRequireDefault = require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/interopRequireDefault.js")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));
var _objectToOption = _interopRequireDefault(require("./objectToOption"));
function GetOption(collection, word, indexes, find, where, callback) {
  var query = {
    limit: 100,
    where: (0, _objectSpread2["default"])({}, where),
    keys: indexes
  }; // don't mutate where
  if (word && indexes.length > 0) {
    query.where['$or'] = indexes.map(function (index) {
      var or = {};
      or[index] = {
        '$regex': word,
        '$options': 'i'
      };
      return or;
    });
  }
  clearTimeout(this.timeout);
  this.timeout = setTimeout(function () {
    find.execute(collection, query).then(function (objects) {
      // sort the result
      callback(objects.map(function (obj) {
        return (0, _objectToOption["default"])(obj, indexes);
      }).sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }));
    });
  }, 500);
}
var _default = GetOption;
exports["default"] = _default;