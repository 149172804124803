import { dialog } from "nq-component";
import BaseFormPresenter from "../../../base/BaseFormPresenter";
import classNames from "../../../classNames";
import { findObjectUseCase } from "../../../usecases/object";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};
class ProjectTimelineFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async submit() {
    console.log("Dates", this.object.startDate, this.view.state.endDate);
    console.log("Dates Changes", this.change.startDate, this.change.endDate);
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    if (
      !this.change.startDate &&
      !this.change.endDate &&
      !this.object.startDate &&
      !this.object.endDate
    ) {
      this.change.time = "No dates";
    }

    // if (this.change.startDate || !this.object.startDate) {
    //   this.object.startDate = this.change.startDate;
    // }
    // let startDate = this.object.startDate;

    // // let startDate = this.change.startDate
    // //   ? this.change.startDate
    // //   : this.object.startDate;

    // if (this.change.endDate || !this.object.endDate) {
    //   this.change.endDate = this.object.endDate;
    // }
    // let endDate = this.object.endDate;

    // // let endDate = this.change.endDate
    // //   ? this.change.endDate
    // //   : this.object.endDate;

    this.change.time = `${formatDate(this.object.startDate)} - ${formatDate(
      this.object.endDate
    )}`;
    console.log("time", this.change.time);

    try {
      console.log("sendDate", this.change);
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Data has been successfully saved.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo(
        "/view/project/" +
          this.object.id +
          "/" +
          this.object.client.id +
          "/" +
          this.object.name
      );
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default ProjectTimelineFormPresenter;
