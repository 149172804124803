// Header.js
import React from "react";
import logo from "../../../../assets/gmd-logo.png";

const Header = () => {
  const logoStyle = {
    width: "30mm",
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <p style={{ margin: "0" }}></p>
      </div>

      <div style={{ textAlign: "end" }}>
        <p>
          <img src={logo} alt="GMD Logo" style={logoStyle} />
        </p>
      </div>
    </div>
  );
};

export default Header;
