import React from "react";
import OutputFactory from "../../../components/OutputFactory";

export default function CollapseView({
  index,
  object,
  fields,
  includedFields,
  onClickItem,
  onClickView,
  onApprove,
  onDisapprove,
  roles,
  selectedTab,
}) {
  const renderApprovalButtons = () => {
    const renderButton = (label, color, iconClass, onClick) => (
      <div className="col-auto p-0">
        <button
          className="btn ps-4 pe-4 fw-medium"
          style={{
            background: color,
            color: "white",
            height: "2.75rem",
          }}
          onClick={onClick}
        >
          <i className={iconClass + " me-1"} />
          {label}
        </button>
      </div>
    );

    if (!roles.includes("masterAdmin") && selectedTab === "myTimeSheet")
      return null;

    if (roles.includes("masterAdmin") && object.status === "Pending") {
      return (
        <>
          {renderButton("Approve", "#4FBC4C", "bi bi-check2", () =>
            onApprove(object)
          )}
          {renderButton("Disapprove", "#BB1C1C", "bi bi-x-lg", () =>
            onDisapprove(object)
          )}
        </>
      );
    }

    if (selectedTab !== "myTimeSheet" && object.status === "Pending") {
      return (
        <>
          {renderButton("Approve", "#4FBC4C", "bi bi-check2", () =>
            onApprove(object)
          )}
          {renderButton("Disapprove", "#BB1C1C", "bi bi-x-lg", () =>
            onDisapprove(object)
          )}
        </>
      );
    }

    return null;
  };

  const renderEditButton = () => {
    if (object.status === "Pending") {
      return (
        <div className="col-auto p-0">
          <button
            className="btn ps-4 pe-4 fw-medium"
            style={{
              background: "#DAB44E",
              height: "2.75rem",
              color: "white",
            }}
            onClick={() => onClickItem(index)}
          >
            <i className="bi bi-pencil-fill me-1" />
            Edit
          </button>
        </div>
      );
    }
  };

  const renderViewButton = () => {
    if (object.status !== "Pending") {
      return (
        <div className="col-auto p-0">
          <button
            className="btn ps-4 pe-4 fw-medium"
            style={{
              background: "#DAB44E",
              height: "2.75rem",
              color: "white",
            }}
            onClick={() => onClickView(index)}
          >
            <i class="bi bi-eye me-1"></i>
            View
          </button>
        </div>
      );
    }
  };

  return (
    <div className="row gap-4 ps-4 pe-4">
      <div className="col gap-5">
        {includedFields.map((field) => {
          return (
            <div key={field} className="row gap-2">
              <p
                className="p-0 m-0 col-auto fw-medium"
                style={{ fontSize: "0.875rem" }}
              >
                {fields[field]?.label}:
              </p>
              <p
                className="p-0 m-0 col-auto fw-normal"
                style={{ fontSize: "0.875rem" }}
              >
                <OutputFactory
                  type={field === "roles" ? "String" : fields[field].type}
                  field={field}
                  object={object}
                />
              </p>
            </div>
          );
        })}
        <div className="row mt-3 gap-2">
          {renderApprovalButtons()}
          {renderEditButton()}
          {renderViewButton()}
        </div>
      </div>
    </div>
  );
}
