import { dialog } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";
class CashAdvanceFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async submit() {
    const currentUser = this.view.getCurrentUser();
    if (!this.change.name) {
      this.change.name = currentUser;
    }

    if (!this.change.statuses) {
      this.change.statuses = "Pending";
    }
    if (this.change.statuses === "Disapproved") {
      this.change.statuses = "Pending";
    }

    const deduction = this.view.state.deduction;
    if (deduction) {
      this.change.deduction = deduction;
    } else {
      this.change.deduction = 0;
    }

    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }

    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>

              {currentUser.roles.some(
                (role) =>
                  role.name === "admin" ||
                  role.name === "hrAdmin" ||
                  role.name === "masterAdmin" ||
                  role.name === "Production"
              ) ? (
                <>
                  <h4 className="fw-bold">Success!</h4>
                  <p className="m-0">Data has been successfully saved.</p>
                </>
              ) : (
                <>
                  <h4 className="fw-bold">Request Successfully Submitted.</h4>
                  <p className="m-0">Please wait for the admin approval.</p>
                </>
              )}
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo("/cash-advance");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default CashAdvanceFormPresenter;
