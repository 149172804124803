import React from "react";

function ProgressBar({ status }) {
  const progressBarWidth =
    {
      Pending: "20%",
      Approved: "40%",
      Paid: "60%",
      Liquidation: "80%",
      Done: "100%",
    }[status] || "0%";

  // const statusColor = (status) =>
  //   status === "pending" ||
  //   status === "approved" ||
  //   status === "paid" ||
  //   status === "liquidation"
  //     ? "#5cb85c"
  //     : "#000";
  const statusColor = (s) => {
    if (status === "Disapproved" && s === "Approved") {
      return "#d9534f"; // Red color for disapproved
    }
    return s === "Pending" ||
      s === "Approved" ||
      s === "Paid" ||
      s === "Liquidation"
      ? "#5cb85c" // Green color for other statuses
      : "#000"; // Default color
  };
  return (
    <div
      className="d-flex justify-content-center mb-3"
      style={{ width: "100%" }}
    >
      <div
        className="progress"
        role="progressbar"
        aria-valuenow={parseInt(progressBarWidth, 10)}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: "50%", position: "relative" }}
      >
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          style={{ width: progressBarWidth, backgroundColor: "#EBBD2F" }}
        ></div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
              alignItems: "center",
            }}
          >
            {/* {["pending", "approved", "paid", "liquidation", "done"].map((s) => (
              <p
                key={s}
                style={{ color: statusColor(s) }}
                className="fw-bold mt-3"
              >
                {s.charAt(0).toUpperCase() + s.slice(1)}
              </p>
            ))} */}
            {["Pending", "Approved", "Paid", "Liquidation", "Done"].map((s) => (
              <p
                key={s}
                style={{ color: statusColor(s) }}
                className="fw-bold mt-3"
              >
                {status === "Disapproved" && s === "Approved"
                  ? "Disapproved"
                  : s.charAt(0).toUpperCase() + s.slice(1)}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
// import React from "react";

// function ProgressBar({ status }) {
//   const progressBarWidth =
//     {
//       pending: "20%",
//       approved: "40%",
//       paid: "60%",
//       liquidation: "80%",
//       done: "100%",
//       disapproved: "40%", // Assuming you want the same progress for disapproved as for approved
//     }[status] || "0%";

//   const statusColor = (status) => {
//     // Adjusting the color for disapproved status if needed
//     if (status === "disapproved") {
//       return "#d9534f"; // Example: red color for disapproved
//     }
//     return status === "pending" ||
//       status === "approved" ||
//       status === "paid" ||
//       status === "liquidation"
//       ? "#5cb85c"
//       : "#000";
//   };

//   return (
//     <div
//       className="d-flex justify-content-center mb-3"
//       style={{ width: "100%" }}
//     >
//       <div
//         className="progress"
//         role="progressbar"
//         aria-valuenow={parseInt(progressBarWidth, 10)}
//         aria-valuemin="0"
//         aria-valuemax="100"
//         style={{ width: "50%", position: "relative" }}
//       >
//         <div
//           className="progress-bar progress-bar-striped progress-bar-animated"
//           style={{ width: progressBarWidth, backgroundColor: "#EBBD2F" }}
//         ></div>
//         <div
//           style={{
//             position: "absolute",
//             width: "100%",
//             top: "50%",
//             transform: "translateY(-50%)",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               height: "100%",
//               alignItems: "center",
//             }}
//           >
//             {[
//               "pending",
//               "approved",
//               "paid",
//               "liquidation",
//               "done",
//               "disapproved",
//             ].map((s) => (
//               <p
//                 key={s}
//                 style={{ color: statusColor(s) }}
//                 className="fw-bold mt-3"
//               >
//                 {s === "disapproved"
//                   ? "Approve"
//                   : s.charAt(0).toUpperCase() + s.slice(1)}
//               </p>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ProgressBar;
