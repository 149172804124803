import React from "react";
import { formatDate } from "../../../../../../formatDate";

export default function Header({ name, startDate, endDate }) {
  // if (!name || !startDate || !endDate) return;

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex gap-2">
        <div>
          <i class="bi bi-person-fill"></i>
        </div>
        <div className="fw-semibold">{name}</div>
      </div>
      <div className=" d-flex justify-content-end gap-2">
        <div>
          <i class="bi bi-calendar-week"></i>
        </div>
        <div className="fw-semibold ">
          {`${formatDate(startDate)} - ${formatDate(endDate)}`}
        </div>
      </div>
    </div>
  );
}
