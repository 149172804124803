import { dialog } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";
import { getDateRange } from "../payroll/utils/getDateRange";

class LeaveRequestFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  getCurrentUser() {
    return this.context.user;
  }

  async save() {
    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }
    try {
      if (this.change.startDate) {
        this.change.startDate = new Date(this.change.startDate).toISOString();
      }
      if (this.change.endDate) {
        this.change.endDate = new Date(this.change.endDate).toISOString();
      }
      await this.upsertUseCase.execute(collection, this.change);
      this.change = {};
    } catch (error) {
      throw error;
    }
  }

  async submit() {
    const currentUser = this.view.getCurrentUser();
    if (!this.change.name) {
      this.change.name = currentUser;
    }

    if (!this.change.statuses || this.change.statuses === "Disapproved") {
      this.change.statuses = "Pending";
    }

    const calculateCreditsDay = (startDate, endDate) => {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const startTime = start.getHours();
      const endTime = end.getHours();

      if (start.toDateString() === end.toDateString()) {
        if (startTime >= 8 && endTime < 17) {
          return 0.5;
        } else {
          return 1;
        }
      }

      let differenceDays = (end - start) / (1000 * 60 * 60 * 24);
      differenceDays = Math.floor(differenceDays) + 1;

      if (startTime >= 8 && endTime < 17) {
        differenceDays -= 0.5;
      }

      return differenceDays;
    };

    if (!this.change.creditsDay && !this.object.creditsDay) {
      this.change.creditsDay = `${calculateCreditsDay(
        this.change.startDate,
        this.change.endDate
      )}`;
    }

    if (this.object.creditsDay) {
      this.change.creditsDay = `${calculateCreditsDay(
        this.change.startDate || this.object.startDate,
        this.change.endDate || this.object.endDate
      )}`;
    }

    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }

    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Data has been successfully saved.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo("/leave-request");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default LeaveRequestFormPresenter;
