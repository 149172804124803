import React from "react";

export default function OutputButton({ object, placeholder, onClick }) {
  return (
    <button
      className="btn ps-4 pe-4"
      onClick={onClick.bind(this, object.id)}
      style={{ background: "#CF6F00", color: "white", fontWeight: "bold" }}
    >
      {placeholder}
    </button>
  );
}
