import React from "react";
import OutputFactory from "../../../components/OutputFactory";

export default function CollapseView({
  index,
  object,
  fields,
  includedFields,
  onClickItem,
  onToggleStatus,
}) {
  return (
    <div className="row gap-4 ps-4 pe-4">
      <div
        className="col-auto bg-light rounded d-flex justify-content-center align-items-center p-1"
        style={{ height: "8.5rem", width: "8.5rem" }}
      >
        <img className="img-fluid" src={object.photo} alt="User Profile" />
      </div>
      <div className="col gap-5">
        {includedFields.map((field) => {
          return (
            <div key={field} className="row gap-2">
              <p
                className="p-0 m-0 col-auto fw-medium"
                style={{ fontSize: "0.875rem" }}
              >
                {fields[field].label}:
              </p>
              <p
                className="p-0 m-0 col-auto fw-normal"
                style={{ fontSize: "0.875rem" }}
              >
                <OutputFactory
                  type={field === "roles" ? "String" : fields[field].type}
                  field={field}
                  object={object}
                />
              </p>
            </div>
          );
        })}
        <div className="row mt-3">
          <div className="col-auto p-0">
            <button
              className="btn ps-4 pe-4 fw-semibold"
              style={{ background: "#DAB44E" }}
              onClick={() => onClickItem(index)}
            >
              <i className="bi bi-pencil-fill me-1" />
              Edit
            </button>
          </div>
          <div className="col-auto">
            <button
              className="btn ps-4 pe-4 fw-semibold"
              style={{ background: "#CF6F00" }}
              onClick={() => onToggleStatus(object)}
            >
              <i
                className={`bi ${
                  object.status === "Active"
                    ? "bi-person-fill-slash"
                    : "bi-person-fill"
                } me-1`}
              />
              {object.status === "Active" ? "Inactive" : "Active"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
