import { browseFile } from "nq";
import BaseListPresenter from "../../base/BaseListPresenter";
import Papa from "papaparse";

class ClientTabListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 30;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filter2 = {};
    this.filter3 = {};
    this.filterDate = {};
    this.filterList = {};
    this.filterEmployee = {};
    this.include = ["all"];
    this.keys = undefined;
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filter2,
        ...this.filterDate,
        ...this.filter3,
        ...this.filterList,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }
  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  filterSubmit2(where) {
    this.reset();
    this.filter2 = where;

    this.getObjects();
  }

  filterSubmit3(where) {
    this.reset();
    this.filter3 = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeEmployee(where) {
    this.filterEmployee = where;
    this.getObjects();
  }
  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
    console.log("this.change", this.change);
    console.log("this.object", this.object);
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/forms/clients/");
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    // this.view.navigateTo("/collection/" + collection + "/form/" + object.id);
    this.view.navigateTo("/forms/clients/" + object.id);
    console.log(object);
  }
  onClickExport() {
    this.view.exportPDF();
  }

  onClickImport(file) {
    this.view.showProgress();
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const csvText = e.target.result;
        const data = this.parseCSV(csvText);
        console.log("type", data);
        // this.saveObjects(data);
      } catch (error) {
        console.error("Error processing the CSV file:", error);
        alert("An error occurred while reading the CSV file.");
      }
    };
    reader.readAsText(file);
  }

  // parseCSV(csvText) {
  //   Papa.parse(csvText, {
  //     header: true, // Assuming the first row of the CSV is the header
  //     complete: (results) => {
  //       console.log("Parsed Data:", results.data);
  //       this.upsertUseCase.execute("tes", results.data); // Process your parsed data as needed
  //     },
  //     error: (error) => {
  //       console.error("Error parsing CSV:", error);
  //       alert("An error occurred while parsing the CSV file.");
  //     },
  //   });
  // }
  parseCSV(csvText) {
    Papa.parse(csvText, {
      header: true,
      complete: (results) => {
        const dataArray = results.data;
        const dataObject = dataArray.reduce((obj, item) => {
          obj[item.name] = item;
          return item;
        }, {});
        this.upsertUseCase.execute("clients", dataObject);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
        alert("An error occurred while parsing the CSV file.");
      },
    });
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += `"${array[i][index]}"`;
      }

      str += line + "\r\n";
    }

    return str;
  }

  exportToCSV = async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const bankNameFromURL = urlSearchParams.get("bank");
      const specified = bankNameFromURL ? bankNameFromURL + "-" : "";
      const collection = "clients";
      const query = {
        include: ["all"],
      };
      const objectCSV = await this.findObjectUseCase.execute(collection, query);

      const csvData = objectCSV.map((client) => ({
        Date: client.createdAt,
        "Client Name": client.name,
        "Company Name": client.company_name,

        Email: client.email,
        "Phone Number": client.telephone_number,
        "Mobile Number": client.mobile_number,
        Amount: client.amount,
        Fax: client.fax,
        Other: client.other,
        Website: client.website,
        Notes: client.notes,

        "Billing Street": client.billing_address[0]?.street_address,
        "Billing City": client.billing_address[0]?.city,
        "Billing Province": client.billing_address[0]?.province,
        "Billing Zip Code": client.billing_address[0]?.zip_code,
        "Billing Country": client.billing_address[0]?.country,

        "Shipping Street": client.shipping_address[0]?.street_address,
        "Shipping City": client.shipping_address[0]?.city,
        "Shipping Province": client.shipping_address[0]?.province,
        "Shipping Zip Code": client.shipping_address[0]?.zip_code,
        "Shipping Country": client.shipping_address[0]?.country,

        "Total Projects": client.total_projects,
        "Total Profit": client.total_profit,
        "Total Expenses": client.total_expenses,
      }));

      const csvString = this.convertToCSV(csvData);
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = specified + collection + ".csv";
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting to CSV:", error);
    }
  };
}

export default ClientTabListPresenter;
