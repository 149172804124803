import React from "react";
import Checkbox from "../Checkbox";
import Accordion from "../Accordion";
import OutputFactory from "../OutputFactory";
import CollapseView from "./CollapseView";
import AccordionProvider from "../Accordion/AccordionProvider";
import AccordionCollapse from "../Accordion/AccordionCollapse";

let timeoutId;

function Body({
  objects,
  fields,
  excludeFields,
  selected,
  selectable,
  onSelect,
  onClick,
  onLongPress,
  actionsList,
  collapsable,
  onCollapse,
  actions,
  onClickItem,
  fieldImage,
  role,
  toggleable,
}) {
  const onPointerDown = () => {
    timeoutId = setTimeout(onLongPress, 500); // set long press time to 500ms
  };

  const onPointerUp = () => {
    clearTimeout(timeoutId);
  };

  const getDynamicStyle = (object, field) => {
    // Define default style
    let style = {};

    // Check if the 'type' field exists and its value is 'Accrued' or 'Diminished'
    if (field === "credits") {
      if (object.type === "Accrued") {
        style.color = "green";
      } else if (object.type === "Diminished") {
        style.color = "red";
      }
    }

    return style;
  };

  return objects.map((object, index) => {
    const id = object.id || object._id;
    const checked = selected.includes(object);
    return (
      <AccordionProvider key={id}>
        <tr>
          <th className="align-middle"></th>

          {Object.keys(fields).map((field, i) => {
            const options = fields[field];
            if (excludeFields.includes(field)) return null;
            return (
              <Accordion
                as="td"
                eventKey={`_${id}`}
                key={field}
                className="fs-sm text-truncate"
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
                // onClick={() => onClick(index, field)}
                style={{
                  cursor: "pointer",
                  maxWidth: "15em",
                  ...getDynamicStyle(object, field), // Apply dynamic style
                }}
              >
                {field === "credits" && (
                  <>
                    {object.type === "Accrued" && (
                      <i
                        className="bi bi-caret-up-fill"
                        style={{ color: "green" }}
                      /> // Green up caret icon
                    )}
                    {object.type === "Diminished" && (
                      <i
                        className="bi bi-caret-down-fill"
                        style={{ color: "red" }}
                      /> // Red down caret icon
                    )}
                    <OutputFactory
                      field={field}
                      role={role}
                      object={object}
                      {...options}
                    />
                  </>
                )}
                {field !== "credits" && (
                  <OutputFactory
                    field={field}
                    role={role}
                    object={object}
                    {...options}
                  />
                )}
              </Accordion>
            );
          })}
          {actionsList.length > 0 && (
            <td>
              <div style={{ width: "50px" }}></div>
            </td>
          )}
          {actionsList.map((action) => {
            return (
              <td className="text-truncate">
                <button
                  onClick={action.onClick && action.onClick.bind(this, index)}
                  className="btn btn-outline-primary btn-sm fs-xs shadow-none"
                >
                  <i className={action.icon} /> {action.label}
                </button>
              </td>
            );
          })}
        </tr>
        {collapsable && (
          <tr>
            <td className="p-0" colSpan={Object.keys(fields).length + 1}>
              <AccordionCollapse
                toggleable={toggleable}
                className="collapse"
                eventKey={`_${id}`}
              >
                <div className="p-3">
                  {onCollapse(index, object, excludeFields) ||
                    CollapseView(
                      index,
                      object,
                      excludeFields,
                      fields,
                      actions,
                      onClickItem,
                      fieldImage
                    )}
                </div>
              </AccordionCollapse>
            </td>
          </tr>
        )}
      </AccordionProvider>
    );
  });
}

export default Body;
