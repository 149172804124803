// Footer.js
import React from "react";

const Footer = () => {
  return (
    <div style={{ position: "absolute", bottom: "0", width: "90%" }}>
      <hr style={{ marginTop: "20px", marginBottom: "0" }} />
      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <p>
          32 Santan Unit H and K, Brgy Fortune Marikina
          <br />
          7092 8807 | 0968 887 0928
        </p>
      </div>
    </div>
  );
};

export default Footer;
