import React from "react";
import {
  Button,
  InfiniteScroll,
  Progress,
  Search,
  SelectSearch,
} from "nq-component";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../../usecases/object";

import { browseFile } from "nq";
import BaseListPage from "../../../../base/BaseListPage";
import CardList from "../../../../components/CardList";
import ProjectTaskCardPresenter from "./ProjectTaskCardPresenter";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

class ProjectTaskCard extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ProjectTaskCardPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
    };
    this.contractPDF = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    const projectId = this.props.projectId;
    const typeName = this.props.typeName;
    // console.log("hi", projectId, typeName);
    if (role.some((role) => role.name === "admin")) {
      this.presenter.filterListing({
        project: { id: projectId },
        type: { name: typeName },
      });
    } else {
      this.presenter.filterListing({
        project: { id: projectId },
        type: { name: typeName },
      });
    }
  }
  getCollectionName() {
    return "tasks";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress } = this.state;
    const user = this.getCurrentUser();
    if (!schema) return <Progress />;
    return (
      <CardList
        user={user}
        fields={schema.fields}
        groups={schema.groups}
        selectable
        selected={selected}
        onSelect={this.onSelect.bind(this)}
        onSelectAll={this.onSelectAll.bind(this)}
        progress={progress}
        objects={objects}
        onClickItem={this.onClickItem.bind(this)}
        excludeFields={Object.keys(schema.fields).reduce(
          (acc, key) => {
            const options = schema.fields[key];
            if (options.read === false) {
              acc.push(key);
            }
            switch (options._type || options.type) {
              case "Relation":
              case "Array":
              case "Object":
              case "File":
                acc.push(key);
                break;
              default:
            }
            return acc;
          },
          ["id", "updatedAt", "acl", "password", "br", "or", "created_by"]
        )}
      />
    );
  }
}

export default ProjectTaskCard;
