import React from "react";
import classNames from "../../classNames";
const noop = () => { };
const defaultProps = {
  loadOptions: noop,
  onChange: noop,
  onSearch: noop,
  onClickAdd: noop,
  onClickClear: noop,
  onFocus: noop,
  value: { label: "", value: "" },
  placeholder: "Select",
  dynamic: false,
};

function AccountsSelectSearch({
  className,
  value,
  placeholder,
  onChange,
  onSearch,
  onClickAdd,
  onClickClear,
  onFocus,
  focus,
  options,
  required,
  dynamic,
  excludeFields,
  ...props
}) {
  const [search, setSearch] = React.useState("");
  const [text, setText] = React.useState("");
  const [isOpen, setOpen] = React.useState(false);
  const ref = React.useRef(null);

  // handle click outside event
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
      onFocus(false);
    }
  };

  React.useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // when value change
  React.useEffect(() => {
    setText(value.label);
    setSearch("");
  }, [value]);

  function _onChange(e) {
    // Get the new character input by the user by removing the previous text from the current value.
    const character = e.target.value.replace(text, "");
    if (search === "") {
      e.target.value = character;
    }
    // set the new character input by the user
    setSearch(e.target.value);
    setText(e.target.value);
    onSearch(e.target.value);
  }

  function onClick(e) {
    // e.target.setSelectionRange(0, 0);
    if (!isOpen) {
      e.target.blur();
    }
    setOpen(!isOpen || focus);
  }

  function onSelect(value, index) {
    onChange(value, index);
    setOpen(false);
    if (search) {
      // reset the options if has search
      onSearch("");
    }
  }

  function _onFocus(e) {
    if (!focus) {
      e.target.blur();
    }
    onFocus(true);
  }

  const style = { cursor: isOpen && focus ? "text" : "default" };
  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div className="input-group">
        <input
          onClick={onClick}
          type="text"
          className={classNames(className, "form-control")}
          placeholder={placeholder}
          value={text}
          onChange={_onChange}
          onFocus={_onFocus}
          style={style}
          required={required}
          {...props}
        />
        {/* <button
          onClick={onClickIcon}
          className="btn btn-link"
          type="button">
          <i className={icon}></i>
        </button> */}
      </div>

      {isOpen && (
        <ul
          className="list-group rounded-0 bg-white"
          style={{
            position: "absolute",
            top: "100%",
            width: "100%",
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: 1000,
          }}
        >
          {options
            .filter((option) => !excludeFields?.includes(option.label))
            .map((option, index) => (
              <li
                key={option.value}
                type="button"
                className="list-group-item list-group-item-action"
                onClick={() => onSelect(option, index)}
              >
                {option.label}
              </li>
            ))}
          <li
            className="list-group-item list-group-item-action"
            onClick={() => onSelect({ label: "Revolving Fund", account: { name: "Revolving Fund" } })}
          >
            Revolving Fund
          </li>

        </ul>
      )}
    </div>
  );
}

AccountsSelectSearch.defaultProps = defaultProps;
export default AccountsSelectSearch;
