// import React from "react";
// import { Progress } from "nq-component";
// import {
//   findObjectUseCase,
//   getObjectUseCase,
//   upsertUseCase,
// } from "../../../usecases/object";
// import { restUseCase } from "../../../usecases/rest";
// import NavBar from "../../../components/navbar";
// import InputFactory from "../../../components/InputFactory";
// import InputFactory2 from "../../../components/InputFactory2";
// import Drop from "../../../components/DropdownFilter";
// import BaseFormPage from "../../../base/BaseFormPage";

// import withRouter from "../../../withRouter";
// import ExamQuestion from "../../../components/ExamQuestion";
// import EventPresenter from "./EventPresenter";

// class EventFormPage extends BaseFormPage {
//   constructor(props) {
//     super(props);
//     this.state = {
//       object: {
//         services: [],
//         project_name: "",
//         discountType: "",
//         discount: null,
//         exclusive: "",
//         notes: "",
//         asfType: "",
//         asf: null,
//         tax: 0,
//         subTotal: 0,
//         total: 0,
//         nameService: "",
//         description: "",
//         notes:
//           "1) Inclusive of Labor Fees (Inclusive of Night Differential)\n" +
//           "2) Inclusive of Contingency Fee\n" +
//           "3) Inclusive of Indirect Cost Fee\n" +
//           "4) Inclusive of Value Added Taxes\n" +
//           "5) Price Validity: 30 Days after Receiving\n" +
//           "6) If the quotation is approved, Quotation Number must be indicated at Purchase Orders (PO)\n" +
//           "7) Any alteration of the design and additional items not included in the contract will be billed accordingly.\n" +
//           "8) GMD deserves the right to hold, pullout, suspend delivery if payments and other conditions are not met.\n" +
//           "- GMD Productions has a NO DP & NO Signed Contract = NO Production Policy\n" +
//           "- We request the following Payment Terms: 50% downpayment upon confirmation of project\n" +
//           "- Cost is based on specified requirements however additional requirements other than stated above shall be billed separately.\n" +
//           "- All permits must be c/o client\n" +
//           "Account Name: GMD PRODUCTIONS INC\n" +
//           "BDO CHECKING - 012758000370\n" +
//           "BPI CHECKING - 6011 04 82 03",
//       },
//       selectedProject: null,
//       filtered: [],
//     };
//     this.presenter = new EventPresenter(
//       this,
//       getObjectUseCase(),
//       upsertUseCase(),
//       restUseCase(),
//       findObjectUseCase()
//     );
//     this.contractPDF = React.createRef();

//     this.handleSubtotalChange = this.handleSubtotalChange.bind(this);
//     this.handleTaxChange = this.handleTaxChange.bind(this);
//     this.handleTotalChange = this.handleTotalChange.bind(this);
//     this.handleNameChange = this.handleNameChange.bind(this);
//     this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
//   }

//   getCollectionName() {
//     return "services_final";
//   }

//   handleProjectChange = (selectedProjectId) => {
//     console.log(
//       "handleProjectChange - selected project ID:",
//       selectedProjectId
//     );
//     const selectedProject = this.state.filtered.find(
//       (project) => project.id === selectedProjectId
//     );
//     console.log("handleProjectChange - selected project:", selectedProject);

//     if (selectedProject) {
//       // this.setState({ selectedProject });
//       this.setState((prevState) => ({
//         object: {
//           ...prevState.object,
//           project_name: selectedProject,
//         },
//       }));
//       console.log("Project name selected:", selectedProject);
//       this.onChange(selectedProject.name, "project_name");
//       this.setState({ selectedProject: selectedProject });
//     } else {
//       console.log("No project found with ID:", selectedProjectId);
//       this.setState({ selectedProject: null });
//     }
//   };

//   getObject() {
//     return this.state.object;
//   }

//   getExamId() {
//     return this.getArgument();
//   }

//   onSubmitForm(e) {
//     e.preventDefault();
//     this.presenter.submit();
//   }

//   setObject(object) {
//     this.setState({ object });
//   }

//   async onChange(value, field) {
//     console.log("value", value, "field", field);
//     if (field === "client_name") {
//       const clientName = value.id;
//       const projects = await findObjectUseCase().execute("projects");

//       const filteredProjects = projects
//         .filter((project) => project.client?.id === clientName)
//         .map((project) => ({
//           name: project.name,
//           id: project.id,
//         }));

//       this.setState(
//         {
//           filtered: filteredProjects,
//           selectedProject:
//             filteredProjects.length > 0 ? filteredProjects[0] : null,
//         },
//         () => {}
//       );
//     } else if (field === "project_name") {
//     } else {
//       this.presenter.onChange(value, field);
//     }
//   }

//   handleSubtotalChange(newSubtotal) {
//     if (this.state.object.subTotal !== newSubtotal) {
//       this.setState((prevState) => ({
//         object: {
//           ...prevState.object,
//           subTotal: newSubtotal,
//         },
//       }));
//     }
//   }

//   handleTaxChange(newTax) {
//     if (this.state.object.tax !== newTax) {
//       this.setState((prevState) => ({
//         object: {
//           ...prevState.object,
//           tax: newTax,
//         },
//       }));
//     }
//   }

//   handleTotalChange(newTotal) {
//     if (this.state.object.total !== newTotal) {
//       this.setState((prevState) => ({
//         object: {
//           ...prevState.object,
//           total: newTotal,
//         },
//       }));
//     }
//   }

//   handleNameChange(serviceName) {
//     console.log("sss", serviceName);
//     if (this?.state.object?.nameService !== serviceName) {
//       this.setState((prevState) => ({
//         object: {
//           ...prevState.object,
//           nameService: serviceName,
//         },
//       }));
//     }
//     console.log("name: ", this?.state.object?.nameService);
//   }

//   handleDescriptionChange(serviceDescription) {
//     if (this?.state.object?.description !== serviceDescription) {
//       this.setState((prevState) => ({
//         object: {
//           ...prevState.object,
//           description: serviceDescription,
//         },
//       }));
//     }
//   }

//   onClickApproved(e) {
//     e.preventDefault();
//     this.presenter.onClickApproved();
//   }

//   render() {
//     const { pid, cid } = this.props.params;
//     const object = this.state.object;
//     const selectedProject = this.state.selectedProject;

//     console.log("SelectedProject", selectedProject);
//     const user = this.getCurrentRoles();
//     const schema = this.getSchema(this.getCollectionName());

//     if (!schema) return <Progress />;

//     return (
//       <>
//         <NavBar />
//         <div className="overflow-auto">
//           <div className="p-3 p-lg-4">
//             <div className="row align-items-center">
//               <div className="col-auto d-flex align-items-center">
//                 <button
//                   type="button"
//                   className="btn btn-link"
//                   style={{ padding: "0" }}
//                 >
//                   <i
//                     className="bi bi-arrow-left fs-4"
//                     onClick={this.onClickBack.bind(this)}
//                     style={{ color: "#DAB451", margin: "0" }}
//                   ></i>
//                 </button>
//               </div>

//               <div className="col">
//                 <h1 className="fw-bold mt-3 text-capitalize">
//                   {object && object.id ? "Edit Event" : "Event"}
//                 </h1>
//               </div>
//               <div className="col-auto ml-auto">
//                 {object && object.id && object.statuses === "Pending" && (
//                   <button
//                     type="button"
//                     className="btn fs-sm text-white"
//                     style={{ backgroundColor: "#3FC65C" }}
//                     onClick={this.onClickApproved.bind(this)}
//                   >
//                     <i className="bi bi-file-earmark-check me-2"></i>Approve
//                     Estimate
//                   </button>
//                 )}
//               </div>
//             </div>

//             <div className="row align-items-center">
//               <div className="col-auto d-flex align-items-center">
//                 <h6
//                   className="fw-normal mt-3 text-sm"
//                   style={{
//                     paddingLeft: "50px",
//                     color: "#434343",
//                     opacity: "0.5",
//                   }}
//                 >
//                   Select service type and enter data to the required fields.
//                   {cid && pid && (
//                     <p>Client and Project names have been automatically set.</p>
//                   )}
//                 </h6>
//               </div>
//               <div className="mt-2 rounded p-3 px-lg-5 py-lg-4">
//                 <form onSubmit={this.onSubmitForm.bind(this)}>
//                   <div className="row" style={{ width: "50%" }}>
//                     {!cid && (
//                       <div className="col">
//                         <label className="form-label">Client Name</label>
//                         <InputFactory
//                           type="UserInputPointer"
//                           dynamic={false}
//                           target="clients"
//                           field="client_name"
//                           object={object}
//                           placeholder="Select Client Name"
//                           onChange={this.onChange.bind(this)}
//                           required={true}
//                         />
//                       </div>
//                     )}
//                     {!pid && (
//                       <div className="col">
//                         <label className="form-label">Project Name</label>
//                         <Drop
//                           placeholder={
//                             selectedProject
//                               ? "Select a project"
//                               : object.project_name.name
//                           }
//                           value={selectedProject ? selectedProject : null}
//                           options={this.state.filtered}
//                           onChange={(selectedValue) =>
//                             this.handleProjectChange(selectedValue)
//                           } // Pass the selected value directly
//                         />
//                       </div>
//                     )}
//                   </div>
//                   <ExamQuestion
//                     object={object}
//                     setObjectState={this.setObject.bind(this)}
//                     onChangeService={this.onChange.bind(this)}
//                     back={this.onClickBack.bind(this)}
//                     onSubtotalChange={this.handleSubtotalChange}
//                     onTaxChange={this.handleTaxChange}
//                     onTotalChange={this.handleTotalChange}
//                     onNameChange={this.handleNameChange}
//                     onDescriptionChange={this.handleDescriptionChange}
//                   />
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default withRouter(EventFormPage);

import React from "react";
import { Progress } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { restUseCase } from "../../../usecases/rest";
import NavBar from "../../../components/navbar";
import InputFactory from "../../../components/InputFactory";
import InputFactory2 from "../../../components/InputFactory2";
import Drop from "../../../components/DropdownFilter";
import BaseFormPage from "../../../base/BaseFormPage";

import withRouter from "../../../withRouter";
import ExamQuestion from "../../../components/ExamQuestion";
import EventPresenter from "./EventPresenter";

class EventFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {
        services: [],
        project_name: "",
        discountType: "",
        discount: null,
        exclusive: "",
        notes: "",
        asfType: "",
        asf: null,
        tax: 0,
        subTotal: 0,
        total: 0,
        nameService: "",
        description: "",
        notes:
          "1) Inclusive of Labor Fees (Inclusive of Night Differential)\n" +
          "2) Inclusive of Contingency Fee\n" +
          "3) Inclusive of Indirect Cost Fee\n" +
          "4) Inclusive of Value Added Taxes\n" +
          "5) Price Validity: 30 Days after Receiving\n" +
          "6) If the quotation is approved, Quotation Number must be indicated at Purchase Orders (PO)\n" +
          "7) Any alteration of the design and additional items not included in the contract will be billed accordingly.\n" +
          "8) GMD deserves the right to hold, pullout, suspend delivery if payments and other conditions are not met.\n" +
          "- GMD Productions has a NO DP & NO Signed Contract = NO Production Policy\n" +
          "- We request the following Payment Terms: 50% downpayment upon confirmation of project\n" +
          "- Cost is based on specified requirements however additional requirements other than stated above shall be billed separately.\n" +
          "- All permits must be c/o client\n" +
          "Account Name: GMD PRODUCTIONS INC\n" +
          "BDO CHECKING - 012758000370\n" +
          "BPI CHECKING - 6011 04 82 03",
      },
      selectedProject: null,
      filtered: [],
    };
    this.presenter = new EventPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
    this.contractPDF = React.createRef();

    this.handleSubtotalChange = this.handleSubtotalChange.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleTotalChange = this.handleTotalChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
  }

  getCollectionName() {
    return "services_final";
  }

  handleProjectChange = (selectedProjectId) => {
    console.log(
      "handleProjectChange - selected project ID:",
      selectedProjectId
    );
    const selectedProject = this.state.filtered.find(
      (project) => project.id === selectedProjectId
    );
    console.log("handleProjectChange - selected project:", selectedProject);

    if (selectedProject) {
      // this.setState({ selectedProject });
      this.setState((prevState) => ({
        object: {
          ...prevState.object,
          project_name: selectedProject,
        },
      }));
      console.log("Project name selected:", selectedProject);
      this.onChange(selectedProject.name, "project_name");
      this.setState({ selectedProject: selectedProject });
    } else {
      console.log("No project found with ID:", selectedProjectId);
      this.setState({ selectedProject: null });
    }
  };

  getObject() {
    return this.state.object;
  }

  getExamId() {
    return this.getArgument();
  }

  onSubmitForm(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  setObject(object) {
    this.setState({ object });
  }

  async onChange(value, field) {
    console.log("value", value, "field", field);
    if (field === "client_name") {
      const clientName = value.id;
      const projects = await findObjectUseCase().execute("projects");

      const filteredProjects = projects
        .filter((project) => project.client?.id === clientName)
        .map((project) => ({
          name: project.name,
          id: project.id,
        }));

      this.setState(
        {
          filtered: filteredProjects,
          selectedProject:
            filteredProjects.length > 0 ? filteredProjects[0] : null,
        },
        () => {}
      );
    } else if (field === "project_name") {
    } else {
      this.presenter.onChange(value, field);
    }
  }

  handleSubtotalChange(newSubtotal) {
    if (this.state.object.subTotal !== newSubtotal) {
      this.setState((prevState) => ({
        object: {
          ...prevState.object,
          subTotal: newSubtotal,
        },
      }));
    }
  }

  handleTaxChange(newTax) {
    if (this.state.object.tax !== newTax) {
      this.setState((prevState) => ({
        object: {
          ...prevState.object,
          tax: newTax,
        },
      }));
    }
  }

  handleTotalChange(newTotal) {
    if (this.state.object.total !== newTotal) {
      this.setState((prevState) => ({
        object: {
          ...prevState.object,
          total: newTotal,
        },
      }));
    }
  }

  handleNameChange(serviceName) {
    console.log("sss", serviceName);
    if (this?.state.object?.nameService !== serviceName) {
      this.setState((prevState) => ({
        object: {
          ...prevState.object,
          nameService: serviceName,
        },
      }));
    }
    console.log("name: ", this?.state.object?.nameService);
  }

  handleDescriptionChange(serviceDescription) {
    if (this?.state.object?.description !== serviceDescription) {
      this.setState((prevState) => ({
        object: {
          ...prevState.object,
          description: serviceDescription,
        },
      }));
    }
  }

  onClickApproved(e) {
    e.preventDefault();
    this.presenter.onClickApproved();
  }

  render() {
    const { pid, cid } = this.props.params;
    const object = this.state.object;
    const selectedProject = this.state.selectedProject;

    console.log("SelectedProject", selectedProject);
    const user = this.getCurrentRoles();
    const schema = this.getSchema(this.getCollectionName());

    if (!schema) return <Progress />;

    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="row align-items-center">
              <div className="col-auto d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-link"
                  style={{ padding: "0" }}
                >
                  <i
                    className="bi bi-arrow-left fs-4"
                    onClick={this.onClickBack.bind(this)}
                    style={{ color: "#DAB451", margin: "0" }}
                  ></i>
                </button>
              </div>

              <div className="col">
                <h1 className="fw-bold mt-3 text-capitalize">
                  {object && object.id ? "Edit Event" : "Event"}
                </h1>
              </div>
              <div className="col-auto ml-auto">
                {object && object.id && object.statuses === "Pending" && (
                  <button
                    type="button"
                    className="btn fs-sm text-white"
                    style={{ backgroundColor: "#3FC65C" }}
                    onClick={this.onClickApproved.bind(this)}
                  >
                    <i className="bi bi-file-earmark-check me-2"></i>Approve
                    Estimate
                  </button>
                )}
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-auto d-flex align-items-center">
                <h6
                  className="fw-normal mt-3 text-sm"
                  style={{
                    paddingLeft: "50px",
                    color: "#434343",
                    opacity: "0.5",
                  }}
                >
                  Select service type and enter data to the required fields.
                  {cid && pid && (
                    <p>Client and Project names have been automatically set.</p>
                  )}
                </h6>
              </div>
              <div className="mt-2 rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row" style={{ width: "50%" }}>
                    {!cid && (
                      <div className="col">
                        <label className="form-label">Client Name</label>
                        <InputFactory
                          type="UserInputPointer"
                          dynamic={false}
                          target="clients"
                          field="client_name"
                          object={object}
                          placeholder="Select Client Name"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {!pid && (
                      <div className="col">
                        <label className="form-label">Project Name</label>
                        <Drop
                          placeholder={
                            selectedProject
                              ? "Select a project"
                              : object.project_name.name
                          }
                          value={selectedProject ? selectedProject : null}
                          options={this.state.filtered}
                          onChange={(selectedValue) =>
                            this.handleProjectChange(selectedValue)
                          } // Pass the selected value directly
                        />
                      </div>
                    )}
                  </div>
                  <ExamQuestion
                    object={object}
                    setObjectState={this.setObject.bind(this)}
                    onChangeService={this.onChange.bind(this)}
                    back={this.onClickBack.bind(this)}
                    onSubtotalChange={this.handleSubtotalChange}
                    onTaxChange={this.handleTaxChange}
                    onTotalChange={this.handleTotalChange}
                    onNameChange={this.handleNameChange}
                    onDescriptionChange={this.handleDescriptionChange}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EventFormPage);
