import React from "react";
import classNames from "../../../classNames";

const MarkDoneDialogContent = ({ onMarkDone, onCancel }) => (
  <div className="text-end">
    <div className="text-center">
      <i
        className={classNames("bi bi-check", "text-success")}
        style={{ fontSize: "5rem" }}
      ></i>
      <h4 className="fw-bold">Mark as Done?</h4>
      <p className="m-0">This will mark the task as done. Continue?</p>
      <button
        className="btn mb-2 mt-2 me-2"
        style={{ backgroundColor: "#4aab73", color: "white" }}
        onClick={onMarkDone}
      >
        Continue
      </button>
      <button className="btn mb-2 mt-2 btn-light" onClick={onCancel}>
        Cancel
      </button>
    </div>
  </div>
);

export default MarkDoneDialogContent;
