import React from "react";
import InputFactory from "../../../components/InputFactory";

export default function OtherDeductions({
  object,
  onIncreasesAndDeductionsChange,
  setEmployeeIncreasesAndDeductsObject,
}) {
  const deductions = ["PAGIBIG LOAN", "SSS DELQ", "SSS LOAN", "SSS CONDO"];

  return (
    <>
      <div className="row">
        <div className="col">
          <p
            className="fw-semibold mt-5"
            style={{ fontSize: "1.25rem", color: "#434343" }}
          >
            Other Deductions
            <span className="ms-2 text-danger">(-)</span>
          </p>
        </div>
        <div
          className="col d-flex justify-content-end align-align-items-center"
          style={{ marginRight: "20%" }}
        >
          <p
            onClick={() => {
              const newObject = {
                ...object,
                moreOtherDeductions: [
                  ...(object?.moreOtherDeductions || []),
                  { deductionLabel: "", deductionAmount: "" },
                ],
              };

              setEmployeeIncreasesAndDeductsObject(newObject);
            }}
            className="fw-semibold mt-5"
            style={{
              fontSize: "1rem",
              color: "#DAB44E",
              textAlign: "end",
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            Add
          </p>
        </div>
      </div>
      <div
        className="col rounded m-0 p-3"
        style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)", width: "80%" }}
      >
        {deductions.map((item) => {
          return (
            <div className="row mt-3 mb-3 align-items-center " key={item}>
              <div className="col-4">{item}</div>
              <div className="col-6">
                <InputFactory
                  type="Peso"
                  object={object}
                  onChange={onIncreasesAndDeductionsChange}
                  field={item.replace(/\s/g, "")}
                />
              </div>
              <div className="col-2">
                <i
                  class="bi bi-trash3-fill text-danger"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          );
        })}
        {object?.moreOtherDeductions?.map((item, i) => {
          return (
            <div className="row mt-3 mb-3 align-items-center " key={item}>
              <div className="col-4">
                <InputFactory
                  type="String"
                  object={item}
                  onChange={onIncreasesAndDeductionsChange}
                  field="deductionLabel"
                />
              </div>
              <div className="col-6">
                <InputFactory
                  type="Peso"
                  object={item}
                  onChange={onIncreasesAndDeductionsChange}
                  field="deductionAmount"
                />
              </div>
              <div className="col-2">
                <i
                  class="bi bi-trash3-fill text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const newObject = {
                      ...object,
                      deductions:
                        object.deductions -
                        object.moreOtherDeductions[i].deductionAmount,
                      total:
                        object.total +
                        object.moreOtherDeductions[i].deductionAmount,
                      moreOtherDeductions: object.moreOtherDeductions.filter(
                        (_, index) => index !== i
                      ),
                    };

                    setEmployeeIncreasesAndDeductsObject(newObject);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
