import BaseFormPresenter from "../../base/BaseFormPresenter";


class EditEstimatePresenter extends BaseFormPresenter {

    onChangeObject(object) {
        this.change = object;
        this.object = object;
    }
}

export default EditEstimatePresenter;
