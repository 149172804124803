import React, { useEffect, useState } from "react";
// import logo from "../../../assets/gmd-logo.png";
// import dateFormat from "../../../dateFormat";
import dateFormat from "../../dateFormat";
import logo from "../../assets/gmd-logo.png";
import { findObjectUseCase, getObjectUseCase } from "../../usecases/object";

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(amount);
};

const getDiscountOrASF = (value, type, subtotal) => {
  if (!value) return 0;

  if (type === "value") return value;

  return subtotal * (value / 100);
};

export default function EstimateListPrint({ object }) {
  console.log("tqtqtq", object);
  console.log("GANE", object[0]?.descriptionArray);
  const mamamo = object[0]?.id;

  const [fetchedObject, setFetchedObject] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const query = {
        include: [
          "client_name",
          "services",
          "services.questions",
          "services.questions.service",
          "project_name",
        ],
      };
      try {
        const fetchedData = await findObjectUseCase().execute(
          "services_final",
          query
        );
        const fetchedObject = fetchedData.find((item) => item.id === mamamo);

        setFetchedObject(fetchedObject);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [mamamo]);

  if (!fetchedObject || !fetchedObject.services) {
    return <div>Loading...</div>; // Show loading message or spinner
  }

  // Organize services into tables based on service name
  const tables = fetchedObject.services.reduce((acc, serviceObj) => {
    // Here serviceObj should refer directly to each service in fetchedObject.services
    const { service } = serviceObj.questions[0]; // Assuming each 'questions' array has at least one element

    // Find if a table already exists for this service
    const existingTable = acc.find(
      (table) => table.service.name === service.name
    );
    if (existingTable) {
      existingTable.services.push(serviceObj);
    } else {
      acc.push({ service, services: [serviceObj] });
    }
    return acc;
  }, []);

  const groupByName = (services) => {
    const grouped = {};

    services.forEach((service, sindex) => {
      service.questions.forEach((question) => {
        const name = question.name;
        if (!grouped[name]) {
          grouped[name] = [];
        }
        grouped[name].push({
          ...question,
          serviceIndex: sindex,
        });
      });
    });

    return grouped;
  };

  const groupedQuestions = groupByName(fetchedObject.services);

  console.log("TT", tables);
  console.log("fetch", fetchedObject);

  const paper = {
    padding: "5mm",
    fontFamily: "Montserrat, sans-serif",
    pageBreakInside: "avoid",
  };

  const logoStyle = {
    width: "30mm",
  };

  const header = {
    display: "flex",
    justifyContent: "space-between",
  };

  const styles = {
    table: {
      marginBottom: "10px",
      width: "100%",
      justifyContent: "space-between",
      textAlign: "center",
    },
    tableDesc: {
      borderCollapse: "collapse",
      marginBottom: "10px",
      width: "100%",
    },
    header: {
      backgroundColor: "#FFA500",
      color: "white",
      padding: "10px",
    },
    dataCell: {
      padding: "10px",
      verticalAlign: "top",
      border: "none",
    },
    headerTotal: {
      backgroundColor: "#2e2e2e",
      color: "white",
      padding: "10px",
    },
    dataCellTotal: {
      backgroundColor: "#2e2e2e",
      color: "white",
      padding: "10px",
      verticalAlign: "top",
      textAlign: "right",
    },
    itemTable: {
      width: "100%",
      marginBottom: "10px",
    },
    summaryTable: {
      width: "100%",
    },
    headerCell: {
      backgroundColor: "#FFA500",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    itemCell: {
      padding: "10px",
      border: "1px solid #000",
      borderTop: "none",
    },
    summaryHeaderCell: {
      backgroundColor: "#000",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    summaryCell: {
      padding: "10px",
      // border: "1px solid #000",
      backgroundColor: "#f2f2f2",
    },
  };
  const line = {
    border: "1px solid #FFA500",
  };

  const clientInfo = {
    display: "flex",
    justifyContent: "space-between",
    gap: "2mm",
    backgroundColor: "#fff2cc",
    fontSize: "10px",
    border: "1px dotted black",
    textAlign: "right",
  };

  const ASFInfo = {
    display: "flex",
    justifyContent: "space-evenly",
    backgroundColor: "#f0ecec",
    fontSize: "10px",
    border: "1px dotted black",
  };

  const Signature = {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#f0ecec",
    fontSize: "13px",
    height: "80px",
    width: "450px",
    // border: "1px dotted black",
  };
  const EstimateTable = {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "10px",
  };

  const Notes = {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
    backgroundColor: "#f0ecec",
    fontSize: "10px",
    border: "1px dotted black",
  };

  console.log("OBJECT", fetchedObject);

  return (
    <div style={paper} className="">
      <div style={header}>
        <div>
          <img src={logo} alt="GMD Logo" style={logoStyle} />
          <p>
            <b>PROJECT PROPOSAL</b>
          </p>
        </div>

        <div style={{ textAlign: "end", fontSize: "10px" }}>
          <p style={{ marginBottom: "0px" }}>
            <b>GMD PRODCUTION INC</b>
          </p>
          <p style={{ marginBottom: "0px" }}>
            32 Santan Unit H Brgy Fortune Marikina Marikina
          </p>
          <p style={{ marginBottom: "0px" }}>Marikina, NCR !802 PH</p>
          <p style={{ marginBottom: "0px" }}>+63 9688870928</p>
          <p style={{ marginBottom: "0px" }}>pjg@gmd.ph</p>
          <p style={{ marginBottom: "0px" }}>https://www.gmd.ph</p>
        </div>
      </div>
      <div style={clientInfo}>
        <table style={styles.table}>
          <tbody>
            <tr>
              <td>
                <span className="text-center">
                  PROJECT: <b>{object[0]?.project_name.name ?? "N/A"} </b> |
                  CLIENT: <b>{object[0]?.client_name?.name ?? "N/A"} </b> |
                  CONTACT:
                  <b>{object[0]?.client_name?.mobile_number ?? "N/A"} </b> | CE
                  REF: <b>{object[0]?.estimate_num ?? "N/A"} </b>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style={{ textAlign: "center", fontSize: "10px" }}>
        Thank you for considering <b>GMD PRODUCTION INC</b> as your partner in
        production. We look forward to working with you.
      </p>
      <br />
      {/* <div style={{ width: "100%" }}>
        {fetchedObject.services.map((table, sindex) => (
          <>
            {table.questions?.map((t, index) => {
              console.log("ga,e", sindex);
              return (
                <>
                  <table
                    key={index}
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginBottom: "20px",
                      border: "1px dotted black",
                    }}
                  >
                    <thead>
                      <tr style={{ backgroundColor: "yellow" }}>
                        <th
                          colSpan={5}
                          style={{
                            border: "1px dotted black",
                            textAlign: "left",
                            padding: "10px",
                          }}
                        >
                          {t.name}
                        </th>
                      </tr>
                      <tr style={{ backgroundColor: "lightgray" }}>
                        <th
                          style={{
                            border: "1px dotted black",
                            textAlign: "left",
                            padding: "10px",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            border: "1px dotted black",
                            textAlign: "left",
                            padding: "10px",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          style={{
                            border: "1px dotted black",
                            textAlign: "left",
                            padding: "10px",
                          }}
                        >
                          Rate
                        </th>
                        <th
                          style={{
                            border: "1px dotted black",
                            textAlign: "left",
                            padding: "10px",
                          }}
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#f0ecec" }}>
                      <tr style={{ border: "1px dotted black" }}>
                        <td
                          style={{
                            border: "1px dotted black",
                            padding: "10px",
                          }}
                        >
                          {fetchedObject?.descriptionArray &&
                          fetchedObject?.descriptionArray.find(
                            (desc) => desc.categoryIndex === sindex
                          )
                            ? fetchedObject?.descriptionArray.find(
                                (desc) => desc.categoryIndex === sindex
                              ).description
                            : null}
                        </td>
                        <td
                          style={{
                            border: "1px dotted black",
                            padding: "10px",
                          }}
                        >
                          {t?.quantity}
                        </td>
                        <td
                          style={{
                            border: "1px dotted black",
                            padding: "10px",
                          }}
                        >
                          {t?.rate}
                        </td>
                        <td
                          style={{
                            border: "1px dotted black",
                            padding: "10px",
                          }}
                        >
                          {t?.amount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              );
            })}
          </>
        ))}
      </div> */}

      <div style={{ width: "100%" }}>
        {Object.keys(groupedQuestions).map((name) => (
          <table
            key={name}
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginBottom: "20px",
              border: "1px dotted black",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "yellow" }}>
                <th
                  colSpan={5}
                  style={{
                    border: "1px dotted black",
                    textAlign: "left",
                    padding: "10px",
                  }}
                >
                  {name}
                </th>
              </tr>
              <tr style={{ backgroundColor: "lightgray" }}>
                <th
                  style={{
                    border: "1px dotted black",
                    textAlign: "left",
                    padding: "10px",
                  }}
                >
                  Description
                </th>
                <th
                  style={{
                    border: "1px dotted black",
                    textAlign: "left",
                    padding: "10px",
                  }}
                >
                  Quantity
                </th>
                <th
                  style={{
                    border: "1px dotted black",
                    textAlign: "left",
                    padding: "10px",
                  }}
                >
                  Rate
                </th>
                <th
                  style={{
                    border: "1px dotted black",
                    textAlign: "left",
                    padding: "10px",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "#f0ecec" }}>
              {groupedQuestions[name].map((question, index) => (
                <tr key={index} style={{ border: "1px dotted black" }}>
                  <td
                    style={{
                      border: "1px dotted black",
                      padding: "10px",
                    }}
                  >
                    {/* {fetchedObject?.descriptionArray &&
                    fetchedObject?.descriptionArray.find(
                      (desc) => desc.categoryIndex === question.serviceIndex
                    )
                      ? fetchedObject?.descriptionArray.find(
                          (desc) => desc.categoryIndex === question.serviceIndex
                        )?.description
                      : null} */}
                    <pre
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontSize: "13px",
                      }}
                    >
                      {fetchedObject?.descriptionArray &&
                      fetchedObject?.descriptionArray.find(
                        (desc) => desc.categoryIndex === question.serviceIndex
                      )
                        ? fetchedObject?.descriptionArray.find(
                            (desc) =>
                              desc.categoryIndex === question.serviceIndex
                          )?.description
                        : null}
                    </pre>
                  </td>
                  <td
                    style={{
                      border: "1px dotted black",
                      padding: "10px",
                    }}
                  >
                    {question?.quantity}
                  </td>
                  <td
                    style={{
                      border: "1px dotted black",
                      padding: "10px",
                    }}
                  >
                    {formatCurrency(question?.rate)}
                  </td>
                  <td
                    style={{
                      border: "1px dotted black",
                      padding: "10px",
                    }}
                  >
                    {formatCurrency(question?.amount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>

      <div style={ASFInfo}>
        <div style={Signature}>
          <div style={{ marginLeft: "70px", width: "100%" }}>
            <div style={{ height: "70%" }}></div>
            <hr
              style={{
                borderTop: "3px solid black",
                width: "100%",
              }}
            />
            <div>CONFORME: Signature over Name and Date</div>
          </div>
        </div>
        <table
          style={{
            marginLeft: "120px",
            borderCollapse: "collapse",
            cellpadding: 0,
            cellspacing: 0,
          }}
        >
          <tbody>
            <tr>
              <td>SUBTOTAL</td>
              <td
                style={{
                  backgroundColor: "yellow",
                  width: "100px",
                  textAlign: "end",
                }}
              >
                <b>
                  {fetchedObject?.subTotal
                    ? formatCurrency(parseFloat(fetchedObject.subTotal))
                    : "N/A"}
                </b>
              </td>
              {/* <td
                style={{
                  backgroundColor: "yellow",
                  width: "100px",
                  textAlign: "end",
                }}
              >
                <b>
                  {fetchedObject?.subTotal
                    ? formatCurrency(parseFloat(fetchedObject.subTotal))
                    : "N/A"}
                </b>
              </td> */}
            </tr>
            <tr>
              <td>
                ASF{" "}
                {fetchedObject?.asfType === "value"
                  ? "(₱)"
                  : fetchedObject?.asfType === "percent"
                  ? "(%)"
                  : ""}
              </td>
              <td
                style={{
                  backgroundColor: "yellow",
                  width: "100px",
                  textAlign: "end",
                }}
              >
                <b>
                  {formatCurrency(
                    getDiscountOrASF(
                      fetchedObject.asf,
                      fetchedObject.asfType,
                      fetchedObject.subTotal
                    )
                  )}
                </b>
              </td>
              {/* <td
                style={{
                  backgroundColor: "yellow",
                  width: "100px",
                  textAlign: "end",
                }}
              >
                <b>
                  {formatCurrency(
                    getDiscountOrASF(
                      fetchedObject.asf,
                      fetchedObject.asfType,
                      fetchedObject.subTotal
                    )
                  )}
                </b>
              </td> */}
            </tr>

            <tr>
              <td>
                DISCOUNT{" "}
                {fetchedObject?.discountType === "value"
                  ? "(₱)"
                  : fetchedObject?.discountType === "percent"
                  ? "(%)"
                  : ""}{" "}
              </td>

              <td
                style={{
                  backgroundColor: "yellow",
                  width: "100px",
                  textAlign: "end",
                }}
              >
                <b>
                  {formatCurrency(
                    getDiscountOrASF(
                      fetchedObject.discount,
                      fetchedObject.discountType,
                      fetchedObject.subTotal
                    )
                  )}
                </b>
              </td>
              {/* <td
                style={{
                  backgroundColor: "yellow",
                  width: "100px",
                  textAlign: "end",
                }}
              >
                <b>
                  {formatCurrency(
                    getDiscountOrASF(
                      fetchedObject.discount,
                      fetchedObject.discountType,
                      fetchedObject.subTotal
                    )
                  )}
                </b>
              </td> */}
            </tr>
            {object[0]?.addVAT === true && (
              <tr>
                <td>12% VAT</td>
                <td
                  style={{
                    backgroundColor: "yellow",
                    width: "100px",
                    textAlign: "end",
                  }}
                >
                  <b>{formatCurrency(parseFloat(fetchedObject?.tax))}</b>
                </td>
                {/* <td
                style={{
                  backgroundColor: "yellow",
                  width: "100px",
                  textAlign: "end",
                }}
              >
                <b>
                  <b>{formatCurrency(parseFloat(fetchedObject?.tax))}</b>
                </b>
              </td> */}
              </tr>
            )}
            {/* {object[0]?.downpaymentAmount !== "0" && (
              <tr>
                <td>Downpayment</td>
                <td
                  style={{
                    backgroundColor: "yellow",
                    width: "100px",
                    textAlign: "end",
                  }}
                >
                  {object[0]?.downpaymentType === "percent" ? (
                    <b>
                      ₱
                      {parseFloat(
                        object[0]?.total * (object[0]?.downpaymentAmount / 100)
                      )}
                    </b>
                  ) : (
                    <b>
                      ₱
                      {parseFloat(
                        object[0]?.total - object[0]?.downpaymentAmount
                      )}
                    </b>
                  )}
                </td>
               
              </tr>
            )} */}

            <tr>
              <td>GRAND TOTAL</td>
              <td
                style={{
                  backgroundColor: "yellow",
                  width: "100px",
                  textAlign: "end",
                }}
              >
                <b>
                  <b>{formatCurrency(parseFloat(object[0]?.total))}</b>
                </b>
              </td>
              {/* <td
                style={{
                  backgroundColor: "yellow",
                  width: "100px",
                  textAlign: "end",
                }}
              >
                <b>{formatCurrency(parseFloat(object[0]?.total))}</b>
              </td> */}
            </tr>
          </tbody>
        </table>
      </div>
      <br />

      <br />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            ...Notes,
            flex: 1,
            marginRight: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.2em",
                textAlign: "center",
              }}
            >
              NOTES AND REMINDERS
            </p>
            {object[0]?.notes ? (
              object[0]?.notes
                .split("\n")
                .map((note, index) => <p key={index}>{note}</p>)
            ) : (
              <p>N/A</p>
            )}
          </div>
        </div>
        <div
          style={{
            ...Notes,
            flex: 1,
            marginLeft: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              EXCLUSIVE ITEMS
            </p>
            {object[0]?.exclusive_item ? (
              object[0].exclusive_item
                .split("\n")
                .map((item, index) => <p key={index}>{item}</p>)
            ) : (
              <p style={{ marginLeft: "35px" }}>{fetchedObject.exclusive}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
