import React from "react";
import Progress from "../Progress";
import classNames from "../../classNames";
import Body from "./Body";

const noop = () => {};
const defaultProps = {
  fields: {},
  objects: [],
  selected: [],
  selectable: false,
  excludeFields: [],
  progress: false,
  onClick: noop,
  onClickItem: noop,
  onSelect: noop,
  onSelectAll: noop,
  readOnly: false,
  onLongPress: noop,
};
function CardList({
  className,
  fields,
  user,
  objects,
  progress,
  selected,
  onSelect,
  onSelectAll,
  onClick,
  onClickItem,
  selectable,
  setRef,
  excludeFields,
  ...props
}) {
  return (
    <div className="position-relative">
      <div
        className={classNames("card mt-2 border-0", className)}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        ref={setRef}
        {...props}
      >
        {objects.length === 0 && !progress && (
          <h4
            className="text-center fs-lg"
            colSpan={Object.keys(fields).length + 1}
          >
            No Data Found
          </h4>
        )}
        <div className="mb-0">
          <Body
            selectable={selectable}
            selected={selected}
            onClick={onClick}
            onSelect={onSelect}
            user={user}
            objects={objects}
            fields={fields}
            onClickItem={onClickItem}
            excludeFields={excludeFields}
          />
        </div>
        {progress && (
          <div colSpan={Object.keys(fields).length + 1}>
            <Progress className="fs-sm">Loading ...</Progress>
          </div>
        )}
      </div>
    </div>
  );
}

CardList.defaultProps = defaultProps;
export default CardList;
