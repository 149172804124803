import React from "react";
import logo from "../../../assets/gmd-logo.png";
import dateFormat from "../../../dateFormat";

export default function ClientPrint({ object }) {
  const paper = {
    padding: "10mm",
    fontFamily: "Montserrat, sans-serif",
  };

  const logoStyle = {
    width: "30mm",
  };

  const header = {
    display: "flex",
    justifyContent: "space-between",
  };

  const styles = {
    table: {
      borderCollapse: "collapse",
      marginBottom: "10px",
      width: "100%",
      border: "1px solid #ebe3cf",
    },
    tableDesc: {
      borderCollapse: "collapse",
      marginBottom: "10px",
      width: "100%",
    },
    header: {
      backgroundColor: "#f0cead",
      color: "white",
      padding: "10px",
    },
    dataCell: {
      padding: "10px",
      verticalAlign: "top",
    },
    headerTotal: {
      backgroundColor: "#2e2e2e",
      color: "white",
      padding: "10px",
    },
    dataCellTotal: {
      backgroundColor: "#2e2e2e",
      color: "white",
      padding: "10px",
      verticalAlign: "top",
      textAlign: "right",
    },
    itemTable: {
      borderCollapse: "collapse",
      width: "100%",
      marginBottom: "10px",
    },
    summaryTable: {
      borderCollapse: "collapse",
      width: "100%",
    },
    headerCell: {
      backgroundColor: "#FFA500",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    itemCell: {
      padding: "10px",
      border: "1px solid #000",
      borderTop: "none",
    },
    summaryHeaderCell: {
      backgroundColor: "#000",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    summaryCell: {
      padding: "10px",
      border: "1px solid #000",
      backgroundColor: "#f2f2f2",
    },
  };
  const line = {
    border: "1px solid #FFA500",
  };
  console.log("print", object);

  return (
    <div style={paper} className="">
      <div style={header}>
        <div>
          <p style={{ margin: "0" }}>
            {" "}
            {/* Set margin to 0 */}
            <b>GMD PRODUCTIONS INC</b>
          </p>
          <p style={{ margin: "0" }}>32 Santan Unit H Brgy Fortune Marikina</p>
          <p style={{ margin: "0" }}>Marikina, NCR 1802 PH</p>
          <p style={{ margin: "0" }}>+63 9688870928</p>
          <p style={{ margin: "0" }}>pjg@gmd.ph</p>
          <p style={{ margin: "0" }}>https://www.gmd.ph</p>
        </div>

        <div style={{ textAlign: "end" }}>
          <p>
            <img src={logo} alt="GMD Logo" style={logoStyle} />
          </p>
        </div>
      </div>

      <div style={{ color: "#EBBD2F", fontSize: "26px", marginTop: "25px" }}>
        Clients
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flexGrow: 1 }}></div>

        <div style={{ display: "flex", marginRight: "10px" }}>
          <div style={{ flex: 1, textAlign: "end", paddingRight: "5px" }}>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
                margin: 0,
                whiteSpace: "nowrap",
              }}
            >
              <b>DATE</b>
            </p>
          </div>
          <div style={{ flex: 1, textAlign: "start" }}>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
                margin: 0,
              }}
            >
              {dateFormat(Math.floor(Date.now()))}
            </p>
          </div>
        </div>
      </div>

      <hr></hr>

      <div style={header}></div>
      <table style={styles.tableDesc}>
        <tr style={line}>
          <th style={styles.header} colSpan="9">
            Client Name
          </th>
          <th style={styles.header} colSpan="1">
            Billing Address
          </th>
          <th style={styles.header} colSpan="1">
            Shipping Address
          </th>
          <th style={styles.header} colSpan="1">
            Total Projects
          </th>
          <th style={styles.header} colSpan="1">
            Total Profit
          </th>
          <th style={styles.header} colSpan="1">
            Total Expenses
          </th>
        </tr>

        {object.map((item) => (
          <tr style={line} key={item.id}>
            <td style={styles.dataCell} colSpan="9">
              {item.name}
            </td>
            <td style={styles.dataCell} colSpan="1">
              {`${item.billing_address[0]?.street_address}, 
              ${item.billing_address[0]?.city}, 
              ${item.billing_address[0]?.province}, 
              ${item.billing_address[0]?.zip_code}, 
              ${item.billing_address[0]?.country}`}
            </td>
            <td style={styles.dataCell} colSpan="1">
              {`${item.billing_address[0]?.street_address}, 
              ${item.billing_address[0]?.city}, 
              ${item.billing_address[0]?.province}, 
              ${item.billing_address[0]?.zip_code}, 
              ${item.billing_address[0]?.country}`}
            </td>
            <td style={styles.dataCell} colSpan="1">
              {item.total_projects}
            </td>
            <td style={styles.dataCell} colSpan="1">
              {item.total_profit}
            </td>
            <td style={styles.dataCell} colSpan="1">
              {item.total_expenses}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}
