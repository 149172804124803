import React, { useState } from "react";
import classNames from "../../../../classNames";
import InputFactory from "../../../../components/InputFactory";

const ApproveDialogContent = ({ onApprove, onCancel, object, onInput }) => {
  const [amountToApprove, setAmountToApprove] = useState("");

  const handleApprove = () => {
    if (amountToApprove.trim() !== "" && amountToApprove !== "0.00") {
      onApprove();
    } else {
      console.log("Please enter a valid amount to approve.");
    }
  };

  return (
    <div className="text-end">
      <div className="text-center">
        <i
          className={classNames("bi bi-file-earmark-check", "text-warning")}
          style={{ fontSize: "5rem" }}
        ></i>
        <h4 className="fw-bold">Approve Cash Advance</h4>
        <p className="m-0">Employee: {object.name.fullName}</p>
        <p className="m-0">Amount Asked: ₱{object.amounts}</p>
        <p className="m-0">Description: {object.description}</p>
        <div className="mt-3 mb-3">
          <label className="form-label">Amount to approve: </label>
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Enter Amount to Approve"
            onInput={onInput}
            value={amountToApprove}
            onChange={(e) => setAmountToApprove(e.target.value)}
          />
        </div>
        {amountToApprove.trim() !== "" && amountToApprove !== "0.00" && (
          <button
            className="btn mb-2 mt-2 me-2"
            style={{ backgroundColor: "#EBBD2F" }}
            onClick={handleApprove}
          >
            Approve
          </button>
        )}
        <button className="btn mb-2 mt-2 btn-light" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ApproveDialogContent;
