import React from "react";

const Test = () => {
  const style = {
    conatainer: {},
    title: {
      fontSize: "2rem",
      textAlign: "center",
      fontWeight: 100,
      marginBottom: ".8rem",
    },
    subtitle: { textAlign: "center", fontWeight: "bold" },
    date: {
      textAlign: "center",
      marginBottom: "2rem",
      fontSize: ".8rem",
      fontWeight: "200",
    },
    total: {
      textAlign: "end",
      fontWeight: "bold",
      paddingRight: "10px",
      paddingTop: "8px",
      paddingBottom: "5px",
    },
    dataRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontWeight: 100,
      color: "#606060",
    },
    secondPart: { marginLeft: "8px" },
    secondPartContent: { marginLeft: "40px" },
    thirdPart: { marginLeft: "18px" },
    thirdPartContent: { marginLeft: "50px" },
    hrline: { margin: 0 },
  };
  console.log(style.conatainer);
  return (
    <div
      className="mt-4 flex justify-content-center align-items-center "
      style={{ height: "vh-100 " }}
    >
      <div
        style={{
          padding: "5mm",
          fontFamily: "Montserrat, sans-serif",

          width: "60rem",
          background: "#fff",
        }}
      >
        <div style={style.title}>GMD PRODUCTIONS INC</div>
        <div style={style.subtitle}>Profit and Loss</div>
        <div style={style.date}>1 January - 22 November, 2023</div>
        <hr
          style={{
            ...style.hrline,
            height: "1px",
            background: "black",
            opacity: 100,
          }}
        />
        <div style={style.total}>TOTAL</div>
        <hr style={{ ...style.hrline, height: "2px", background: "#505050" }} />
        <div style={style.dataRow}>
          <span>
            <i class="bi bi-caret-down-fill"></i> Income
          </span>
        </div>

        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Discounts given</span>
          <span>-34,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>
            Sale of service/Fee income
          </span>
          <span>38,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Services</span>
          <span>15,234,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>
            Unapplied Cash Payment Incom
          </span>
          <span>-4,345.23</span>
        </div>

        <hr style={style.hrline} />
        <div style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}>
          <span style={style.secondPart}>Total Income</span>
          <span>PHP15,234,345.23</span>
        </div>

        <div style={style.dataRow}>
          <span>
            <i class="bi bi-caret-down-fill"></i> Cost of Sales
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPart}>
            <i class="bi bi-caret-down-fill"></i> Cost of Sales
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>
            Productions +Supplies and materials
          </span>
          <span>-160,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Production Services</span>
          <span>74,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Telephone</span>
          <span>345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}>
          <span style={style.secondPartContent}>Total Cost of Sales</span>
          <span>239,345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={style.dataRow}>
          <span
            style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}
          >
            Total Cost of Sales
          </span>
          <span>PHP239,345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={style.dataRow}>
          <span style={{ ...style.dataRow }}>GROSS PROFIT</span>
          <span style={{ fontWeight: "bold", color: "#505050" }}>
            PHP15,239,345.23
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPart}>
            <i class="bi bi-caret-down-fill"></i> Other Income(Loss)
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Interest income</span>
          <span>345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={style.dataRow}>
          <span
            style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}
          >
            Total Other Income(Loss)
          </span>
          <span>PHP130.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPart}>
            <i class="bi bi-caret-down-fill"></i> Expenses
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Amortization expense</span>
          <span>82,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Bank charges</span>
          <span>45.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Income tax expense</span>
          <span>86,450.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Insurance - Liability</span>
          <span>14,450.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Interest expense</span>
          <span>67,450.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Meals and entertainment</span>
          <span>13,450.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>
            Shipping and delivery expense
          </span>
          <span>350.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Supplies</span>
          <span>18,450.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Utilities</span>
          <span>17,450.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}>
          <span style={{ ...style.dataRow }}>Total Expenses</span>
          <span>PHP301,456,345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}>
          <span style={{ ...style.dataRow }}>NET EARNINGS</span>
          <span>PHP15,180,345.23</span>
        </div>
        <hr
          style={{
            ...style.hrline,
            background: "#505050",
            marginBottom: "2px",
            opacity: 100,
          }}
        />
        <hr
          style={{
            ...style.hrline,
            background: "#505050",
            opacity: 100,
          }}
        />
      </div>
    </div>
  );
};

export default Test;
