import React from "react";
import BaseListPage from "../../../base/BaseListPage";
import NavBar from "../../../components/navbar";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import withRouter from "../../../withRouter";
import PayslipPresenter from "./PayslipPresenter";
import Payslip from "../components/Payslip";
import printComponent from "../../../printComponent";
import { InfiniteScroll } from "nq-component";
import CliTable from "../../../components/CliTable";
import ViewPayslipModal from "../components/modal/ViewPayslipModal";

class PayslipPage extends BaseListPage {
  constructor(props) {
    super(props);

    this.presenter = new PayslipPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      userPayrolls: [],
      selectedView: "",
      userPayroll: {},
      holidays: [],
      modalOpen: false,
      progress: true,
      total: 0,
      count: 0,
    };
    this.payslipRef = React.createRef();
    this.fieldsToDisplay = ["name", "createdAt"];
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return "payroll";
  }

  async exportPDF() {
    if (!this.payslipRef) return;

    printComponent(this.payslipRef.current, "File");
  }

  toggleModal(objectID) {
    const payroll = this.state.userPayrolls.find(
      (payroll) => payroll.payrollId === objectID
    );

    this.setState((prev) => ({
      ...prev,
      modalOpen: !prev.modalOpen,
      selectedView: objectID,
      userPayroll: payroll,
    }));
  }

  formatObjects(objects) {
    return objects.map((object) => {
      return {
        ...object,
      };
    });
  }

  formatSchema(fields) {
    return {
      ...fields,
      download: {
        type: "DownloadButton",
        label: "Action",
        placeholder: "View",
        onClick: this.toggleModal.bind(this),
      },
    };
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const user = this.getCurrentUser();
    const { objects, progress, count, userPayroll } = this.state;

    const selectedObject = objects.find(
      (object) => object.id === this.state.selectedView
    );

    return (
      <div className="h-100" style={{ background: "white" }}>
        <NavBar />
        <ViewPayslipModal
          isOpen={this.state.modalOpen}
          toggleModal={this.toggleModal.bind(this)}
          onClickDownload={this.exportPDF.bind(this)}
          payroll={userPayroll}
          user={user}
          holidays={this.state.holidays}
          object={selectedObject}
        />

        <Payslip
          payslipRef={this.payslipRef}
          payroll={userPayroll}
          user={user}
          holidays={this.state.holidays}
          object={selectedObject}
        />
        <div
          className="container-fluid"
          style={{
            paddingInline: "3rem",
            paddingBlock: "2rem",
            background: "white",
          }}
        >
          <p className="fs-3 fw-bold">Payslip</p>
          <div className="mt-3">
            <InfiniteScroll
              loadMore={this.loadMore.bind(this)}
              hasMore={!progress && count > objects.length}
            >
              <CliTable
                fields={this.formatSchema(schema.fields)}
                objects={objects}
                tableHeadBackground="#DAB44E"
                shadow={false}
                striped={false}
                progress={progress}
                excludeFields={Object.keys(schema.fields).filter(
                  (key) => !this.fieldsToDisplay.includes(key)
                )}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PayslipPage);
