import InputFactory from "../../../components/InputFactory";

export default function SearchFilter({
  classNames,
  filter,
  onChangeFilter,
  field,
}) {
  const { type, ...options } = filter;

  return (
    <div
      className={`position-relative d-flex align-items-center w-45 col-auto ${classNames}`}
    >
      <i class="bi bi-search position-absolute" style={{ left: "1.6rem" }}></i>
      <InputFactory
        type={type}
        field={field}
        onChange={(value, field) => onChangeFilter(type, value, field)}
        className="rounded-pill ps-5 text-light-emphasis"
        {...options}
      />
    </div>
  );
}
