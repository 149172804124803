import BaseFormPresenter from "../../../base/BaseFormPresenter";
import { findObjectUseCase } from "../../../usecases/object";

class UserFormPresenter extends BaseFormPresenter {
  /* 
    Overrode onChange to put the value in an array if the field is "roles". Backend type of roles is Relation, and I made the frontend type  to be Pointer so I can make use of the select component in the factory. Relation type expects an array value in the backend thus the reason for override.
   */

  onChange(value, field) {
    if (field === "roles") {
      this.change[field] = [value];
      this.object[field] = [value];
      this.change = {
        ...this.change,
        monthlyRate: 0,
        dailyRate: 0,
      };
    } else {
      this.change[field] = value;
      this.object[field] = value;
    }
    this.view.setObject(this.object);
  }

  /*
    Overrode submit to populate the firstName, middleName and lastName to the fullName field. This allows the search to target the fullName
  */
  async submit() {
    const change = this.change;
    const state = this.view.state.object;
    const daily =
      state.roles.some((role) => role.name === "user") ||
      state.roles.some((role) => role.name === "Production");

    this.change = {
      ...change,
      dailyRate: daily ? state.dailyRate : (state.monthlyRate * 12) / 261,
      fullName: `${
        change.hasOwnProperty("firstName") ? change.firstName : state.firstName
      } ${
        change.hasOwnProperty("middleName")
          ? change.middleName || ""
          : state.middleName || ""
      } ${
        change.hasOwnProperty("lastName") ? change.lastName : state.lastName
      }`,
    };

    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    try {
      this.view
        .showAddUserDialog()
        .then(async () => {
          this.view.submitting();
          await this.save();
          this.view.submissionSuccess();
          this.view.showToast("User has been successfully added.");
          this.view.navigateBack();
        })
        .catch((error) => console.log(error));
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  async save() {
    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }
    try {
      const newUser = await this.upsertUseCase.execute(collection, this.change);
      this.createDefaults(newUser);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async createDefaults(user) {
    if (this.view.getParams().id) return;
    const payrolls = await findObjectUseCase().execute("payroll");

    if (!payrolls || !user) return;

    if (
      user.roles[0].name === "Production" ||
      user.roles[0].name === "user" ||
      user.roles[0].name === "Coordinator"
    ) {
      payrolls.forEach(async (payroll) => {
        if (
          payroll.type === "Weekly" &&
          (payroll.status === "Draft" || payroll.status === "Approved")
        ) {
          await this.upsertUseCase.execute("employee_payroll_info", {
            userId: user.id,
            payrollId: payroll.id,
            daysWorked: 0,
            deductions: 0,
            total: 0,
          });
        }
      });
    } else {
      payrolls.forEach(async (payroll) => {
        if (
          payroll.type === "Monthly" &&
          (payroll.status === "Draft" || payroll.status === "Approved")
        ) {
          await this.upsertUseCase.execute("employee_payroll_info", {
            userId: user.id,
            payrollId: payroll.id,
            daysWorked: 0,
            subtotal: 0,
            total: 0,
            deductions: 0,
          });
        }
      });
    }
  }
}

export default UserFormPresenter;
