import { Scroll } from "nq-component";
import React from "react";

const defaultProps = {
  element: "div",
  parentElement: null, // the default is the parent element of the current element
  loadMore: null,
  hasMore: true,
  isReverse: false,
  useWindow: false,
  threshold: 50,
};

const InfiniteScroll = ({
  children,
  loadMore,
  hasMore,
  isReverse = false,
  threshold = 50,
  useWindow,
  parentElement,
  ...props
}) => {
  const scrollComponentRef = React.useRef(null);
  const calculateOffset = React.useCallback((element, scrollTop) => {
    if (useWindow) {
      const windowHeight = window.innerHeight;
      const scrollHeight = document.documentElement.scrollHeight;
      const offset = scrollHeight - scrollTop - windowHeight;
      return offset;
    } else {
      const parent = parentElement || element.parentNode;
      const scrollHeight = parent.scrollHeight;
      const elementHeight = parent.clientHeight;
      const offset = scrollHeight - scrollTop - elementHeight;
      return offset;
    }
  }, []);
  const getRef = React.useCallback((node) => {
    scrollComponentRef.current = node;
  }, []);
  const onScroll = React.useCallback(
    (scrollTop) => {
      console.log("Scrolled");

      if (!hasMore) {
        return;
      }

      const element = scrollComponentRef.current;
      const offset = isReverse
        ? scrollTop
        : calculateOffset(element, scrollTop);
      if (offset < Number(threshold) && typeof loadMore === "function") {
        loadMore();
      }
    },
    [hasMore, isReverse, threshold, loadMore]
  );
  return (
    <Scroll
      parentElement={parentElement}
      useWindow={useWindow}
      {...props}
      onScroll={onScroll}
      getRef={getRef}
    >
      {children}
    </Scroll>
  );
};

InfiniteScroll.defaultProps = defaultProps;
export default InfiniteScroll;
