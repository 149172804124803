import React, { useState } from "react";
import { Button } from "nq-component";
import InputFactory from "../../../components/InputFactory";
import { useNavigate } from "react-router-dom";

const AddNewEstimate = ({ showModal, toggleModal, pid, cid }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (value) => {
    const selectedName = value.name;
    setSelectedOption(selectedName);
  };

  const onClickItem = (option) => {
    console.log("ssa" + option);
    if (option) {
      switch (option) {
        case "Event":
          {
            pid && cid
              ? navigate("/forms/event/" + pid + "/" + cid, {
                  state: { estimateType: option },
                })
              : navigate("/forms/event/", { state: { estimateType: option } });
          }
          break;
        case "Fabrication":
          {
            pid && cid
              ? navigate("/forms/fabrication/" + pid + "/" + cid, {
                  state: { estimateType: option },
                })
              : navigate("/forms/fabrication/", {
                  state: { estimateType: option },
                });
          }
          break;
        case "Construction":
          {
            pid && cid
              ? navigate("/forms/construction/" + pid + "/" + cid, {
                  state: { estimateType: option },
                })
              : navigate("/forms/construction/", {
                  state: { estimateType: option },
                });
          }
          break;
        default:
          alert(`Navigating to ${option}-page`);
      }
    } else {
      alert("Please select an option before confirming.");
    }
  };

  return (
    // <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="exampleModal" aria-hidden="true">
    //     <div className="modal-dialog modal-dialog-centered">
    //         <div className="modal-content">
    //             <div className="modal-header" style={{ borderBottom: 'none', backgroundColor: "gree" }}>
    //                 <h5 className="modal-title" style={{ color: "#D8AE3F", fontSize: "22px" }}>Add New Estimates</h5>
    //                 <button type="button" className="btn-close" aria-label="Close" onClick={toggleModal}></button>
    //             </div>
    //             <div className="modal-body">
    //                 <div className="mb-3">
    //                     <label className="form-label">Type of Estimates</label>
    //                     <InputFactory
    //                         type="UserInputPointer"
    //                         dynamic={false}
    //                         target="type_of_estimates"
    //                         field="type_of_estimates_name"
    //                         placeholder="Select"
    //                         onChange={handleInputChange}
    //                         required={true}
    //                     />
    //                 </div>

    //             </div>
    //             <div className="modal-footer" style={{ borderTop: 'none', justifyContent: "center" }}>
    //                 <Button
    //                     className="btn"
    //                     onClick={toggleModal}
    //                     style={{ backgroundColor: "#E8E8E8", height: "50px", width: "154px", border: "none", fontSize: "18px", borderRadius: "5px" }}>
    //                     Cancel
    //                 </Button>
    //                 <Button
    //                     className="btn"
    //                     onClick={() => onClickItem(selectedOption)}
    //                     style={{ backgroundColor: "#DAB451", height: "50px", width: "154px", border: "none", fontSize: "18px", borderRadius: "5px" }}>
    //                     Confirm
    //                 </Button>

    //             </div>
    //         </div>
    //     </div>
    // </div>
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
            zIndex: 1040, // Ensure this is below the modal but above other content
          }}
        ></div>
      )}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none", zIndex: 1050 }}
        tabIndex="-1"
        aria-labelledby="exampleModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ borderBottom: "none", backgroundColor: "#DAB451" }}
            >
              <h5
                className="modal-title"
                style={{ color: "black", fontSize: "22px" }}
              >
                Add New Estimates
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={toggleModal}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Type of Estimates</label>
                <InputFactory
                  type="UserInputPointer"
                  dynamic={false}
                  target="type_of_estimates"
                  field="type_of_estimates_name"
                  placeholder="Select"
                  onChange={handleInputChange}
                  required={true}
                />
              </div>
            </div>
            <div
              className="modal-footer"
              style={{ borderTop: "none", justifyContent: "center" }}
            >
              <Button
                className="btn"
                onClick={toggleModal}
                style={{
                  backgroundColor: "#E8E8E8",
                  height: "50px",
                  width: "154px",
                  border: "none",
                  fontSize: "18px",
                  borderRadius: "5px",
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn"
                onClick={() => onClickItem(selectedOption)}
                style={{
                  backgroundColor: "#DAB451",
                  height: "50px",
                  width: "154px",
                  border: "none",
                  fontSize: "18px",
                  borderRadius: "5px",
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewEstimate;
