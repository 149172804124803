import React from "react";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate("/signin");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <div
      className=""
      style={{
        backgroundImage: `url('/bg-gmd.png')`,

        backgroundSize: "contain",
        width: "100%",
        height: "600px",
        borderRadius: "0 0 50px 50px",
      }}
    >
      <div>
        <nav
          className="navbar navbar-expand-lg  fixed-top"
          style={{ backgroundColor: "#fdf8eb" }}
        >
          <div className="container-fluid">
            <img
              src="/gmd-logo.png"
              style={{ width: "100px", height: "50px" }}
            />
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarNav"
            >
              <ul className="navbar-nav mx-auto">
                <div
                  className="d-flex m-1 ps-5 pe-5"
                  style={{
                    borderRadius: "50px 50px",
                    backgroundColor: "orange",
                    border: "1px solid orange",
                  }}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="#home"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item ms-4">
                    <a className="nav-link" href="#services">
                      Solutions
                    </a>
                  </li>
                </div>
              </ul>
              <div className="d-flex">
                <button
                  className="btn  me-2"
                  onClick={handleSignIn}
                  style={{
                    border: "1px solid orange",
                    backgroundColor: "orange",
                  }}
                >
                  SIGN IN
                </button>
                <button
                  className="btn "
                  type="submit"
                  onClick={handleSignUp}
                  style={{
                    border: "1px solid orange",
                  }}
                >
                  SIGN UP
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="" style={{ paddingTop: "130px" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <p className="fw-bold ms-2" style={{ fontSize: "60px" }}>
                Fabrication IS NOW Done Right
              </p>
              <p className="ms-2">FABRICATE. INNOVATE. REVOLUTIONIZE.</p>
              <p className="ms-2">
                Here in GMD, we believe that you deserve partners who are as
                invested in your projects as you are. We're ready to go the
                distance in aiding your growth.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="/fab.png"
                className="img-fluid"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>

        <div className=" container" style={{ marginTop: "110px" , paddingBottom:"90px"}}>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-12 " style={{marginBottom:"10px"}}>
              <img
                src="/innovation.png"
                className="img-fluid img-thumbnail"
                alt="innovation"
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 "style={{marginBottom:"10px"}}>
              <img
                src="/customer-centric.png"
                className="img-fluid img-thumbnail"
                alt="customer-centric"
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12"style={{marginBottom:"10px"}}>
              <img
                src="/accountability.png"
                className="img-fluid img-thumbnail"
                alt="accountability"
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 "style={{marginBottom:"10px"}}>
              <img
                src="/teamwork.png"
                className="img-fluid img-thumbnail"
                alt="teamwork"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
