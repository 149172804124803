import React from "react";
import NavBar from "../../components/navbar";
import {
  Button,
  InfiniteScroll,
  Progress,
  Search,
  SelectSearch,
} from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import InputFactory from "../../components/InputFactory";
import DateRange from "../../components/DateRange";

import CliTable1 from "../../components/CliTable1";
import ClientTabListPresenter from "./ClientTabListPresenter";
import printComponent from "../../printComponent";
import { browseFile } from "nq";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

class ClientTabList extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ClientTabListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
    };
    this.contractPDF = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }

  filterList() {
    const clientId = this.props.clientId;

    if (this.props.tabCollection === "projects") {
      this.presenter.filterListing({ client: { id: clientId } });
    }
    if (this.props.tabCollection === "invoices_final") {
      this.presenter.filterListing({ client_name: { id: clientId } });
    }
  }
  getCollectionName() {
    return this.props.tabCollection;
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeEmployee(where) {
    this.presenter.onChangeEmployee(where);
  }

  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  filterSubmit2(where) {
    console.log(where);
    this.presenter.filterSubmit2(where);
  }

  filterSubmit3(where) {
    this.presenter.filterSubmit3(where);
  }
  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit2(where);
  }

  onChangeFilter3(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit3(where);
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    // console.log(`${this.props.tabCollection} - `, objects);

    const clientId = this.props.clientId;

    console.log("CLIENTID", clientId);

    if (!schema) return <Progress />;
    return (
      <>
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-block d-md-none mt-2">
                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {/* {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3  justify-content-between align-items-center ">
                <div className="d-flex">
                  {/* {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })} */}
                </div>
              </div>

              <CliTable1
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "or",
                    "items",
                    "id",
                    "createdAt",
                    "company_name",
                    "email",
                    "fax",
                    "amount",
                    "amounts",
                    "other",
                    "notes",
                    "website",
                    "billing_address",
                    "shipping_address",
                    "phone_number",
                    "telephone_number",
                    "test",
                    "testing",
                    "mobile_number",
                    "project_name",
                    "account",
                    "total",
                    "created_by",
                    "installDates",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default ClientTabList;
