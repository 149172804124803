import React from "react";
import { InputJson } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import withRouter from "../../../withRouter";
import BaseFormPage from "../../../base/BaseFormPage";
import NavBar from "../../../components/navbar";
import FormFactory2 from "../../../components/FormFactory2";
import { restUseCase } from "../../../usecases/rest";
import TotalLeaveCreditsFormPresenter from "./TotalLeaveCreditsFormPresenter";
import InputFactory from "../../../components/InputFactory";
import Drop from "../../../components/DropdownFilter";

class TotalLeaveCreditsFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      selectedProject: null,
      filtered: [],
    };
    this.presenter = new TotalLeaveCreditsFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "users";
  }

  onClickAdvance() {
    this.setState({
      advanced: !this.state.advanced,
    });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
    console.log(object);
  }

  render() {
    const object = this.state.object;
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label =
      this.getObjectId() === undefined ? "Add/Create " : "Edit Employee ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label} Leave Credits
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    {advanced ? (
                      <InputJson
                        defaultValue={object}
                        onChange={this.onChangeObject.bind(this)}
                        rows="15"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mt-3 mb-3">
                    <label className="form-label">Employee Name</label>
                    <InputFactory
                      type="String"
                      dynamic={false}
                      field="fullName"
                      object={object}
                      placeholder="Select Employee"
                      onChange={this.onChange.bind(this)}
                      required={true}
                      disabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Credits</label>
                    <InputFactory
                      type="Number"
                      _type="parseNumber"
                      dynamic={false}
                      field="credits"
                      object={object}
                      placeholder="Enter Credits"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary fs-sm me-3"
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(TotalLeaveCreditsFormPage);
