import { browseFile } from "nq";
import BaseListPresenter from "../../../base/BaseListPresenter";
import Papa from "papaparse";

class CashDisbursementPresenter extends BaseListPresenter {
  constructor(view, findObjectUseCase, countObjectUseCase, upsertUseCase) {
    super(view, findObjectUseCase, countObjectUseCase, upsertUseCase);
    this.upsertUseCase = upsertUseCase;
  }
  componentDidMount() {
    this.init();

    return this.getObjects();
  }
  async getObjects() {
    this.reset();
    await this.getPO()
    await this.countObjects();
    await this.findObjects();
  }



  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filter2 = {};
    this.filter3 = {};
    this.filterDate = {};
    this.filterList = {};
    this.filterEmployee = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const bankNameFromURL = urlSearchParams.get("bank");
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filter2,
        ...this.filterDate,
        ...this.filter3,
        ...this.filterList,
        ...(bankNameFromURL ? { account: { name: bankNameFromURL } } : {}),
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }


  async getPO() {
    try {
      const collection = "purchase_orders";
      const query = {
        include: ['all'],
        where: { ...this.filterDate }
      };
      const purchase_order = await this.findObjectUseCase.execute(collection, query);
      this.view.setPO(purchase_order);
    } catch (error) {
      console.error("Error fetching payables:", error);
    }
  }
  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  filterSubmit2(where) {
    this.reset();
    this.filter2 = where;

    this.getObjects();
  }

  filterSubmit3(where) {
    this.reset();
    this.filter3 = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeEmployee(where) {
    this.filterEmployee = where;
    this.getObjects();
  }

  onClickAdd() {
    // const collection = this.view.getCollectionName();
    // this.view.navigateTo("/form/expenses/" + collection);
    this.view.navigateTo("/accounts/new-transaction");
  }

  onClickImport(file) {
    this.view.showProgress();
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const csvText = e.target.result;
        const data = this.parseCSV(csvText);
        console.log("type", data);
        // this.saveObjects(data);
      } catch (error) {
        console.error("Error processing the CSV file:", error);
        alert("An error occurred while reading the CSV file.");
      }
    };
    reader.readAsText(file);
  }

  createCSVQuery() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const bankNameFromURL = urlSearchParams.get("bank");
    const skip = this.current - 1;
    const query = {
      skip: skip,
      where: {
        ...this.filterDate,
        ...(bankNameFromURL ? { account: { name: bankNameFromURL } } : {}),
      },
      include: "all",
    };
    return query;
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += `"${array[i][index]}"`;
      }

      str += line + "\r\n";
    }

    return str;
  }

  exportToCSV = async (salariesWages, officeTeam, workforce, weekOneTotalAmount, weekTwoTotalAmount, weekThreeTotalAmount, weekFourTotalAmount, weekFiveTotalAmount, totalAmount, creditCardBillTotalAmount, purchaseOrderTotalAmount) => {
    try {
      console.log("checkobj", salariesWages)
      const dataName = "TotalCashReceipt"
      const collectionOpex = "expense";
      const queryOpex = {
        include: ['all'],
        where: { ...this.filterDate }
      };

      const opEx = await this.findObjectUseCase.execute(collectionOpex, queryOpex);
      const opExpense = "OPEX -";
      const opExpenseObjects = opEx.filter(item =>
        item.chart_accounts?.name.toLowerCase().includes(opExpense.toLowerCase())
      );

      const combinedOpExpenseData = opExpenseObjects.reduce((result, obj) => {
        const name = obj.chart_accounts?.name;
        if (!result[name]) {
          result[name] = { amount: obj.amount };
        } else {
          result[name].amount += obj.amount;
        }
        return result;
      }, {});

      const totalProjectExpense = Object.values(combinedOpExpenseData)
        .reduce((total, data) => total + (data.amount || 0), 0);
      const collection = "purchase_orders";
      const query = {
        include: ['all'],
        where: { ...this.filterDate }
      };

      const purchase_order = await this.findObjectUseCase.execute(collection, query);
      const purchaseOrder = "Sent";
      const purchaseOrderArray = Object.values(purchase_order);
      const purchaseOrderObjects = purchaseOrderArray.filter(item => item.statuses === purchaseOrder);
      const vendorTotalMap = new Map();

      purchaseOrderObjects.forEach(item => {
        const vendorName = item.vendor_name?.name;
        const totalAmount = item.total;

        if (vendorName && totalAmount) {
          if (!vendorTotalMap.has(vendorName)) {
            vendorTotalMap.set(vendorName, totalAmount);
          } else {
            vendorTotalMap.set(vendorName, vendorTotalMap.get(vendorName) + totalAmount);
          }
        }
      });

      const data = [
        { A: "Salaries and Wages", B: salariesWages },
        { A: "Office Team", B: officeTeam },
        { A: "Workforce", B: workforce },
        { A: "Week 1", B: weekOneTotalAmount },
        { A: "Week 2", B: weekTwoTotalAmount },
        { A: "Week 3", B: weekThreeTotalAmount },
        { A: "Week 4", B: weekFourTotalAmount },
        { A: "Week 5", B: weekFiveTotalAmount },
        { A: "Operating Expenses", B: totalProjectExpense },
        ...Object.entries(combinedOpExpenseData).map(([name, data]) => ({
          A: name.replace("OPEX -", ""),
          B: data.amount
        })),
        { A: "Credit Card Bill", B: creditCardBillTotalAmount },
        { A: "Team Supplier", B: purchaseOrderTotalAmount },
        ...[...vendorTotalMap.entries()].map(([vendorName, totalAmount]) => ({
          A: vendorName,
          B: totalAmount
        })),
      ];


      const csvData = data.map(item => ({
        "": item.A,
        "Date": item.B
      }));

      const csvString = this.convertToCSV(csvData);
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = dataName + ".csv";
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting to CSV:", error);
    }
  };

  onClick() {
    this.view.navigateTo("/cash-in-position");
  }
}

export default CashDisbursementPresenter;
