import BaseFormPage from "../../../base/BaseFormPage";
import dialog from "../../../components/Modal/dialog";
import NavBar from "../../../components/navbar";
import createPromise from "../../../createPromise";
import { getObjectUseCase, upsertUseCase } from "../../../usecases/object";
import { restUseCase } from "../../../usecases/rest";
import withRouter from "../../../withRouter";
import CreateOvertimeForm from "../components/CreateOvertimeForm";
import AddOvertime from "../components/modals/AddOvertime";
import ProcessedOvertime from "../components/modals/ProcessedOvertime";
import OvertimeFormPresenter from "./OvertimeFormPresenter";
import toast, { Toaster } from "react-hot-toast";

class OvertimeFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = { object: {} };
    this.presenter = new OvertimeFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase()
    );
  }

  getCollectionName() {
    return "overtime";
  }

  formatTableFieldsLabel(fields) {
    return this.presenter.formatTableFieldsLabel(fields);
  }

  isEditing() {
    const params = this.getParams();
    if (params.hasOwnProperty("id")) {
      return true;
    } else {
      return false;
    }
  }

  showToast(message) {
    toast(message, {
      position: "bottom-right",
      style: {
        background: "#4FBC4C",
        color: "white",
        fontWeight: "600",
      },
    });
  }

  showAddOvertimeDialog() {
    const promise = createPromise();
    dialog.fire({
      html: <AddOvertime isEditing={this.isEditing()} />,
      positiveButton: this.isEditing() ? "Edit" : "Add",
      negativeButton: "Cancel",
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
    });
    return promise;
  }

  showProcessedOvertimeDialog() {
    const promise = createPromise();
    dialog.fire({
      html: <ProcessedOvertime />,
      positiveButton: "Confirm",
      negativeButton: "",
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
    });
    return promise;
  }

  getExcludedFields() {
    const roles = this.getCurrentRoles().map((item) => item.name);
    return this.isEditing()
      ? ["id", "createdAt", "updatedAt", "acl", "employee", "date"]
      : [
          "id",
          "createdAt",
          "updatedAt",
          "acl",
          "status",
          "totalOvertime",
          "employeeName",
          "nightDifferential",
          "department",
          "employeeType",
          roles.includes("masterAdmin") ? "" : "employee",
        ];
  }

  getAcl() {
    return {
      read: ["*"],
      write: ["role:hrAdmin", "role:masterAdmin"],
    };
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { object } = this.state;

    return (
      <>
        <NavBar />
        <Toaster />
        <div
          className="container-fluid"
          style={{
            paddingInline: "3rem",
            paddingBlock: "2rem",
            background: "white",
          }}
        >
          <div className="row align-items-center ">
            <i
              class="bi bi-chevron-left col-auto btn btn-link"
              onClick={this.onClickBack.bind(this)}
            ></i>
            <p className="fs-3 fw-bold col-auto m-0">Create New Time Sheet</p>
          </div>
          <form onSubmit={this.onSubmitForm.bind(this)}>
            <CreateOvertimeForm
              fields={this.formatTableFieldsLabel(schema.fields)}
              object={object}
              view={this.getArgument()?.view}
              editObject={this.presenter.object}
              onClickBack={this.onClickBack.bind(this)}
              onChange={this.onChange.bind(this)}
              excludedFields={this.getExcludedFields()}
            />
          </form>
        </div>
      </>
    );
  }
}

export default withRouter(OvertimeFormPage);
