import React from "react";
import { InputFactory as Factory, InputString } from "nq-component";
import { findObjectUseCase, saveObjectUseCase } from "../../usecases/object";
import { saveFileUseCase, saveImageUseCase } from "../../usecases/file";
import Context from "../../AppContext";
import InputSelect from "../InputSelect";
import InputStrings from "../InputString";
import RelatedFactory from "../RelatedFactoryMod";
import RelatedFactoryNoBtn from "../RelatedFactoryNoBtn";
const defaultProps = {};

function InputFactory2({ type, _type, field,disabled, object, onChange, ...props }) {
  const context = React.useContext(Context);
  const value = object && object[field];

  function _onChange(field, value) {
    if (object) {
      object[field] = value;
    }
    onChange(value, field);
  }

  switch (_type || type) {
    case "Related":
      return (
        <RelatedFactory
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          field={field}
          disabled={disabled}
          {...props}
        />
      );
    case "Relateds":
      return (
        <RelatedFactoryNoBtn
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          field={field}
          {...props}
        />
      );
    case "Enums":
      return (
        <InputSelect
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          placeholder={
            props.placeholder ||
            (props.dynamic ? "Select of type " : "Select ") +
              (props.label || field)
          }
          {...props}
        />
      );
    case "String1":
      return (
        <InputStrings
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          {...props}
        />
      );
    case "parseNumber":
      return (
        // <InputStrings
        //   defaultValue={value}
        //   onChange={_onChange.bind(this, field)}
        //   type={type.toLowerCase()}
        //   {...props}
        // />
        // <input />
        <InputSelect
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          placeholder={
            props.placeholder ||
            (props.dynamic ? "Select of type " : "Select ") +
              (props.label || field)
          }
          {...props}
        />
      );

    default:
      return (
        <Factory
          type={type}
          _type={_type}
          field={field}
          object={object}
          schemas={context.schemas}
          onChange={onChange}
          findObject={findObjectUseCase()}
          saveObject={saveObjectUseCase()}
          saveImage={saveImageUseCase()}
          saveFile={saveFileUseCase()}
          {...props}
        />
      );
  }
}

InputFactory2.defaultProps = defaultProps;
export default InputFactory2;
