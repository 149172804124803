import React from "react";
import NavBar from "../../components/navbar";
import {
  Button,
  InfiniteScroll,
  Progress,
  Search,
  SelectSearch,
  dialog,
} from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import InputFactory from "../../components/InputFactory";
import DateRange from "../../components/DateRange";

import CliTable from "../../components/CliTable";
import TaskListPresenter from "./TaskListPresenter";
import printComponent from "../../printComponent";
import { browseFile } from "nq";
import TaskPrint from "./components/TaskPrint";
import MarkDoneDialogContent from "./components/MarkDoneDialogContent";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

class TaskListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TaskListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
    };
    this.contractPDF = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (
      role.some(
        (role) =>
          role.name === "admin" ||
          role.name === "masterAdmin" ||
          role.name === "Sales"
      )
    ) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({
        employee: { $elemMatch: { id: user.id } },
      });
    }
  }
  getCollectionName() {
    return "tasks";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }
  onClickMarkAsDone(index, dp) {
    dialog.fire({
      html: (
        <MarkDoneDialogContent
          onMarkDone={() => {
            this.presenter.onClickStatus(index, "Done");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  onCollapse(index, object) {
    const role = this.getCurrentRoles();
    const user = this.getCurrentUser();
    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Task Name: </span>
              <span className="text-nowrap">{object.name}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Assignee/s: </span>
              <span className="text-nowrap">
                {object.employee && object.employee.length > 0
                  ? object.employee
                      .map((employee) => employee.fullName)
                      .join(", ")
                  : "No Assignee"}
              </span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">Location: </span>
              <span className="text-nowrap">{object.location}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Description: </span>
              <div
                className="ms-2 fw-light"
                style={{
                  whiteSpace: "pre-wrap",
                  width: "100%",
                  wordWrap: "break-word",
                }}
              >
                {object.description}
              </div>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Start Date: </span>
              <span className="text-nowrap">
                {formatDate(object.startDate)}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">End Date: </span>
              <span className="text-nowrap">{formatDate(object.endDate)}</span>
            </li>
          </ul>
        </div>
        {user.roles.some(
          (role) =>
            role.name === "masterAdmin" ||
            role.name === "admin" ||
            role.name === "Sales"
        ) && (
          <>
            {object.statuses !== "Done" && (
              <button
                onClick={this.onClickMarkAsDone.bind(this, index)}
                className="btn btn-primary mb-2 mt-2 me-2"
                style={{ backgroundColor: "#4aab73", border: 0 }}
              >
                <i class="bi bi-check"></i> Mark as Done
              </button>
            )}
            <button
              onClick={this.onClickItem.bind(this, index)}
              className="btn btn-primary"
              style={{ backgroundColor: "#EBBD2F", border: 0 }}
            >
              <i class="bi bi-pencil-square"></i> EDIT
            </button>
          </>
        )}
      </div>
    );
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  exportPDF() {
    printComponent(this.contractPDF.current, "Tasks");
  }
  onClickExportPDF() {
    this.presenter.onClickExport();
  }

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress } = this.state;

    console.log(selected);
    const user = this.getCurrentUser();
    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="d-none">
          <div ref={this.contractPDF}>
            {this.state.selected.length > 0 ? (
              <TaskPrint object={selected} />
            ) : (
              <TaskPrint object={objects} />
            )}
          </div>
        </div>
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Tasks</h1>

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#EBBD2F" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="startDate"
                        />
                        <div className="d-flex align-items-end"></div>
                        <Button
                          className="btn"
                          style={{ backgroundColor: "#dab451" }}
                          onClick={this.onClickExportPDF.bind(this)}
                        >
                          Export PDF
                        </Button>

                        {user.roles.some(
                          (role) =>
                            role.name === "masterAdmin" || role.name === "admin"
                        ) && this.state.selected.length > 0 ? (
                          <button
                            className="btn btn-danger"
                            aria-expanded="false"
                            onClick={this.onClickDeleteSelected.bind(this)}
                          >
                            <i className="bi bi-trash"></i>Delete
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                {user.roles.some(
                  (role) =>
                    role.name === "masterAdmin" ||
                    role.name === "admin" ||
                    role.name === "Sales"
                ) && (
                  <Button
                    className="btn m-1"
                    style={{ backgroundColor: "#dab451" }}
                    onClick={this.onClickAdd.bind(this)}
                  >
                    <i className="bi bi-plus" /> Create/Add Task
                  </Button>
                )}
              </div>

              <div className="d-md-flex d-none mt-3  justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="startDate"
                  />
                </div>

                <div className="d-flex align-items-center">
                  {user.roles.some(
                    (role) =>
                      role.name === "masterAdmin" || role.name === "admin"
                  ) && this.state.selected.length > 0 ? (
                    <button
                      className="btn btn-danger"
                      aria-expanded="false"
                      onClick={this.onClickDeleteSelected.bind(this)}
                    >
                      <i className="bi bi-trash"></i>Delete
                    </button>
                  ) : (
                    <></>
                  )}
                  <Button
                    className="btn m-1"
                    style={{ backgroundColor: "#dab451" }}
                    onClick={this.onClickExportPDF.bind(this)}
                  >
                    Export to PDF
                  </Button>
                </div>
              </div>

              <CliTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "or",
                    "items",
                    "id",
                    "client",
                    "project",
                    "type",
                    "location",
                    "description",
                    "created_by",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default TaskListPage;
