import React from "react";
import { formatDate } from "../../../formatDate";
import { formatCurrency } from "../../../formatCurrency";

export default function CashAdvance({ cashAdvanceInfo }) {
  if (!cashAdvanceInfo) return;

  return (
    <div className="col-5">
      <p
        className="fw-semibold mt-5"
        style={{ fontSize: "1.25rem", color: "#434343" }}
      >
        Cash Advance
      </p>
      <div
        className="col rounded m-0 p-3"
        style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)" }}
      >
        {cashAdvanceInfo?.map((item) => {
          console.log(item);

          return (
            <div
              className="d-flex flex-row justify-content-around mt-3 mb-3 align-items-center"
              style={{ fontSize: ".9rem" }}
              key={item}
            >
              <div className=" fs-xs">{formatDate(item.createdAt)}</div>
              <div className=" fw-semibold">
                {formatCurrency(
                  parseFloat(item.amounts.replace(/,/g, ""), 10) -
                    (item.deduction || 0)
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
