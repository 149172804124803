import React from "react";
import { InputJson, Button } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../../usecases/object";
import withRouter from "../../../../withRouter";
import BaseFormPage from "../../../../base/BaseFormPage";
import NavBar from "../../../../components/navbar";
import FormFactory2 from "../../../../components/FormFactory2";
import { restUseCase } from "../../../../usecases/rest";
import ProjectPrintPresenter from "./ProjectPrintPresenter";
import InputFactory from "../../../../components/InputFactory";
import Drop from "../../../../components/DropdownFilter";
import printComponent from "../../../../printComponent";
import ProjectCOC from "../components/ProjectCOC";

class ProjectPrintPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      filtered: [],
      images: [],
    };
    this.presenter = new ProjectPrintPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
    this.contractPDF = React.createRef();
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:admin", "role:masterAdmin"],
      write: [user.id, "role:admin", "role:masterAdmin"],
    };
  }

  getCollectionName() {
    return "projects";
  }

  addImageField() {
    this.setState({
      images: [...this.state.images, { name: "", file: null }], // Updated image state to include file
    });
  }

  handleImageChange(index, event) {
    const { files } = event.target;
    const images = [...this.state.images];
    if (files && files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        // Display uploaded image preview
        const imageUrl = e.target.result;
        images[index] = {
          file: file,
          //   name: this.state.images[index]?.name || file.name,
          imageUrl: imageUrl, // Store image URL for preview
        };
        this.setState({ images });
      };
      reader.readAsDataURL(file);
    }
  }

  removeImageField(index) {
    const images = [...this.state.images];
    images.splice(index, 1);
    this.setState({ images });
  }

  exportPDF() {
    const object = this.state.object;
    printComponent(
      this.contractPDF.current,
      `COC-${object.name}-${object.client.name}`
    );
  }

  onClickExportPDF() {
    this.presenter.onClickExport();
  }

  onClickAdvance() {
    this.setState({
      advanced: !this.state.advanced,
    });
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  render() {
    const object = this.state.object;
    const currentDate = this.getCurrentDateFormatted();
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    return (
      <>
        <div className="d-none">
          <div ref={this.contractPDF}>
            <ProjectCOC object={object} images={this.state.images} />
          </div>
        </div>

        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                Print Certificate of Completion
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    {advanced ? (
                      <InputJson
                        defaultValue={object}
                        onChange={this.onChangeObject.bind(this)}
                        rows="15"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  {this.state.images.map((image, index) => (
                    <div key={index} className="mt-3 mb-3">
                      <label className="form-label">Image {index + 1}</label>
                      <div className="row align-items-center">
                        <div className="col">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={(e) => this.handleImageChange(index, e)}
                          />
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Image Text"
                            value={image.name}
                            onChange={(e) => {
                              const images = [...this.state.images];
                              images[index].name = e.target.value;
                              this.setState({ images });
                            }}
                          />
                        </div>
                        <div className="col-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => this.removeImageField(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="mt-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.addImageField()}
                    >
                      {this.state.images.length === 0
                        ? "Add Image"
                        : "Add More Images"}
                    </button>
                  </div>
                </form>
                <div className="mt-4">
                  <Button
                    className="btn text-white"
                    style={{ backgroundColor: "#CF6F00" }}
                    onClick={this.onClickExportPDF.bind(this)}
                  >
                    <i className="bi bi-printer-fill" /> Print COC (A4)
                  </Button>
                  <button
                    type="button"
                    className="btn  mx-2 btn-light fs-sm"
                    onClick={this.onClickBack.bind(this)}
                  >
                    GO BACK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProjectPrintPage);
