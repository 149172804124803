import BaseFormPresenter from "../../base/BaseFormPresenter";

class InvoicesEditPresenter extends BaseFormPresenter {

    onChangeObject(object) {
        this.change = object;
        this.object = object;

    }

    async handleSubmit(e){
        const mappedServices = [];

        this.change = this.view.state.object
        this.change.statuses = "Approved"
        this.change.amount = parseFloat((this.view.state.totalAmount === 0 ? this.change.amount : this.view.state.totalAmount).toFixed(3));
        this.change.collectibles = this.change.amount
        this.change.tax = this.view.state.tax
        
        this.change.services.forEach(service => {
            const mappedService = {
                id: service.id,
                service: service.service,
                description: service.description,
                name:service.name,
                quantity: service.quantity,
                rate: service.rate
            };
        
            mappedServices.push(mappedService);
        });
        mappedServices.forEach(service => {
          service.invoice_num = this.change.invoice_num;
        });

        this.change.services = mappedServices

        try {
            await this.upsertUseCase.execute("invoices", this.change);
            
              this.change = {};
              await this.view.showSuccess("Successfully saved!");
              await this.view.navigateTo("/invoices")
          
           
          } catch (error) {
            throw error; 
          }
    }
}

export default InvoicesEditPresenter;