import React, { useState, useEffect } from "react";
import Checkbox from "../Checkbox";
import Accordion from "../Accordion";
import OutputFactory from "../OutputFactory";
import CollapseView from "./CollapseView";
import AccordionProvider from "../Accordion/AccordionProvider";
import AccordionCollapse from "../Accordion/AccordionCollapse";
import { findObjectUseCase } from "../../usecases/object";

let timeoutId;

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  // providedDate.setHours(0, 0, 0, 0);

  // const options = { year: "numeric", month: "short", day: "numeric" };
  // return providedDate.toLocaleDateString("en-US", options);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return providedDate.toLocaleString("en-US", options);
};

const getColorForEndDate = (endDate) => {
  const currentDate = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  const differenceInDays = Math.round(
    (new Date(endDate) - currentDate) / oneDay
  );

  if (differenceInDays <= 1) {
    return "red";
  } else if (differenceInDays <= 3) {
    return "orange";
  } else {
    return "green";
  }
};

function Body({
  objects,
  fields,
  excludeFields,
  selected,
  selectable,
  onSelect,
  onLongPress,
  actionsList,
  collapsable,
  onCollapse,
  toggleable,
  actions,
  onClickItem,
  fieldImage,
  onDaysWorkedChange,
}) {
  const [projectCounts, setProjectCounts] = useState({});
  const [profitCounts, setProfits] = useState({});
  const [expenseCounts, setExpenses] = useState({});

  useEffect(() => {
    const fetchProjectCounts = async () => {
      const projectCountsObj = {};
      for (const object of objects) {
        if (object.id && !projectCountsObj[object.id]) {
          const count = await getProjectCount(object.id);
          projectCountsObj[object.id] = count;
        }
      }
      setProjectCounts(projectCountsObj);
    };
    const fetchProfits = async () => {
      const profitsObj = {};
      for (const object of objects) {
        if (object.id && !profitsObj[object.id]) {
          const count = await getProfitCount(object.id);
          console.log("COUNT", count);
          profitsObj[object.id] = count;
        }
      }
      setProfits(profitsObj);
    };
    const fetchExpenses = async () => {
      const expensesObj = {};
      for (const object of objects) {
        if (object.id && !expensesObj[object.id]) {
          const count = await getExpenseCount(object.id);
          expensesObj[object.id] = count;
        }
      }
      setExpenses(expensesObj);
    };

    fetchProjectCounts();
    fetchProfits();
    fetchExpenses();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [objects]);

  const onPointerDown = () => {
    timeoutId = setTimeout(onLongPress, 500);
  };

  const onPointerUp = () => {
    clearTimeout(timeoutId);
  };

  const getProjectCount = async (clientId) => {
    const query = {
      where: {
        client: { id: clientId },
      },
    };
    const projects = await findObjectUseCase().execute("projects", query);
    return projects.length;
  };
  const getExpenseCount = async (clientId) => {
    const query = {
      where: {
        client_name: { id: clientId },
      },
    };
    const expenses = await findObjectUseCase().execute("expense", query);
    return expenses.reduce((acc, curr) => acc + curr.amount, 0);
  };
  const getProfitCount = async (clientId) => {
    const query = {
      where: {
        client_name: { id: clientId },
      },
    };
    const invoices = await findObjectUseCase().execute("invoices_final", query);

    return invoices.reduce((acc, curr) => acc + curr.received, 0);
  };

  return objects.map((object, index) => {
    const id = object.id || object._id;
    const checked = selected.some((item) => item.id === id);

    return (
      <AccordionProvider key={id}>
        <tr>
          {selectable && (
            <th
              className="align-middle"
              style={{ backgroundColor: "rgba(0,0,0,0)" }}
            >
              <Checkbox
                checked={checked}
                id={"check_" + id}
                onChange={() => onSelect(index)}
              />
            </th>
          )}

          {Object.keys(fields).map((field, i) => {
            if (excludeFields.includes(field)) return null;
            const options = fields[field];

            return (
              <Accordion
                as="td"
                eventKey={`_${id}`}
                key={field}
                className="fs-sm"
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
                style={{
                  cursor: "pointer",
                  maxWidth: "15em",
                  backgroundColor: "rgba(0,0,0,0)",
                  verticalAlign: "middle",
                }}
              >
                {field === "endDate" ? (
                  <span style={{ color: getColorForEndDate(object[field]) }}>
                    {formatDate(object[field])}
                  </span>
                ) : field === "startDate" ? (
                  <span>{formatDate(object[field])}</span>
                ) : field === "total_projects" ? (
                  <span className="">{projectCounts[object["id"]] || 0}</span>
                ) : field === "total_profit" ? (
                  <span className="">₱{profitCounts[object["id"]] || 0}</span>
                ) : field === "total_expenses" ? (
                  <span className="">₱{expenseCounts[object["id"]] || 0}</span>
                ) : (
                  <OutputFactory field={field} object={object} {...options} />
                )}
              </Accordion>
            );
          })}
          {actionsList.length > 0 && (
            <td>
              <div style={{ width: "50px" }}></div>
            </td>
          )}
          {actionsList.map((action) => {
            return (
              <td className="text-truncate">
                <button
                  onClick={action.onClick && action.onClick.bind(this, index)}
                  className="btn btn-outline-primary btn-sm fs-xs shadow-none"
                >
                  <i className={action.icon} /> {action.label}
                </button>
              </td>
            );
          })}
        </tr>
        {collapsable && (
          <tr>
            <td
              className="p-0"
              colSpan={Object.keys(fields).length + 1}
              style={{ backgroundColor: "rgba(0,0,0,0)" }}
            >
              <AccordionCollapse
                toggleable={toggleable}
                className="collapse"
                eventKey={`_${id}`}
              >
                <div className="p-3">
                  {onCollapse(index, object, excludeFields) ||
                    CollapseView(
                      index,
                      object,
                      excludeFields,
                      fields,
                      actions,
                      onClickItem,
                      fieldImage
                    )}
                </div>
              </AccordionCollapse>
            </td>
          </tr>
        )}
      </AccordionProvider>
    );
  });
}

export default Body;
