import React from "react";

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(amount);
};

const getAllowancePay = (payroll) => {
  return payroll?.adjustments?.reduce(
    (acc, current) => (acc += parseInt(current.adjustmentAmount) || 0),
    0
  );
};

const getHolidays = (holidays, startDate, endDate) => {
  const holidaysList = [];

  const start = new Date(startDate);
  const end = new Date(endDate);

  holidays.forEach((holiday) => {
    const holidayDate = new Date(holiday.date);
    const holidayMonth = holidayDate.getMonth();
    const holidayDay = holidayDate.getDate();

    const startMonth = start.getMonth();
    const startDay = start.getDate();

    const endMonth = end.getMonth();
    const endDay = end.getDate();

    if (
      (holidayMonth > startMonth ||
        (holidayMonth === startMonth && holidayDay >= startDay)) &&
      (holidayMonth < endMonth ||
        (holidayMonth === endMonth && holidayDay <= endDay))
    ) {
      holidaysList.push(holiday.date);
    }
  });

  return holidaysList;
};

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

// TODO:: FIX THIS. CURRENTLY RETURNING NAN
const computeHolidaysTotal = (holidays, startDate, endDate, object) => {
  const holidaysList = getHolidays(holidays, startDate, endDate);
  let total = 0;

  holidaysList.forEach((holiday) => {
    console.log(holiday);
    const holidayDate = new Date(holiday);

    const holidayName = days[holidayDate.getDay()];
    total += object[`${holidayName}Total`];
  });

  return total;
};

const computeAdjustmentsTotal = (adjustments) => {
  if (!adjustments) return 0;
  return adjustments.reduce(
    (acc, current) => (acc += parseInt(current.adjustmentAmount) || 0),
    0
  );
};

export default function Earnings({
  payroll,
  monthlyRate,
  payrollType,
  holidays,
  startDate,
  endDate,
}) {
  console.log("Perol", monthlyRate);
  console.log("Perolll", payroll?.totalRegularPay);

  const isWeekly = payrollType === "Weekly";

  const earnings = [
    {
      name: isWeekly ? "Normal Work Day" : "Basic Pay",
      amount: isWeekly
        ? (payroll?.totalRegularPay || 0) +
          (payroll?.totalOTPay || 0) +
          (payroll?.totalNDPay || 0) -
          (payroll?.saturdayTotal || 0) -
          computeHolidaysTotal(holidays, startDate, endDate, payroll)
        : payroll?.totalRegularPay + (monthlyRate / 2 || 0),
    },
    {
      name: isWeekly ? "Rest Day Worked" : "Overtime Pay",
      amount: isWeekly ? payroll?.saturdayTotal || 0 : payroll?.totalOTPay || 0,
    },
    {
      name: isWeekly ? "Holiday Worked" : "Night Differential Pay",
      amount: isWeekly
        ? computeHolidaysTotal(holidays, startDate, endDate, payroll)
        : payroll?.totalNDPay || 0,
    },
    { name: "Allowance", amount: getAllowancePay(payroll) || 0 },
    { name: "Cash Advance", amount: payroll?.caRequestAmount || 0 },
    {
      name: "Adjustments",
      amount: computeAdjustmentsTotal(payroll?.adjustments),
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      <div
        style={{ flex: 1, border: "1px solid #e9983d", height: "fit-content" }}
      >
        <div
          style={{
            background: "#e9983d",
            display: "flex",
          }}
        >
          <p style={{ fontWeight: "bold", flex: 2, padding: ".1rem 1rem" }}>
            Earnings
          </p>
          <div
            style={{
              flex: 1,
              background: "#333333",
              padding: ".1rem 1rem",
              borderBottom: "1px solid #e9983d",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                textAlign: "end",
                color: "white",
              }}
            >
              Amount
            </p>
          </div>
        </div>
        {earnings.map((earning, index) => {
          return (
            <div
              key={earning.name}
              style={{
                display: "flex",
                borderBottom:
                  index !== earnings.length - 1
                    ? "1px dashed rgba(0, 0, 0, .5)"
                    : "",
              }}
            >
              <p style={{ flex: 2, padding: "0px 1rem" }}>{earning.name}</p>
              <div
                style={{
                  flex: 1,
                  background: "#333333",
                  padding: "0px 1rem",
                  borderBottom:
                    index !== earnings.length - 1
                      ? "1px dashed rgba(255, 255, 255, .5)"
                      : "",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "end",
                    color: "white",
                  }}
                >
                  {formatCurrency(earning.amount)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          border: "1px solid transparent",
        }}
      >
        <p style={{ flex: 2, padding: "0px 1rem", fontWeight: "bold" }}>
          SUBTOTAL
        </p>
        <div
          style={{
            flex: 1,
            background: "#333333",
            padding: "0px 1rem",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              textAlign: "end",
              color: "white",
            }}
          >
            {formatCurrency(
              earnings.reduce((acc, current) => (acc += current.amount), 0)
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
