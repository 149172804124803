export default function DeleteButton({ selected, onDelete }) {
  if (selected?.length < 1) return null;

  return (
    <div className="col-auto">
      <i
        onClick={onDelete}
        class="btn btn-danger float-end ps-3 pe-3 bi bi-trash3"
      ></i>
    </div>
  );
}
