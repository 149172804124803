import React, { useState } from "react";

function DateRange({ field, onChange }) {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  function _onChange(startDate, endDate) {
    if (startDate && endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      const where = {};
      where[field] = {
        $gte: startDate.toISOString(),
        $lte: endDate.toISOString(),
      };
      onChange(where);
    }
  }

  function _onChangeStart(date) {
    if (date) {
      setStart(date.toISOString().slice(0, 10));
      end && _onChange(date, new Date(end));
    } else {
      setStart(null);
    }
  }

  function _onChangeEnd(date) {
    if (date) {
      setEnd(date.toISOString().slice(0, 10));
      start && _onChange(new Date(start), date);
    } else {
      setEnd(null);
    }
  }

  function clearDates() {
    setStart(null);
    setEnd(null);
    onChange({});
  }

  React.useEffect(() => {
    const date = new Date();
    // setStart(date.toISOString().slice(0, 10));
    // setEnd(date.toISOString().slice(0, 10));
  }, []);

  return (
    <div
      className="d-flex align-items-center"
      style={{ height: "2.35rem" }}
    >
      <input
        className="form-control form-control shadow-none rounded-0 border-end-0 rounded-start"
        value={start || ""}
        onChange={(e) => _onChangeStart(new Date(e.target.value))}
        type="date"
      />
      <input
        className="form-control form-control shadow-none rounded-0"
        value={end || ""}
        onChange={(e) => _onChangeEnd(new Date(e.target.value))}
        type="date"
      />
      <button
        className="btn btn-secondary h-100 shadow-none rounded-0 border-start-0 rounded-end"
        type="button"
        style={{ borderColor: "#dee2e6" }}
        onClick={clearDates}
      >
        Clear
      </button>
    </div>
  );
}

export default DateRange;
