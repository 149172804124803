import React, { useEffect, useState } from "react";
import PayslipHeader from "./PayslipHeader";
import PayslipEmployeeInfo from "./PayslipEmployeeInfo";
import PayslipInfo from "./PayslipInfo";
import Earnings from "./Earnings";
import Deductions from "./Deductions";
import PayslipTotal from "./PayslipTotal";
import { findObjectUseCase } from "../../../usecases/object";

export default function Payslip({
  payslipRef,
  payroll,
  user,
  object,
  holidays,
}) {
  const [monthlyRate, setMonthlyRate] = useState();

  useEffect(() => {
    const getUser = async () => {
      const [user] = await findObjectUseCase().execute("users", {
        where: { id: payroll.userId },
      });

      setMonthlyRate(user.monthlyRate);
    };

    if (payroll) {
      getUser();
    }
  }, [payroll]);

  return (
    <div className="d-none">
      <div
        style={{
          padding: "1rem",
          fontFamily: "Arial, sans-serif",
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
        }}
        ref={payslipRef}
      >
        <PayslipHeader />
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <PayslipEmployeeInfo
            employeeName={user.fullName}
            employeeID={user.employeeId}
            position={user.position}
          />
          <PayslipInfo
            startDate={object?.startDate}
            endDate={object?.endDate}
            total={payroll?.total}
            monthlyRate={monthlyRate}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Earnings
            payroll={payroll}
            payrollType={object?.type}
            holidays={holidays}
            startDate={object?.startDate}
            endDate={object?.endDate}
            monthlyRate={monthlyRate}
          />
          <Deductions payroll={payroll} />
        </div>
        <PayslipTotal total={payroll?.total} monthlyRate={monthlyRate} />
      </div>
    </div>
  );
}
