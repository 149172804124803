import React from "react";

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(amount);
};

const getOtherDeductionsTotal = (payroll) => {
  const otherDeductions =
    payroll?.moreOtherDeductions?.reduce(
      (acc, current) => (acc += current.deductionAmount || 0),
      0
    ) || 0;

  return (
    (payroll?.SSSLOAN || 0) +
    (payroll?.PAGIBIGLOAN || 0) +
    (payroll?.SSSDELQ || 0) +
    (payroll?.caRequest || 0) +
    (payroll?.SSSCONDO || 0) +
    otherDeductions
  );
};

export default function Deductions({ payroll }) {
  const deductions = [
    { name: "CA Deductions", amount: payroll?.CADeductions || 0 },
    { name: "TIN", amount: payroll?.TIN || 0 },
    { name: "PHILHEALTH", amount: payroll?.PHILHEALTH | 0 },
    { name: "SSS", amount: payroll?.SSS | 0 },
    { name: "PAGIBIG", amount: payroll?.PAGIBIG | 0 },
    {
      name: "TARDINESS",
      amount: payroll?.lates + payroll?.undertime + payroll?.absences || 0,
    },
    {
      name: "OTHER",
      amount: getOtherDeductionsTotal(payroll),
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      <div style={{ border: "1px solid #e9983d" }}>
        <div
          style={{
            background: "#e9983d",
            display: "flex",
          }}
        >
          <p style={{ fontWeight: "bold", flex: 2, padding: ".1rem 1rem" }}>
            Deductions
          </p>
          <div
            style={{
              flex: 1,
              background: "#333333",
              padding: ".1rem 1rem",
              borderBottom: "1px solid #e9983d",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                textAlign: "end",
                color: "white",
              }}
            >
              Amount
            </p>
          </div>
        </div>
        {deductions.map((deduction, index) => {
          return (
            <div
              key={deduction.name}
              style={{
                display: "flex",
                borderBottom:
                  index !== deduction.length - 1
                    ? "1px dashed rgba(0, 0, 0, .5)"
                    : "",
              }}
            >
              <p style={{ flex: 2, padding: "0px 1rem" }}>{deduction.name}</p>
              <div
                style={{
                  flex: 1,
                  background: "#333333",
                  padding: "0px 1rem",
                  borderBottom:
                    index !== deduction.length - 1
                      ? "1px dashed rgba(255, 255, 255, .5)"
                      : "",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "end",
                    color: "white",
                  }}
                >
                  {formatCurrency(deduction.amount)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          border: "1px solid transparent",
        }}
      >
        <p style={{ flex: 2, padding: "0px 1rem", fontWeight: "bold" }}>
          TOTAL DEDUCTIONS
        </p>
        <div
          style={{
            flex: 1,
            background: "#333333",
            padding: "0px 1rem",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              textAlign: "end",
              color: "white",
            }}
          >
            {formatCurrency(
              deductions.reduce((acc, current) => (acc += current.amount), 0)
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
