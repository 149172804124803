import React from "react";
import { InputJson } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import withRouter from "../../../withRouter";
import BaseFormPage from "../../../base/BaseFormPage";
import NavBar from "../../../components/navbar";
import FormFactory2 from "../../../components/FormFactory2";
import { restUseCase } from "../../../usecases/rest";
import ProjectTimelineFormPresenter from "./ProjectTimelineFormPresenter";
import InputFactory from "../../../components/InputFactory";
import Drop from "../../../components/DropdownFilter";

class ProjectTimelineFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      filtered: [],
    };
    this.presenter = new ProjectTimelineFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "projects";
  }

  onClickAdvance() {
    this.setState({
      advanced: !this.state.advanced,
    });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  onChange(value, field) {
    console.log("CHANGE", field, value);
    this.presenter.onChange(value, field);
    if (field === "endDate") {
      this.setState({
        object: {
          ...this.state.object,
          endDate: this.state.object.endDate || [value].name,
          installDate: null,
        },
      });
    } else {
      this.setState({
        object: {
          ...this.state.object,
          startDate: this.state.object.startDate || [value].name,
          installDate: this.state.object.installDate || [value].name,
        },
      });
    }
  }

  getCurrentStartDateFormatted() {
    const { object } = this.state;
    if (object && object.startDate) {
      const startDate = new Date(object.startDate).toISOString().split("T")[0];
      return startDate;
    }
  }
  getCurrentEndDateFormatted() {
    const { object } = this.state;
    if (object && object.endDate) {
      const endDate = new Date(object.endDate).toISOString().split("T")[0];
      return endDate;
    }
  }

  render() {
    const object = this.state.object;
    console.log("object error", object);
    const startDate = this.getCurrentStartDateFormatted();
    const endDate = this.getCurrentEndDateFormatted();
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label + object.name} Timeline
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    {advanced ? (
                      <InputJson
                        defaultValue={object}
                        onChange={this.onChangeObject.bind(this)}
                        rows="15"
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Start Date</label>
                    <InputFactory
                      type="Date"
                      dynamic={false}
                      field="startDate"
                      object={object}
                      placeholder="Enter Start Date"
                      onChange={(value) => this.onChange(value, "startDate")}
                      required={true}
                      min={startDate}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">End Date</label>
                    <InputFactory
                      type="Date"
                      id="endId"
                      dynamic={false}
                      field="endDate"
                      object={object}
                      placeholder="Enter End Date"
                      onChange={(value) => this.onChange(value, "endDate")}
                      required={true}
                      min={startDate}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Installation Date</label>
                    <InputFactory
                      type="Related"
                      target="project_install_dates"
                      id="installId"
                      dynamic={true}
                      label="Dates"
                      field="installDates"
                      object={object}
                      placeholder="Enter Installation Date"
                      onChange={this.onChange.bind(this)}
                      required={true}
                      min={startDate}
                      max={endDate}
                    />
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary fs-sm me-3"
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProjectTimelineFormPage);
