import React from "react";
import InputFactory from "../../components/InputFactory";



const EstimateTable = ({ object, onChange }) => {


  return (
    <table className="table mt-3">
      <thead style={{ textAlign: 'center' }}>
        <tr>
          <th style={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>SERVICE</th>
          <th style={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>NAME</th>
          <th style={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>DESCRIPTION</th>
          <th style={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>QTY</th>
          <th style={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>RATE</th>
          <th style={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>AMOUNT</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border-end">
            <div className="d-flex align-items-center">
              <span className="bi bi-trash me-2"></span>
              {/* <InputFactory
                      type="UserInputPointer"
                      dynamic={false}
                      target="clients"
                      field="client_name"
                      object={object}
                      placeholder="Select Client Name"
                      // onChange={this.onChange.bind(this)}
                      required={true}
                      // disabled={
                      //   object.statuses === "Approved" ||
                      //   object.statuses === "Sent" ||
                      //   object.statuses === "Paid"
                      // }
                    /> */}
              <InputFactory
               type="ModdedInputPointer"
                dynamic={false}
                target= "event_service"
                field="name"
                object={object}
                placeholder="Enter"
                required={true}
              />
            </div>
            <button className="btn d-flex justify-content-center align-items-center" type="button">
              <span className="bi bi-plus-circle-fill text-danger me-1 ms-2"></span>
              <span className="text-danger ms-2">Add Service</span>
            </button>
          </td>
          <td className="border-end">
            <InputFactory
              type="String"
              dynamic={false}
              field="notes"
              object={object}
              placeholder="Enter Note"
              // onChange={onChange}
              required={true}
            />
          </td>
          <td className="border-end">
            <InputFactory
              type="String"
              dynamic={false}
              target= "event_service"
              field="description"
              object={object}
              placeholder="Enter Note"
              // defaultValue={object.name?.description}
              // onChange={onChange}
              required={true}
            />
          </td>
          <td className="border-end">
            <InputFactory
              type="String"
              dynamic={false}
              field="notes"
              object={object}
              placeholder="Enter Note"
              onChange={onChange}
              required={true}
            />
          </td>
          <td className="border-end">
            <InputFactory
              type="String"
              dynamic={false}
              field="notes"
              object={object}
              placeholder="Enter Note"
              onChange={onChange}
              required={true}
            />
          </td>
          <td className="border-end">
            <InputFactory
              type="String"
              dynamic={false}
              field="notes"
              object={object}
              placeholder="Enter Note"
              onChange={onChange}
              required={true}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EstimateTable;
