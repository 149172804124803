import React from "react";
import Checkbox from "../Checkbox";
import Accordion from "../Accordion";
import OutputFactory from "../OutputFactory";
import CollapseView from "./CollapseView";
import AccordionProvider from "../Accordion/AccordionProvider";
import AccordionCollapse from "../Accordion/AccordionCollapse";

let timeoutId;

function Body({
  objects,
  fields,
  excludeFields,
  selected,
  selectable,
  onSelect,
  onLongPress,
  actions,
  collapsable,
  onCollapse,
  onClickItem,
  fieldImage,
  toggleable,
}) {
  const onPointerDown = () => {
    timeoutId = setTimeout(onLongPress, 500); // set long press time to 500ms
  };

  const onPointerUp = () => {
    clearTimeout(timeoutId);
  };
  console.log(objects);
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
    }).format(amount);
  };

  return objects.map((object, index) => {
    const id = object.id || object._id;
    const checked = selected[index]?.id.includes(object.id);
    const currentDate = new Date();
    const dueDate = new Date(object.due_date);
    const differenceInTime = dueDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    let label = "";
    let onClick = null;

    // switch (object.statuses) {
    //   case "Pending":
    //     label = "Edit";
    //     onClick = () => actions.handleEdit(index);
    //     break;
    //   case "Pending (Warm Lead)":
    //     label = [
    //       "Edit",
    //       <span className="bi bi-trash ms-2 text-danger"></span>,
    //     ];
    //     onClick = (action) => {
    //       if (action === "Edit") {
    //         actions.handleEdit(index);
    //       } else {
    //         actions.handleDelete(index);
    //       }
    //     };
    //     break;

    //   case "Pending (Cold Lead)":
    //     label = [
    //       "Edit",
    //       <span className="bi bi-trash ms-2 text-danger"></span>,
    //     ];
    //     onClick = (action) => {
    //       if (action === "Edit") {
    //         actions.handleEdit(index);
    //       } else {
    //         actions.handleDelete(index);
    //       }
    //     };
    //     break;
    //   case "Approved":
    //     if (object.statuses === "Approved") {
    //       label = ["Receive", "View"];
    //       onClick = (action) => {
    //         if (action === "Receive") {
    //           actions.handleReceive(index);
    //         } else if (action === "View") {
    //           actions.handleEdit(index);
    //         }
    //       };
    //     }

    //     // // Additional conditional action if estimate_type exists
    //     // if (object.estimate_type) {
    //     //   label = [<span className="bi bi-trash ms-2 text-danger"></span>];
    //     //   onClick = () => actions.handleDelete(index);
    //     //   // label = ["Receive", "View"];
    //     //   // onClick = (action) => {
    //     //   //   if (action === "Receive") {
    //     //   //     actions.handleReceive(index);
    //     //   //   } else if (action === "View") {
    //     //   //     actions.handleEdit(index);
    //     //   //   }
    //     //   // };
    //     // }
    //     break;

    //   case "Approved Estimate":
    //     label = ["Create Invoice", "Delete"];
    //     onClick = (action) => {
    //       if (action === "Create Invoice") {
    //         actions.handleCreate(index);
    //       } else if (action === "Delete") {
    //         actions.handleDelete(index);
    //       }
    //     };
    //     break;
    //   case "Partially Paid":
    //     label = ["Receive", "View"];
    //     onClick = (action) => {
    //       if (action === "Receive") {
    //         actions.handleReceive(index);
    //       } else if (action === "View") {
    //         actions.handleEdit(index);
    //       }
    //     };
    //     break;
    //   case "Paid":
    //     label = ["View"];
    //     onClick = (action) => {
    //       if (action === "View") {
    //         actions.handleEdit(index);
    //       }
    //     };
    //     break;
    //   default:
    //     break;
    // }
    console.log("label", label);

    let textColor;
    if (differenceInDays < 0) {
      textColor = "red";
    } else if (differenceInDays === 0) {
      textColor = "orange";
    } else {
      textColor = "green";
    }

    const dueDateDisplay =
      differenceInDays < 0 ? (
        <span style={{ color: "red" }}>
          Overdue by {Math.abs(differenceInDays)} days
        </span>
      ) : differenceInDays === 0 ? (
        <span style={{ color: "orange" }}>Due today</span>
      ) : (
        <span style={{ color: "green" }}>Due in {differenceInDays} days</span>
      );

    return (
      <AccordionProvider key={id}>
        <tr key={id}>
          {selectable && (
            <th className="align-middle">
              <Checkbox
                checked={checked}
                id={"check_" + id}
                onChange={() => onSelect(index)}
              />
            </th>
          )}
          {Object.keys(fields).map((field, i) => {
            const options = fields[field];
            if (excludeFields.includes(field)) return null;
            return (
              <Accordion
                as="td"
                eventKey={`_${id}`}
                key={field}
                className="fs-sm text-truncate"
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
                style={{ cursor: "pointer", maxWidth: "15em" }}
              >
                {/* {field === "due_date" ? (
                  <span style={{ color: textColor }}>{dueDateDisplay}</span>
                ) : field === "total" ? (
                  <span>₱{object.total?.toLocaleString()}</span>
                ) : field === "received" ? (
                  <span>₱{object.received?.toLocaleString()}</span>
                ) : field === "collectibles" ? (
                  <span>₱{object.collectibles?.toLocaleString()}</span>
                ) : field === "tax" ? (
                  <span>₱{object.tax?.toLocaleString()}</span>
                ) : (
                  <OutputFactory field={field} object={object} {...options} />
                )} */}
                {field === "due_date" && object.statuses !== "Paid" ? (
                  <span style={{ color: textColor }}>{dueDateDisplay}</span>
                ) : field === "due_date" && object.statuses === "Paid" ? (
                  <span style={{ color: "#cf6f00" }}>Paid</span>
                ) : field === "subTotal" ? (
                  // <span>₱{object.total?.toFixed(2).toLocaleString()}</span>
                  <span>
                    {formatCurrency(object.total?.toFixed(2).toLocaleString())}
                  </span>
                ) : field === "received" ? (
                  // <span>₱{object.received?.toFixed(2).toLocaleString()}</span>
                  <span>
                    {formatCurrency(
                      object.received?.toFixed(2).toLocaleString()
                    )}
                  </span>
                ) : field === "downpaymentAmount" ? (
                  // <span>
                  //   ₱
                  //   {parseFloat(
                  //     (object.total * (object.downpaymentAmount / 100)).toFixed(
                  //       2
                  //     )
                  //   )}
                  // </span>
                  <span>
                    {formatCurrency(
                      parseFloat(
                        (
                          object.total *
                          (object.downpaymentAmount / 100)
                        ).toFixed(2)
                      )
                    )}
                  </span>
                ) : field === "downpaymentVal" ? (
                  // <span>
                  //   ₱{object.downpaymentVal?.toFixed(2).toLocaleString() || 0}
                  // </span>
                  <span>
                    {formatCurrency(
                      object.downpaymentVal?.toFixed(2).toLocaleString()
                    ) || 0}
                  </span>
                ) : field === "downpayment" ? (
                  // <span>
                  //   ₱{object.downpayment?.toFixed(2).toLocaleString()}
                  // </span>
                  <span>
                    {formatCurrency(
                      object.downpayment?.toFixed(2).toLocaleString()
                    )}
                  </span>
                ) : field === "collectibles" ? (
                  <span>
                    {/* ₱{object.collectibles?.toFixed(2).toLocaleString()} */}
                    {/* ₱{object.collectibles?.toFixed(2).toLocaleString()} */}
                    {/* {object.downpaymentVal?.toFixed(2).toLocaleString() -
                      object.received?.toFixed(2).toLocaleString()} */}
                    {/* ₱{object.collectibles?.toFixed(2).toLocaleString()} */}
                    {formatCurrency(
                      object.collectibles?.toFixed(2).toLocaleString()
                    )}
                  </span>
                ) : field === "tax" ? (
                  // <span>₱{object.tax?.toFixed(2).toLocaleString()}</span>
                  <span>
                    {formatCurrency(object.tax?.toFixed(2).toLocaleString())}
                  </span>
                ) : (
                  <OutputFactory field={field} object={object} {...options} />
                )}
              </Accordion>
            );
          })}
          {/* {object.due_date ? (
            <td className="text-trunc">
              {Array.isArray(label) ? (
                label.map((action, i) => (
                  <p key={i} onClick={() => onClick(action)}>
                    {action}
                  </p>
                ))
              ) : (
                <p onClick={() => onClick(label)}>{label}</p>
              )}
            </td>
          ) : null} */}
        </tr>
        {collapsable && (
          <tr>
            <td className="p-0" colSpan={Object.keys(fields).length + 1}>
              <AccordionCollapse
                toggleable={toggleable}
                className="collapse"
                eventKey={`_${id}`}
              >
                <div className="p-3">
                  {onCollapse(index, object, excludeFields) ||
                    CollapseView(
                      index,
                      object,
                      excludeFields,
                      fields,
                      actions,
                      onClickItem,
                      fieldImage
                    )}
                </div>
              </AccordionCollapse>
            </td>
          </tr>
        )}
      </AccordionProvider>
    );
  });
}

export default Body;
