import React from "react";
import ProjectTab from "../projectTabs/ProjectTab";

function ProjectNav({
  object,
  projectIncome,
  projectCost,
  projectProfit,
  projectInvoice,
  projectManpower,
  projectEstimate,
  projectMaterial,
}) {
  const pId = object.id;
  const pName = object.name;
  const cId = object.client?.id;
  console.log("SUMMER", projectIncome - projectCost);
  console.log("pid", pId);
  console.log("pName", pName);
  console.log("cid", cId);
  return (
    <div className="">
      <ul className="nav nav-tabs mb-3 justify-content-between border-0">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="overview-tab"
            data-bs-toggle="tab"
            href="#overview"
            role="tab"
            aria-controls="overview"
            aria-selected="true"
          >
            Overview
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="design-tab"
            data-bs-toggle="tab"
            href="#design"
            role="tab"
            aria-controls="design"
            aria-selected="false"
          >
            Design
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="employees-tab"
            data-bs-toggle="tab"
            href="#employees"
            role="tab"
            aria-controls="employees"
            aria-selected="false"
          >
            Employees
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="expenses-tab"
            data-bs-toggle="tab"
            href="#expenses"
            role="tab"
            aria-controls="expenses"
            aria-selected="false"
          >
            Expenses
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="estimates-tab"
            data-bs-toggle="tab"
            href="#estimates"
            role="tab"
            aria-controls="estimates"
            aria-selected="false"
          >
            Estimates
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="invoices-tab"
            data-bs-toggle="tab"
            href="#invoices"
            role="tab"
            aria-controls="invoices"
            aria-selected="false"
          >
            Invoices
          </a>
        </li>
      </ul>
      <div
        className="tab-content"
        style={{ overflowX: "auto", maxWidth: "100%" }}
      >
        <div
          className="tab-pane fade show active"
          id="overview"
          role="tabpanel"
          aria-labelledby="overview-tab"
        >
          <div className="d-flex justify-content-end ">
            <a href="" style={{ color: "#D8AE3F" }}>
              <i className="bi bi-table"></i> Export to CSV
            </a>
          </div>
          <div className="container text-center">
            <div className="row m-3">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title text-success">
                      {projectIncome
                        ? "₱" + (projectIncome * 1).toLocaleString()
                        : "₱0"}
                    </h6>
                    <p className="card-text" style={{ color: "#43434380" }}>
                      INCOME
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4" style={{ height: "100%" }}>
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title text-danger">
                      {projectCost
                        ? "₱" + (projectCost * 1).toLocaleString()
                        : "₱0"}
                    </h6>
                    <p className="card-text" style={{ color: "#43434380" }}>
                      COST
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4" style={{ height: "100%" }}>
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">
                      {projectProfit
                        ? "₱" + (projectProfit * 1).toLocaleString()
                        : "₱0"}
                    </h6>
                    <p className="card-text" style={{ color: "#43434380" }}>
                      PROFIT
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-3">
              <div className="col-md-4" style={{ height: "100%" }}>
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">
                      {projectInvoice
                        ? "₱" + (projectInvoice * 1).toLocaleString()
                        : "₱0"}
                    </h6>
                    <p className="card-text" style={{ color: "#43434380" }}>
                      INVOICE
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4" style={{ height: "100%" }}>
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">
                      {projectManpower ? projectManpower : "MANPOWER"}
                    </h6>
                    <p className="card-text" style={{ color: "#43434380" }}>
                      MANPOWER
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-md-4" style={{ height: "100%" }}>
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">
                      {projectEstimate
                        ? "₱" + (projectEstimate * 1).toLocaleString()
                        : "₱0"}
                    </h6>
                    <p className="card-text" style={{ color: "#43434380" }}>
                      ESTIMATE
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row m-3">
              <div className="col-md-4" style={{ height: "100%" }}>
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">
                      {projectEstimate ? projectEstimate : "ESTIMATE"}
                    </h6>
                    <p className="card-text" style={{ color: "#43434380" }}>
                      ESTIMATE
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4" style={{ height: "100%" }}>
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">
                      {projectMaterial ? projectMaterial : "MATERIAL"}
                    </h6>
                    <p className="card-text" style={{ color: "#43434380" }}>
                      MATERIAL
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="design"
          role="tabpanel"
          aria-labelledby="design-tab"
        >
          <ProjectTab
            key={object.id}
            tabCollection="project_designs"
            project={object}
            projectId={pId}
            clientId={cId}
          />
        </div>
        <div
          className="tab-pane fade"
          id="employees"
          role="tabpanel"
          aria-labelledby="employees-tab"
        >
          <ProjectTab
            key={object.id}
            tabCollection="users"
            project={object}
            projectName={object.name}
            projectId={pId}
            clientId={cId}
          />
        </div>
        <div
          className="tab-pane fade"
          id="expenses"
          role="tabpanel"
          aria-labelledby="expenses-tab"
        >
          <ProjectTab
            key={object.id}
            tabCollection="expense"
            project={object}
            projectId={pId}
            clientId={cId}
          />
        </div>
        <div
          className="tab-pane fade"
          id="estimates"
          role="tabpanel"
          aria-labelledby="estimates-tab"
        >
          <ProjectTab
            key={object.id}
            tabCollection="services_final"
            project={object}
            projectName={object.name}
            projectId={pId}
            clientId={cId}
          />
        </div>
        <div
          className="tab-pane fade"
          id="invoices"
          role="tabpanel"
          aria-labelledby="invoices-tab"
        >
          <ProjectTab
            key={object.id}
            tabCollection="invoices_final"
            project={object}
            projectName={pName}
            projectId={pId}
            clientId={cId}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectNav;
