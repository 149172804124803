export default function OvertimeDisapproved() {
  return (
    <div className="col">
      <div className="row">
        <p
          className="fw-semibold"
          style={{ fontSize: "1.3rem", color: "#BB1C1C" }}
        >
          Disapprove Overyime Request
        </p>
        <p className="" style={{ fontSize: "1rem" }}>
          Reason of disapproval:
        </p>
        <div className="col">
          <input className="form-control" placeholder="Enter Reason" />
        </div>
      </div>
    </div>
  );
}
