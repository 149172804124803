import BaseListPresenter from "../../base/BaseListPresenter";

class BudgetRequestPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filter2 = {};
    this.filter3 = {};
    this.filter4 = {};
    this.filter5 = {};
    this.filterDate = {};
    this.filterClient = {};
    this.filterEmployee = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filter2,
        ...this.filterDate,
        ...this.filterClient,
        ...this.filter3,
        ...this.filter4,
        ...this.filter5,
        ...this.filterList,
      },

      include: this.include,
    };

    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  filterSubmit2(where) {
    this.reset();
    console.log("st", where);
    this.filter2 = where;
    this.getObjects();
  }
  filterSubmitClient(where) {
    this.reset();
    console.log("st", where);
    this.filterClient = where;
    this.getObjects();
  }
  filterSubmit4(where) {
    this.reset();
    this.filter4 = where;
    this.getObjects();
  }

  filterSubmit5(where) {
    this.reset();
    this.filter5 = where;
    this.getObjects();
  }

  filterSubmit3(where) {
    this.reset();

    this.filter3 = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeEmployee(where) {
    this.filterEmployee = where;
    this.getObjects();
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/form/" + collection);
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    // this.view.navigateTo("/collection/" + collection + "/form/" + object.id);
    this.view.navigateTo("/form/" + collection + "/" + object.id);
  }

  async onClickStatus(index, status, obj, dp) {
    const collection = "budget_request";
    console.log("This", this.objects[index]);
    console.log("obj", obj);
    const object = this.objects[index];
    const gmdFundsObject = await this.findObjectUseCase.execute("gmd_accounts");
    const combineBal = gmdFundsObject.reduce(
      (total, account) => total + account.balance,
      0
    );
    const gmdFundObject = gmdFundsObject.find(
      (fund) => fund.name === "GMD Revolving Fund"
    );

    if (status === "Paid") {
      if (!gmdFundObject) {
        console.error("GMD Revolving Fund object not found.");
        return;
      }
      const updatedBalance = gmdFundObject.balance - obj?.totalAmount;

      if (updatedBalance < 0) {
        this.view.showError("Insufficient Fund.");
        return;
      }

      gmdFundObject.balance = updatedBalance;
    }

    object.statuses =
      status === "Approved"
        ? "Approved"
        : status === "Paid"
        ? "Paid"
        : status === "Disapproved"
        ? "Disapproved"
        : status === "Cancelled"
        ? "Cancelled"
        : "Liquidation";

    object.disApproveMessage = dp;

    const originalObject = {
      note: "budget_request",
      amount: obj?.totalAmount,
      types: {
        type: "Money In",
      },

      account: {
        name: "GMD Revolving Fund",
      },

      account_balance: gmdFundObject.balance,
    };

    const obAccount = {
      id: "79f9b3c7-84b1-4a95-9530-efd31953ef72",
      name: "GMD Revolving Fund",
    };
    const obTypes = {
      id: "f3a5c4d2-cbd0-4305-9835-5c8c75de59c4",
      type: "Money Out",
    };

    const desiredObject = {
      note: originalObject.note,
      account: obAccount,
      account_balance: combineBal - obj?.totalAmount,
      types: obTypes,
      amounts: originalObject.amount?.toString(),
      project_name: object.project_name,
    };

    try {
      if (status === "Paid") {
        const newGmdFunds = await this.upsertUseCase.execute(
          "gmd_accounts",
          gmdFundObject
        );
        const trans = await this.upsertUseCase.execute(
          "transaction",
          desiredObject
        );
      }

      const data = await this.upsertUseCase.execute(collection, object);
      console.log("update", data);
      if (data.statuses === object.statuses) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }

  onClickLiquidate(index) {
    const object = this.objects[index];
    this.view.navigateTo("/liquidate/form/" + object.id);
  }

  onClickViewLiquidation(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    // this.view.navigateTo("/collection/" + collection + "/form/" + object.id);
    this.view.navigateTo("/liquidate/form/" + object.id);
  }
}

export default BudgetRequestPresenter;
