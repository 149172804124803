import BaseFormPresenter from "../../base/BaseFormPresenter";
import { dialog } from "nq-component";
import classNames from "../../classNames";

class PayablesFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async save() {
    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }
    const project_name = this.view.state.selectedProject;
    this.change.project_name = project_name || this.object.project_name;
    this.change.amount = this.view.state.totalItems;
    console.log("this.change", this.change);
    try {
      await this.upsertUseCase.execute(collection, this.change);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    try {
      this.view.submitting();
      await this.save();
      // Continue with your success handling
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
      await this.view.navigateTo("/payables");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default PayablesFormPresenter;
