import { InfiniteScroll } from "nq-component";
import BaseListPage from "../../../base/BaseListPage";
import NavBar from "../../../components/navbar";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
} from "../../../usecases/object";
import withRouter from "../../../withRouter";
import Filters from "../components/Filters";
import SearchFilter from "../components/SearchFilter";
import OvertimeListPresenter from "./OvertimeListPresenter";
import toast, { Toaster } from "react-hot-toast";
import CliTable from "../../../components/CliTable";
import FloatingButton from "../../user/components/FloatingButton";
import CollapseView from "../components/CollapseView";
import createPromise from "../../../createPromise";
import dialog from "../../../components/Modal/dialog";
import OvertimeApproved from "../components/modals/OvertimeApproved";
import OvertimeDisapproved from "../components/modals/OvertimeDisapproved";
import DeleteButton from "../components/DeleteButton";
import Tabs from "../components/Tabs";
import DateRange from "../../../components/DateRange";

class OvertimeListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new OvertimeListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase()
    );
    this.fieldsToDisplay = [
      "employeeName",
      "projectName",
      "totalOvertime",
      "nightDifferential",
      "status",
      "date",
    ];
    this.state = {
      objects: [],
      selected: [],
      selectedTab: "myTimeSheet",
      progress: true,
      total: 0,
      count: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return "overtime";
  }

  onApproveClick(object) {
    this.presenter.onApproveClick(object);
  }

  onDisapproveClick(object) {
    this.presenter.onDisapproveClick(object);
  }

  setSelectedTab(selectedTab) {
    this.setState({ selectedTab });
  }

  onClickView(index) {
    this.presenter.onClickView(index);
  }

  onCollapse(index, object) {
    const schema = this.getSchema(this.getCollectionName());
    const roles = this.getCurrentRoles().map((item) => item.name);

    return (
      <CollapseView
        object={object}
        fields={schema.fields}
        includedFields={this.fieldsToDisplay}
        onClickItem={this.onClickItem.bind(this)}
        onClickView={this.onClickView.bind(this)}
        onApprove={this.onApproveClick.bind(this)}
        onDisapprove={this.onDisapproveClick.bind(this)}
        index={index}
        roles={roles}
        selectedTab={this.state.selectedTab}
      />
    );
  }

  showToast(message) {
    toast(message, {
      position: "bottom-right",
      style: {
        background: "#4FBC4C",
        color: "white",
        fontWeight: "600",
      },
    });
  }

  showOvertimeApprovedDialog() {
    const promise = createPromise();
    dialog.fire({
      html: <OvertimeApproved />,
      positiveButton: "Confirm",
      negativeButton: "",
      onPositiveClick: () => {
        promise.resolve();
      },
    });
    return promise;
  }

  showOvertimeDisapprovedDialog() {
    const promise = createPromise();
    dialog.fire({
      html: <OvertimeDisapproved />,
      positiveButton: "Disapprove",
      negativeButton: "Cancel",
      positiveButtonBg: "#BB1C1C",
      positiveButtonColor: "white",
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
    });
    return promise;
  }

  onDateRangeChange(where) {
    this.filterSubmit(where);
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "Date":
        where[field] = {
          $eq: value,
        };
        break;
      default:
        where[field] =
          field === "employeeName"
            ? { $regex: value, $options: "i" }
            : field === "status" && value
            ? { $eq: value }
            : { $regex: value, $options: "i" };
    }
    this.filterSubmit(where);
  }

  modifyObjectBasedOnRole(objects) {
    console.log("object pinasa", objects);
    const user = this.getCurrentUser();
    const roles = this.getCurrentRoles().map((item) => item.name);
    console.log("roles pinasa", roles);

    if (roles.includes("masterAdmin")) return objects;

    if (this.state.selectedTab === "myTimeSheet") {
      return objects.filter((object) => object?.employee?.id === user.id);
    } else {
      return objects.filter((object) =>
        roles.includes(object?.approver?.roles[0]?.name)
      );
    }
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { objects, progress, count, selected } = this.state;
    const roles = this.getCurrentRoles().map((item) => item.name);

    return (
      <div className="h-100" style={{ background: "white" }}>
        <NavBar />
        <Toaster />
        <div
          className="container-fluid"
          style={{
            paddingInline: "3rem",
            paddingBlock: "2rem",
            background: "white",
          }}
        >
          <p className="fs-3 fw-bold">Time Sheet</p>
          <div className="row align-items-center justify-content-between gap-2">
            <SearchFilter
              filter={schema.filters.employeeName}
              onChangeFilter={this.onChangeFilter.bind(this)}
              field="employeeName"
            />

            <Filters
              schema={schema}
              keys={["date", "status"]}
              onChangeDate={this.onDateRangeChange.bind(this)}
              onChangeFilter={this.onChangeFilter.bind(this)}
            />

            <div className="d-flex w-100 flex-row justify-content-between align-items-center">
              <Tabs
                roles={roles}
                setSelectedTab={this.setSelectedTab.bind(this)}
              />
              <DeleteButton
                selected={selected}
                onDelete={this.onClickDeleteSelected.bind(this)}
              />
            </div>
          </div>
          <div className="mt-3">
            <InfiniteScroll
              loadMore={this.loadMore.bind(this)}
              hasMore={!progress && count > objects.length}
            >
              <CliTable
                fields={schema.fields}
                objects={this.modifyObjectBasedOnRole(objects)}
                collapsable
                selectable
                tableHeadBackground="#DAB44E"
                shadow={false}
                striped={false}
                selected={selected}
                progress={progress}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                excludeFields={Object.keys(schema.fields).filter(
                  (key) => !this.fieldsToDisplay.includes(key)
                )}
              />
            </InfiniteScroll>
          </div>
          <FloatingButton onClick={this.onClickAdd.bind(this)} />
        </div>
      </div>
    );
  }
}

export default withRouter(OvertimeListPage);
