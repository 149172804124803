import React, { useEffect } from "react";

const Drop = ({
  label,
  placeholder,
  value,
  options,
  onChange,
  defaultValue,
}) => {
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleSelectChange = (event) => {
    setSelectedValue(event);
    onChange(event);
  };
  console.log(options);
  return (
    <div>
      <select
        style={{
          appearance: "none",
          WebkitAppearance: "none",
          MozAppearance: "none",
          outline: "none",
          border: "1px solid #ddd",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "15px",
          backgroundColor: "#fff",
          backgroundImage:
            "linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%)",
          backgroundPosition:
            "calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px)",
          backgroundSize: "5px 5px, 5px 5px",
          backgroundRepeat: "no-repeat",
          width: "100%",
          boxSizing: "border-box",
        }}
        value={selectedValue}
        onChange={(e) => handleSelectChange(e.target.value)}
        defaultValue={defaultValue ? defaultValue : ""}
        className="form-control"
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options?.map((option) => (
          <option key={option.id} value={option?.id}>
            {option?.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Drop;
