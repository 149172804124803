import toast, { Toaster } from "react-hot-toast";
import BaseListPage from "../../../base/BaseListPage";
import NavBar from "../../../components/navbar";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import SearchFilter from "../components/SearchFilter";
import DropdownFilters from "../../user/components/DropdownFilters";
import DeleteButton from "../components/DeleteButton";
import { InfiniteScroll } from "nq-component";
import CliTable from "../../../components/CliTable";
import FloatingButton from "../components/FloatingButton";
import withRouter from "../../../withRouter";
import PayrollListPresenter from "./PayrollListPresenter";
import createPromise from "../../../createPromise";
import dialog from "../../../components/Modal/dialog";
import DeletePayroll from "../components/modals/DeletePayroll";
import CollapseView from "../components/CollapseView";

class PayrollListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new PayrollListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      holidays: [],
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return "payroll";
  }

  showDeletePayrollDialog(selected) {
    const promise = createPromise();
    dialog.fire({
      html: <DeletePayroll selected={selected} />,
      positiveButton: "Delete",
      negativeButton: "Cancel",
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
    });
    return promise;
  }

  showToast(message) {
    toast(message, {
      position: "bottom-right",
      style: {
        background: "#4FBC4C",
        color: "white",
        fontWeight: "600",
      },
    });
  }

  onCollapse(index, object) {
    const schema = this.getSchema(this.getCollectionName());

    return (
      <CollapseView
        object={object}
        fields={schema.fields}
        includedFields={this.presenter.fieldsToDisplay}
        onClickItem={this.onClickItem.bind(this)}
        index={index}
      />
    );
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "Date":
        where[field] = {
          $eq: value,
        };
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    console.log(where);
    this.filterSubmit(where);
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, progress, count } = this.state;

    return (
      <div className="h-100" style={{ background: "white" }}>
        <NavBar />
        <Toaster />
        <div
          className="container-fluid"
          style={{
            paddingInline: "3rem",
            paddingBlock: "2rem",
            background: "white",
          }}
        >
          <p className="fs-3 fw-bold">
            {schema.collection.charAt(0).toUpperCase() +
              schema.collection.slice(1)}
          </p>
          <SearchFilter
            filter={schema.filters.name}
            onChangeFilter={this.onChangeFilter.bind(this)}
          />
          <p className="mt-3 mb-3">Filter:</p>
          <div className="row gx-2">
            <DropdownFilters
              schema={schema}
              keys={["dateCreated"]}
              onChangeFilter={this.onChangeFilter.bind(this)}
            />
            <DeleteButton
              selected={selected}
              onDelete={this.onClickDeleteSelected.bind(this)}
            />
          </div>
          <div className="mt-3">
            <InfiniteScroll
              loadMore={this.loadMore.bind(this)}
              hasMore={!progress && count > objects?.length}
            >
              <CliTable
                fields={schema.fields}
                objects={objects}
                collapsable
                selectable
                tableHeadBackground="#DAB44E"
                shadow={false}
                striped={false}
                selected={selected}
                progress={progress}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                excludeFields={Object.keys(schema.fields).filter(
                  (key) => !this.presenter.fieldsToDisplay.includes(key)
                )}
              />
            </InfiniteScroll>
          </div>
          <FloatingButton onClick={this.onClickAdd.bind(this)} />
        </div>
      </div>
    );
  }
}

export default withRouter(PayrollListPage);
