import React from "react";

export default function EditPayrollActionButtons({ onClickBack }) {
  return (
    <div className="row mt-5 m-0 gap-3 justify-content-center ">
      <div className="col-auto p-0">
        <button
          onClick={onClickBack}
          type="button"
          className="btn btn-light fw-semibold"
          style={{ width: "8rem", height: "2.75rem" }}
        >
          CANCEL
        </button>
      </div>
      <div className="col-auto p-0">
        <button
          type="submit"
          className="btn fw-semibold"
          style={{ background: "#D8AE3F", width: "8rem", height: "2.75rem" }}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
}
