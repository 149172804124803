import React from "react";
// import logo from "../../../assets/gmd-logo.png";
// import dateFormat from "../../../dateFormat";

export default function InvoicesPrint({ object, services }) {
  console.log("obbb", object);
  // object?.forEach((invoice) => {
  //   services.forEach((service) => {
  //     if (service.invoice_num === invoice.invoice_num) {
  //       const existingService = invoice.services.find(
  //         (existingService) => existingService.id === service.id
  //       );
  //       if (!existingService) {
  //         invoice.services.push(service);
  //       }
  //     }
  //   });
  // });

  // const paper = {
  //   padding: "20mm",
  //   fontFamily: "Montserrat, sans-serif",
  // };

  // const logoStyle = {
  //   width: "30mm",
  // };

  // const header = {
  //   display: "flex",
  //   justifyContent: "space-between",
  // };

  // const styles = {
  //   table: {
  //     borderCollapse: "collapse",
  //     width: "100%",
  //     marginBottom: "10px",
  //     tableLayout: "fixed",
  //   },
  //   tableDesc: {
  //     borderCollapse: "collapse",
  //     marginBottom: "10px",
  //     width: "100%",
  //   },
  //   header: {
  //     backgroundColor: "#FFE8CC",
  //     color: "#FFAE4D",
  //     padding: "10px 0",
  //     fontSize: "0.6rem",
  //     width: "15%",
  //   },
  //   activityHeader: {
  //     textAlign: "left",
  //     padding: "10px",
  //     backgroundColor: "#FFE8CC",
  //     color: "#FFAE4D",
  //     fontSize: "0.6rem",
  //     width: "50%",
  //   },
  //   dataCellFirst: {
  //     padding: "10px",
  //     verticalAlign: "top",
  //   },
  //   dataCell: {
  //     padding: "10px",
  //     verticalAlign: "top",
  //     textAlign: "center",
  //   },
  //   headerTotal: {
  //     backgroundColor: "#2e2e2e",
  //     color: "white",
  //     padding: "10px",
  //   },
  //   dataCellTotal: {
  //     backgroundColor: "#2e2e2e",
  //     color: "white",
  //     padding: "10px",
  //     verticalAlign: "top",
  //   },
  //   itemTable: {
  //     borderCollapse: "collapse",
  //     width: "100%",
  //     marginBottom: "10px",
  //   },
  //   summaryTable: {
  //     borderCollapse: "collapse",
  //     width: "100%",
  //   },
  //   headerCell: {
  //     backgroundColor: "#FFA500",
  //     color: "white",
  //     padding: "10px",
  //     border: "1px solid #000",
  //   },
  //   itemCell: {
  //     padding: "10px",
  //     border: "1px solid #000",
  //     borderTop: "none",
  //   },
  //   summaryHeaderCell: {
  //     backgroundColor: "#000",
  //     color: "white",
  //     padding: "10px",
  //     border: "1px solid #000",
  //   },
  //   summaryCell: {
  //     padding: "10px",
  //     border: "1px solid #000",
  //     backgroundColor: "#f2f2f2",
  //   },
  // };

  // const line = {
  //   border: "none",
  // };

  // const containerStyles = {
  //   container: {
  //     display: "flex",
  //     justifyContent: "space-between",
  //     margin: 0,
  //     padding: 0,
  //   },
  //   oneContainer: {
  //     flexBasis: "50%",
  //     fontSize: "16px",
  //     textAlign: "left",
  //   },
  //   subContainer: {
  //     flexBasis: "50%",
  //     fontSize: "16px",
  //     textAlign: "center",
  //   },
  //   subTable: {
  //     width: "100%",
  //     borderCollapse: "collapse",
  //     marginTop: "10px",
  //   },
  //   subTableCell: {
  //     padding: "5px 10px",
  //     border: "1px solid #000",
  //   },
  //   subTableHeader: {
  //     backgroundColor: "#FFA500",
  //     color: "white",
  //     padding: "10px",
  //     border: "1px solid #000",
  //   },
  //   phContainer: {
  //     flexBasis: "25%",
  //     fontSize: "25px",
  //     fontWeight: "bold",
  //     textAlign: "end",
  //   },
  // };

  // // Calculating subtotal, tax, and total
  // const subtotal = object.items
  //   ?.map((items) => Number(items.amounts?.replace(/,/g, "")) * items.quantity)
  //   .reduce((acc, prev) => acc + prev, 0);

  // const taxRate = 0.0;
  // const tax = subtotal * taxRate;
  // const shippingCost = 0;
  // const total = subtotal + tax + shippingCost;
  // const vendor = object[0]?.vendor_name;

  // return (
  //   <div style={paper} className="">
  //     <div style={header}>
  //       <div>
  //         <p style={{ margin: "0" }}>
  //           <b>GMD PRODUCTIONS INC</b>
  //         </p>
  //         <p style={{ margin: "0" }}>32 Santan Unit H Brgy Fortune Marikina</p>
  //         <p style={{ margin: "0" }}>Marikina, NCR 1802 PH</p>
  //         <p style={{ margin: "0" }}>+63 9688870928</p>
  //         <p style={{ margin: "0" }}>pjg@gmd.ph</p>
  //         <p style={{ margin: "0" }}>https://www.gmd.ph</p>
  //       </div>
  //       <div style={{ textAlign: "end" }}>
  //         <p>
  //           <img src={logo} alt="GMD Logo" style={logoStyle} />
  //         </p>
  //       </div>
  //     </div>
  //     <div style={{ color: "#FFAE4D", fontSize: "26px", marginTop: "25px" }}>
  //       INVOICE
  //     </div>
  //     <div style={{ display: "flex", justifyContent: "space-between" }}>
  //       <div style={{ flexGrow: 1 }}>
  //         <p
  //           style={{ fontSize: "14px", marginTop: "13px", marginBottom: "3px" }}
  //         >
  //           <b>BILL TO</b>
  //         </p>
  //         <p
  //           style={{
  //             fontFamily: "Montserrat, sans-serif",
  //             fontSize: "13px",
  //             margin: 0,
  //           }}
  //         >
  //           {object[0]?.client_name.name}
  //         </p>
  //         <p
  //           style={{
  //             fontFamily: "Montserrat, sans-serif",
  //             fontSize: "13px",
  //             margin: 0,
  //           }}
  //         >
  //           {object[0]?.client_name?.name}
  //         </p>
  //       </div>
  //       <div
  //         style={{ display: "flex", marginRight: "10px", marginTop: "10px" }}
  //       >
  //         <div style={{ flex: 1, textAlign: "end", paddingRight: "5px" }}>
  //           <p
  //             style={{
  //               fontFamily: "Montserrat, sans-serif",
  //               fontSize: "13px",
  //               margin: 0,
  //               whiteSpace: "nowrap",
  //             }}
  //           >
  //             <b>INVOICE NO.</b>
  //           </p>
  //           <p
  //             style={{
  //               fontFamily: "Montserrat, sans-serif",
  //               fontSize: "13px",
  //               margin: 0,
  //               whiteSpace: "nowrap",
  //             }}
  //           >
  //             <b>DATE</b>
  //           </p>
  //           <p
  //             style={{
  //               fontFamily: "Montserrat, sans-serif",
  //               fontSize: "13px",
  //               margin: 0,
  //               whiteSpace: "nowrap",
  //             }}
  //           >
  //             <b>DUE DATE</b>
  //           </p>
  //           <p
  //             style={{
  //               fontFamily: "Montserrat, sans-serif",
  //               fontSize: "13px",
  //               margin: 0,
  //               whiteSpace: "nowrap",
  //             }}
  //           >
  //             <b>TERMS</b>
  //           </p>
  //         </div>
  //         <div style={{ flex: 1, textAlign: "start" }}>
  //           <p
  //             style={{
  //               fontFamily: "Montserrat, sans-serif",
  //               fontSize: "13px",
  //               margin: 0,
  //             }}
  //           >
  //             {object[0]?.invoice_num}
  //           </p>
  //           <p
  //             style={{
  //               fontFamily: "Montserrat, sans-serif",
  //               fontSize: "13px",
  //               margin: 0,
  //             }}
  //           >
  //             {object[0]?.due_date}
  //           </p>
  //           <p
  //             style={{
  //               fontFamily: "Montserrat, sans-serif",
  //               fontSize: "13px",
  //               margin: 0,
  //             }}
  //           >
  //             {object[0]?.due_date}
  //           </p>
  //           <p
  //             style={{
  //               fontFamily: "Montserrat, sans-serif",
  //               fontSize: "13px",
  //               margin: 0,
  //               whiteSpace: "nowrap",
  //             }}
  //           >
  //             Due on receipt
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //     <hr style={{ borderColor: "#FFAE4D", marginBottom: "50px" }} />
  //     <div>
  //       <table style={styles.table}>
  //         <tr>
  //           <th style={styles.activityHeader}>ACTIVITY</th>
  //           <th style={styles.header}>QTY</th>
  //           <th style={styles.header}>RATE</th>
  //           <th style={styles.header}>AMOUNT</th>
  //         </tr>
  //         {object &&
  //           object.length > 0 &&
  //           object[0].services &&
  //           object[0].services.length > 0 &&
  //           object[0].services.map((item) => (
  //             <tr style={line} key={item.id}>
  //               <td style={styles.dataCellFirst} colSpan="1">
  //                 {item.name}
  //               </td>
  //               <td style={styles.dataCell} colSpan="1">
  //                 {item.qty}
  //               </td>
  //               <td style={styles.dataCell} colSpan="1">
  //                 ₱ {item.rate}
  //               </td>
  //               <td style={styles.dataCell} colSpan="1">
  //                 ₱ {item.qty * item.rate}
  //               </td>
  //             </tr>
  //           ))}
  //       </table>
  //     </div>
  //     <hr style={{ borderColor: "gray", marginTop: "10px" }} />
  //     <div style={containerStyles.container}>
  //       <div style={containerStyles.oneContainer}>
  //         <p>
  //           Thank you for your business! We hope that we can continue to help
  //           grow your company even further.
  //         </p>
  //         <p style={{ margin: 0 }}>Account Name: GMD PRODUCTIONS INC</p>
  //         <p style={{ margin: 0 }}>BDO CHECKING - 012758000370</p>
  //         <p style={{ margin: 0 }}>BPI CHECKING - 6011 04 82 03</p>
  //       </div>
  //       <div style={containerStyles.subContainer}>
  //         <table style={{ borderCollapse: "collapse", width: "100%" }}>
  //           <tr>
  //             <th style={{ padding: "10px", textAlign: "right" }}>Subtotal</th>
  //             <td style={{ padding: "10px", textAlign: "center" }}>
  //               ₱ {Number(object[0]?.subtotal)}
  //             </td>
  //           </tr>
  //           <tr>
  //             <th style={{ padding: "10px", textAlign: "right" }}>ASF</th>
  //             <td style={{ padding: "10px", textAlign: "center" }}>
  //               ₱ {object[0]?.asf}
  //             </td>
  //           </tr>
  //           <tr>
  //             <th style={{ padding: "10px", textAlign: "right" }}>Discount</th>
  //             <td style={{ padding: "10px", textAlign: "center" }}>
  //               ₱ {object[0]?.discount}
  //             </td>
  //           </tr>
  //           <tr>
  //             <th style={{ padding: "10px", textAlign: "right" }}>VAT</th>
  //             <td style={{ padding: "10px", textAlign: "center" }}>
  //               ₱ {object[0]?.tax}
  //             </td>
  //           </tr>
  //           <tr>
  //             <th
  //               style={{
  //                 padding: "10px",
  //                 textAlign: "right",
  //                 fontSize: "24px",
  //                 fontWeight: "bold",
  //               }}
  //             >
  //               BALANCE DUE
  //             </th>
  //             <td
  //               style={{
  //                 padding: "10px",
  //                 textAlign: "center",
  //                 fontSize: "24px",
  //                 fontWeight: "bold",
  //                 backgroundColor: "#FFE8CC",
  //               }}
  //             >
  //               PHP {Number(object[0]?.amount)}
  //             </td>
  //           </tr>
  //         </table>
  //       </div>
  //     </div>
  //   </div>
  // );
}
