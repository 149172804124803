import { useEffect, useState } from "react";
import InputFactory from "../../../components/InputFactory";
import { findObjectUseCase } from "../../../usecases/object";

const formatSections = (schema) => {
  const formattedSections = {};

  Object.keys(schema.fields).forEach((field) => {
    const section = schema.fields[field].section || "General";
    if (!formattedSections[section]) {
      formattedSections[section] = {
        sectionLabel: schema.sections[section]?.label || section,
        fields: {},
      };
    }
    formattedSections[section].fields[field] = schema.fields[field];
  });

  const formattedSectionsArray = Object.values(formattedSections);

  return formattedSectionsArray;
};

export default function CreateUserForm({
  schema,
  excludedFields,
  onClickBack,
  onChange,
  object,
  isEditing,
}) {
  const sections = formatSections(schema);
  const [masterAdminID, setMasterAdminId] = useState("");

  useEffect(() => {
    findObjectUseCase()
      .execute("roles", { where: { name: "masterAdmin" } })
      .then((obj) => {
        setMasterAdminId(obj[0].id);
      });
  }, []);

  if (!masterAdminID) return null;

  const modifiedObject = {
    ...object,
    roles:
      isEditing && Object.keys(object).length > 1
        ? object.roles[0]
        : object.roles,
  };

  return (
    <>
      <div className="row">
        {sections.map((section) => {
          return (
            <div className="row gx-5 gy-4 mt-0" key={section}>
              <div>
                {section.sectionLabel === "General" ? null : (
                  <>
                    <p className="fw-bold fs-5">{section.sectionLabel}</p>
                    <div className="border" />
                  </>
                )}
              </div>
              {Object.keys(section.fields).map((field) => {
                const { type, ...options } = section.fields[field];

                if (excludedFields.includes(field)) return null;

                return (
                  <div className={options.col} key={field}>
                    <label
                      class="form-label fw-semibold"
                      style={{ fontSize: "0.875rem" }}
                    >
                      {options.label}
                      {options.required || options._required ? (
                        <span className="text-danger ms-1">*</span>
                      ) : null}
                    </label>
                    <InputFactory
                      field={field}
                      key={field}
                      object={modifiedObject}
                      type={field === "roles" ? "Pointer" : type}
                      where={
                        field === "departmentHead"
                          ? { roles: { id: masterAdminID } }
                          : {}
                      }
                      onChange={onChange}
                      {...options}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="row gap-3 mt-5 justify-content-center ">
        <button
          onClick={onClickBack}
          type="button"
          className="btn btn-light fw-semibold col-1"
          style={{ width: "8rem" }}
        >
          CANCEL
        </button>
        <button
          type="submit"
          className="btn fw-semibold col-1"
          style={{ background: "#D8AE3F", width: "8rem" }}
        >
          SUBMIT
        </button>
      </div>
    </>
  );
}
