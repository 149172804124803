function OutputDate({ field, object }) {
  const value = object[field];
  console.log(object, "asd");

  if (value) {
    const dueDate = new Date(value);
    const currentDate = new Date();

    dueDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const diffTime = dueDate - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (object.statuses === "Approved" || object.statuses === "Sent") {
      return (
        <span style={{ color: "black" }}>
          {dueDate.toLocaleDateString("en-US", { dateStyle: "medium" })}
        </span>
      );
    } else {
      if (diffDays < 0) {
        if (diffDays === -1) {
          return <span style={{ color: "#B20001" }}>Overdue (1 Day)</span>;
        } else if (diffDays <= -7) {
          const options = { year: "numeric", month: "short", day: "numeric" };
          return (
            <span style={{ color: "#B20001" }}>
              Overdue on {dueDate.toLocaleDateString("en-US", options)}
            </span>
          );
        } else {
          return (
            <span style={{ color: "#B20001" }}>
              Overdue {Math.abs(diffDays)} Days Ago
            </span>
          );
        }
      } else if (diffDays === 0) {
        return <span style={{ color: "#CF6F00" }}>Due Today</span>;
      } else if (diffDays === 1) {
        return <span style={{ color: "#CF6F00" }}>Due Tomorrow</span>;
      } else if (diffDays <= 7) {
        return <span style={{ color: "#CF6F00" }}>Due in {diffDays} Days</span>;
      } else if (diffDays <= 30) {
        const diffWeeks = Math.ceil(diffDays / 7);
        return (
          <span style={{ color: "#CF6F00" }}>
            Due in {diffWeeks} Week{diffWeeks !== 1 ? "s" : ""}
          </span>
        );
      } else {
        const diffMonths = Math.floor(diffDays / 30);
        return (
          <span style={{ color: "#CF6F00" }}>
            Due in {diffMonths} Month{diffMonths !== 1 ? "s" : ""}
          </span>
        );
      }
    }
  }

  return null;
}

export default OutputDate;
