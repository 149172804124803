import React from "react";

// Default props
const defaultProps = {
  keys: [],
};

// Function to get color based on value
function getColorBasedOnValue(value) {
  switch (value) {
    case "Disapproved":
      return "#B20001";
    case "Declined":
      return "#B20001";
    case "Done":
      return "#3FC65C";
    case "Pending":
      return "#D8AE3F";
    case "For Approval":
      return "#D8AE3F";
    case "Pending (Warm Lead)":
      return "#FF0000 ";
    case "Pending (Cold Lead)":
      return "#0000FF";
    case "Liquidation":
      return "#9747FF";
    case "Paid":
      return "#CF6F00";
    case "Approved":
      return "#3FC65C";
    case "Approved Estimate":
      return "#3FC65C";
    case "Partially Paid":
      return "#CF6F00";
    default:
      return "inherit";
  }
}

function getColorBasedOnType(object) {
  const { types, type } = object;
  const typeValue = types?.type || type;
  return typeValue === "Money In"
    ? "#3FC65C"
    : typeValue === "Money Out"
    ? "#B20001"
    : "inherit";
}

// Function to format currency
const formatCurrency = (amount) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "PHP" }).format(
    amount
  );

// Function to format currency string
const formatCurrencyString = (amounts) =>
  formatCurrency(Number(amounts.replace(/,/g, "")));

function OutputString({ field, object, keys, role }) {
  const value = object[field];
  let color;

  // Check for specific conditions
  if (typeof value === "string" && value.includes("Overdue")) {
    color = "#B20001";
  } else {
    color =
      getColorBasedOnValue(value) !== "inherit"
        ? getColorBasedOnValue(value)
        : getColorBasedOnType(object);
  }

  // Format the value based on the field
  let renderedValue;
  switch (field) {
    case "totalAmount":
    case "amount":
    case "pending_balance":
    case "total":
    case "subtotal":
    case "deductions":
      renderedValue = formatCurrency(value);
      break;
    case "amounts":
      renderedValue = formatCurrencyString(value);
      break;
    default:
      renderedValue = value;
      break;
  }

  // Hide sensitive information for non-master admins
  if (!role?.includes("masterAdmin") && field === "account_balance") {
    renderedValue = "****";
  }

  // Render the value
  if (value || value === 0) {
    if (
      (field && field === "pending_balance") ||
      field === "totalAmount" ||
      field === "amount" ||
      field == "tax" ||
      field == "collectibles" ||
      field === "received"
    ) {
      return <span style={{ color }}>{renderedValue}</span>;
    }
  }
  return <span style={{ color }}>{renderedValue}</span>;
}

OutputString.defaultProps = defaultProps;

export default OutputString;
