import { dialog } from "nq-component";
import classNames from "../../../../classNames";
import BaseFormPresenter from "../../../../base/BaseFormPresenter";
import { findObjectUseCase } from "../../../../usecases/object";
const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};
class ProjectTabFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async submit() {
    const currentUser = this.view.getCurrentUser();
    const queryPID = {
      where: {
        id: this.view.props.params.pid,
      },
    };
    const queryCID = {
      where: {
        id: this.view.props.params.cid,
      },
    };

    const pid = await findObjectUseCase().execute("projects", queryPID);
    const cid = await findObjectUseCase().execute("clients", queryCID);
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }

    if (!this.change.employee) {
      this.change.employee = currentUser;
    }

    if (!this.change.project_name || !this.object.project_name) {
      this.change.project_name = pid[0];
    }
    if (!this.change.client_name || !this.object.client_name) {
      this.change.client_name = cid[0];
    }

    if (!this.change.statuses) {
      this.change.statuses = "For Approval";
    }
    if (this.change.statuses === "Disapproved") {
      this.change.statuses = "For Approval";
    }

    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Data has been successfully saved.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo("/view/project/" + this.view.props.params.pid);
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default ProjectTabFormPresenter;
