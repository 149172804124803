import React from "react";
import InputFactory from "../../../components/InputFactory";

export default function DropdownFilters({ schema, keys, onChangeFilter }) {
  return Object.keys(schema.filters)
    .filter((key) => keys.includes(key))
    .map((key) => {
      const { type, ...options } = schema.filters[key];
      return (
        <div className={`${key === "status" ? "col-2" : "col-3"}`} key={key}>
          <InputFactory
            type={type}
            field={key}
            onChange={(value, field) => onChangeFilter(type, value, field)}
            className="text-light-emphasis"
            {...options}
          />
        </div>
      );
    });
}
