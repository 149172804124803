export default function AddPayroll() {
  return (
    <div className="col">
      <div className="row">
        <p
          className="fw-semibold"
          style={{ fontSize: "1.3rem", color: "#DAB44E" }}
        >
          Add Payroll
        </p>
        <p className="" style={{ fontSize: "1rem" }}>
          Are you sure you want to add this payroll?
        </p>
      </div>
    </div>
  );
}
