import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import dateFormat from "../../../../dateFormat";
import signature from "../../../../assets/signature/signature-upscale-no-bg.png";

export default function ProjectCOC({ object, images }) {
  const paperSize = {
    // A4 Sizing
    width: "210mm",
    height: "297mm",
  };

  const paper = {
    padding: "10mm",
    fontFamily: "Montserrat, sans-serif",
    width: paperSize.width,
    height: paperSize.height,
    overflow: "hidden",
    position: "relative",
    pageBreakInside: "avoid",
    pageBreakAfter: "always",
    fontSize: "16px",
  };

  const logoStyle = {
    width: "30mm",
  };

  const header = {
    display: "flex",
    justifyContent: "space-between",
  };

  const styles = {
    table: {
      borderCollapse: "collapse",
      marginBottom: "10px",
      width: "100%",
      border: "1px solid #ebe3cf",
    },
    tableDesc: {
      borderCollapse: "collapse",
      marginBottom: "10px",
      width: "100%",
    },
    header: {
      backgroundColor: "#f0cead",
      color: "white",
      padding: "10px",
    },
    dataCell: {
      padding: "5px",
      verticalAlign: "top",
    },
    headerTotal: {
      backgroundColor: "#2e2e2e",
      color: "white",
      padding: "10px",
    },
    dataCellTotal: {
      backgroundColor: "#2e2e2e",
      color: "white",
      padding: "10px",
      verticalAlign: "top",
      textAlign: "right",
    },
    itemTable: {
      borderCollapse: "collapse",
      width: "100%",
      marginBottom: "10px",
    },
    summaryTable: {
      borderCollapse: "collapse",
      width: "100%",
    },
    headerCell: {
      backgroundColor: "#FFA500",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    itemCell: {
      padding: "10px",
      border: "1px solid #000",
      borderTop: "none",
    },
    summaryHeaderCell: {
      backgroundColor: "#000",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    summaryCell: {
      padding: "10px",
      border: "1px solid #000",
      backgroundColor: "#f2f2f2",
    },
  };

  const line = {
    border: "1px solid #FFA500",
  };

  const renderImagesChunk = (imagesChunk, pageIndex) => (
    <div
      style={{ ...paper, display: "flex", flexDirection: "column" }}
      key={pageIndex}
    >
      <Header />
      <div style={{ display: "flex", flex: 1 }}>
        <div style={{ position: "relative", flex: 1 }}>
          {imagesChunk.map((image, index) => (
            <div
              key={index}
              style={{
                position: "absolute",
                top: index < 2 ? 0 : "45%",
                left: index % 2 === 0 ? 0 : "50%",
                width: "50%",
                height: "45%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                padding: "10px",
                border: "2px solid black",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  width: "90%",
                  height: "90%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {image.file && (
                  <img
                    src={URL.createObjectURL(image.file)}
                    alt={`Image ${index + 1}`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
                <span style={{ marginTop: "5px" }}>{image.name}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );

  const imagesChunks = [];
  for (let i = 0; i < images.length; i += 4) {
    imagesChunks.push(images.slice(i, i + 4));
  }

  return (
    <>
      {/* First page */}
      <div style={paper}>
        <Header />

        <div style={{ marginTop: "20px" }}>
          <p style={{ margin: "0" }}>
            <strong>TO: {object.client?.name}</strong>
            <br />
            {object.client?.company_name}
            <br />
            {object.client?.address}
          </p>
        </div>

        <div style={{ marginTop: "30px" }}>
          <h2 style={{ textAlign: "center", fontSize: "24px" }}>
            CERTIFICATE OF COMPLETION
          </h2>
          <br />
          <br />

          <p>
            This is to certify that I, <strong>Paulo Miguel J. Garcia</strong>,
            President of GMD Productions Inc., have successfully completed the
            project <strong>{object.name}</strong> on{" "}
            <strong>{dateFormat(Math.floor(Date.now()))}</strong>.
          </p>
          <br />
          <p>
            This serves as your warranty card for the next 3 months from
            accepting this certificate.
          </p>
          <br />

          {images.length > 0 && <p>Attached are photos of the installation:</p>}
        </div>

        <div style={{ marginTop: "40px" }}>
          <p style={{ textAlign: "left" }}>
            Signed by:
            <br />
            <img src={signature} alt="" style={logoStyle} />
            <br />
            <strong>Paulo Miguel J. Garcia</strong>
            <br />
            President
            <br />
            GMD PRODUCTIONS
          </p>
        </div>

        <Footer />
      </div>

      {/* Second and subsequent pages */}
      {imagesChunks.map((chunk, index) => renderImagesChunk(chunk, index + 2))}
    </>
  );
}
