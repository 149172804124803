import React from "react";

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(amount);
};

export default function PayslipTotal({ total, monthlyRate }) {
  return (
    <div
      style={{
        padding: ".1rem 1rem",
        background: "#333333",
        border: "1px solid #e9983d",
        borderRadius: ".2rem",
        color: "white",
      }}
    >
      <p style={{ textAlign: "center", fontWeight: "bold" }}>
        TOTAL: {formatCurrency(total || monthlyRate / 2 || 0)}
      </p>
    </div>
  );
}
