import React from "react";

function formatDate(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateString);

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}

function abbreviateTerm(term) {
  const words = term.split(" ");

  let abbreviation = "";

  words.forEach((word) => {
    abbreviation += word.charAt(0).toUpperCase();
  });

  return abbreviation;
}

const getColor = (leaveType) => {
  if (!leaveType) return null;
  if (leaveType === "Sick Leave") {
    return "#2F80ED";
  }
  if (leaveType === "Emergency Leave") {
    return "#F5F079";
  }
  if (leaveType === "Vacation Leave") {
    return "#4FBC4C";
  }
};

export default function LeavesFiled({ leaveRequestInfo }) {
  const header = [
    "Start Date and Time",
    "Leave Date and Time",
    "Type",
    "Credit",
    "Status",
  ];

  return (
    <div>
      <p
        className="fw-semibold"
        style={{ fontSize: "1.25rem", color: "#434343" }}
      >
        Leaves Filed
      </p>
      <table
        class="table tablexa"
        style={{ boxShadow: "1px 1px 5px rgba(0, 0, 0, .06)" }}
      >
        <thead>
          <tr style={{ background: "#DAB44E", borderTopRadius: "1rem" }}>
            {header.map((item) => {
              return (
                <th
                  key={item}
                  style={{
                    background: "transparent",
                    fontSize: ".75rem",
                    height: "2.875rem",
                  }}
                  scope="col"
                >
                  <div
                    className="d-flex align-items-center   m-0"
                    style={{ height: "100%" }}
                  >
                    {item}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {leaveRequestInfo?.map((data, index) => {
            return (
              <tr key={index}>
                <td style={{ fontSize: ".75rem", height: "2.875rem" }}>
                  <div
                    className="d-flex align-items-center m-0"
                    style={{ height: "100%" }}
                  >
                    {formatDate(data.startDate)}
                  </div>
                </td>
                <td style={{ fontSize: ".75rem", height: "2.875rem" }}>
                  <div
                    className="d-flex align-items-center m-0"
                    style={{ height: "100%" }}
                  >
                    {formatDate(data.endDate)}
                  </div>
                </td>
                <td style={{ fontSize: ".75rem", height: "2.875rem" }}>
                  <div
                    className="d-flex align-items-center m-0"
                    style={{ height: "100%", color: getColor(data.type.name) }}
                  >
                    {abbreviateTerm(data.type.name)}
                  </div>
                </td>
                <td style={{ fontSize: ".75rem", height: "2.875rem" }}>
                  <div
                    className="d-flex align-items-center m-0"
                    style={{ height: "100%" }}
                  >
                    {data.isHalfday ? 0.5 : 1}
                  </div>
                </td>
                <td style={{ fontSize: ".75rem", height: "2.875rem" }}>
                  <div
                    className="d-flex align-items-center m-0"
                    style={{ height: "100%" }}
                  >
                    {data.statuses}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
