import React from "react";
import { formatCurrency } from "../../../../../formatCurrency";

export default function OtherDeductions({ adjustments }) {
  console.log("ADJ", adjustments);

  return (
    <div
      className="rounded position-relative m-0 p-3 flex-1 w-100"
      style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)", fontSize: ".9rem" }}
    >
      <div className="d-flex fw-semibold opacity-75  justify-content-between">
        <p>Adjustments</p>
        <p>
          {formatCurrency(
            adjustments.reduce((acc, current) => (acc += current.value), 0)
          )}
        </p>
      </div>
      <div
        className="d-flex p-2 m-0 rounded flex-column"
        style={{ background: "#e6e6e6", fontSize: ".8rem" }}
      >
        <div className="d-flex p-2 m-0 rounded justify-content-between">
          <p className=" flex-grow w-100 fw-semibold">Description</p>
          <p className="text-end flex-grow w-100 fw-semibold">Amount</p>
        </div>
        {adjustments.length > 0 ? (
          adjustments.map((adjustment) => {
            return (
              <div
                key={adjustment.label}
                className="d-flex ps-2 pe-2 p-0 m-0 rounded justify-content-between"
                style={{ fontSize: ".8rem" }}
              >
                <p className=" flex-grow w-100">
                  {adjustment?.label || "No label"}
                </p>
                <p className="text-end flex-grow w-100">
                  {formatCurrency(adjustment.value)}
                </p>
              </div>
            );
          })
        ) : (
          <p className="text-center flex-grow w-100">No Adjustments</p>
        )}
      </div>
    </div>
  );
}
