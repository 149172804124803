import { Button } from "nq-component";

export default function FloatingButton({ onClick }) {
  return (
    <div className="position-fixed" style={{ bottom: "2rem", right: "2rem" }}>
      <Button
        className="btn shadow-sm"
        onClick={onClick}
        style={{
          width: "4.8rem",
          height: "4.8rem",
          borderRadius: "5rem",
          backgroundColor: "#DAB44E",
        }}
      >
        <i className="bi bi-plus h1" />
      </Button>
    </div>
  );
}
