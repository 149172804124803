import React from "react";
import { InputJson, InputString } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory from "../../components/FormFactory";
import { restUseCase } from "../../usecases/rest";
import BudgetRequestFormPresenter from "./BudgetRequestFormPresenter";
import InputFactory from "../../components/InputFactory";
import InputFactory2 from "../../components/InputFactory2";
import Drop from "../../components/DropdownFilter";

class BudgetRequestFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      itemsAmount: [],
      selectedProject: null,
      filtered: [],
    };
    this.presenter = new BudgetRequestFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }
  onChangeFilter(value) {
    this.presenter.onChangeFilter(value);
  }

  handleProjectChange = (value) => {
    const selectedProject = this.state.filtered.find(
      (project) => project.id === value
    );
    this.setState({ selectedProject: selectedProject || null });
  };

  // onChange(value, field) {
  //   if (Array.isArray(value)) {
  //     this.setState({ itemsAmount: value });
  //   }
  //   this.presenter.onChange(value, field);
  // }
  getAcl() {
    // const roles = this.getCurrentRoles();
    // const aclRoles = roles.map((r) => `role:${r.name}`);
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:admin"],
      write: ["*", user.id, "role:admin"],
    };
  }

  async onChange(value, field) {
    if (field === "client_name") {
      const clientName = value.id;
      const proj = await findObjectUseCase().execute("projects");
      console.warn("proj", proj);
      const filteredProjects = proj
        .filter((project) => project.client?.id === clientName)
        .map((project) => ({
          name: project.name,
          id: project.id,
        }));
      this.setState({
        filtered: filteredProjects,
        selectedProject:
          filteredProjects.length > 0 ? filteredProjects[0] : null,
      });
    } else if (field === "project_name" && !value) {
      this.setState({ filtered: [], selectedProject: null });
    } else if (Array.isArray(value)) {
      this.setState({ itemsAmount: value });
    }

    this.presenter.onChange(value, field);
  }

  render() {
    const { object, selectedProject, advanced } = this.state;
    console.warn(object);

    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h5 className="fw-bold mt-3 text-capitalize">
                {/* {label + (schema.label || this.getCollectionName())} */}
                Create Budget Request
              </h5>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    {advanced ? (
                      <InputJson
                        defaultValue={object}
                        onChange={this.onChangeObject.bind(this)}
                        rows="15"
                      />
                    ) : (
                      <>
                        {/* <FormFactoryN
                          schema={schema}
                          object={object}
                          onChange={this.onChange.bind(this)}
                          excludeFields={[
                            "creditedTo",
                            "statuses",
                            "liqui",
                            "items_list",
                          ]}
                        /> */}
                        <div className="mb-3">
                          <label className="form-label">Client Name</label>
                          <InputFactory
                            type="UserInputPointer"
                            target="clients"
                            dynamic={false}
                            field="client_name"
                            object={object}
                            placeholder="Select Client Name"
                            onChange={this.onChange.bind(this)}
                            required={true}
                          />
                        </div>
                        <div className="mb-3">
                          {this.state.filtered.length > 0 ||
                          selectedProject ||
                          object.project_name?.name ? (
                            <>
                              <label className="form-label">Project Name</label>
                              <Drop
                                placeholder={
                                  selectedProject
                                    ? "Select a project"
                                    : object.project_name?.name
                                }
                                value={
                                  selectedProject
                                    ? selectedProject
                                    : object.project_name?.name
                                }
                                options={this.state.filtered}
                                onChange={this.handleProjectChange}
                              />
                            </>
                          ) : null}
                        </div>
                        <div className="mt-4">Items</div>
                        <InputFactory2
                          type="Relation"
                          _type="Related"
                          field="items_list"
                          target="items"
                          object={object}
                          onChange={this.onChange.bind(this)}
                          required={true}
                          excludeFields={[
                            "or#",
                            "supplier",
                            "tin#",
                            "quantity",
                          ]}
                        />

                        {this.state.itemsAmount &&
                          Array.isArray(this.state.itemsAmount) && (
                            <div className="col">
                              <p
                                className="mb-1 mt-2"
                                style={{ fontSize: "13px" }}
                              >
                                Total Amount
                              </p>
                              <div
                                className="card col-l-6 pt-2 h-50 ps-2"
                                style={{ backgroundColor: "#e5e3e3" }}
                              >
                                <p>
                                  {formatCurrency(
                                    this.state.itemsAmount
                                      .map((items) =>
                                        Number(items.amounts?.replace(/,/g, ""))
                                      )
                                      .reduce(
                                        (accumulator, currentValue) =>
                                          accumulator + currentValue,
                                        0
                                      )
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn  fs-sm me-3"
                      style={{
                        backgroundColor: "#EBBD2F",
                      }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(BudgetRequestFormPage);
