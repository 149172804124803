import React from "react";
import { InputJson } from "nq-component";
import { getObjectUseCase, upsertUseCase } from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import { restUseCase } from "../../usecases/rest";
import FormFactory2 from "../../components/FormFactory2";
import VendorAddPresenter from "./VendorAddPresenter";

class VendorAddPage extends BaseFormPage {
    constructor(props) {
        super(props);
        this.state = { object: {}, advanced: false };
        this.presenter = new VendorAddPresenter(
            this,
            getObjectUseCase(),
            upsertUseCase(),
            restUseCase()
        );
    }

    getCollectionName() {
        return "vendor";
    }

    onClickAdvance() {
        this.setState({ advanced: !this.state.advanced });
    }

    onChangeObject(object) {
        this.presenter.onChangeObject(object);
    }
    onChangeFilter(value) {
        this.presenter.onChangeFilter(value);
    }

    render() {
        const object = this.state.object;
        console.log("o", object);
        const advanced = this.state.advanced;
        const schema = this.getSchema(this.getCollectionName());
        if (!schema) return <h1>no schema</h1>;
        const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
        return (
            <>
                <NavBar
                    action={() => (
                        <div className="dropdown dropstart d-inline-block">
                            <i
                                role="button"
                                data-bs-toggle="dropdown"
                                className="bi bi-three-dots-vertical"
                            ></i>
                            <div className="dropdown-menu fs-xs">
                                <button
                                    onClick={this.onClickAdvance.bind(this)}
                                    className="dropdown-item py-3"
                                >
                                    <i className="bi bi-gear pe-2" />
                                    Toggle Advance
                                </button>
                            </div>
                        </div>
                    )}
                />
                <div className="overflow-auto">
                    <div className="h-100">
                        <div className="p-3 p-lg-4">
                            <h1 className="fw-bold mt-3 text-capitalize">
                                {label + (schema.label || this.getCollectionName())}
                            </h1>
                            <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                                <form onSubmit={this.onSubmitForm.bind(this)}>
                                    <div className="row g-3">
                                        {advanced ? (
                                            <InputJson
                                                defaultValue={object}
                                                onChange={this.onChangeObject.bind(this)}
                                                rows="15"
                                            />
                                        ) : (
                                            <>
                                                <FormFactory2
                                                    schema={schema}
                                                    object={object}
                                                    onChange={this.onChange.bind(this)}
                                                    excludeFields={["id", "acl", "createdAt", "updatedAt"]}
                                                />
                                                {/* <InputFactory
                          type="String"
                          //   _type="String1"
                          field="br"
                          object={object}
                          onChange={this.onChange.bind(this)}
                        /> */}
                                            </>
                                        )}
                                    </div>
                                    <div className="mt-4 d-flex justify-content-between">
                                        <button
                                            type="submit"
                                            className="btn fs-sm me-3"
                                            style={{ background: "#FFC03F" }}
                                        >
                                            <i className="me-2"></i>Submit
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-light fs-sm"
                                            style={{ background: "#DDDDDD" }}
                                            onClick={this.onClickBack.bind(this)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(VendorAddPage);
