import React from "react";
import NavBar from "../../components/navbar";
import { Button, dialog } from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import AccountsPresenter from "./AccountsPresenter";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import TransactionTablePage from "./transaction/TransactionTablePage";
import { upsertUseCase } from "../../usecases/object";
import fundTransfer from "../../assets/bank-transfer.svg";
import { NumericFormat } from "react-number-format";
import PayablesPage from "../payables/PayablesPage";

import MetrobankLogo from "../../assets/gmd_accounts_logo/metrobank-logo.png";

class AccountsPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new AccountsPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase()
    );
    this.state = {
      totalPayables: {},
      fromBalance: 0,
      fromAccount: "Choose...", //new
      toBalance: 0,
      toAccount: "Choose...",
      isHoveringTotal: false,
      isHoveringCash: false,
      totalCollectibles: null,
    };
  }

  getCollectionName() {
    return "gmd_accounts";
  }

  setPayables(totalPayables) {
    this.setState({ totalPayables });
  }

  setInvoices(totalCollectibles) {
    this.setState({ totalCollectibles });
  }

  handleFromChange = (event) => {
    const roles = this.getCurrentRoles().map((item) => item.name);
    const selectedAccountName = event.target.value;
    const selectedAccount = this.state.objects.find(
      (account) => account.name === selectedAccountName
    );

    if (selectedAccount) {
      this.setState(
        {
          fromAccount: selectedAccountName,
          fromBalance: roles.includes("masterAdmin")
            ? selectedAccount.balance
            : selectedAccountName === "GMD Revolving Fund" ||
              selectedAccountName === "GMD Fund"
            ? selectedAccount.balance
            : "****",
        },
        () => {
          dialog.close();
          this.onClickTransfer();
        }
      );
    } else {
      this.setState({ fromAccount: "", fromBalance: 0 });
    }
  };

  handleToChange = (event) => {
    const roles = this.getCurrentRoles().map((item) => item.name);
    const selectedAccountNameTo = event.target.value;
    const selectedAccountTo = this.state.objects.find(
      (account) => account.name === selectedAccountNameTo
    );

    if (selectedAccountTo) {
      this.setState(
        {
          toAccount: selectedAccountNameTo,
          toBalance: roles.includes("masterAdmin")
            ? selectedAccountTo.balance
            : selectedAccountNameTo === "GMD Revolving Fund" ||
              selectedAccountNameTo === "GMD Fund"
            ? selectedAccountTo.balance
            : "****",
        },
        () => {
          dialog.close();
          this.onClickTransfer();
        }
      );
      // var intervalId = setInterval(this.onClickTransfer, 2000);
    } else {
      this.setState({ toAccount: "", toBalance: 0 });
    }
  };

  formatNumberAsCurrency = (value) => {
    // Convert the raw value to a formatted string
    return new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(
      value
    );
  };

  handleAmountChange = (values) => {
    // this.setState({ amount: event.target.value });
    const { formattedValue, value } = values;
    // If you want to store the numeric value without formatting (e.g., for calculations or submissions):
    this.setState({ amount: value });
  };

  handleTransfer = () => {
    const { fromAccount, toAccount, amount } = this.state;
    if (!fromAccount || !toAccount || !amount || isNaN(parseFloat(amount))) {
      console.error(
        "Invalid input. Please fill in all fields with valid values."
      );
      return;
    }
    this.presenter.handleTransfer(fromAccount, toAccount, amount);
  };

  onClickTransfer() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    const roleName = role.map((roles) => roles.name);
    dialog.fire({
      html: (
        <div className="p-8 py-2">
          <p className="h3 d-flex text-start align-center">
            <img src={fundTransfer} />
            <b>Transfer Fund</b>
          </p>
          <form>
            <div className="form-group mb-3">
              <label htmlFor="exampleInputEmail1">Enter Amount:</label>
              {/* <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder=""
                onChange={this.handleAmountChange}
                value={this.state.amount}
              /> */}
              <NumericFormat
                thousandSeparator={true}
                className="form-control"
                id="formattedNumber"
                onValueChange={this.handleAmountChange}
                value={this.state.amount}
              />
            </div>

            {/* From*/}
            <div className="input-group mb-3">
              <div className="input-group-prepend w-25">
                <label className="input-group-text" htmlFor="fromAccountSelect">
                  From:
                </label>
              </div>
              <div className="w-50 d-flex align-items-center">
                <select
                  className="custom-select flex-grow-1"
                  id="fromAccountSelect"
                  onChange={this.handleFromChange}
                  value={this.state.fromAccount}
                  style={{ marginRight: "20px" }}
                >
                  <option disabled>Choose...</option>
                  <option value="GMD Revolving Fund">GMD Revolving Fund</option>
                  <option value="GMD Fund">GMD Fund</option>
                  {user.roles[0].name === "masterAdmin" ? (
                    <option value="Management money">Management money</option>
                  ) : (
                    ""
                  )}
                  <option value="Metrobank">Metrobank</option>
                  <option value="BPI">BPI</option>
                  <option value="BDO Main">BDO Main</option>
                </select>
                <span className="ml-2 mr-2">
                  <span style={{ fontWeight: "bold" }}>Balance: </span> ₱{" "}
                  {this.state.fromBalance}
                </span>
              </div>
            </div>

            {/* To */}
            <div className="input-group mb-3">
              <div className="input-group-prepend w-25">
                <label className="input-group-text" htmlFor="toAccountSelect">
                  To:
                </label>
              </div>
              <div className="w-50 d-flex align-items-center">
                <select
                  className="custom-select flex-grow-1"
                  id="toAccountSelect"
                  onChange={this.handleToChange}
                  style={{ marginRight: "20px" }}
                  value={this.state.toAccount}
                >
                  <option disabled>Choose...</option>
                  <option value="GMD Revolving Fund">GMD Revolving Fund</option>
                  <option value="GMD Fund">GMD Fund</option>
                  {user.roles[0].name === "masterAdmin" ? (
                    <option value="Management money">Management money</option>
                  ) : (
                    ""
                  )}
                  <option value="Metrobank">Metrobank</option>
                  <option value="BPI">BPI</option>
                  <option value="BDO Main">BDO Main</option>
                </select>
                <span className="ml-2 mr-2">
                  <span style={{ fontWeight: "bold" }}>Balance:</span> ₱{" "}
                  {this.state.toBalance}
                </span>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-end gap-2 mb-3">
              <button
                type="button"
                className="btn btn-warning"
                onClick={this.handleTransfer}
              >
                Transfer
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  {
                    this.setState({
                      fromAccount: "Choose...",
                      fromBalance: 0,
                      toAccount: "Choose...",
                      toBalance: 0,
                    });
                  }
                  dialog.close();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ),
      footer: false,
      showCancelButton: false,
      preConfirm: () => {
        return false;
      },
      onClose: () => {},
    });
  }

  onClickBDOHistory(BDO) {
    this.presenter.onClickBDOHistory(BDO);
  }

  onClickBPIHistory(BPI) {
    this.presenter.onClickBPIHistory(BPI);
  }

  onClickPJGHistory(PJG) {
    this.presenter.onClickPJGHistory(PJG);
  }

  onClickMBHistory(MB) {
    this.presenter.onClickMBHistory(MB);
  }

  onClickGMDFundHistory(GMDFund) {
    this.presenter.onClickGMDFundHistory(GMDFund);
  }

  onClickGMDRevoHistory(GMDRevo) {
    this.presenter.onClickGMDRevoHistory(GMDRevo);
  }

  onClickExport() {
    this.presenter.exportToCSV();
  }

  displayBalance(balance, formatCurrency, roleName) {
    return roleName.includes("masterAdmin") ? formatCurrency(balance) : "****";
  }

  render() {
    const { objects, totalPayables, totalCollectibles } = this.state;
    console.warn("heyt", objects);
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    const pjgAbonoObject = objects?.find(
      (obj) => obj.name === "Management money"
    );
    const bdoObject = objects?.find((obj) => obj.name === "BDO Main");
    const bpiObject = objects?.find((obj) => obj.name === "BPI");
    const metrobankObject = objects?.find((obj) => obj.name === "Metrobank");
    const gmdFundObject = objects?.find((obj) => obj.name === "GMD Fund");
    const gmdRevolvingObject = objects?.find(
      (obj) => obj.name === "GMD Revolving Fund"
    );

    const roleName = this.getCurrentRoles().map((roles) => roles.name);

    const BDO = { bdoObject };
    const BPI = { bpiObject };
    const PJG = { pjgAbonoObject };
    const MB = { metrobankObject };
    const GMDFund = { gmdFundObject };
    const GMDRevo = { gmdRevolvingObject };

    const cashInBank =
      bdoObject?.balance +
      bpiObject?.balance +
      metrobankObject?.balance +
      gmdFundObject?.balance +
      gmdRevolvingObject?.balance;
    const accountReceivable = totalCollectibles;
    const totalMoney = cashInBank + accountReceivable - totalPayables;

    return (
      <>
        <NavBar />
        <div
          style={{
            backgroundImage: `url('/bg-gmd.png')`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "contain",
            // width: "200%",
            borderRadius: "0 0 20px 20px",
            height: "32%",
          }}
        >
          {roleName.includes("masterAdmin") ? (
            <div>
              <div
                className="card m-4 p-2 d-flex justify-content-center"
                style={{ opacity: ".7" }}
              >
                <div>
                  <ul className="list-unstyled ms-1 text-truncate">
                    <li>
                      <span
                        className="ms-2 fw-light fw-bold"
                        onMouseEnter={() =>
                          this.setState({ isHoveringTotal: true })
                        }
                        onMouseLeave={() =>
                          this.setState({ isHoveringTotal: false })
                        }
                      >
                        Total Money:{" "}
                      </span>

                      {this.state.isHoveringTotal ? (
                        <span className="text-nowrap fw-bold">
                          Total Money = Cash in Bank + Account Receivables -
                          Account Payables
                        </span>
                      ) : (
                        <span className=" text-nowrap">
                          {formatCurrency(totalMoney)}
                        </span>
                      )}
                    </li>
                    <li>
                      <span
                        className="ms-2 fw-light fw-bold"
                        onMouseEnter={() =>
                          this.setState({ isHoveringCash: true })
                        }
                        onMouseLeave={() =>
                          this.setState({ isHoveringCash: false })
                        }
                      >
                        Cash in Bank:{" "}
                      </span>
                      {this.state.isHoveringCash ? (
                        <span className="fw-bold text-nowrap">
                          CashInBank = BDO + BPI + METROBANK + GMDFunds +
                          GMDRevolvingFund
                        </span>
                      ) : (
                        <span className=" text-nowrap">
                          {formatCurrency(cashInBank)}
                        </span>
                      )}
                    </li>
                    <li>
                      <span className="ms-2 fw-light fw-bold">
                        Account Receivables:{" "}
                      </span>
                      <span className=" text-nowrap">
                        {formatCurrency(accountReceivable)}
                      </span>
                    </li>
                    <li>
                      <span className="ms-2 fw-light fw-bold">
                        Account Payables:{" "}
                      </span>
                      <span className=" text-nowrap">
                        {formatCurrency(totalPayables)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          <div className="d-flex justify-content-center mb-2 mt-2 ">
            <Button
              className="btn me-2"
              style={{ backgroundColor: "#d2770c" }}
              onClick={this.onClickTransfer.bind(this)}
            >
              Fund Transfer
            </Button>
            <Button
              className="btn"
              style={{ backgroundColor: "#d4942a" }}
              onClick={this.onClickExport.bind(this)}
            >
              Download CSV
            </Button>
          </div>
        </div>
        <div className="m-3">
          <h5 className="me-2 mt-3 mb-3 text-dark">Bank Accounts</h5>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-6">
              <div
                className="card "
                style={{ backgroundColor: "#0c50a1", height: "150px" }}
              >
                <div className="d-flex justify-content-center align-items-center mb-0">
                  <p
                    className="text-white pt-3 fw-bold"
                    style={{ fontSize: "35px" }}
                  >
                    B
                    <span className="" style={{ marginLeft: "-5px" }}>
                      D
                    </span>
                    <span
                      className=""
                      style={{ color: "#feb716", marginLeft: "-5px" }}
                    >
                      O
                    </span>
                  </p>
                </div>
                <div className="text-center" style={{ marginTop: "-10px" }}>
                  <p className="mb-0 text-white">
                    {this.displayBalance(
                      bdoObject?.balance,
                      formatCurrency,
                      roleName
                    )}
                  </p>
                  <button
                    className="mt-1 btn fw-bold"
                    style={{ opacity: ".7", backgroundColor: "white" }}
                    onClick={() => this.onClickBDOHistory(BDO)}
                  >
                    View History
                  </button>
                </div>
              </div>
              <p className="text-center">BDO</p>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mb-3">
              <div
                className="card "
                style={{ backgroundColor: "#9b1d21", height: "150px" }}
              >
                <div className="d-flex justify-content-center align-items-center mb-0">
                  <img
                    src="https://www.rappler.com/tachyon/2022/07/bpi-resized.jpg"
                    style={{ width: "85px", height: "85px" }}
                    alt="logo"
                  />
                  {/* <p
                    className="text-white pt-3 fw-bold"
                    style={{ fontSize: "35px" }}
                  >
                    BPI
                  </p> */}
                </div>
                <div className="text-center" style={{ marginTop: "-10px" }}>
                  <p className="mb-0 text-white">
                    {this.displayBalance(
                      bpiObject?.balance,
                      formatCurrency,
                      roleName
                    )}
                  </p>
                  <button
                    className="mt-1 btn fw-bold"
                    style={{ opacity: ".7", backgroundColor: "white" }}
                    onClick={() => this.onClickBPIHistory(BPI)}
                  >
                    View History
                  </button>
                </div>
              </div>
              <p className="text-center mb-0">BPI</p>
            </div>

            <div className="col-lg-3 col-md-6 col-6">
              <div
                className="card "
                style={{ backgroundColor: "#dda74c", height: "150px" }}
              >
                <div
                  className="d-flex justify-content-center align-items-center mb-0 mt-3"
                  style={{ height: "60px" }}
                >
                  <img
                    src={MetrobankLogo}
                    className="img-fluid"
                    style={{ height: "30px" }}
                    alt="logo"
                  />
                </div>
                <div className="text-center" style={{ marginTop: "1px" }}>
                  <p className="mb-0 text-white">
                    {this.displayBalance(
                      metrobankObject?.balance,
                      formatCurrency,
                      roleName
                    )}
                  </p>
                  <button
                    className="mt-1 btn fw-bold"
                    style={{ opacity: ".7", backgroundColor: "white" }}
                    onClick={() => this.onClickMBHistory(MB)}
                  >
                    View History
                  </button>
                </div>
              </div>
              <p className="text-center">METROBANK</p>
            </div>

            <div className="col-lg-3 col-md-6 col-6 mb-3">
              <div className="card " style={{ height: "150px" }}>
                <div className="d-flex justify-content-center align-items-center mb-0">
                  <img
                    className="mt-3"
                    src="/gmd-logo.png"
                    style={{ width: "100px", height: "50px" }}
                    alt="logo"
                  />
                </div>
                <div className="text-center" style={{ marginTop: "2px" }}>
                  <p className="mb-0">
                    {formatCurrency(gmdFundObject?.balance)}
                  </p>
                  <button
                    className="btn fw-bold"
                    style={{
                      opacity: ".7",
                      backgroundColor: "#e5e5e5",
                      marginTop: "12px",
                    }}
                    onClick={() => this.onClickGMDFundHistory(GMDFund)}
                  >
                    View History
                  </button>
                </div>
              </div>
              <p className="text-center mb-0">GMD FUNDS</p>
            </div>

            <div className="col-lg-3 col-md-6 col-6">
              <div className="card " style={{ height: "150px" }}>
                <div className="d-flex justify-content-center align-items-center mb-0">
                  <img
                    className="mt-3"
                    src="/gmd-logo.png"
                    style={{ width: "100px", height: "50px" }}
                    alt="logo"
                  />
                </div>
                <div className="text-center" style={{ marginTop: "2px" }}>
                  <p className="mb-0 ">
                    {formatCurrency(gmdRevolvingObject?.balance)}
                  </p>
                  <button
                    className="btn fw-bold"
                    style={{
                      opacity: ".7",
                      backgroundColor: "#e5e5e5",
                      marginTop: "12px",
                    }}
                    onClick={() => this.onClickGMDRevoHistory(GMDRevo)}
                  >
                    View History
                  </button>
                </div>
              </div>
              <p className="text-center mb-0">GMD REVOLVING FUNDS</p>
            </div>

            {roleName.includes("masterAdmin") ? (
              <div className="col-lg-3 col-md-6 col-6 mb-3">
                <div className="card " style={{ height: "150px" }}>
                  <div className="d-flex justify-content-center align-items-center mb-0">
                    <img
                      className="mt-3"
                      src="/gmd-logo.png"
                      style={{ width: "100px", height: "50px" }}
                      alt="logo"
                    />
                  </div>

                  <div className="text-center" style={{ marginTop: "2px" }}>
                    <p className="mb-0 ">
                      {formatCurrency(pjgAbonoObject?.balance)}
                    </p>
                    <button
                      className="btn fw-bold"
                      style={{
                        opacity: ".7",
                        backgroundColor: "#e5e5e5",
                        marginTop: "12px",
                      }}
                      onClick={() => this.onClickPJGHistory(PJG)}
                    >
                      View History
                    </button>
                  </div>
                </div>
                <p className="text-center mb-0">MANAGEMENT MONEY</p>
              </div>
            ) : null}
          </div>
        </div>
        <TransactionTablePage />
        <div className="position-fixed bottom-0 end-0 m-4">
          <Button
            className="btn  shadow-sm "
            onClick={this.onClickAdd.bind(this)}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "25px",
              backgroundColor: "#EBBD2F",
            }}
          >
            <i className="bi bi-plus " />
          </Button>
        </div>
      </>
    );
  }
}
export default AccountsPage;
