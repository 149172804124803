import React from "react";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(amount);
};

export default function PayslipInfo({
  startDate,
  endDate,
  total,
  monthlyRate,
}) {
  return (
    <div
      style={{ flex: 2, border: "1px solid #e9983d", height: "fit-content" }}
    >
      <div style={{ background: "#e9983d", padding: ".1rem 1rem" }}>
        <p style={{ fontWeight: "bold" }}>Payslip Info</p>
      </div>
      <div
        style={{
          padding: ".1rem 1rem",
        }}
      >
        <div>
          <p>Start: {formatDate(startDate)}</p>
          <p>End: {formatDate(endDate)}</p>
          <p>Total: {formatCurrency(total || monthlyRate / 2 || 0)}</p>
        </div>
      </div>
    </div>
  );
}
