import React from "react";

export default function Tabs({ roles, setSelectedTab }) {
  if (
    roles.includes("masterAdmin") ||
    roles.includes("Production") ||
    roles.includes("Designer") ||
    roles.includes("Sales") ||
    roles.includes("admin")
  ) {
    return;
  }

  const handleTimeSheetClick = () => {
    setSelectedTab("myTimeSheet");
  };

  const handleTimeSheetRequestClick = () => {
    setSelectedTab("requestedTimeSheet");
  };

  return (
    <ul
      class="nav nav-tabs pt-4 mb-1"
      style={{ width: "fit-content" }}
      id="myTab"
      role="tablist"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          data-bs-toggle="tab"
          type="button"
          role="tab"
          onClick={handleTimeSheetClick}
        >
          My Time Sheet
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          type="button"
          role="tab"
          onClick={handleTimeSheetRequestClick}
        >
          Time Sheet Requested
        </button>
      </li>
    </ul>
  );
}
