import BaseFormPresenter from "../../../base/BaseFormPresenter";

class SettingsFormPresenter extends BaseFormPresenter {
  async getObject() {
    const collection = this.view.getCollectionName();
    const { id } = this.view.getCurrentUser();
    if (id) {
      const params = { include: ["all"] };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });

        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }
}

export default SettingsFormPresenter;
