import BaseFormPresenter from "../../base/BaseFormPresenter";

class VendorAddPresenter extends BaseFormPresenter {
    onChangeObject(object) {
        this.change = object;
        this.object = object;
    }

    //   async save() {
    //     const collection = this.view.getCollectionName();
    //     const user = this.view.getCurrentUser();

    //     if (this.object.id) {
    //       this.change.id = this.object.id;
    //     } else {
    //       this.change.acl = this.view.getAcl();
    //     }
    //     try {
    //       await this.upsertUseCase.execute(collection, this.change);
    //       this.change = {};
    //     } catch (error) {
    //       throw error; // rethrow the error to be caught by the caller
    //     }
    //   }

    //   async submit() {
    //     if (Object.values(this.change).length === 0) {
    //       this.view.showSuccessSnackbar("Successfully saved!");
    //       return;
    //     }
    //     try {
    //       this.view.submitting();
    //       await this.save();
    //       this.view.submissionSuccess();
    //       this.view.showSuccessSnackbar("Successfully saved!");
    //     } catch (error) {
    //       this.view.submissionError(error);
    //       this.view.showError(error);
    //     }
    //   }

    //   async getObject2() {
    //     const collection = "clients";
    //     const id = this.object.id || this.view.getObjectId();
    //     if (id) {
    //       const params = { include: ["all"] };
    //       try {
    //         this.view.showProgress();
    //         this.object = await this.getObjectUseCase.execute(collection, id, {
    //           params,
    //         });
    //         this.view.hideProgress();
    //         this.view.setObject(this.object);
    //       } catch (error) {
    //         this.view.hideProgress();
    //         this.view.showError(error);
    //       }
    //     }
    //   }

    //   onChangeFilter(object) {
    //     this.object = object;
    //     this.getObject2();
    //   }
}

export default VendorAddPresenter;