const getNewHourlyRate = (number, percentage) => {
  return number * (percentage / 100);
};

// REGULAR

export const RegularDayBasic = (hours, hourlyRate) => {
  if (!hours) return 0;

  return hourlyRate * hours;
};

export const RegularHolidayBasic = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(hourlyRate, 200) * hours;
};

export const SpecialNonWorkingBasic = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(hourlyRate, 130) * hours;
};

export const TimeOffBasic = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(hourlyRate, 130) * hours;
};

export const RegularDayAndTimeOffBasic = (hours, hourlyRate) => {
  if (!hours) return 0;

  // return getNewHourlyRate(hourlyRate, 130) * hours;
  return getNewHourlyRate(hourlyRate, 130) * hours;
};

export const RegularDayAndTimeOffBasicMonthly = (hours, hourlyRate) => {
  // if (!hours) return 0;

  // return hourlyRate * 8 * 1.3 * hours;
  const rate = ((hourlyRate * 8 * 1.3) / 8) * hours;

  const dailyWage = rate === 0 ? 0 : hourlyRate * 8;

  return rate + dailyWage || 0;
};

export const RegularDayAndTimeOffBasicOnCall = (hours, hourlyRate) => {
  if (!hours) return 0;

  // return hourlyRate * 8 * hours + hourlyRate * hours;
  return ((hourlyRate * 8 * 1.3) / 8 + hourlyRate) * hours;
};

export const SpecialNonWorkingAndTimeOffBasic = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(hourlyRate, 150) * hours;
};

// export const SpecialNonWorkingAndTimeOffBasicOnCall = (hours, hourlyRate) => {
//   if (!hours) return 0;

//   return getNewHourlyRate(hourlyRate, 260) * hours;
// };

// OVERTIME

export const RegularDayOT = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(hourlyRate, 125) * hours;
};

export const RegularHolidayOT = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 200), 130) * hours;
};

export const SpecialNonWorkingOT = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 130), 130) * hours;
};

export const TimeOffOT = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 130), 130) * hours;
};

// export const RegularHolidayAndTimeOffOTOnCall = (hours, hourlyRate) => {
//   if (!hours) return 0;

//   return getNewHourlyRate(getNewHourlyRate(hourlyRate, 130), 260) * hours;
// };
export const RegularHolidayAndTimeOffOTOnCall = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 260), 130) * hours;
};

// export const SpecialNonWorkingAndTimeOffOT = (hours, hourlyRate) => {
//   if (!hours) return 0;

//   return getNewHourlyRate(getNewHourlyRate(hourlyRate, 130), 150) * hours;
// };
// export const RegularDayAndTimeOffOT = (hours, hourlyRate) => {
//   if (!hours) return 0;

//   return getNewHourlyRate(getNewHourlyRate(hourlyRate, 130), 260) * hours;
// };
export const RegularDayAndTimeOffOT = (hours, hourlyRate) => {
  if (!hours) return 0;

  return ((hourlyRate * 8 * 2 * 1.3 * 1.3) / 8) * hours;
};

// export const SpecialNonWorkingAndTimeOffOTOnCall = (hours, hourlyRate) => {
//   if (!hours) return 0;

//   return getNewHourlyRate(getNewHourlyRate(hourlyRate, 260), 130) * hours;
// };
export const SpecialNonWorkingAndTimeOffOT = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 130), 150) * hours;
};

// NIGHT DIFFERENTIAL

export const RegularDayND = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(hourlyRate, 10) * hours;
};

export const RegularHolidayND = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 10), 200) * hours;
};

export const SpecialNonWorkingND = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 10), 130) * hours;
};

export const TimeOffND = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 10), 130) * hours;
};

export const RegularDayAndTimeOffND = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 10), 260) * hours;
};

export const SpecialNonWorkingAndTimeOffND = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 10), 150) * hours;
};

export const SpecialNonWorkingAndTimeOffNDOnCall = (hours, hourlyRate) => {
  if (!hours) return 0;

  return getNewHourlyRate(getNewHourlyRate(hourlyRate, 260), 10) * hours;
};
