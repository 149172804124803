"use strict";

var _interopRequireDefault = require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/interopRequireDefault.js")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactDom = _interopRequireDefault(require("react-dom"));
var _printIframe = _interopRequireDefault(require("./printIframe"));
/**
 * Add content to an iframe and wait until all images are loaded before resolving the promise.
 * @param {HTMLIFrameElement} iframe - The iframe element to add content to.
 * @param {string} content - The HTML content to add to the iframe.
 * @returns {Promise<void>}
 */
var boostrap = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css';
function addContentInIframe(iframe, content) {
  var styles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [boostrap];
  return new Promise(function (resolve) {
    var _iframe$contentWindow;
    var contentDocument = iframe.contentDocument || ((_iframe$contentWindow = iframe.contentWindow) === null || _iframe$contentWindow === void 0 ? void 0 : _iframe$contentWindow.document);
    //add component into print
    if (contentDocument) {
      contentDocument.open();
      contentDocument.write(content);
      contentDocument.close();
    }
    // remove date/time from top
    var styleEl = contentDocument.createElement("style");
    var style = "\n            @page {\n                /* Remove browser default header (title) and footer (url) */\n                margin: 0;\n            }\n            @media print {\n                body {\n                    /* Tell browsers to print background image/colors */\n                    -webkit-print-color-adjust: exact; /* Chrome/Safari/Edge/Opera */\n                    color-adjust: exact; /* Firefox */\n                }\n            }\n        ";
    styleEl.appendChild(contentDocument.createTextNode(style));
    contentDocument.head.appendChild(styleEl);
    // get all elements need to load
    var images = contentDocument.querySelectorAll("img");
    var links = document.querySelectorAll("link[rel='stylesheet']");
    var total = images.length + links.length + styles.length;
    var loaded = [];
    function markLoaded(node, error) {
      loaded.push(node);
      if (error) {
        console.warn(error);
      }
      // resolve if all elements is loaded
      if (total === loaded.length) {
        resolve();
      }
    }
    for (var i = 0; i < styles.length; ++i) {
      var link = contentDocument.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("type", "text/css");
      link.setAttribute("href", styles[i]);
      link.onload = markLoaded.bind(null, link, true);
      link.onerror = markLoaded.bind(null, link, false);
      contentDocument.head.appendChild(link);
    }
    // copy all styles from parent and load
    for (var _i = 0; _i < links.length; ++_i) {
      var node = links[_i];
      var _link = contentDocument.createElement(node.tagName);
      _link.setAttribute("rel", "stylesheet");
      _link.setAttribute("type", "text/css");
      _link.setAttribute("href", node.getAttribute("href"));
      _link.onload = markLoaded.bind(null, node, true);
      _link.onerror = markLoaded.bind(null, node, false);
      contentDocument.head.appendChild(_link);
    }
    // pre-load all images
    for (var _i2 = 0; _i2 < images.length; _i2++) {
      var imgNode = images[_i2];
      var src = imgNode.getAttribute("src");
      if (!src) {
        markLoaded(imgNode, 'encountered an <img> tag with an empty "src" attribute. It will not attempt to pre-load it. The <img> is:' + imgNode);
      } else {
        // https://stackoverflow.com/questions/10240110/how-do-you-cache-an-image-in-javascript
        var img = new Image();
        img.onload = markLoaded.bind(null, imgNode, true);
        img.onerror = markLoaded.bind(null, imgNode, false);
        img.src = src;
      }
    }
  });
}
function createIFrame() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "printWindow";
  return new Promise(function (resolve, reject) {
    var iframe = document.createElement("iframe");
    iframe.style.position = "absolute"; // hide from user view
    iframe.width = "".concat(document.documentElement.clientWidth, "px");
    iframe.height = "".concat(document.documentElement.clientHeight, "px");
    iframe.style.top = "-".concat(document.documentElement.clientHeight + 100, "px");
    iframe.style.left = "-".concat(document.documentElement.clientWidth + 100, "px");
    iframe.id = id;
    iframe.title = "Print Window";
    iframe.srcdoc = "<!DOCTYPE html>";
    iframe.onload = function () {
      iframe.onload = null; // Ensures that it is only called once.
      resolve(iframe);
    };
    iframe.onerror = function (e) {
      return reject(e);
    };
    //remove first if already exist on the body
    var printWindow = document.getElementById(id);
    if (printWindow) {
      document.body.removeChild(printWindow);
    }
    document.body.appendChild(iframe);
  });
}
function printNode(node, fileName) {
  return Promise.resolve().then(function () {
    return createIFrame();
  }).then(function (iframe) {
    return addContentInIframe(iframe, node.outerHTML).then(function () {
      return iframe;
    });
  }).then(function (iframe) {
    return (0, _printIframe["default"])(iframe, fileName).then(function () {
      return iframe;
    });
  }).then(function (iframe) {
    // remove the iframe to the body
    setTimeout(function () {
      document.body.removeChild(iframe);
    }, 1000);
  });
}
function printComponent(component, fileName) {
  var node = _reactDom["default"].findDOMNode(component);
  return printNode(node, fileName);
}
var _default = printComponent;
exports["default"] = _default;