function dateFormat(string) {
  const date = new Date(string);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export default dateFormat;
