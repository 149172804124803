import BaseFormPresenter from "../../../base/BaseFormPresenter";
import {
  RegularDayAndTimeOffBasic,
  RegularDayAndTimeOffBasicMonthly,
  RegularDayAndTimeOffBasicOnCall,
  RegularDayAndTimeOffND,
  RegularDayAndTimeOffOT,
  RegularDayBasic,
  RegularDayND,
  RegularDayOT,
  RegularHolidayBasic,
  RegularHolidayND,
  RegularHolidayOT,
  SpecialNonWorkingAndTimeOffBasic,
  SpecialNonWorkingAndTimeOffBasicOnCall,
  SpecialNonWorkingAndTimeOffND,
  SpecialNonWorkingAndTimeOffNDOnCall,
  SpecialNonWorkingAndTimeOffOT,
  SpecialNonWorkingAndTimeOffOTOnCall,
  SpecialNonWorkingBasic,
  SpecialNonWorkingND,
  SpecialNonWorkingOT,
  TimeOffBasic,
  TimeOffND,
  TimeOffOT,
} from "../utils/IncreasesComputations";

class PayrollComputations extends BaseFormPresenter {
  // TODO
  constructor(view) {
    super(view);
    this.view = view;
  }

  timeSheetDeductions(value) {
    if (!value || isNaN(value)) return 0;
    return value * this.getHourlyRate();
  }

  deductions(value) {
    if (!value || isNaN(value)) return 0;
    return value;
  }

  computeWorkDayByHours(hours) {
    hours = parseFloat(hours);
    let day = Math.floor(hours / 8);

    return day;
  }

  getHourlyRate() {
    if (!this.view.state.userInfo.dailyRate) return 0;
    return this.view.state.userInfo.dailyRate / 8;
  }

  getAdjustments() {
    const values = this.view.state.employeeIncreasesAndDeductions;
    if (!values) return;
    let total = 0;

    values?.adjustments?.forEach(
      (item) => (total += item.adjustmentAmount || 0)
    );

    return total;
  }

  getCARequest() {
    const { caRequest } = this.view.state.employeeIncreasesAndDeductions;
    const cashAdvanceInfo = this.view.state.cashAdvanceInfo;
    const { startDate, endDate } = this.view.getArgument();

    const totalCashAdvance = cashAdvanceInfo
      .filter((ca) => ca.createdAt >= startDate && ca.createdAt <= endDate)
      .reduce(
        (acc, curr) =>
          acc + (parseFloat(curr.amounts.replace(/,/g, ""), 10) || 0),
        0
      );

    const amount = totalCashAdvance;

    if (caRequest) {
      return amount;
    }

    return 0;
  }

  getDeductions() {
    const values = this.view.state.employeeIncreasesAndDeductions;
    if (!values) return;
    let total = 0;

    values?.moreOtherDeductions?.forEach(
      (item) => (total += item.deductionAmount || 0)
    );
    console.log("get ", total);
    return total;
  }

  // MAIN COMPUTATION
  getTotalDeductions() {
    const values = this.view.state.employeeIncreasesAndDeductions;
    if (!values) return;

    return (
      this.timeSheetDeductions(values?.absences) +
      this.timeSheetDeductions(values?.undertime) +
      this.timeSheetDeductions(values?.lates) +
      this.deductions(values?.TIN) +
      this.deductions(values?.PHILHEALTH) +
      this.deductions(values?.SSS) +
      this.deductions(values?.PAGIBIG) +
      this.deductions(values?.PAGIBIGLOAN) +
      this.deductions(values?.SSSDELQ) +
      this.deductions(values?.SSSLOAN) +
      this.deductions(values?.SSSCONDO) +
      this.deductions(values?.CADeductions) +
      this.getDeductions()
    );
  }

  getTotalHoursDeducted() {
    const values = this.view.state.employeeIncreasesAndDeductions;
    if (!values) return;
    return (
      (values?.absences || 0) + (values?.undertime || 0) + (values?.lates || 0)
    );
  }

  countRegularHolidays() {
    const { startDate, endDate } = this.view.getArgument();
    const holidays = this.view.state.holidays;
    const holidayDays = [];
    // let regularHolidayCount = 0;
    const filteredHolidays = holidays.filter((holiday) =>
      holiday.type.name.includes("Regular Holiday")
    );

    let regularHolidayCount = 0;

    const getMonthDay = (date) => {
      const d = new Date(date);
      return { month: d.getMonth() + 1, day: d.getDate() };
    };

    const start = new Date(startDate);
    const end = new Date(endDate);

    // Array of day names
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Iterate over each day in the range from startDate to endDate
    for (
      let currentDate = new Date(start);
      currentDate <= end;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const currentMonthDay = getMonthDay(currentDate);
      const isHoliday = filteredHolidays.some((holiday) => {
        const holidayDate = getMonthDay(holiday.date);
        return (
          holidayDate.month === currentMonthDay.month &&
          holidayDate.day === currentMonthDay.day
        );
      });

      if (isHoliday) {
        regularHolidayCount++;
        const dayOfWeek = daysOfWeek[currentDate.getDay()]; // Get the day of the week
        holidayDays.push(dayOfWeek);
      }
    }

    console.log("HOLIIIII", holidayDays);
    // Optional: Store holidayDays in state or return it
    this.view.setState((prevState) => ({ ...prevState, holidayDays }));

    return regularHolidayCount;
  }

  //   holidays.forEach((holiday) => {
  //     const holidayDate = new Date(holiday.date);
  //     const holidayMonth = holidayDate.getMonth() + 1;
  //     const holidayDay = holidayDate.getDate();

  //     const startMonth = new Date(startDate).getMonth() + 1;
  //     const startDay = new Date(startDate).getDate();

  //     const endMonth = new Date(endDate).getMonth() + 1;
  //     const endDay = new Date(endDate).getDate();

  //     if (
  //       holidayMonth === startMonth &&
  //       holidayDay >= startDay &&
  //       holidayMonth === endMonth &&
  //       holidayDay <= endDay
  //     ) {
  //       regularHolidayCount++;
  //     }
  //   });

  //   return regularHolidayCount;
  // }

  getSubTotal() {
    const hourhlyRate = this.getHourlyRate();
    const values = this.view.state.employeeIncreasesAndDeductions;
    let totalRegularPay = 0;
    let totalOTPay = 0;
    let totalNDPay = 0;

    // REGULAR
    const HRNormalDay = RegularDayBasic(values?.HRNormalDay, hourhlyRate);

    const HRRegHoliday = RegularDayBasic(values?.HRRegHoliday, hourhlyRate);
    const HRSpeNonWorking = SpecialNonWorkingBasic(
      values?.HRSpeNonWorking,
      hourhlyRate
    );
    const HRTimeOff = TimeOffBasic(values?.HRTimeOff, hourhlyRate);
    // const HRRegTimeOff =
    //   RegularDayAndTimeOffBasic(values?.HRRegTimeOff, hourhlyRate) +
    //   (values?.HRRegTimeOff ? hourhlyRate : 0);
    // const HRRegTimeOff = RegularDayAndTimeOffBasic(
    //   values?.HRRegTimeOff,
    //   hourhlyRate
    // );
    // const HRRegTimeOff = RegularDayAndTimeOffBasic(
    //   values?.HRRegTimeOff,
    //   hourhlyRate
    // );
    const HRRegTimeOff = RegularDayAndTimeOffBasicMonthly(
      values?.HRRegTimeOff,
      hourhlyRate
    );
    // const HRRegTimeOff =
    //   RegularDayAndTimeOffBasic(values?.HRRegTimeOff, hourhlyRate) +
    //     values?.HRRegTimeOff >
    //   0
    //     ? hourhlyRate * 8
    //     : 0;
    // hourhlyRate * 8;
    // const HRRegTimeOff =
    //   RegularDayAndTimeOffBasic(values?.HRRegTimeOff, hourhlyRate) +
    //   (values?.HRRegTimeOff ? hourhlyRate : 0);
    // const HRRegTimeOff =
    //   RegularDayAndTimeOffBasic(values?.HRRegTimeOff, hourhlyRate) +
    //   (values?.HRRegTimeOff ? hourhlyRate : 0);
    const HRSpeTimeOff = SpecialNonWorkingAndTimeOffBasic(
      values?.HRSpeTimeOff,
      hourhlyRate
    );

    // OVERTIME
    const OTNormalDay = RegularDayOT(values?.OTNormalDay, hourhlyRate);
    const OTRegHoliday = RegularHolidayOT(values?.OTRegHoliday, hourhlyRate);
    const OTSpeNonWorking = SpecialNonWorkingOT(
      values?.OTSpeNonWorking,
      hourhlyRate
    );
    const OTTimeOff = TimeOffOT(values?.OTTimeOff, hourhlyRate);
    const OTRegTimeOff = RegularDayAndTimeOffOT(
      values?.OTRegTimeOff,
      hourhlyRate
    );
    const OTSpeTimeOff = SpecialNonWorkingAndTimeOffOT(
      values?.OTSpeTimeOff,
      hourhlyRate
    );

    // NIGHT DIFFERENTIAL
    const NDNormalDay = RegularDayND(values?.NDNormalDay, hourhlyRate);
    const NDRegHoliday = RegularHolidayND(values?.NDRegHoliday, hourhlyRate);
    const NDSpeNonWorking = SpecialNonWorkingND(
      values?.NDSpeNonWorking,
      hourhlyRate
    );
    const NDTimeOff = TimeOffND(values?.NDTimeOff, hourhlyRate);
    const NDRegTimeOff = RegularDayAndTimeOffND(
      values?.NDRegTimeOff,
      hourhlyRate
    );
    const NDSpeTimeOff = SpecialNonWorkingAndTimeOffND(
      values?.NDSpeTimeOff,
      hourhlyRate
    );

    totalRegularPay =
      HRNormalDay +
      HRRegHoliday +
      HRSpeNonWorking +
      HRTimeOff +
      HRRegTimeOff +
      HRSpeTimeOff;

    totalOTPay =
      OTNormalDay +
      OTRegHoliday +
      OTSpeNonWorking +
      OTTimeOff +
      OTRegTimeOff +
      OTSpeTimeOff;

    totalNDPay =
      NDNormalDay +
      NDRegHoliday +
      NDSpeNonWorking +
      NDTimeOff +
      NDRegTimeOff +
      NDSpeTimeOff;

    const basicSalary = this.view.state.userInfo.monthlyRate / 2;

    const total =
      basicSalary +
      totalRegularPay +
      totalOTPay +
      totalNDPay +
      this.getCARequest() +
      (values?.allowance || 0) +
      // this.getAdjustments() +
      parseFloat(this.getAdjustments().toFixed(0));

    return {
      total: total,
      totalRegularPay,
      totalNDPay,
      totalOTPay,
    };
  }

  getTotalRate(value, rate, date, type, day) {
    const { employeeType } = this.view.state.userInfo;
    const holidays = this.view.state.holidays;

    const getTypeOfDay = () => {
      let holidayType = "Regular Day";
      const isHoliday = holidays.find(
        (holiday) =>
          new Date(holiday?.date)?.getDate() === date?.getDate() &&
          new Date(holiday?.date)?.getMonth() === date?.getMonth()
      );
      if (isHoliday) {
        if (isHoliday.type.name.includes("Regular")) {
          holidayType = "Regular Holiday";
        } else if (isHoliday.type.name.includes("Special")) {
          holidayType = "Special Non-working Holiday";
        }
      }

      return holidayType;
    };

    console.log(type, getTypeOfDay(), day);

    let totalRate = 0;

    switch (type) {
      case "HR":
        switch (getTypeOfDay()) {
          case "Regular Day":
            // if (employeeType === "On call") {
            //   totalRate = RegularDayBasic(value, rate);
            // } else {
            //   totalRate =
            //     day === "Sunday"
            //       ? TimeOffBasic(value, rate)
            //       : RegularDayBasic(value, rate);
            // }
            // totalRate =
            //   day === "Sunday"
            //     ? TimeOffBasic(value, rate)
            //     : RegularDayBasic(value, rate);
            if (employeeType === "On call") {
              totalRate = RegularDayBasic(value, rate);
            } else {
              totalRate =
                day === "Sunday"
                  ? RegularDayAndTimeOffBasic(value, rate)
                  : RegularDayBasic(value, rate);
            }
            break;
          case "Regular Holiday":
            // if (employeeType === "On call") {
            //   totalRate =
            //     day === "Sunday"
            //       ? RegularDayAndTimeOffBasic(value, rate)
            //       : RegularHolidayBasic(value, rate);
            // } else {
            //   const hoursWorked = Math.min(value || 0, 8);
            // if (employeeType === "On call") {
            //   totalRate =
            //     day === "Sunday"
            //       ? TimeOffBasic(value, rate)
            //       : RegularDayBasic(value, rate);
            // } else {
            //   const hoursWorked = Math.min(value || 0, 8);

            //   totalRate =
            //     day === "Sunday"
            //       ? RegularDayAndTimeOffBasic(hoursWorked, rate)
            //       : RegularDayBasic(hoursWorked, rate);
            //   if (value > 8) {
            //     totalRate +=
            //       day === "Sunday"
            //         ? RegularDayAndTimeOffBasic(value - 8, rate)
            //         : RegularHolidayBasic(value - 8, rate);
            //   }

            //   this.view.setState((prev) => ({
            //     ...prev,
            //     regularHolidayPayToSave: totalRate,
            //   }));
            // }
            if (employeeType === "On call") {
              totalRate =
                day === "Sunday"
                  ? RegularDayAndTimeOffBasicOnCall(value, rate)
                  : RegularHolidayBasic(value, rate);
            } else {
              const hoursWorked = Math.min(value || 0, 8);

              totalRate =
                day === "Sunday"
                  ? RegularDayAndTimeOffBasic(hoursWorked, rate)
                  : RegularDayBasic(hoursWorked, rate);
              if (value > 8) {
                totalRate +=
                  day === "Sunday"
                    ? RegularDayAndTimeOffBasic(value - 8, rate)
                    : RegularHolidayBasic(value - 8, rate);
              }
              this.view.setState((prev) => ({
                ...prev,
                regularHolidayPayToSave: totalRate,
              }));
            }
            break;
          case "Special Non-working Holiday":
            if (employeeType === "On call") {
              // totalRate = RegularDayBasic(value, rate);
              // totalRate =
              //   day === "Sunday"
              //     ? RegularDayBasic(value, rate)
              //     : SpecialNonWorkingBasic(value, rate);
              // totalRate = RegularDayBasic(value, rate);
              // totalRate =
              //   day === "Sunday"
              //     ? SpecialNonWorkingAndTimeOffBasicOnCall(value, rate)
              //     : RegularDayBasic(value, rate);
              totalRate = RegularDayBasic(value, rate);
            } else {
              totalRate =
                day === "Sunday"
                  ? // SpecialNonWorkingAndTimeOffBasic(value, rate)
                    SpecialNonWorkingAndTimeOffBasic(value, rate)
                  : SpecialNonWorkingBasic(value, rate);
            }
            break;
          default:
            return;
        }
        break;
      case "OT":
        switch (getTypeOfDay()) {
          case "Regular Day":
            // totalRate =
            //   day === "Sunday"
            //     ? TimeOffOT(value, rate)
            //     : RegularDayOT(value, rate);
            if (employeeType === "On call") {
              totalRate = RegularDayOT(value, rate);
            } else {
              totalRate =
                day === "Sunday"
                  ? TimeOffOT(value, rate)
                  : RegularDayOT(value, rate);
            }
            break;
          case "Regular Holiday":
            // totalRate =
            //   day === "Sunday"
            //     ? RegularDayAndTimeOffOT(value, rate)
            //     : RegularHolidayOT(value, rate);
            if (employeeType === "On call") {
              totalRate =
                day === "Sunday"
                  ? RegularDayAndTimeOffOT(value, rate)
                  : RegularHolidayOT(value, rate);
            } else {
              totalRate =
                day === "Sunday"
                  ? RegularDayAndTimeOffOT(value, rate)
                  : RegularHolidayOT(value, rate);
            }
            break;
          case "Special Non-working Holiday":
            // totalRate =
            //   day === "Sunday"
            //     ? SpecialNonWorkingAndTimeOffOT(value, rate)
            //     : SpecialNonWorkingOT(value, rate);
            if (employeeType === "On call") {
              // totalRate =
              //   day === "Sunday0"
              //     ? RegularDayOT(value, rate)
              //     : SpecialNonWorkingOT(value, rate);
              // totalRate = RegularDayOT(value, rate);
              // totalRate =
              //   day === "Sunday"
              //     ? SpecialNonWorkingAndTimeOffOTOnCall(value, rate)
              //     : RegularDayOT(value, rate);
              totalRate = RegularDayOT(value, rate);
            } else {
              totalRate =
                day === "Sunday"
                  ? SpecialNonWorkingAndTimeOffOT(value, rate)
                  : SpecialNonWorkingOT(value, rate);
            }
            break;
          default:
            return;
        }
        break;
      case "ND":
        switch (getTypeOfDay()) {
          case "Regular Day":
            // totalRate =
            //   day === "Sunday"
            //     ? TimeOffND(value, rate)
            //     : RegularDayND(value, rate);
            if (employeeType === "On call") {
              totalRate = RegularDayND(value, rate);
            } else {
              totalRate =
                day === "Sunday"
                  ? TimeOffND(value, rate)
                  : RegularDayND(value, rate);
            }
            break;
          case "Regular Holiday":
            // totalRate =
            //   day === "Sunday"
            //     ? RegularDayAndTimeOffND(value, rate)
            //     : RegularHolidayND(value, rate);
            if (employeeType === "On call") {
              totalRate =
                day === "Sunday"
                  ? RegularDayAndTimeOffND(value, rate)
                  : // : RegularDayND(value, rate);
                    RegularHolidayND(value, rate);
            } else {
              totalRate =
                day === "Sunday"
                  ? RegularDayAndTimeOffND(value, rate)
                  : RegularHolidayND(value, rate);
            }
            break;
          case "Special Non-working Holiday":
            // totalRate =
            //   day === "Sunday"
            //     ? SpecialNonWorkingAndTimeOffND(value, rate)
            //     : SpecialNonWorkingND(value, rate);
            if (employeeType === "On call") {
              // totalRate =
              //   day === "Sunday"
              //     ? RegularDayND(value, rate)
              //     : SpecialNonWorkingND(value, rate);
              // totalRate = RegularDayND(value, rate);
              // totalRate =
              //   day === "Sunday"
              //     ? SpecialNonWorkingAndTimeOffNDOnCall(value, rate)
              //     : RegularDayND(value, rate);
              totalRate = RegularDayND(value, rate);
            } else {
              totalRate =
                day === "Sunday"
                  ? SpecialNonWorkingAndTimeOffND(value, rate)
                  : SpecialNonWorkingND(value, rate);
            }
            break;
          default:
            return;
        }
        break;
      default:
        return;
    }

    return totalRate;
  }

  getWeeklySubtotal() {
    const values = this.view.state.employeeIncreasesAndDeductions;
    console.log("VALUES", values);
    console.log("INCREASES", this.view.state.increases);
    const regularHolidayPay = this.view.state.regularHolidayPay;
    const holidayDays = this.view.state.holidayDays;
    const { employeeType } = this.view.state.userInfo;

    console.log("PAYYYY", regularHolidayPay);

    const isHoliday = (day) => {
      let holi = false;

      if (holidayDays.includes(day)) {
        holi = true;
      }

      return holi ? regularHolidayPay / holidayDays.length || 0 : 0;
    };

    const totalRegularPay =
      (this.view.state.increases?.HRMonday || 0) +
      (this.view.state.increases?.HRTuesday || 0) +
      (this.view.state.increases?.HRWednesday || 0) +
      (this.view.state.increases?.HRThursday || 0) +
      (this.view.state.increases?.HRFriday || 0) +
      (this.view.state.increases?.HRSaturday || 0) +
      (this.view.state.increases?.HRSunday || 0);

    const totalOTPay =
      (this.view.state.increases?.OTMonday || 0) +
      (this.view.state.increases?.OTTuesday || 0) +
      (this.view.state.increases?.OTWednesday || 0) +
      (this.view.state.increases?.OTThursday || 0) +
      (this.view.state.increases?.OTFriday || 0) +
      (this.view.state.increases?.OTSaturday || 0) +
      (this.view.state.increases?.OTSunday || 0);

    const totalNDPay =
      (this.view.state.increases?.NDMonday || 0) +
      (this.view.state.increases?.NDTuesday || 0) +
      (this.view.state.increases?.NDWednesday || 0) +
      (this.view.state.increases?.NDThursday || 0) +
      (this.view.state.increases?.NDFriday || 0) +
      (this.view.state.increases?.NDSaturday || 0) +
      (this.view.state.increases?.NDSunday || 0);

    const mondayTotal =
      (this.view.state.increases?.HRMonday || 0) +
      (this.view.state.increases?.OTMonday || 0) +
      // (this.view.state.increases?.NDMonday || 0);
      (this.view.state.increases?.NDMonday || 0) +
      isHoliday("Monday");

    const tuesdayTotal =
      (this.view.state.increases?.HRTuesday || 0) +
      (this.view.state.increases?.OTTuesday || 0) +
      // (this.view.state.increases?.NDTuesday || 0);
      (this.view.state.increases?.NDTuesday || 0) +
      isHoliday("Tuesday");

    const wednesdayTotal =
      (this.view.state.increases?.HRWednesday || 0) +
      (this.view.state.increases?.OTWednesday || 0) +
      // (this.view.state.increases?.NDWednesday || 0);
      (this.view.state.increases?.NDWednesday || 0) +
      isHoliday("Wednesday");

    const thursdayTotal =
      (this.view.state.increases?.HRThursday || 0) +
      (this.view.state.increases?.OTThursday || 0) +
      // (this.view.state.increases?.NDThursday || 0);
      (this.view.state.increases?.NDThursday || 0) +
      isHoliday("Thursday");

    const fridayTotal =
      (this.view.state.increases?.HRFriday || 0) +
      (this.view.state.increases?.OTFriday || 0) +
      // (this.view.state.increases?.NDFriday || 0);
      (this.view.state.increases?.NDFriday || 0) +
      isHoliday("Friday");

    const saturdayTotal =
      (this.view.state.increases?.HRSaturday || 0) +
      (this.view.state.increases?.OTSaturday || 0) +
      // (this.view.state.increases?.NDSaturday || 0);
      (this.view.state.increases?.NDSaturday || 0) +
      isHoliday("Saturday");

    const sundayTotal =
      (this.view.state.increases?.HRSunday || 0) +
      (this.view.state.increases?.OTSunday || 0) +
      // (this.view.state.increases?.NDSunday || 0);
      (this.view.state.increases?.NDSunday || 0) +
      isHoliday("Sunday");

    console.log(totalRegularPay, totalOTPay, totalNDPay);

    const regularHolidayCount =
      // this.countRegularHolidays() * (this.getHourlyRate() * 8 || 0);
      employeeType === "On call"
        ? 0
        : this.countRegularHolidays() * (this.getHourlyRate() * 8 || 0);

    const total = (
      totalRegularPay +
      totalOTPay +
      totalNDPay +
      this.getCARequest() +
      (values?.allowance || 0) +
      this.getAdjustments() +
      regularHolidayCount
    ).toFixed(2);

    console.log("yun tots", total);

    return {
      total: parseFloat(total),
      totalRegularPay,
      totalNDPay,
      totalOTPay,
      mondayTotal,
      tuesdayTotal,
      wednesdayTotal,
      thursdayTotal,
      fridayTotal,
      saturdayTotal,
      sundayTotal,
    };
  }

  computeWeeklySubtotal(date, field) {
    const hourhlyRate = this.getHourlyRate();
    const values = this.view.state.employeeIncreasesAndDeductions;
    const currentIncreasesObject = this.view.state.increases;

    if (field === "HRMonday") {
      const newObject = {
        ...currentIncreasesObject,
        HRMonday: this.getTotalRate(values?.HRMonday, hourhlyRate, date, "HR"),
      };

      this.view.setIncreasesObject(newObject);
    }

    if (field === "HRTuesday") {
      const newObject = {
        ...currentIncreasesObject,
        HRTuesday: this.getTotalRate(
          values?.HRTuesday,
          hourhlyRate,
          date,
          "HR"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "HRWednesday") {
      const newObject = {
        ...currentIncreasesObject,
        HRWednesday: this.getTotalRate(
          values?.HRWednesday,
          hourhlyRate,
          date,
          "HR"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "HRThursday") {
      const newObject = {
        ...currentIncreasesObject,
        HRThursday: this.getTotalRate(
          values?.HRThursday,
          hourhlyRate,
          date,
          "HR"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "HRFriday") {
      const newObject = {
        ...currentIncreasesObject,
        HRFriday: this.getTotalRate(values?.HRFriday, hourhlyRate, date, "HR"),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "HRSaturday") {
      const newObject = {
        ...currentIncreasesObject,
        HRSaturday: this.getTotalRate(
          values?.HRSaturday,
          hourhlyRate,
          date,
          "HR"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "HRSunday") {
      const newObject = {
        ...currentIncreasesObject,
        HRSunday: this.getTotalRate(
          values?.HRSunday,
          hourhlyRate,
          date,
          "HR",
          "Sunday"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "OTMonday") {
      const newObject = {
        ...currentIncreasesObject,
        OTMonday: this.getTotalRate(values?.OTMonday, hourhlyRate, date, "OT"),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "OTTuesday") {
      const newObject = {
        ...currentIncreasesObject,
        OTTuesday: this.getTotalRate(
          values?.OTTuesday,
          hourhlyRate,
          date,
          "OT"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "OTWednesday") {
      const newObject = {
        ...currentIncreasesObject,
        OTWednesday: this.getTotalRate(
          values?.OTWednesday,
          hourhlyRate,
          date,
          "OT"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "OTThursday") {
      const newObject = {
        ...currentIncreasesObject,
        OTThursday: this.getTotalRate(
          values?.OTThursday,
          hourhlyRate,
          date,
          "OT"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "OTFriday") {
      const newObject = {
        ...currentIncreasesObject,
        OTFriday: this.getTotalRate(values?.OTFriday, hourhlyRate, date, "OT"),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "OTSaturday") {
      const newObject = {
        ...currentIncreasesObject,
        OTSaturday: this.getTotalRate(
          values?.OTSaturday,
          hourhlyRate,
          date,
          "OT"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "OTSunday") {
      const newObject = {
        ...currentIncreasesObject,
        OTSunday: this.getTotalRate(
          values?.OTSunday,
          hourhlyRate,
          date,
          "OT",
          "Sunday"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "NDMonday") {
      const newObject = {
        ...currentIncreasesObject,
        NDMonday: this.getTotalRate(values?.NDMonday, hourhlyRate, date, "ND"),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "NDTuesday") {
      const newObject = {
        ...currentIncreasesObject,
        NDTuesday: this.getTotalRate(
          values?.NDTuesday,
          hourhlyRate,
          date,
          "ND"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "NDWednesday") {
      const newObject = {
        ...currentIncreasesObject,
        NDWednesday: this.getTotalRate(
          values?.NDWednesday,
          hourhlyRate,
          date,
          "ND"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "NDThursday") {
      const newObject = {
        ...currentIncreasesObject,
        NDThursday: this.getTotalRate(
          values?.NDThursday,
          hourhlyRate,
          date,
          "ND"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "NDFriday") {
      const newObject = {
        ...currentIncreasesObject,
        NDFriday: this.getTotalRate(values?.NDFriday, hourhlyRate, date, "ND"),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "NDSaturday") {
      const newObject = {
        ...currentIncreasesObject,
        NDSaturday: this.getTotalRate(
          values?.NDSaturday,
          hourhlyRate,
          date,
          "ND"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }

    if (field === "NDSunday") {
      const newObject = {
        ...currentIncreasesObject,
        NDSunday: this.getTotalRate(
          values?.NDSunday,
          hourhlyRate,
          date,
          "ND",
          "Sunday"
        ),
      };
      this.view.setIncreasesObject(newObject);
    }
  }

  getDaysWorked() {
    const values = this.view.state.employeeIncreasesAndDeductions;
    // const payrollType = this.view.state.object.type;

    // // SEMI MONTHLY
    // // const HRNormalDay = this.getTotalHoursDeducted();
    // const HRNormalDay = values?.HRNormalDay || 0;
    // const HRRegHoliday = values?.HRRegHoliday || 0;
    // const HRSpeNonWorking = values?.HRSpeNonWorking || 0;
    // const HRTimeOff = values?.HRTimeOff || 0;
    // const HRRegTimeOff = values?.HRRegTimeOff || 0;
    // const HRSpeTimeOff = values?.HRSpeTimeOff || 0;
    // const OTNormalDay = values?.OTNormalDay || 0;
    // const OTRegHoliday = values?.OTRegHoliday || 0;
    // const OTSpeNonWorking = values?.OTSpeNonWorking || 0;
    // const OTTimeOff = values?.OTTimeOff || 0;
    // const OTRegTimeOff = values?.OTRegTimeOff || 0;
    // const OTSpeTimeOff = values?.OTSpeTimeOff || 0;
    // const NDNormalDay = values?.NDNormalDay || 0;
    // const NDRegHoliday = values?.NDRegHoliday || 0;
    // const NDSpeNonWorking = values?.NDSpeNonWorking || 0;
    // const NDTimeOff = values?.NDTimeOff || 0;
    // const NDRegTimeOff = values?.NDRegTimeOff || 0;
    // const NDSpeTimeOff = values?.NDSpeTimeOff || 0;

    // // WEEKLY
    // const HRMonday = values?.HRMonday || 0;
    // const HRTuesday = values?.HRTuesday || 0;
    // const HRWednesday = values?.HRWednesday || 0;
    // const HRThursday = values?.HRThursday || 0;
    // const HRFriday = values?.HRFriday || 0;
    // const HRSaturday = values?.HRSaturday || 0;
    // const HRSunday = values?.OTSunday || 0;

    // const totalWeeklyHours =
    //   HRMonday +
    //   HRTuesday +
    //   HRWednesday +
    //   HRThursday +
    //   HRFriday +
    //   HRSaturday +
    //   HRSunday;

    // const totalHours =
    //   HRNormalDay +
    //   HRRegHoliday +
    //   HRSpeNonWorking +
    //   HRTimeOff +
    //   HRRegTimeOff +
    //   HRSpeTimeOff +
    //   OTNormalDay +
    //   OTRegHoliday +
    //   OTSpeNonWorking +
    //   OTTimeOff +
    //   OTRegTimeOff +
    //   OTSpeTimeOff +
    //   NDNormalDay +
    //   NDRegHoliday +
    //   NDSpeNonWorking +
    //   NDTimeOff +
    //   NDRegTimeOff +
    //   NDSpeTimeOff;

    // return this.computeWorkDayByHours(
    //   payrollType === "Weekly" ? totalWeeklyHours : totalHours
    // );
    const days = [
      values?.HRMonday,
      values?.HRTuesday,
      values?.HRWednesday,
      values?.HRThursday,
      values?.HRFriday,
      values?.HRSaturday,
      values?.HRSunday,
    ];

    const totalDays = days.reduce((acc, hours) => {
      return acc + (hours > 0 ? 1 : 0);
    }, 0);

    return totalDays;
  }

  computeWeeklyTotals() {
    this.view.setState((prev) => {
      return {
        ...prev,
        employeeIncreasesAndDeductions: {
          ...prev.employeeIncreasesAndDeductions,
          deductions: this.getTotalDeductions(),
          daysWorked: this.getDaysWorked(),
          mondayTotal: this.getWeeklySubtotal()?.mondayTotal,
          tuesdayTotal: this.getWeeklySubtotal()?.tuesdayTotal,
          wednesdayTotal: this.getWeeklySubtotal()?.wednesdayTotal,
          thursdayTotal: this.getWeeklySubtotal()?.thursdayTotal,
          fridayTotal: this.getWeeklySubtotal()?.fridayTotal,
          saturdayTotal: this.getWeeklySubtotal()?.saturdayTotal,
          sundayTotal: this.getWeeklySubtotal()?.sundayTotal,
          subtotal: this.getWeeklySubtotal()?.total,
          total: this.getWeeklySubtotal()?.total - this.getTotalDeductions(),
          totalRegularPay: this.getWeeklySubtotal()?.totalRegularPay,
          totalNDPay: this.getWeeklySubtotal()?.totalNDPay,
          totalOTPay: this.getWeeklySubtotal()?.totalOTPay,
          caRequestAmount: this.getCARequest(),
        },
      };
    });
  }

  computeTotals() {
    this.view.setState((prev) => {
      return {
        ...prev,
        employeeIncreasesAndDeductions: {
          ...prev.employeeIncreasesAndDeductions,
          deductions: this.getTotalDeductions(),
          subtotal: this.getSubTotal()?.total,
          daysWorked: this.getDaysWorked(),
          total: this.getSubTotal()?.total - this.getTotalDeductions(),
          totalRegularPay: this.getSubTotal()?.totalRegularPay,
          totalNDPay: this.getSubTotal()?.totalNDPay,
          totalOTPay: this.getSubTotal()?.totalOTPay,
        },
      };
    });
  }
}

export default PayrollComputations;
