import React from "react";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import { restUseCase } from "../../usecases/rest";
import InputFactory from "../../components/InputFactory";
import CashAdvanceFormPresenter from "./CashAdvanceFormPresenter";
import { dialog } from "nq-component";
import ConfirmDialogContent from "./components/confirmDialog/ConfirmDialogContent";
import classNames from "../../classNames";

class CashAdvanceFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      filtered: [],
      deduction: "",
    };
    this.presenter = new CashAdvanceFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "cash_advance";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  onChangeFilter(value) {
    this.presenter.onChangeFilter(value);
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:hrAdmin", "role:masterAdmin"],
      write: [user.id, "role:hrAdmin", "role:masterAdmin"],
    };
  }

  onDeductionChange(deduction) {
    this.setState({ deduction });
  }

  async onClickConfirm() {
    const { object, deduction } = this.state;
    dialog.fire({
      html: (
        <ConfirmDialogContent
          // cashAmount={object.amounts}
          // onConfirm={async () => {
          onConfirm={async (deduction) => {
            this.setState({ deduction });
            dialog.close();
            await this.presenter.submit();
            this.navigateTo("/cash-advance");
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  render() {
    const { object, deduction } = this.state;
    const role = this.getCurrentRoles();
    const user = this.getCurrentUser();
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />

        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label} Cash Advance
              </h1>

              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3 py-4">
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <InputFactory
                        type="String"
                        field="description"
                        object={object}
                        placeholder="Enter Description"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                    {user.roles.some(
                      (role) =>
                        role.name === "masterAdmin" || role.name === "hrAdmin"
                    ) && (
                      <div className="mb-3">
                        <label className="form-label">Employee</label>
                        <InputFactory
                          type="Pointer"
                          target="users"
                          dynamic={false}
                          field="name"
                          object={object}
                          placeholder="Select Employee"
                          onChange={this.onChange.bind(this)}
                          required={false}
                        />
                      </div>
                    )}
                    <div className="mt-3 mb-3">
                      <label className="form-label">Amount</label>
                      <InputFactory
                        type="String"
                        _type="parseNumber"
                        field="amounts"
                        object={object}
                        placeholder="Enter Amount"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <button
                      type="button"
                      className="btn  fs-sm me-3"
                      style={{ backgroundColor: "#EBBD2F" }}
                      onClick={this.onClickConfirm.bind(this)}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      CANCEL
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CashAdvanceFormPage);
