import React, { useState } from "react";
import { InfiniteScroll, InputJson } from "nq-component";
import { getObjectUseCase, upsertUseCase } from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory from "../../components/FormFactory";
import { restUseCase } from "../../usecases/rest";
import ClientFormPresenter from "./ClientFormPresenter";
import CliTable from "../../components/CliTable";
import InputFactory from "../../components/InputFactory";
import Drop from "../../components/DropdownFilter";
import ClientProjectList from "./ClientTabList";

class ClientFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = { object: {}, advanced: false, sameAddress: false };
    this.presenter = new ClientFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase()
    );
  }

  getCollectionName() {
    return "clients";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  render() {
    const object = this.state.object;
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";

    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="p-3 p-lg-4">
          <ul className="nav nav-tabs mb-3 border-0">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="general-tab"
                data-bs-toggle="tab"
                href="#general"
                role="tab"
                aria-controls="general"
                aria-selected="true"
              >
                General
              </a>
            </li>
            {this.getObjectId() && (
              <>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="projects-tab"
                    data-bs-toggle="tab"
                    href="#projects"
                    role="tab"
                    aria-controls="projects"
                    aria-selected="false"
                    onClick={() => this.forceUpdate()}
                  >
                    Projects
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="invoices-tab"
                    data-bs-toggle="tab"
                    href="#invoices"
                    role="tab"
                    aria-controls="invoices"
                    aria-selected="false"
                  >
                    Invoices
                  </a>
                </li>
              </>
            )}
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="general"
              role="tabpanel"
              aria-labelledby="general-tab"
            >
              <div className="overflow-auto">
                <div className="h-100">
                  <div className="p-3 p-lg-4">
                    <h1 className="fw-bold mt-3 text-capitalize">
                      {label + (schema.label || this.getCollectionName())}
                    </h1>
                  </div>
                  <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                    <form onSubmit={this.onSubmitForm.bind(this)}>
                      <div className="row g-3">
                        {advanced ? (
                          <InputJson
                            defaultValue={object}
                            onChange={this.onChangeObject.bind(this)}
                            rows="15"
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="row g-3">
                        <div className="col-lg-4">
                          <div className="mt-3 mb-3">
                            <label className="form-label">Client Name</label>
                            <InputFactory
                              type="String"
                              field="name"
                              object={object}
                              placeholder="Enter Client Name"
                              onChange={this.onChange.bind(this)}
                              required={true}
                            />
                            <div className="mt-3 mb-3">
                              <label className="form-label">Company Name</label>
                              <InputFactory
                                type="String"
                                field="company_name"
                                object={object}
                                placeholder="Enter Company Name"
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </div>
                            <div className="mt-3 mb-3">
                              <label className="form-label">Email</label>
                              <InputFactory
                                type="String"
                                field="email"
                                object={object}
                                placeholder="Enter Email Address"
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </div>
                            <div className="mt-3 mb-3">
                              <label className="form-label">Address</label>
                              <InputFactory
                                type="String"
                                field="address"
                                object={object}
                                placeholder="Enter Address"
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </div>
                            <div className="mt-3 mb-3">
                              <label className="form-label">
                                Telephone Number
                              </label>
                              <InputFactory
                                type="Number"
                                field="telephone_number"
                                object={object}
                                placeholder="Enter Telephone Number"
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </div>
                            <div className="mt-3 mb-3">
                              <label className="form-label">
                                Mobile Number
                              </label>
                              <InputFactory
                                type="Number"
                                field="mobile_number"
                                object={object}
                                placeholder="Enter Mobile Number"
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </div>
                            <div className="mt-3 mb-3">
                              <label className="form-label">Amount</label>
                              <InputFactory
                                type="String"
                                _type="parseNumber"
                                field="amounts"
                                object={object}
                                placeholder="Enter Amount"
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </div>
                            <div className="mt-3 mb-3">
                              <label className="form-label">Fax</label>
                              <InputFactory
                                type="String"
                                field="fax"
                                object={object}
                                placeholder="Enter Fax"
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </div>
                            <div className="mt-3 mb-3">
                              <label className="form-label">Other</label>
                              <InputFactory
                                type="String"
                                field="other"
                                object={object}
                                placeholder="Enter Other Details.."
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </div>
                            <div className="mt-3 mb-3">
                              <label className="form-label">Website</label>
                              <InputFactory
                                type="String"
                                field="website"
                                object={object}
                                placeholder="Enter Website"
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mt-3 mb-3">
                            <label className="form-label">
                              Billing Address
                            </label>
                            <InputFactory
                              type="DynamicRelated"
                              dynamic={false}
                              target="client_billing_address"
                              field="billing_address"
                              object={object}
                              placeholder="Enter Billing Address"
                              onChange={this.onChange.bind(this)}
                              required={true}
                            />
                          </div>
                          <div className="mt-3 mb-3">
                            <label className="form-label">
                              Client Billing Address same as Shipping Address?
                            </label>
                            <InputFactory
                              type="Boolean"
                              field="is_same"
                              object={object}
                              onChange={this.onChange.bind(this)}
                              required={false}
                            />
                          </div>
                        </div>
                        {!object.is_same && (
                          <div className="col-lg-4">
                            <div className="mt-3 mb-3">
                              <label className="form-label">
                                Shipping Address
                              </label>
                              <InputFactory
                                type="DynamicRelated"
                                dynamic={false}
                                target="client_shipping_address"
                                field="shipping_address"
                                object={object}
                                placeholder="Enter Shipping Address"
                                onChange={this.onChange.bind(this)}
                                required={!object.is_same}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary fs-sm me-3"
                        >
                          <i className="bi bi-file-earmark-check me-2"></i>SAVE
                        </button>
                        <button
                          type="button"
                          className="btn btn-light fs-sm"
                          onClick={this.onClickBack.bind(this)}
                        >
                          GO BACK
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {this.getObjectId() && (
              <>
                <div
                  className="tab-pane fade"
                  id="projects"
                  role="tabpanel"
                  aria-labelledby="projects-tab"
                >
                  <ClientProjectList
                    key={object.id}
                    clientId={object.id}
                    tabCollection="projects"
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="invoices"
                  role="tabpanel"
                  aria-labelledby="invoices-tab"
                >
                  <ClientProjectList
                    key={object.id}
                    clientId={object.id}
                    tabCollection="invoices_final"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ClientFormPage);
