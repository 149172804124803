import React from "react";
import classNames from "../../../../classNames";

const MarkPaidDialogContent = ({ onMarkPaid, onCancel, object }) => (
  <div className="text-end">
    <div className="text-center">
      <i
        className={classNames("bi bi-file-earmark-excel", "text-warning")}
        style={{ fontSize: "5rem" }}
      ></i>
      <h4 className="fw-bold">Pay Cash Advance</h4>
      <p className="m-0">
        This will add <span className="text-danger">₱{object.amounts}</span> to{" "}
        <span className="text-warning">{object.name.fullName}</span>. Continue?
      </p>
      <button
        className="btn mb-2 mt-2 me-2"
        style={{ backgroundColor: "#EBBD2F" }}
        onClick={onMarkPaid}
      >
        Continue
      </button>
      <button className="btn mb-2 mt-2 btn-light" onClick={onCancel}>
        Cancel
      </button>
    </div>
  </div>
);

export default MarkPaidDialogContent;
