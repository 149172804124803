import { dialog } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import { findObjectUseCase } from "../../usecases/object";

class ExpenseFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async save() {
    const collection = this.view.getCollectionName();
    const gmdFundsObject = await findObjectUseCase().execute("gmd_accounts");

    if (this.object.id) {
      this.change.id = this.object.id;
      console.log("asas", this.change.id);
    } else {
      this.change.acl = this.view.getAcl();

      const gmdFundObject = gmdFundsObject.find(
        (fund) => fund.name === this.change.accounts.name
      );

      const updatedBalance = gmdFundObject.balance - this.view.state.totalItems;
      if (updatedBalance < 0) {
        throw new Error("Insufficient Balance");
      }

      gmdFundObject.balance = updatedBalance;

      const originalObject = {
        note: this.change.remarkss,
        amount: this.view.state.totalItems,
        types: {
          type: "Money Out",
        },

        account: {
          name: "GMD Revolving Fund",
        },

        account_balance: gmdFundObject.balance,
      };

      // Example mapping of account names to their IDs
      const accountIds = {
        BPI: "880add74-8663-4378-a05f-cfb84aaefd2d",
        "BDO Main": "846e6ea9-e9af-434d-9a09-d950a53ed74a",
        "GMD Revolving Fund": "79f9b3c7-84b1-4a95-9530-efd31953ef72",
        Metrobank: "641c215e-c184-45ea-81ca-1f704b009a9f",
        "GMD Fund": "d0210284-95ec-4b35-a9af-4dc5bdef3983",
        "Management money": "ae8d75c4-756e-4630-8357-cac7db7a3c83",
        // ... add other accounts as needed
      };

      // Function to get account ID by name
      function getAccountId(accountName) {
        return accountIds[accountName] || null; // returns null if the account name is not found
      }

      // Usage
      const accountName = this.change.accounts.name; // Get the account name dynamically
      const accountId = getAccountId(accountName); // Get the corresponding account ID

      // Storing in obAccount
      const obAccount = {
        id: accountId, // This will be the ID corresponding to the account name
        name: accountName,
      };

      // Now obAccount contains both the ID and the name of the account

      const obTypes = {
        id: "f3a5c4d2-cbd0-4305-9835-5c8c75de59c4",
        type: "Money Out",
      };

      const desiredObject = {
        note: originalObject.note,
        account: obAccount,
        account_balance: originalObject.account_balance,
        types: obTypes,
        amounts: originalObject.amount.toString(),
        project_name: this.view.state.selectedProject,
      };
      console.log("amm", this.object);
      console.log("damm", this.change);
      console.log("agagad", this.view.state.selectedProject);

      await this.upsertUseCase.execute("gmd_accounts", gmdFundObject);
      const trans = await this.upsertUseCase.execute(
        "transaction",
        desiredObject
      );
    }

    // this.change.project_name = this.object.project_name?.name
    //this.change.chart_accounts = this.object.chart_accounts
    this.change.project_name =
      this.view.state.selectedProject || this.object.project_name;
    this.change.amount = this.view.state.totalItems;

    try {
      const response = await this.upsertUseCase.execute(
        collection,
        this.change
      );
      this.change = {};

      window.location.reload();

      this.view.navigateBack();
    } catch (error) {
      throw error;
    }
  }
}

export default ExpenseFormPresenter;
