import { browseFile } from "nq";
import BaseListPresenter from "../../../base/BaseListPresenter";
import Papa from "papaparse";
import {
  findObjectUseCase,
  updateObjectUseCase,
} from "../../../usecases/object";

class TransactionPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filter2 = {};
    this.filter3 = {};
    this.filter10 = {};
    this.filterDate = {};
    this.filterList = {};
    this.filterEmployee = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const bankNameFromURL = urlSearchParams.get("bank");
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: bankNameFromURL ? 10000 : this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filter2,
        ...this.filterDate,
        ...this.filter3,
        ...this.filter10,
        ...this.filterList,
        ...(bankNameFromURL ? { account: { name: bankNameFromURL } } : {}),
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  filterSubmit2(where) {
    this.reset();
    this.filter2 = where;

    this.getObjects();
  }

  filterSubmit3(where) {
    this.reset();
    this.filter3 = where;
    this.getObjects();
  }

  filterSubmit10(where) {
    this.reset();
    //console.log("mio", where)
    this.filter10 = where;

    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeEmployee(where) {
    this.filterEmployee = where;
    this.getObjects();
  }

  onClickAdd() {
    // const collection = this.view.getCollectionName();
    // this.view.navigateTo("/form/expenses/" + collection);
    this.view.navigateTo("/accounts/new-transaction");
  }

  onClickExport = async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const bankNameFromURL = urlSearchParams.get("bank");
      const specified = bankNameFromURL ? bankNameFromURL + "-" : "";
      const selected = this.state.selected;

      const collection = "transaction";
      // const query = {
      //   include: ["all"],
      // };
      const objectCSV = await this.findObjectUseCase.execute(collection);
      console.warn("objectto", objectCSV);
      const csvData = objectCSV.map((transaction) => ({
        Date: transaction.createdAt,
        Account: transaction.account?.name,
        Note: transaction.note,
        Type: transaction.types?.type,
        Amount: transaction.amounts,
        AccountBalance: transaction.account_balance,
        FundSource: transaction.fund_source?.name
          ? transaction.fund_source?.name
          : transaction.account.name || "n.a",
      }));

      const csvString = this.convertToCSV(csvData);
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = specified + collection + ".csv";
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting to CSV:", error);
    }
  };

  onClickImport(file) {
    this.view.showProgress();
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const csvText = e.target.result;
        const data = this.parseCSV(csvText);
        // this.saveObjects(data);
      } catch (error) {
        console.error("Error processing the CSV file:", error);
        alert("An error occurred while reading the CSV file.");
      }
    };
    reader.readAsText(file);
  }

  createCSVQuery() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const bankNameFromURL = urlSearchParams.get("bank");
    const skip = this.current - 1;
    const query = {
      skip: skip,
      where: {
        ...(bankNameFromURL ? { account: { name: bankNameFromURL } } : {}),
      },
      include: "all",
    };
    return query;
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += `"${array[i][index]}"`;
      }

      str += line + "\r\n";
    }

    return str;
  }

  filterSubmit6(where) {
    this.reset();
    this.filter6 = where;
    this.getObjects();
  }
  exportToCSV = async (role) => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const bankNameFromURL = urlSearchParams.get("bank");
      const specified = bankNameFromURL ? bankNameFromURL + "-" : "";
      const collection = "transaction";
      const selected = this.view.state.selected;
      const query = {
        include: ["all"],
      };
      const objectCSV = await this.findObjectUseCase.execute(collection, query);
      console.log("waha", objectCSV);
      const selectedId = selected ? selected.map((item) => item.id) : [];

      const csvData =
        selectedId.length > 0
          ? objectCSV
              .filter((transaction) => selectedId.includes(transaction.id))
              .map((transaction) => ({
                Date: transaction.createdAt,
                Account: transaction.account?.name,
                Note: transaction.note || "n/a",
                Type: transaction.type,
                Amount: transaction.amounts,
                AccountBalance: role.includes("masterAdmin")
                  ? transaction.account_balance
                  : "****",
                FundSource: transaction.fund_source?.name
                  ? transaction.fund_source?.name
                  : transaction.account.name || "n.a",
              }))
          : objectCSV.map((transaction) => ({
              Date: transaction.createdAt,
              Account: transaction.account?.name,
              Note: transaction.note || "n/a",
              Type: transaction.type,
              Amount: transaction.amounts,
              AccountBalance: role.includes("masterAdmin")
                ? transaction.account_balance
                : "****",
              FundSource: transaction.fund_source?.name
                ? transaction.fund_source?.name
                : transaction.account.name || "n.a",
            }));
      console.log(csvData);
      const csvString = this.convertToCSV(csvData);
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = specified.trim() + collection + ".csv";
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting to CSV:", error);
    }
  };

  onClick() {
    this.view.navigateTo("/accounts");
  }

  async onClickDeleteSelected() {
    const selected = this.view.getSelected();
    const collection = this.view.getCollectionName();
    const gmdAccounts = await findObjectUseCase().execute("gmd_accounts");

    const updatedGMDAccount = gmdAccounts.map((item) => {
      const selectedItem = selected.find(
        (selected) => selected.account.name === item.name
      );

      if (selectedItem) {
        return {
          ...item,
          balance:
            selectedItem.types.type === "Money In"
              ? item.balance - parseFloat(selectedItem.amounts, 10)
              : item.balance + parseFloat(selectedItem.amounts, 10),
        };
      } else {
        return item;
      }
    });

    console.log("Current: ", gmdAccounts);
    console.log("Updated: ", updatedGMDAccount);

    try {
      await this.view.showDialog({
        title: "Delete Data?",
        message: "Are you sure you want to delete?",
      });
      for (const obj of selected) {
        await this.deleteObjectUseCase.execute(collection, obj.id);
        const index = this.objects.indexOf(obj);
        this.objects.splice(index, 1);
        this.view.setObjects(this.objects);
      }

      for (const obj of updatedGMDAccount) {
        await this.upsertUseCase.execute("gmd_accounts", obj);
      }

      this.view.setSelected([]);
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }

  onSelect(index) {
    const selectedObjects = this.view.getSelected();
    const selected = this.view.state.objects[index];
    console.log("sssss", selected);

    const i = selectedObjects.indexOf(selected);
    console.log("i", i);
    if (i > -1) {
      selectedObjects.splice(i, 1);
    } else {
      selectedObjects.push(selected);
    }
    console.log("sssssssssssß", selectedObjects);
    this.view.setSelected(selectedObjects);
  }

  loadMore() {
    if (!this.progress) {
      this.current++;
      console.log("current", this.current++);
      this.findObjects();
    }
  }
}

export default TransactionPresenter;
