import React from "react";
import OutputFactory from "../../../components/OutputFactory";

export default function EditCollapseView({
  payroll,
  object,
  fields,
  includedFields,
  onClickItem,
  toggleModal,
}) {
  return (
    <div className="row gap-4 ps-4 pe-4">
      <div className="col gap-5">
        {includedFields.map((field) => {
          return (
            <div key={field} className="row gap-2">
              <p
                className="p-0 m-0 col-auto fw-medium"
                style={{ fontSize: "0.875rem" }}
              >
                {fields[field]?.label}:
              </p>
              <p
                className="p-0 m-0 col-auto fw-normal"
                style={{ fontSize: "0.875rem" }}
              >
                <OutputFactory
                  type={fields[field].type}
                  field={field}
                  object={object}
                />
              </p>
            </div>
          );
        })}
        <div className="row mt-3 gap-2">
          <div className="col-auto p-0 d-flex gap-2">
            {/* {payroll.status === "Draft" ? ( */}
            <button
              className="btn ps-4 pe-4 fw-medium"
              style={{
                background: "#DAB44E",
                height: "2.75rem",
                color: "#434343",
              }}
              onClick={() =>
                onClickItem(
                  payroll.id,
                  object.id,
                  payroll.startDate,
                  payroll.endDate,
                  payroll.type
                )
              }
            >
              <i className="bi bi-pencil-fill me-2" />
              Edit
            </button>
            {/* ) : null} */}
            <button
              className="btn ps-4 pe-4 fw-medium"
              style={{
                background: "#cf6f00",
                height: "2.75rem",
                color: "white",
              }}
              onClick={() =>
                toggleModal(
                  payroll.id,
                  object.id,
                  payroll.startDate,
                  payroll.endDate,
                  object.fullName,
                  payroll.type
                )
              }
            >
              <i className="bi bi-pencil-fill me-2" />
              Preview
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
