// MarkUnpaidDialogContent.js
import React from "react";
import classNames from "../../../classNames";

const MarkUnpaidDialogContent = ({ onUnpaid, onCancel }) => (
  <div className="text-end">
    <div className="text-center">
      <i
        className={classNames("bi bi-file-earmark-dollar", "text-danger")}
        style={{ fontSize: "5rem" }}
      ></i>
      <h4 className="fw-bold">Mark as Unpaid</h4>
      <p className="m-0">Are you sure you want to mark this as unpaid?</p>
      <button
        className="btn mb-2 mt-2 me-2"
        style={{ backgroundColor: "#CF6F00" }}
        onClick={onUnpaid}
      >
        Mark as Unpaid
      </button>
      <button className="btn mb-2 mt-2 btn-light" onClick={onCancel}>
        Cancel
      </button>
    </div>
  </div>
);

export default MarkUnpaidDialogContent;
