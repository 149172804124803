export default function OvertimeApproved() {
  return (
    <div className="col">
      <div className="row">
        <p
          className="fw-semibold"
          style={{ fontSize: "1.3rem", color: "#DAB44E" }}
        >
          Overtime Request Approved
        </p>
        <p className="" style={{ fontSize: "1rem" }}>
          Overtime request has been successfully approved.
        </p>
      </div>
    </div>
  );
}
