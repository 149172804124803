export const ActionButton = ({
  onClick,
  className,
  style,
  iconClass,
  label,
}) => (
  <button onClick={onClick} className={className} style={style}>
    {iconClass && <i className={iconClass}></i>} {label}
  </button>
);
