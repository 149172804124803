import { InfiniteScroll } from "nq-component";
import BaseListPage from "../../../base/BaseListPage";
import NavBar from "../../../components/navbar";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import withRouter from "../../../withRouter";
import PayrollCard from "../components/PayrollCard";
import StatusButton from "../components/StatusButton";
import EditPayrollPresenter from "./EditPayrollPresenter";
import CliTable from "../../../components/CliTable";
import EditCollapseView from "../components/EditCollapseView";
import createPromise from "../../../createPromise";
import SelectAccountsModal from "./components/modals/SelectAccountsModal";
import dialog from "../../../components/Modal/dialog";
import toast, { Toaster } from "react-hot-toast";
import ViewPayslipModal from "./components/modals/ViewPayslipModal";

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(amount);
};

class EditPayrollPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new EditPayrollPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      roles: [],
      objects: [],
      payroll: {},
      selected: [],
      selectedPreview: {},
      holidays: [],
      leaveRequestInfo: [],
      progress: true,
      modalOpen: false,
      total: 0,
      count: 0,
      object: {},
    };
    this.fieldsToDisplay = ["fullName", "position"];
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return "users";
  }

  onClickBack() {
    this.presenter.onClickBack();
  }

  onClickItem(payrollId, userId, startDate, endDate, payrollType) {
    this.presenter.onClickItem(
      payrollId,
      userId,
      startDate,
      endDate,
      payrollType
    );
  }

  onClickEdit(payRollId) {
    this.presenter.onClickEdit(payRollId);
  }

  setPayrollObject(payroll) {
    this.setState({ payroll });
  }

  setLeaveRequestObject(leaveRequestInfo) {
    this.setState({ leaveRequestInfo });
  }

  async onStatusClick(status, payrollId, payrollDate) {
    this.presenter.onStatusClick(status, payrollId, payrollDate);
  }

  onDaysWorkedChange(days, userId) {
    this.presenter.onDaysWorkedChange(days, userId);
  }

  onPayrollChange(value, field) {
    this.presenter.onPayrollChange(value, field);
  }

  showToast(message, type = "success") {
    toast(message, {
      position: "bottom-right",
      style: {
        background: type === "error" ? "#BB1C1C" : "#4FBC4C",
        color: "white",
        fontWeight: "600",
      },
    });
  }

  formatTableFields(fields) {
    return {
      ...fields,
      subtotal: {
        type: "Number",
        label: "Subtotal",
      },
      deductions: {
        type: "Number",
        label: "Deduction",
      },
      total: {
        type: "Number",
        label: "Total",
      },
    };
  }

  onCollapse(index, object) {
    const schema = this.getSchema(this.getCollectionName());

    return (
      <EditCollapseView
        object={object}
        fields={this.formatTableFields(schema.fields)}
        includedFields={this.fieldsToDisplay}
        onClickItem={this.onClickItem.bind(this)}
        payroll={this.state.payroll}
        toggleModal={this.toggleModal.bind(this)}
      />
    );
  }

  toggleModal(payrollId, userId, startDate, endDate, fullName, payrollType) {
    if (this.state.modalOpen) {
      this.setState({ modalOpen: false });
    } else {
      const set = async () => {
        const [employeePayrollInfo] = await findObjectUseCase().execute(
          "employee_payroll_info",
          {
            where: {
              userId,
              payrollId,
            },
          }
        );

        const employeePayroll = this.state.objects.find(
          (payroll) => payroll.userId === userId
        );

        const [user] = await findObjectUseCase().execute("users", {
          where: { id: userId },
        });

        this.setState({
          selectedPreview: {
            userId,
            payrollId,
            startDate,
            endDate,
            fullName,
            employeePayrollInfo,
            payrollType,
            basicSalary: user.monthlyRate / 2 || 0,
            subtotal: employeePayroll.subtotal,
          },
          modalOpen: true,
        });
      };

      set();
    }
  }

  showAccountsSelectionModal() {
    const schema = this.getSchema("payroll");
    const promise = createPromise();
    dialog.fire({
      html: (
        <SelectAccountsModal
          object={this.state.payroll}
          schema={schema}
          onChange={this.onPayrollChange.bind(this)}
        />
      ),
      positiveButton: "Submit",
      negativeButton: "Cancel",
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
    });
    return promise;
  }

  render() {
    // const { objects, selected, progress, count, payroll, holidays } =
    //   this.state;
    const {
      objects,
      selected,
      progress,
      count,
      payroll,
      holidays,
      selectedPreview,
    } = this.state;
    const schema = this.getSchema(this.getCollectionName());

    console.log("payroll", payroll);
    console.log("objects", objects);

    return (
      <div className="h-100" style={{ background: "white" }}>
        <NavBar />
        <Toaster />
        <ViewPayslipModal
          // selectedPreview={this.state.selectedPreview}
          selectedPreview={selectedPreview}
          isOpen={this.state.modalOpen}
          toggleModal={this.toggleModal.bind(this)}
          holidays={holidays}
        />
        <div
          className="container-fluid"
          style={{
            paddingInline: "3rem",
            paddingBlock: "2rem",
            background: "white",
          }}
        >
          <div className="row align-items-center">
            <i
              class="bi bi-chevron-left col-auto btn btn-link"
              onClick={this.onClickBack.bind(this)}
            ></i>
            <p className="fs-3 fw-bold col-auto m-0">Edit Payroll</p>
          </div>
          <div className="row pt-4">
            <div className="col-6">
              <PayrollCard
                onClickItem={this.onClickEdit.bind(this)}
                payroll={payroll}
                progress={progress}
              />
            </div>
            <div className="col d-flex justify-content-end align-items-start">
              <StatusButton
                status={payroll?.status}
                onStatusClick={this.onStatusClick.bind(this)}
                payrollId={payroll?.id}
                payrollDate={payroll?.createdAt}
              />
            </div>
            <div className="d-flex justify-content-end align-items-start">
              <span>
                Total: {formatCurrency(this.presenter.overallTotal || 0)}
              </span>
              {/* <span>Total: {payroll?.type || 0}</span> */}
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <InfiniteScroll
              loadMore={this.loadMore.bind(this)}
              hasMore={!progress && count > objects.length}
            >
              <CliTable
                fields={this.formatTableFields(schema.fields)}
                objects={objects}
                collapsable={payroll.status !== "Paid"}
                tableHeadBackground="#DAB44E"
                shadow={false}
                striped={false}
                selected={selected}
                progress={progress}
                onCollapse={this.onCollapse.bind(this)}
                excludeFields={Object.keys(schema.fields).filter(
                  (key) => !this.fieldsToDisplay.includes(key)
                )}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditPayrollPage);
