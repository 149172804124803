import React from "react";
import Checkbox from "../Checkbox";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

function Body({
  objects,
  fields,
  excludeFields,
  selected,
  selectable,
  user,
  onSelect,
  onClick,
  onClickItem,
}) {
  return objects.map((object, index) => {
    const id = object.id || object._id;
    const checked = selected.includes(object);
    return (
      <div key={id} className="card">
        <div className="card-body mb-0 p-2">
          <h4 className="card-title d-flex align-items-center fs-6 mb-0">
            <div className="fs-6 mb-0">
              {object.name} -
              <span
                className="mb-0"
                style={{ fontSize: "13px", color: "#333333BF" }}
              >
                {" "}
                {formatDate(object.startDate)} - {formatDate(object.endDate)}
              </span>
            </div>
          </h4>

          <p className="card-text fs-6 mb-0">{object.location}</p>
          <p className="card-text fs-6 mb-0">
            {object.description && object.description.length > 100
              ? `${object.description.slice(0, 25)}...`
              : object.description}
          </p>
          <p className="card-text fs-6 mb-0">
            Status: {object.statuses ? object.statuses : "In Progress"}
          </p>
        </div>
      </div>
    );
  });
}

export default Body;
