// MarkPaidDialogContent.js
import React from "react";
import classNames from "../../../classNames";

const MarkPaidDialogContent = ({ onPaid, onCancel, user, creds }) => (
  <div className="text-end">
    <div className="text-center">
      <i
        className={classNames("bi bi-file-earmark-dollar", "text-success")}
        style={{ fontSize: "5rem" }}
      ></i>
      <h4 className="fw-bold">Mark as Paid</h4>
      <p className="m-0">Are you sure you want to mark this as paid?</p>
      <p className="m-0">
        {user[0].fullName} currently has {user[0].credits}
      </p>
      <p className="m-0">
        Marking it paid will use {creds.creditsDay} credits.
      </p>

      <button
        className="btn mb-2 mt-2 me-2"
        style={{ backgroundColor: "#D8AE3F" }}
        onClick={onPaid}
      >
        Mark as Paid
      </button>
      <button className="btn mb-2 mt-2 btn-light" onClick={onCancel}>
        Cancel
      </button>
    </div>
  </div>
);

export default MarkPaidDialogContent;
