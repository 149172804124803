import React from "react";
import InputFactory from "../../../components/InputFactory";
import DateRange from "../../../components/DateRange";

export default function Filters({
  schema,
  keys,
  onChangeFilter,
  onChangeDate,
}) {
  return keys.map((key) => {
    const { type, ...options } = schema.filters[key];

    return (
      <div
        className={`${key === "status" ? "col-auto" : "col-3"} ps-1 pe-1`}
        key={key}
      >
        {type === "Date" ? (
          <DateRange onChange={onChangeDate} field="date" />
        ) : (
          <InputFactory
            type={type}
            field={key}
            onChange={(value, field) => onChangeFilter(type, value, field)}
            className="text-light-emphasis"
            {...options}
          />
        )}
      </div>
    );
  });
}
