import InputFactory from "../../../components/InputFactory";

export default function CreatePayrollForm({
  fields,
  excludedFields,
  onClickBack,
  onChange,
  object,
}) {
  return (
    <div
      className="w-100 p-4 mt-3 rounded"
      style={{ border: "1px solid #EFEFEF" }}
    >
      <div className="col-4 mt-2 p-0">
        {Object.keys(fields).map((field) => {
          const { type, ...options } = fields[field];

          if (excludedFields.includes(field)) return null;
          if (object.type === "Weekly" && field === "endDate") return null;

          return (
            <div className="row m-0 p-0 mb-4" key={field}>
              <label
                class="form-label fw-semibold"
                style={{ fontSize: "0.875rem", color: "#434343" }}
              >
                {options.label}
                {options.required || options._required ? (
                  <span className="text-danger ms-1">*</span>
                ) : null}
              </label>
              <div className="col">
                <InputFactory
                  field={field}
                  key={field}
                  object={object}
                  type={type}
                  onChange={onChange}
                  {...options}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="row mt-5 m-0 gap-3">
        <div className="col-auto p-0">
          <button
            onClick={onClickBack}
            type="button"
            className="btn btn-light fw-semibold"
            style={{ width: "8rem", height: "2.75rem" }}
          >
            CANCEL
          </button>
        </div>
        <div className="col-auto p-0">
          <button
            type="submit"
            className="btn fw-semibold"
            style={{ background: "#D8AE3F", width: "8rem", height: "2.75rem" }}
          >
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  );
}
