import React from "react";
import getIndexes from "../../../../getIndexes";

const defaultProps = {
  schemas: [],
};

function OutputHiddenPointer({
  field,
  fields,
  object,
  target,
  schemas,
  indexes,
}) {
  const schema = schemas.find((s) => s.collection === target) || {};
  const _fields = fields || schema.fields || {};

  const _indexes = React.useMemo(() => {
    const items = getIndexes(_fields);
    return items.length > 0 ? items : ["name", "fullName"];
  }, [_fields]);

  const pointer = object[field];
  if (pointer) {
    const modifiedValues = _indexes.map((index) => {
      const value = pointer[index];
      if (value) {
        return value.replace(/\([^()]*\)/g, "").trim();
      }
      return "";
    });
    return modifiedValues.join(" ");
  }
  return null;
}

OutputHiddenPointer.defaultProps = defaultProps;
export default OutputHiddenPointer;
