const defaultProps = {
    keys: []
};

function getColorBasedOnValue(value) {
    switch (value) {
        case "Disapproved":
            return "#B20001";
        case "Partially Paid":
            return "#87CEEB";
        case "Done":
            return "#3FC65C";
        case "Pending":
            return "#E1E35D";
        case "Liquidation":
            return "#9747FF";
        case "Paid":
            return "#CF6F00";
        case "Approved":
            return "#3FC65C"
        default:
            return "inherit";
    }
}
const formatCurrency = (pending_balance) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
    }).format(pending_balance);
  };

function OutputString({ field, object, keys }) {
    const value = object[field];
    console.log("value", field)
    const color = getColorBasedOnValue(value);

    if (value || value === 0) {
        if (field && field === 'pending_balance' || field === 'totalAmount' || field === "amount") {
          return <span style={{ color }}>{formatCurrency(value)}</span>;
        } else {
          return <span style={{ color }}>{value}</span>;
        }
      } else {
        return <span style={{ color }}>{formatCurrency(value)}</span>; 
      }

    
}

OutputString.defaultProps = defaultProps;

export default OutputString;
