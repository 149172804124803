import React from "react";
import InputFactory from "../../../components/InputFactory";

export default function TimeSheetDeductions({
  onIncreasesAndDeductionsChange,
  object,
}) {
  const getTotalHours = () => {
    return (
      (object?.absences || 0) + (object?.lates || 0) + (object?.undertime || 0)
    );
  };

  return (
    <>
      <p
        className="fw-semibold mt-5"
        style={{ fontSize: "1.25rem", color: "#434343" }}
      >
        Time Sheet Deductions (In hrs)
      </p>
      <div
        className="rounded position-relative m-0"
        style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)", width: "60%" }}
      >
        <div
          className="row p-3 ps-4 pe-4 m-0 rounded-top "
          style={{ background: "#DAB44E" }}
        >
          <p
            className="p-0 m-0 fw-semibold "
            style={{ color: "#434343", fontSize: ".75rem" }}
          >
            Total Hours: {getTotalHours()}
          </p>
        </div>
        <div className="row p-3 m-0">
          <div className="col d-flex  align-items-center ">
            <p
              className="p-0 m-0 fw-semibold "
              style={{ color: "#434343", fontSize: ".75rem" }}
            >
              Absences
            </p>
          </div>
          <div className="col-9">
            <InputFactory
              type="Number"
              field="absences"
              object={object}
              onChange={onIncreasesAndDeductionsChange}
            />
          </div>
        </div>
        <div className="row p-3 pt-1 pb-1 m-0">
          <div className="col d-flex  align-items-center ">
            <p
              className="p-0 m-0 fw-semibold "
              style={{ color: "#434343", fontSize: ".75rem" }}
            >
              Lates
            </p>
          </div>
          <div className="col-9">
            <InputFactory
              type="Number"
              field="lates"
              object={object}
              onChange={onIncreasesAndDeductionsChange}
            />
          </div>
        </div>
        <div className="row p-3 m-0">
          <div className="col d-flex  align-items-center ">
            <p
              className="p-0 m-0 fw-semibold "
              style={{ color: "#434343", fontSize: ".75rem" }}
            >
              Undertime
            </p>
          </div>
          <div className="col-9">
            <InputFactory
              type="Number"
              field="undertime"
              object={object}
              onChange={onIncreasesAndDeductionsChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
