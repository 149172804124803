import React from "react";

export default function ProfitLossSummary() {
  const style = {
    conatainer: {},
    title: {
      fontSize: "2rem",
      textAlign: "center",
      fontWeight: 100,
      marginBottom: ".8rem",
    },
    subtitle: { textAlign: "center", fontWeight: "bold" },
    date: {
      textAlign: "center",
      marginBottom: "2rem",
      fontSize: ".8rem",
      fontWeight: "200",
    },
    total: {
      textAlign: "end",
      fontWeight: "bold",
      paddingRight: "10px",
      paddingTop: "8px",
      paddingBottom: "5px",
    },
    dataRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontWeight: 100,
      color: "#606060",
    },
    secondPart: { marginLeft: "8px" },
    secondPartContent: { marginLeft: "40px" },
    thirdPart: { marginLeft: "18px" },
    thirdPartContent: { marginLeft: "50px" },
    hrline: { margin: 0 },
  };
  return (
    <div
      className="mt-4 flex justify-content-center align-items-center "
      style={{ height: "vh-100 " }}
    >
      <div
        style={{
          padding: "5mm",
          fontFamily: "Montserrat, sans-serif",

          width: "60rem",
          background: "#fff",
        }}
      >
        <div style={style.title}>GMD PRODUCTIONS INC</div>
        <div style={style.subtitle}>Quarterly Profit and Loss Summary</div>
        <div style={style.date}>As of November 22, 2023</div>
        <hr
          style={{
            ...style.hrline,
            height: "1px",
            background: "black",
            opacity: 100,
          }}
        />
        <div style={style.total}>TOTAL</div>
        <hr style={{ ...style.hrline, height: "2px", background: "#505050" }} />
        <div style={style.dataRow}>
          <span>
            <i class="bi bi-caret-down-fill"></i> Assets
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPart}>
            <i class="bi bi-caret-down-fill"></i> Current Assets
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.thirdPart}>
            <i class="bi bi-caret-down-fill"></i> Cast and cash equivalents
          </span>
          <span>1,234,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.thirdPartContent}>Cash in BDO</span>
          <span>12,234,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.thirdPartContent}>Cash in BPI</span>
          <span>2,234,345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={style.dataRow}>
          <span style={style.thirdPartContent}>
            Total Cash and cash equivalents
          </span>
          <span>16,234,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Loans to Others</span>
          <span>-234,345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}>
          <span style={style.secondPartContent}>Total Current Assets</span>
          <span>PHP16,234,345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}>
          <span>Total Assets</span>
          <span>PHP16,234,345.23</span>
        </div>
        <hr style={{ ...style.hrline, marginBottom: "2px" }} />
        <hr style={style.hrline} />

        <div style={style.dataRow}>
          <span>
            <i class="bi bi-caret-down-fill"></i> Liabilities and shareholder's
            equity
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPart}>
            <i class="bi bi-caret-down-fill"></i> Current liabilities:
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Payroll Clearing</span>
          <span>-1,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>VAT Controll</span>
          <span>1,234,345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={style.dataRow}>
          <span
            style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}
          >
            Total current liabilities
          </span>
          <span>PHP1,234,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPart}>
            <i class="bi bi-caret-down-fill"></i> Non-current liabilities
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Long-term dept</span>
          <span>-34,345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={style.dataRow}>
          <span
            style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}
          >
            Total non-current liabilities
          </span>
          <span>PHP-34,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPart}>
            <i class="bi bi-caret-down-fill"></i> Shareholders' equity:
          </span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>NET income</span>
          <span>15,344,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Dividend disbursed</span>
          <span>-116,345.23</span>
        </div>
        <div style={style.dataRow}>
          <span style={style.secondPartContent}>Retained Earnings</span>
          <span>11,450.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={style.dataRow}>
          <span
            style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}
          >
            Total shareholders' equity
          </span>
          <span>PHP15,456,345.23</span>
        </div>
        <hr style={style.hrline} />
        <div style={{ ...style.dataRow, fontWeight: "bold", color: "#505050" }}>
          <span>Total liabilities and equity</span>
          <span>PHP16,234,345.23</span>
        </div>
        <hr style={style.hrline} />
        <div
          style={{
            textAlign: "center",
            fontSize: ".8rem",
            marginTop: "2rem",
            color: "#606060",
          }}
        >
          Cash basis Wednesday, November 22, 2023 02:56 PM GMD+08:00
        </div>
      </div>
    </div>
  );
}
