import React from "react";
import { InputFactory as Factory } from "nq-component";
import { findObjectUseCase, saveObjectUseCase } from "../../usecases/object";
import { saveFileUseCase, saveImageUseCase } from "../../usecases/file";
import Context from "../../AppContext";
import InputSelect from "../InputSelect";
import UserInputPointer from "../InputPointer";
import ModdedInputPointer from "../ModdedInputPointer";
import AccountsInputPointer from "../AccountsInputPointer";
import Parse from "../Parse";
import DynamicRelatedFactory from "../DynamicRelatedFactory";
import InputImage from "../inputImage";
import InputPointer from "../InputPointer";
import InputPeso from "../InputPeso";
import ModdedInputServices from "../ModdedInputServices";
import InputNumber from "../InputNumber";
import RelatedFactory from "../RelatedFactory";
import InputString from "../InputString";
import InputDateTime from "../InputDateTime";
import InputArea from "../InputArea";

const defaultProps = {};

function InputFactory({
  type,
  _type,
  field,
  object,
  customChange,
  schemas,
  onChange,
  required,
  _required,
  ...props
}) {
  const context = React.useContext(Context);
  const value = object && object[field];

  function _onChange(field, value) {
    if (object) {
      object[field] = value;
    }

    if (customChange && Object.keys(customChange).length > 0) {
      onChange(value, field, customChange.date);
    } else {
      onChange(value, field);
    }
  }

  switch (_type || type) {
    case "Related":
      return (
        <RelatedFactory
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          field={field}
          {...props}
        />
      );
    case "Enums":
      return (
        <InputSelect
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          placeholder={
            props.placeholder ||
            (props.dynamic ? "Select of type " : "Select ") +
              (props.label || field)
          }
          {...props}
        />
      );
    case "String1":
      return (
        <InputString
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          {...props}
        />
      );
    case "TextArea":
      return (
        <InputArea
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          {...props}
        />
      );
    case "UserInputPointer":
      return (
        <UserInputPointer
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          findObject={findObjectUseCase()}
          //options={props.options}
          {...props}
        />
      );
    case "UserInputPointers":
      return (
        <UserInputPointer
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          findObject={findObjectUseCase()}
          options={props.options}
          {...props}
        />
      );

    // case "Pointer":
    //   return (
    //     <InputPointer
    //       defaultValue={value}
    //       onChange={_onChange.bind(this, field)}
    //       schema={
    //         props.schema ||
    //         context.schemas.find((s) => s.collection === props.target)
    //       }
    //       findObject={findObjectUseCase()}
    //       schemas={context.schemas}
    //       {...props}
    //     />
    //   );
    //   break;

    case "AccountsInputPointer":
      return (
        <AccountsInputPointer
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          findObject={findObjectUseCase()}
          {...props}
        />
      );
    case "DynamicRelated":
      return (
        <DynamicRelatedFactory
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          field={field}
          {...props}
        />
      );
    case "ModdedInputPointer":
      return (
        <ModdedInputPointer
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          findObject={findObjectUseCase()}
          saveObject={saveObjectUseCase()}
          saveImage={saveImageUseCase()}
          saveFile={saveFileUseCase()}
          {...props}
        />
      );

    case "DateTime": {
      return (
        <InputDateTime
          defaultValue={value && value.slice(0, 10)}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          required={_required || required}
          {...props}
        />
      );
    }

    case "ModdedInputService":
      return (
        <ModdedInputServices
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          findObject={findObjectUseCase()}
          saveObject={saveObjectUseCase()}
          saveImage={saveImageUseCase()}
          saveFile={saveFileUseCase()}
          {...props}
        />
      );

    case "UserImage":
      return (
        <InputImage
          value={value}
          saveImage={saveImageUseCase()}
          onChange={_onChange.bind(this, field)}
          {...props}
        />
      );

    case "Pointer":
      const schema =
        props.schema ||
        context.schemas.find((s) => s.collection === props.target);

      if (!schema) return null;
      return (
        <InputPointer
          defaultValue={value}
          type={type.toLowerCase()}
          field={field}
          onChange={_onChange.bind(this, field)}
          schema={schema}
          findObject={findObjectUseCase()}
          required={_required || required}
          {...props}
        />
      );

    case "Enum":
      return (
        <InputSelect
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          placeholder={
            props.placeholder ||
            (props.dynamic ? "Select of type " : "Select ") +
              (props.label || field)
          }
          required={_required || required}
          {...props}
        />
      );

    case "Peso":
      return (
        <InputPeso
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          required={_required || required}
          {...props}
        />
      );

    case "Number":
    case "Tel":
      return (
        <InputNumber
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          required={_required || required}
          {...props}
        />
      );

    case "parseNumber":
      return (
        // <InputStrings
        //   defaultValue={value}
        //   onChange={_onChange.bind(this, field)}
        //   type={type.toLowerCase()}
        //   {...props}
        // />
        // <input type="text" />
        // <InputSelect
        //   defaultValue={value}
        //   onChange={_onChange.bind(this, field)}
        //   type={type.toLowerCase()}
        //   placeholder={
        //     props.placeholder ||
        //     (props.dynamic ? "Select of type " : "Select ") +
        //       (props.label || field)
        //   }
        //   {...props}
        // />
        <Parse
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          {...props}
        />
      );

    default:
      return (
        <Factory
          type={type}
          _type={_type}
          field={field}
          object={object}
          schemas={context.schemas}
          onChange={onChange}
          findObject={findObjectUseCase()}
          saveObject={saveObjectUseCase()}
          saveImage={saveImageUseCase()}
          saveFile={saveFileUseCase()}
          {...props}
        />
      );
  }
}

InputFactory.defaultProps = defaultProps;
export default InputFactory;
