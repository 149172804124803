import React from "react";
import { InputJson } from "nq-component";
import { findObjectUseCase, getObjectUseCase, upsertUseCase } from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory from "../../components/FormFactory";
import { restUseCase } from "../../usecases/rest";
import Drop from "../../components/DropdownFilter";
import InputFactory from "../../components/InputFactory";
import PayablesFormPresenter from "./PayablesFormPresenter";
import InputFactory2 from "../../components/InputFactory2";

class PayablesFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = { object: {}, advanced: false, totalItems: 0, filteredProjects: [], selectedProject: null,
    filtered: []};
    this.presenter = new PayablesFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase(),
    );
  }

  getCollectionName() {
    return "payables";
  }
  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }
  onChangeFilter(value) {
    this.presenter.onChangeFilter(value);
  }


  async onChange(value, field) {
    if (field === "client_name") {
      const clientName = value.id;
      const proj = await findObjectUseCase().execute("projects");
      console.warn("proj", proj);
      const filteredProjects = proj
        .filter((project) => project.client?.id === clientName)
        .map((project) => ({
          name: project.name,
          id: project.id,
        }));
      this.setState({ filtered: filteredProjects, selectedProject: filteredProjects.length > 0 ? filteredProjects[0] : null });
    } else if (field === "project_name" && !value) {
      this.setState({ filtered: [], selectedProject: null });
    } else if (Array.isArray(value)) {
      this.setState({ itemsAmount: value });
    }

    this.presenter.onChange(value, field);
  }
  handleProjectChange = (value) => {
    const selectedProject = this.state.filtered.find((project) => project.id === value);
    this.setState({ selectedProject: selectedProject || null });
  };

  render() {
    const { object, totalItems,selectedProject } = this.state;
    console.warn("totalitems",totalItems)
    const user = this.getCurrentUser();
    const currentDate = this.getCurrentDateFormatted();
    const schema = this.getSchema(this.getCollectionName());

    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    let totalQuantity = 0;
    let totalAmount = 0;
    let totalItemsValue = 0; // This will hold the total value of all items

    if (object.items && object.items.length > 0) {
      object.items.forEach((item) => {
        const quantity = item.quantity > 0 ? item.quantity : 0;
        const amount =
          Number(item.amounts?.replace(/,/g, "")) > 0
            ? Number(item.amounts?.replace(/,/g, ""))
            : 0;

        totalQuantity += quantity;
        totalAmount += amount;

        // Calculate the total value for this item and add it to the totalItemsValue
        totalItemsValue += quantity * amount;
      });
      // console.log("Total Amount", totalAmount); 
      console.warn(object)
    }

    // Set a default value of 1 for totalQuantity if no items are present
    totalQuantity = totalQuantity > 0 ? totalQuantity : 1;

    // The totalItemsValue already holds the correct total value
    if (totalItemsValue !== totalItems) {
      this.setState({ totalItems: totalItemsValue });
    }

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label + (schema.label || this.getCollectionName())}
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    <div className="mb-3">
                      <label className="form-label">Client Name</label>
                      <InputFactory
                        type="UserInputPointer"
                        dynamic={false}
                        target="clients"
                        field="client_name"
                        object={object}
                        placeholder="Select Client Name"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                    {this.state.filtered.length > 0 || selectedProject || object.project_name?.name ? (
                          <>
                            <label className="form-label">Project Name</label>
                            <Drop
                              placeholder={selectedProject ? "Select a project" : object.project_name?.name}
                              value={selectedProject ? selectedProject : object.project_name?.name}
                              options={this.state.filtered}
                              onChange={this.handleProjectChange}
                            />
                          </>
                        ) : null}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Vendor</label>
                      <InputFactory
                        type="UserInputPointer"
                        target="vendor"
                        dynamic={false}
                        field="vendor_name"
                        object={object}
                        placeholder="Select Vendor"
                        onChange={this.onChange.bind(this)}
                        required={false}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date</label>
                      <InputFactory
                        type="Date"
                        field="due_date"
                        object={object}
                        placeholder="Add Due Date"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        min={currentDate}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Items</label>
                      <InputFactory2
                        type="Relation"
                        _type="Related"
                        field="items"
                        target="items"
                        object={object}
                        onChange={this.onChange.bind(this)}
                        required={true}
                        excludeFields={
                          "Chart of Accounts"
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Account</label>
                      <InputFactory
                        type="UserInputPointer"
                        dynamic={false}
                        target="gmd_accounts"
                        field="accounts"
                        object={object}
                        placeholder="Select Account Name"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        // excludeFields={["GMD Fund"]}
                        excludeFields={
                          user.roles[0].name === "admin"
                            ? ["Management money"]
                            : ["GMD Fund"]
                        }
                      />
                    </div>
                    <div className="mb-3">
                      {/* <label className="form-label">Chart of Accounts</label>
                      <InputFactory
                        type="UserInputPointer"
                        dynamic={false}
                        target="chart_of_accounts"
                        field="chart_accounts"
                        object={object}
                        placeholder="Select Chart of Accounts"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      /> */}
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label">Remarks</label>
                      <InputFactory
                        type="String"
                        dynamic={false}
                        target="gmd_accounts"
                        field="remarkss"
                        object={object}
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                      
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label">Total: </label>
                      {/* <InputFactory
                        type="Number"
                        disabled
                        value={formatCurrency(totalItem)}
                      /> */}
                      {this.state.totalItems}
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary fs-sm me-3"
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                    
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(PayablesFormPage);
