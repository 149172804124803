import React from "react";
import { InputJson, dialog } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory2 from "../../components/FormFactory2";
import { restUseCase } from "../../usecases/rest";
import InputFactory from "../../components/InputFactory";
import Drop from "../../components/DropdownFilter";
import classNames from "../../classNames";
import LeaveRequestViewPresenter from "./LeaveRequestViewPresenter";
import ApproveDialogContent from "./components/ApproveDialogContent";
import DisapproveDialogContent from "./components/DisapproveDialogContent";
import MarkPaidDialogContent from "./components/MarkPaidDialogContent";
import MarkUnpaidDialogContent from "./components/MarkUnpaidDialogContent";

// const formatDate = (dateString) => {
//   const providedDate = new Date(dateString);
//   providedDate.setHours(0, 0, 0, 0);

//   const options = { year: "numeric", month: "short", day: "numeric" };
//   return providedDate.toLocaleDateString("en-US", options);
// };
const formatDate = (dateString) => {
  const providedDate = new Date(dateString);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return providedDate.toLocaleString("en-US", options);
};

class LeaveRequestView extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      userInfo: {},
      advanced: false,
      selectedProject: null,
      filtered: [],
    };
    this.presenter = new LeaveRequestViewPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "leave_request";
  }

  onClickAdvance() {
    this.setState({
      advanced: !this.state.advanced,
    });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  onClickApprove() {
    dialog.fire({
      html: (
        <ApproveDialogContent
          onApprove={() => {
            this.presenter.onClickStatus("Approved");
            dialog.close();
            this.navigateTo("/leave-request");
            dialog.fire({
              html: (
                <div className="text-center">
                  <i
                    className={classNames(
                      "bi bi-file-earmark-check",
                      "text-success"
                    )}
                    style={{ fontSize: "5rem" }}
                  ></i>
                  <h4 className="fw-bold">Request Approved</h4>
                  <p className="m-0">
                    The request has been approved successfully.
                  </p>
                  <button
                    className="btn btn-primary mb-2 mt-2"
                    onClick={() => dialog.close()}
                  >
                    Confirm
                  </button>
                </div>
              ),
              footer: false,
            });
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  onClickDisapprove() {
    dialog.fire({
      html: (
        <DisapproveDialogContent
          onDisapprove={() => {
            this.presenter.onClickStatus("Disapproved");
            dialog.close();
            this.navigateTo("/leave-request");
            dialog.fire({
              html: (
                <div className="text-center">
                  <i
                    className={classNames(
                      "bi bi-file-earmark-excel",
                      "text-warning"
                    )}
                    style={{ fontSize: "5rem" }}
                  ></i>
                  <h4 className="fw-bold">Request Disapproved</h4>
                  <p className="m-0">The request has been disapproved.</p>
                  <button
                    className="btn btn-primary mb-2 mt-2"
                    onClick={() => dialog.close()}
                  >
                    Confirm
                  </button>
                </div>
              ),
              footer: false,
            });
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  onClickPaid() {
    const query = {
      where: {
        id: this.state.object.name.id,
      },
    };

    findObjectUseCase()
      .execute("users", query)
      .then((user) => {
        console.log("User:", user[0].credits);
        console.log("Credits Day", this.state.object.creditsDay);
        const userCred = user[0].credits.replace(/,/g, "");
        const credDay = this.state.object.creditsDay.replace(/,/g, "");
        const parseUserCred = parseFloat(userCred);
        const parseCredDay = parseFloat(credDay);

        if (parseCredDay > parseUserCred) {
          dialog.fire({
            html: (
              <div className="text-center">
                <h4 className="m-0">Insufficient Credits.</h4>
                <p className="m-0">
                  {user[0].fullName} currently has {user[0].credits} credits.
                </p>
                <p className="m-0">
                  Required credits: {this.state.object.creditsDay}
                </p>
                <button
                  className="btn btn-primary mb-2 mt-2"
                  onClick={() => dialog.close()}
                >
                  Confirm
                </button>
              </div>
            ),
            footer: false,
          });
        } else {
          dialog.fire({
            html: (
              <MarkPaidDialogContent
                onPaid={() => {
                  this.presenter.onClickStatus("Paid");
                  this.presenter.onClickMarkPaid(
                    user[0].credits,
                    this.state.object.creditsDay
                  );
                  dialog.close();
                  this.navigateTo("/leave-request");
                  dialog.fire({
                    html: (
                      <div className="text-center">
                        <i
                          className={classNames(
                            "bi bi-file-earmark-check",
                            "text-success"
                          )}
                          style={{ fontSize: "5rem" }}
                        ></i>
                        <h4 className="fw-bold">Request Marked as Paid</h4>
                        <p className="m-0">
                          The request has been marked as paid successfully.
                        </p>
                        <button
                          className="btn btn-primary mb-2 mt-2"
                          onClick={() => dialog.close()}
                        >
                          Confirm
                        </button>
                      </div>
                    ),
                    footer: false,
                  });
                }}
                onCancel={() => dialog.close()}
                user={user}
                creds={this.state.object}
              />
            ),
            footer: false,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });
  }

  onClickUnpaid() {
    dialog.fire({
      html: (
        <MarkUnpaidDialogContent
          onUnpaid={() => {
            this.presenter.onClickStatus("Unpaid");
            dialog.close();
            this.navigateTo("/leave-request");
            dialog.fire({
              html: (
                <div className="text-center">
                  <i
                    className={classNames(
                      "bi bi-file-earmark-excel",
                      "text-warning"
                    )}
                    style={{ fontSize: "5rem" }}
                  ></i>
                  <h4 className="fw-bold">Request Marked as Unpaid</h4>
                  <p className="m-0">The request has been marked as unpaid.</p>
                  <button
                    className="btn btn-primary mb-2 mt-2"
                    onClick={() => dialog.close()}
                  >
                    Confirm
                  </button>
                </div>
              ),
              footer: false,
            });
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  render() {
    const { object, userInfo } = this.state;
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;

    console.log("ONEFNF", object, userInfo);
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                View Leave Request
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <div className="row g-3">
                  {advanced ? (
                    <InputJson
                      defaultValue={object}
                      onChange={this.onChangeObject.bind(this)}
                      rows="15"
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mt-3 mb-3">
                  <label className="form-label fw-bold">Employee Name:</label>
                  <p>{object.name?.fullName}</p>
                </div>
                <div className="mt-3 mb-3">
                  <label className="form-label fw-bold">Leave Type:</label>
                  <p>{object.type?.name}</p>
                </div>
                <div className="mt-3 mb-3">
                  <label className="form-label fw-bold">Start Date:</label>
                  <p>{formatDate(object.startDate)}</p>
                </div>
                <div className="mt-3 mb-3">
                  <label className="form-label fw-bold">End Date:</label>
                  <p>{formatDate(object.endDate)}</p>
                </div>
                <div className="mt-3 mb-3">
                  <label className="form-label fw-bold">Credits Day:</label>
                  <p>{object.creditsDay}</p>
                </div>
                <div className="mt-3 mb-3">
                  <label className="form-label fw-bold">Date Submitted:</label>
                  <p>{formatDate(object.createdAt)}</p>
                </div>
                <div className="mt-3 mb-3">
                  <label className="form-label fw-bold">Status:</label>
                  <p>{object.statuses}</p>
                </div>
                <div className="mt-3 mb-3">
                  <label className="form-label fw-bold">Description:</label>
                  <p>{object.description}</p>
                </div>
                {object.isHalfday && (
                  <div className="mt-3 mb-3">
                    <label className="form-label fw-bold">Type:</label>
                    {object.isUnpaid === true ? <p>Unpaid</p> : <></>}
                    {object.isHalfday === true ? <p>Halfday</p> : <></>}
                  </div>
                )}

                <div className="mt-4">
                  {object.statuses === "Pending" && (
                    <>
                      <button
                        type="button"
                        className="btn text-white fs-sm me-3"
                        style={{ backgroundColor: "#4aab73", border: 0 }}
                        onClick={this.onClickApprove.bind(this)}
                      >
                        <i className="bi bi-check me-2"></i>Approve
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger fs-sm me-3"
                        onClick={this.onClickDisapprove.bind(this)}
                      >
                        <i className="bi bi-x me-2"></i>Disapprove
                      </button>
                    </>
                  )}
                  {object.statuses === "Approved" && (
                    <>
                      <button
                        type="button"
                        // disabled={
                        //   object.creditsDay > parseFloat(userInfo.credits)
                        // }
                        className="btn fs-sm me-3"
                        style={{ backgroundColor: "#D8AE3F", border: 0 }}
                        onClick={this.onClickPaid.bind(this)}
                      >
                        <i className="bi bi-check me-2"></i>Mark as Paid
                      </button>
                      <button
                        type="button"
                        // disabled={
                        //   object.creditsDay > parseFloat(userInfo.credits)
                        // }
                        className="btn fs-sm me-3"
                        style={{ backgroundColor: "#CF6F00", border: 0 }}
                        onClick={this.onClickUnpaid.bind(this)}
                      >
                        <i className="bi bi-x me-2"></i>Mark as Unpaid
                      </button>
                    </>
                  )}
                  <button
                    type="button"
                    className="btn btn-light fs-sm"
                    onClick={this.onClickBack.bind(this)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LeaveRequestView);
