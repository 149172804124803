export default function DeletePayroll({ selected }) {
  console.log(selected);
  return (
    <div className="col">
      <div className="row">
        <p
          className="fw-semibold"
          style={{ fontSize: "1.3rem", color: "#BB1C1C" }}
        >
          Delete Payroll
        </p>
        <div className="ps-2 pe-2 mb-3 ">
          <div
            className="col p-3 rounded justify-content-center align-align-items-center w-100 gap-2"
            style={{ border: "1px solid #EFEFEF" }}
          >
            {selected.map((item) => {
              return (
                <p
                  className="text-center m-1 p-0"
                  key={item.id}
                  style={{ fontSize: "1rem" }}
                >
                  {item.name}
                  <span className="ms-2" style={{ color: "#D8AE3F" }}>
                    ({item.total || 0})
                  </span>
                </p>
              );
            })}
          </div>
        </div>
        <p className="m-0" style={{ fontSize: ".9rem" }}>
          Are you sure you want to delete this payroll?
        </p>
        <p
          className="opacity-75 m-0"
          style={{ fontSize: ".875rem", color: "#BB1C1C" }}
        >
          This action cannot be undone.
        </p>
      </div>
    </div>
  );
}
