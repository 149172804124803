import React, { useState, useEffect } from "react";

const CustomDateInput = ({ onDateChange, defaultDate, disabled }) => {
  const [date, setDate] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized && defaultDate) {
      setDate(defaultDate);
      onDateChange(defaultDate);
      setInitialized(true);
    }
  }, [defaultDate, initialized, onDateChange]);

  const handleButtonClick = (daysToAdd) => {
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
    const formattedDate = newDate.toISOString().substring(0, 10);
    setDate(formattedDate);
    onDateChange(formattedDate);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value || null;
    setDate(selectedDate);
    onDateChange(selectedDate);
  };

  return (
    <div>
      <input
        type="date"
        value={date || ""}
        style={{ padding: "5px", marginRight: "10px" }}
        required
        onChange={handleDateChange}
        disabled={disabled} // Apply disabled prop to the input element
      />

      <button type="button" className="px-2 py-1 mx-1 border border-dark shadow rounded" style={{ backgroundColor: 'white' }} onClick={() => handleButtonClick(15)} disabled={disabled}>15</button>
      <button type="button" className="px-2 py-1 mx-1 border border-dark shadow rounded" style={{ backgroundColor: 'white' }} onClick={() => handleButtonClick(30)} disabled={disabled}>30</button>
      <button type="button" className="px-2 py-1 mx-1 border border-dark shadow rounded" style={{ backgroundColor: 'white' }} onClick={() => handleButtonClick(45)} disabled={disabled}>45</button>
      <button type="button" className="px-2 py-1 mx-1 border border-dark shadow rounded" style={{ backgroundColor: 'white' }} onClick={() => handleButtonClick(60)} disabled={disabled}>60 Days</button>
    </div>
  );
};

export default CustomDateInput;
