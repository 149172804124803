export default function StatusButton({
  status,
  onStatusClick,
  payrollId,
  payrollDate,
}) {
  const getButtonLabel = () => {
    if (status === "Draft") return "Mark as Approved";
    else if (status === "Approved") return "Mark as Paid";
    return "Download txt";
  };

  const getBackground = () => {
    if (status === "Draft") return "#4FBC4C";
    else if (status === "Approved") return "#CF6F00";
    return "#434343";
  };

  return (
    <button
      className="btn ps-4 pe-4"
      style={{ height: "2.75rem", background: getBackground(), color: "white" }}
      onClick={() => onStatusClick(status, payrollId, payrollDate)}
    >
      <i
        class={`bi ${status === "Paid" ? "bi-download" : "bi-check2"} me-2 h5`}
      ></i>
      {getButtonLabel()}
    </button>
  );
}
