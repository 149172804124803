import React, { useState } from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  onChange: noop,
};

function InputArea({ className, onChange, uppercase, ...props }) {
  function onInput(e) {
    let value = e.target.value;
    if (uppercase) {
      value = value.toUpperCase();
    }
    onChange(value);
  }

  return (
    <textarea
      className={classNames("form-control", className)}
      onChange={onInput}
      {...props}
    />
  );
}

InputArea.defaultProps = defaultProps;
export default InputArea;
