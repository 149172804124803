import { browseFile } from "nq";
import Papa from "papaparse";
import BaseListPresenter from "../../../../base/BaseListPresenter";
import { findObjectUseCase } from "../../../../usecases/object";

class ProjectTabPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filter2 = {};
    this.filterDate = {};
    this.include = ["all"];
    this.keys = [];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }
  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
      },

      include: this.include,
    };
    if (
      this.view.getCollectionName() !== "users" &&
      this.view.getCollectionName() !== "invoices_final"
    ) {
      query.where.project_name = { id: this.view.props.projectId };
      query.where.client_name = { id: this.view.props.clientId };
    }
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }
  createQueryInvoices() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
      },

      include: this.include,
    };
    if (this.view.getCollectionName() === "invoices_final") {
      query.where.project_name = { id: this.view.props.projectId };
      query.where.client_name = { id: this.view.props.clientId };
      // query.where.statuses = "Approved" || "Partially Paid";
    }
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }
  createQueryEstimates() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
      },

      include: this.include,
    };
    if (this.view.getCollectionName() === "services_final") {
      query.where.project_name = { id: this.view.props.projectId };
      query.where.client_name = { id: this.view.props.clientId };
    }
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }
  async createQueryUsers() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
      },
      include: this.include,
    };

    if (this.view.getCollectionName() === "users") {
      const taskQuery = {
        where: {
          project: { id: this.view.props.projectId },
          client: { id: this.view.props.clientId },
        },
      };

      try {
        const tasks = await findObjectUseCase().execute("tasks", taskQuery);

        const uniqueEmployeeIds = new Set();
        tasks.forEach((task) => {
          task.employee.forEach((emp) => {
            uniqueEmployeeIds.add(emp.id);
          });
        });

        console.log("Unique Employee IDs:", Array.from(uniqueEmployeeIds));

        query.where.id = {
          $in: Array.from(uniqueEmployeeIds),
        };

        console.log("AAAAAAAA", query.where);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    if (this.sort) {
      query.sort = this.sort;
    }

    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }

    return query;
  }

  async findObjects() {
    const collection = this.view.getCollectionName();
    let query;

    if (
      collection !== "users" &&
      collection !== "invoices_final" &&
      collection !== "services_final"
    ) {
      query = await this.createQuery();
    }
    if (collection === "users") {
      query = await this.createQueryUsers();
      console.log("queryyy", query);
    }
    if (collection === "invoices_final") {
      query = await this.createQueryInvoices();
      console.log("queryyy", query);
    }
    if (collection === "services_final") {
      query = await this.createQueryEstimates();
      console.log("queryyy", query);
    }
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
    console.log("this.change", this.change);
    console.log("this.object", this.object);
  }

  onClickAdd(projectID, clientID, projectName, estimateType) {
    const collection = this.view.getCollectionName();
    const pid = projectID;
    const cid = clientID;
    const pName = projectName;
    const type = estimateType;
    if (collection === "project_designs") {
      console.log("PID", pid, "CID", cid);
      this.view.navigateTo("/forms/design/" + pid + "/" + cid + "/" + pName);
    }
    if (collection === "invoices_final") {
      this.view.navigateTo("/forms/invoices/" + pid + "/" + cid + "/" + pName);
    }
    // if (collection === "services_final") {
    //   this.view.navigateTo("/forms/" + estimateType + "/" + pid + "/" + cid, { state: { estimateType: type } });
    // }
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo(
      "/view/project/" +
        collection +
        "/forms/" +
        this.view.props.projectId +
        "/" +
        this.view.props.clientId +
        "/" +
        object.id
    );
  }

  async onClickStatus(index, status) {
    const collection = "project_designs";
    const object = this.objects[index];

    object.statuses =
      status === "Approved"
        ? "Approved"
        : status === "Declined"
        ? "Declined"
        : status === "Cancelled"
        ? "Cancelled"
        : status;

    try {
      const data = await this.upsertUseCase.execute(collection, object);
      if (data.statuses === object.statuses) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }
}

export default ProjectTabPresenter;
