import React from "react";
import { InputJson } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory2 from "../../components/FormFactory2";
import { restUseCase } from "../../usecases/rest";
import InputFactory from "../../components/InputFactory";
import Drop from "../../components/DropdownFilter";
import LeaveRequestFormPresenter from "./LeaveRequestFormPresenter";

class LeaveRequestFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      filtered: [],
    };
    this.presenter = new LeaveRequestFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:hrAdmin", "role:masterAdmin"],
      write: [user.id, "role:hrAdmin", "role:masterAdmin"],
    };
  }

  getCollectionName() {
    return "leave_request";
  }

  getCurrentDateTimeFormatted = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // getVacationLeaveDate() {
  //   const now = new Date();
  //   const currentDate = new Date(now);
  //   currentDate.setDate(currentDate.getDate() + 3);

  //   const year = currentDate.getFullYear();
  //   const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  //   const day = String(currentDate.getDate()).padStart(2, "0");

  //   return `${year}-${month}-${day}`;
  // }
  getVacationDateTimeFormatted = () => {
    const now = new Date();
    const futureDate = new Date(now.setDate(now.getDate() + 3));
    const year = futureDate.getFullYear();
    const month = String(futureDate.getMonth() + 1).padStart(2, "0");
    const day = String(futureDate.getDate()).padStart(2, "0");
    const hours = String(futureDate.getHours()).padStart(2, "0");
    const minutes = String(futureDate.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  onClickAdvance() {
    this.setState({
      advanced: !this.state.advanced,
    });
  }

  onChange(value, field) {
    if (field === "type") {
      console.log("Selected leave type:", value);
      this.setState({
        object: {
          ...this.state.object,
          startDate: null,
          endDate: null,
        },
      });
      document.getElementById("startDateInput").value = "";
      document.getElementById("endDateInput").value = "";
    } else if (field === "isHalfday") {
      document.getElementById("endDateDiv").style.display = value ? "none" : "";
      document.getElementById("endDateInput").value = "";
      console.log("true?", this.state.object.isHalfday);
    }

    this.presenter.onChange(value, field);
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  render() {
    // const currentDate = this.getCurrentDateFormatted();
    // const vacationDate = this.getVacationLeaveDate();
    const currentDate = this.getCurrentDateTimeFormatted();
    const vacationDate = this.getVacationDateTimeFormatted();
    const user = this.getCurrentUser();
    const selectedProject = this.state.selectedProject;
    const object = this.state.object;
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";

    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label} Leave Request
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    {advanced ? (
                      <InputJson
                        defaultValue={object}
                        onChange={this.onChangeObject.bind(this)}
                        rows="15"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mt-3 mb-3">
                    <label className="form-label">
                      Available Credits: <span>{user.credits}</span>
                    </label>
                  </div>
                  <div className="mt-3 mb-3">
                    <label className="form-label">Leave Type</label>
                    <InputFactory
                      type="Pointer"
                      target="type_of_leaves"
                      dynamic={false}
                      field="type"
                      object={object}
                      placeholder="Select Type of Leave"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <InputFactory
                      type="String"
                      dynamic={false}
                      field="description"
                      object={object}
                      placeholder="Enter Description"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  {user.roles.some(
                    (role) =>
                      role.name === "masterAdmin" || role.name === "hrAdmin"
                  ) && (
                    <div className="mb-3">
                      <label className="form-label">User</label>
                      <InputFactory
                        type="Pointer"
                        target="users"
                        dynamic={false}
                        field="name"
                        object={object}
                        placeholder="Select User"
                        onChange={this.onChange.bind(this)}
                        required={false}
                      />
                    </div>
                  )}
                  <div className="mb-3">
                    <label className="form-label">Start Date</label>
                    <InputFactory
                      type="DateTime"
                      field="startDate"
                      object={object}
                      placeholder="Enter Start Date"
                      onChange={this.onChange.bind(this)}
                      // min={
                      //   object.type?.name === "Vacation Leave"
                      //     ? vacationDate
                      //     : currentDate
                      // }
                      min={
                        object.type?.name === "Vacation Leave"
                          ? vacationDate
                          : currentDate
                      }
                      id="startDateInput"
                    />
                  </div>

                  <div className="mb-3" id="endDateDiv">
                    <label className="form-label">End Date</label>
                    <InputFactory
                      type="DateTime"
                      dynamic={false}
                      field="endDate"
                      object={object}
                      placeholder="Enter End Date"
                      onChange={this.onChange.bind(this)}
                      required={true}
                      // min={
                      //   object.type?.name === "Vacation Leave"
                      //     ? vacationDate
                      //     : currentDate
                      // }
                      min={
                        object.type?.name === "Vacation Leave"
                          ? vacationDate
                          : currentDate
                      }
                      id="endDateInput"
                    />
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary fs-sm me-3"
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LeaveRequestFormPage);
