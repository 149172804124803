import React from "react";
import InputFactory from "../../../components/InputFactory";
import { getDateRange } from "../utils/getDateRange";

function formatDate(date) {
  if (!date) return;
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}`;
}

const getBackgroundColor = (leaveType) => {
  if (!leaveType) return null;
  const { name } = leaveType;
  if (name === "Sick Leave") {
    return "#2F80ED";
  }
  if (name === "Emergency Leave") {
    return "#F5F079";
  }
  if (name === "Vacation Leave") {
    return "#4FBC4C";
  }
};

export default function WeeklyIncreases({
  startDate,
  endDate,
  onIncreasesAndDeductionsChange,
  object,
  leaveRequestInfo,
  setEmployeeIncreasesAndDeductsObject,
  holidays,
}) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const header = getDateRange(startDate, endDate)
    .slice(0, 7)
    .map((item, index) => {
      return { day: days[item.getDay()], date: item };
    });

  header.unshift("");

  // Check if current date is within any leave range
  const isDateWithinLeaveRange = (date) => {
    const currentDate = new Date(date);
    for (const leaveRequest of leaveRequestInfo) {
      const leaveStartDate = new Date(Date.parse(leaveRequest.startDate));
      const leaveEndDate = new Date(Date.parse(leaveRequest.endDate));
      if (currentDate >= leaveStartDate && currentDate <= leaveEndDate) {
        // Check if the state needs to be updated
        if (!object[`HR${days[currentDate.getDay()]}`]) {
          const newObj = {
            ...object,
            [`HR${days[currentDate.getDay()]}`]: leaveRequest.isHalfday ? 4 : 8,
          };
          setEmployeeIncreasesAndDeductsObject(newObj);
        }
        return { isInLeaveRange: true, leaveType: leaveRequest.type };
      }
    }
    return { isInLeaveRange: false, leaveType: null };
  };

  const getHoliday = (index) => {
    if (holidays.length < 1) return;
    const holiday = holidays.find(
      (holiday) =>
        new Date(holiday.date).getDate() === header[index].date.getDate() &&
        new Date(holiday.date).getMonth() === header[index].date.getMonth()
    );

    const isSunday = header[index].date.getDay() === 0;

    if (isSunday && holiday?.type.name.includes("Regular")) return "RD/REG";
    if (isSunday && holiday?.type.name.includes("Special")) return "RD/SPE";
    if (isSunday) return "RD";
    if (holiday?.type.name.includes("Regular")) return "REG";
    if (holiday?.type.name.includes("Special")) return "SPE";

    return null;
  };
  const getBackground = (value) => {
    const colors = {
      RD: "#f4af42",
      REG: "#4c6ae7",
      SPE: "#bf62e2",
      "RD/REG": "#9b8d95",
      "RD/SPE": "#d49162",
    };

    return colors[value] || null;
  };

  return (
    <div className="mt-3">
      <p
        className="fw-semibold mt-5"
        style={{ fontSize: "1.25rem", color: "#434343" }}
      >
        Increases (Computed in hours)
      </p>
      <table
        className="table table-borderless"
        style={{
          boxShadow: "0 0 5px rgba(0, 0, 0, .1)",
          borderRadius: ".5rem",
        }}
      >
        <thead>
          <tr style={{ background: "#DAB44E" }}>
            {header.map((item, index) => {
              return (
                <th
                  key={item.date}
                  style={{
                    background: "transparent",
                    fontSize: ".75rem",
                    height: "4rem",
                    borderTopLeftRadius: index === 0 ? ".5rem" : "",
                    borderTopRightRadius:
                      index === header.length - 1 ? ".5rem" : "",
                  }}
                  scope="col"
                >
                  <div
                    className="d-flex align-items-center justify-content-center flex-column m-0"
                    style={{ height: "100%" }}
                  >
                    <p className="p-0 m-0">{formatDate(item.date)}</p>
                    <p
                      className="p-0 m-0 opacity-75"
                      style={{ color: "#434343" }}
                    >
                      {item.day}
                    </p>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th
              className="ps-3"
              scope="row"
              style={{ fontSize: ".75rem", height: "2.875rem" }}
            >
              <div
                className="d-flex align-items-center m-0"
                style={{ height: "100%" }}
              >
                HR
              </div>
            </th>
            {[...Array(7)].map((field, index) => (
              <td key={field} style={{ fontSize: ".75rem" }}>
                <InputFactory
                  type="Number"
                  field={`HR${header[index + 1]?.day}`}
                  customChange={{ date: header[index + 1]?.date }}
                  object={object}
                  onChange={onIncreasesAndDeductionsChange}
                  style={{
                    background: isDateWithinLeaveRange(header[index + 1]?.date)
                      ? getBackgroundColor(
                          isDateWithinLeaveRange(header[index + 1]?.date)
                            .leaveType
                        )
                      : "",
                  }}
                />
              </td>
            ))}
          </tr>
          <tr>
            <th
              className="ps-3"
              scope="row"
              style={{ fontSize: ".75rem", height: "2.875rem" }}
            >
              <div
                className="d-flex align-items-center m-0"
                style={{ height: "100%" }}
              >
                OT
              </div>
            </th>
            {[...Array(7)].map((field, index) => (
              <td key={field} style={{ fontSize: ".75rem" }}>
                <InputFactory
                  type="Number"
                  field={`OT${header[index + 1]?.day}`}
                  object={object}
                  customChange={{ date: header[index + 1]?.date }}
                  onChange={onIncreasesAndDeductionsChange}
                  style={{
                    background: isDateWithinLeaveRange(header[index + 1]?.date)
                      ? getBackgroundColor(
                          isDateWithinLeaveRange(header[index + 1]?.date)
                            .leaveType
                        )
                      : "",
                  }}
                />
              </td>
            ))}
          </tr>
          <tr>
            <th
              className="ps-3"
              scope="row"
              style={{ fontSize: ".75rem", height: "2.875rem" }}
            >
              <div
                className="d-flex align-items-center m-0"
                style={{ height: "100%" }}
              >
                ND
              </div>
            </th>
            {[...Array(7)].map((field, index) => (
              <td key={field} style={{ fontSize: ".75rem" }}>
                <InputFactory
                  type="Number"
                  field={`ND${header[index + 1]?.day}`}
                  object={object}
                  customChange={{ date: header[index + 1]?.date }}
                  onChange={onIncreasesAndDeductionsChange}
                  style={{
                    background: isDateWithinLeaveRange(header[index + 1]?.date)
                      ? getBackgroundColor(
                          isDateWithinLeaveRange(header[index + 1]?.date)
                            .leaveType
                        )
                      : "",
                  }}
                />
              </td>
            ))}
          </tr>
          <tr>
            <th
              className="ps-3"
              scope="row"
              style={{ fontSize: ".75rem", height: "2.875rem" }}
            >
              <div
                className="d-flex align-items-center m-0"
                style={{ height: "100%" }}
              >
                INC
              </div>
            </th>
            {[...Array(7)].map((_, index) => (
              <td style={{ fontSize: ".75rem" }}>
                <p
                  className={`text-center fw-semibold rounded h-100 ${
                    getHoliday(index + 1) ? "pt-1 pb-1" : "pt-0 pb-0"
                  } p-2`}
                  style={{
                    background: getBackground(getHoliday(index + 1)),
                    color: "white",
                  }}
                >
                  {getHoliday(index + 1)}
                </p>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
