import React, { useState } from "react";
import CreateTabForm from "./CreateTabForm";

const modifySchema = (schema) => {
  return {
    ...schema.fields,
    monthlyRate: { type: "Peso", label: "Monthly Rate" },
    departmentHead: { ...schema.fields.departmentHead, type: "String" },
    employeeType: { type: "String", label: "Employee Type" },
    roles: { type: "String", label: "User Role" },
  };
};

const modifyObject = (object) => {
  if (Object.keys(object).length < 1) return;
  return {
    ...object,
    monthlyRate: (object.dailyRate || 0) * 20,
    departmentHead: object.departmentHead?.fullName,
    roles: object.roles[0].name,
  };
};

const Tabs = ({ schema, object }) => {
  console.log(object);
  const [activeTab, setActiveTab] = useState("personal");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === "personal" ? "active" : ""}`}
            href="#personal"
            style={{ fontSize: ".875rem" }}
            onClick={() => handleTabClick("personal")}
          >
            Personal Info
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === "employment" ? "active" : ""}`}
            href="#employment"
            onClick={() => handleTabClick("employment")}
            style={{ fontSize: ".875rem" }}
          >
            Employment Info
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === "payroll" ? "active" : ""}`}
            href="#payroll"
            style={{ fontSize: ".875rem" }}
            onClick={() => handleTabClick("payroll")}
          >
            Payroll
          </a>
        </li>
      </ul>

      {/* Content for each tab */}
      <div className="tab-content mt-4">
        <div
          className={`tab-pane ${activeTab === "personal" ? "active" : ""}`}
          id="personal"
        >
          <CreateTabForm
            fields={schema.fields}
            includedFields={[
              "firstName",
              "middleName",
              "lastName",
              "email",
              "contactNumber",
              "address",
              "photo",
            ]}
            object={object}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === "employment" ? "active" : ""}`}
          id="employment"
        >
          <CreateTabForm
            fields={modifySchema(schema)}
            includedFields={[
              "employeeId",
              "roles",
              "department",
              "departmentHead",
              "position",
              "employeeType",
              "hiredDate",
            ]}
            object={modifyObject(object)}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === "payroll" ? "active" : ""}`}
          id="payroll"
        >
          <CreateTabForm
            fields={modifySchema(schema)}
            includedFields={[
              "dailyRate",
              "monthlyRate",
              "tin",
              "sss",
              "philhealth",
              "pagibig",
              "credits",
              "bankNumber",
            ]}
            object={modifyObject(object)}
          />
        </div>
      </div>
    </div>
  );
};

export default Tabs;
