import React from "react";
import NavBar from "../../components/navbar";
import { Button, dialog } from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import CashInPositionPresenter from "./CashInPositionPresenter";
import DateRange from "../../components/DateRange";
import InputFactory from "../../components/InputFactory";
import printComponent from "../../printComponent";
import BalanceSheet from "./reportsPages/BalanceSheet";
import ProfitLoss from "./reportsPages/ProfitLoss";
import ProfitLossSummary from "./reportsPages/ProfitLossSummary";

class CashInPositionPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new CashInPositionPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      progress: true,
      totalBank: 0,
      totalCashReceipt: 0,
      total: 0,
      count: 0,
      isOffcanvasOpen: false,
      document: "Balance Sheet",
      totalDisburse: 0,
      totalCashPaid: 0,
    };
    this.reportsPDF = React.createRef();
  }

  setTotalBank(totalBank) {
    this.setState({ totalBank: totalBank });
  }

  setDisburse(totalDisburse) {
    this.setState({ totalDisburse });
  }

  setCashPaid(totalCashPaid) {
    this.setState({ totalCashPaid });
  }

  onChangeDocs(value) {
    this.setState({ document: value });
  }

  exportPDF() {
    printComponent(this.reportsPDF.current, this.state.document);
  }

  onClickReports() {
    dialog.fire({
      html: (
        <div>
          <div>
            <h6 className="fw-bold mb-4">
              <i
                className="bi bi-file-earmark-medical"
                style={{ color: "#EBBD2F" }}
              ></i>{" "}
              Generate Documents
            </h6>
          </div>

          <div>
            <p className="mb-0">Select Documents:</p>
            <InputFactory
              type="String"
              _type="Enum"
              className=" "
              options={[
                "Balance Sheet",
                "Profit and Loss",
                "Quarterly Profit and Loss Summary",
              ]}
              placeholder="Select Documents"
              onChange={this.onChangeDocs.bind(this)}
            />
          </div>
          <div className="text-center m-4">
            <button
              className="btn btn-light  btn-sm me-2"
              onClick={() => dialog.close()}
            >
              Close
            </button>
            <button
              className="btn btn-sm"
              onClick={this.exportPDF.bind(this)}
              style={{ backgroundColor: "#EBBD2F", color: "white" }}
            >
              Generate
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
  }

  getCollectionName() {
    return "transaction";
  }
  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };
  onClickAllBank() {
    this.navigateTo("/cash-in-position/all-banks");
  }
  onClickCashReceipts() {
    this.navigateTo("/cash-in-position/cash-receipts");
  }
  onClickCashDisbursement() {
    this.navigateTo("/cash-in-position/cash-disbursement");
  }
  onClickCashPaidOut() {
    this.navigateTo("/cash-in-position/cash-paid-out");
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  render() {
    const {
      objects,
      isOffcanvasOpen,
      totalBank,
      document,
      totalDisburse,
      totalCashPaid,
    } = this.state;

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    const totalCashReceipt = objects.reduce(
      (total, obj) => total + obj.amount,
      0
    );

    return (
      <>
        <NavBar />
        <div className="p-3 p-lg-4">
          <div className="d-none">
            <div ref={this.reportsPDF}>
              {this.state.document === "Balance Sheet" ? (
                <BalanceSheet />
              ) : this.state.document === "Profit and Loss" ? (
                <ProfitLoss />
              ) : this.state.document ===
                "Quarterly Profit and Loss Summary" ? (
                <ProfitLossSummary />
              ) : (
                <BalanceSheet />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="fw-bold mt-3 text-capitalize">Cash in Position</h1>

            <div className="d-block d-md-none mt-2">
              <div className="d-flex align-items-center ">
                <i
                  className="bi bi-filter"
                  style={{ fontSize: "25px", color: "#EBBD2F" }}
                  onClick={this.toggleOffcanvas}
                  aria-controls="offcanvasRight"
                ></i>
              </div>

              <div
                className={`offcanvas offcanvas-end ${
                  isOffcanvasOpen ? "show" : ""
                }`}
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
                style={{
                  visibility: isOffcanvasOpen ? "visible" : "hidden",
                }}
              >
                <div
                  className="offcanvas-header"
                  style={{ backgroundColor: "#EBBD2F" }}
                >
                  <h5 className="offcanvas-title" id="offcanvasRightLabel">
                    Filters
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={this.toggleOffcanvas}
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <div className=" mt-3">
                    <DateRange
                      onChange={this.onChangeDate.bind(this)}
                      field="createdAt"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
            <div className="d-flex">
              <DateRange
                onChange={this.onChangeDate.bind(this)}
                field="createdAt"
              />
            </div>
          </div>
          <div className="row">
            <div
              onClick={this.onClickAllBank.bind(this)}
              role="button"
              className="col-lg-3 col-md-6 col-6 p-2"
            >
              <div
                className="card bg-secondary flex flex-column p-3"
                style={{ height: "150px" }}
              >
                <div
                  className="text-start"
                  style={{ fontWeight: "600", fontSize: ".9em" }}
                >
                  Total Cash in Bank
                </div>
                <div className="fs-3 fw-semibold mt-2 text-center ">
                  {formatCurrency(totalBank)}
                </div>
              </div>
            </div>
            <div
              onClick={this.onClickCashReceipts.bind(this)}
              role="button"
              className="col-lg-3 col-md-6 col-6 p-2"
            >
              <div
                className="card bg-secondary flex flex-column p-3"
                style={{ height: "150px" }}
              >
                <div
                  className="text-start"
                  style={{ fontWeight: "600", fontSize: ".9em" }}
                >
                  Total Cash Receipts
                </div>
                <div className="fs-3 fw-semibold mt-2 text-center ">
                  {formatCurrency(totalCashReceipt)}
                </div>
              </div>
            </div>
            <div
              onClick={this.onClickCashDisbursement.bind(this)}
              role="button"
              className="col-lg-3 col-md-6 col-6 p-2"
            >
              <div
                className="card bg-secondary flex flex-column p-3"
                style={{ height: "150px" }}
              >
                <div
                  className="text-start"
                  style={{ fontWeight: "600", fontSize: ".9em" }}
                >
                  Total Cash Disbursements
                </div>
                <div className="fs-3 fw-semibold mt-2 text-center ">
                  {formatCurrency(totalDisburse)}
                </div>
              </div>
            </div>
            <div
              onClick={this.onClickCashPaidOut.bind(this)}
              role="button"
              className="col-lg-3 col-md-6 col-6 p-2"
            >
              <div
                className="card bg-secondary flex flex-column p-3"
                style={{ height: "150px" }}
              >
                <div
                  className="text-start"
                  style={{ fontWeight: "600", fontSize: ".9em" }}
                >
                  Total Cash Paid Out
                </div>
                <div className="fs-3 fw-semibold mt-2 text-center ">
                  {formatCurrency(totalCashPaid)}
                </div>
              </div>
            </div>
          </div>
          <div className="position-fixed bottom-0 end-0 m-4">
            <Button
              className="btn shadow-sm"
              onClick={this.onClickReports.bind(this)}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "25px",
                backgroundColor: "#EBBD2F",
              }}
            >
              <i className="bi bi-file-earmark-medical" />
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default CashInPositionPage;
