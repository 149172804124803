import BaseListPage from "../../../base/BaseListPage";
import UserListPresenter from "./UserListPresenter";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import NavBar from "../../../components/navbar";
import SearchFilter from "../components/SearchFilter";
import DropdownFilters from "../components/DropdownFilters";
import CliTable from "../../../components/CliTable";
import FloatingButton from "../components/FloatingButton";
import DeleteButton from "../components/DeleteButton";
import withRouter from "../../../withRouter";

import createPromise from "../../../createPromise";
import dialog from "../../../components/Modal/dialog";
import DeleteUser from "../components/Modals/DeleteUser";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import InfiniteScroll from "../../../components/InfiniteScroll";

class UserListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new UserListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return "users";
  }

  toggleStatus(object) {
    this.presenter.toggleStatus(object);
  }

  formatTableFieldsLabel(fields) {
    return this.presenter.formatTableFieldsLabel(fields);
  }

  formatTableObjectValue(objects) {
    return this.presenter.formatTableObjectValue(objects);
  }

  onCollapse(index, object) {
    return this.presenter.onCollapse(index, object);
  }

  onChangeFilter(type, value, field) {
    this.presenter.onChangeFilter(type, value, field);
  }

  showDeleteUserDialog(selected) {
    const promise = createPromise();
    dialog.fire({
      html: <DeleteUser selected={selected} />,
      positiveButton: "Delete",
      negativeButton: "Cancel",
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
    });
    return promise;
  }

  showToast(message) {
    toast(message, {
      position: "bottom-right",
      style: {
        background: "#4FBC4C",
        color: "white",
        fontWeight: "600",
      },
    });
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, progress, count } = this.state;

    console.log("PROGRESS", progress);

    console.log(objects);

    return (
      <>
        <NavBar />
        <Toaster />
        <div
          className="container-fluid overflow-auto"
          style={{
            paddingInline: "3rem",
            paddingBlock: "2rem",
            background: "white",
          }}
        >
          {/* <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <p className="fs-3 fw-bold">
              {schema.collection.charAt(0).toUpperCase() +
                schema.collection.slice(1)}
            </p>
            <SearchFilter
              filter={schema.filters.fullName}
              onChangeFilter={this.onChangeFilter.bind(this)}
            />
            <p className="mt-3 mb-3">Filter:</p>
            <div className="row gx-2">
              <DropdownFilters
                schema={schema}
                keys={["status", "roles"]}
                onChangeFilter={this.onChangeFilter.bind(this)}
              />
              <DeleteButton
                selected={selected}
                onDelete={this.onClickDeleteSelected.bind(this)}
              />
            </div>
            <div className="mt-3">
              <InfiniteScroll
                loadMore={this.loadMore.bind(this)}
                hasMore={!progress && count > objects.length}
              >
                <CliTable
                  fields={this.formatTableFieldsLabel(schema.fields)}
                  objects={this.formatTableObjectValue(objects)}
                  collapsable
                  selectable
                  tableHeadBackground="#DAB44E"
                  shadow={false}
                  striped={false}
                  selected={selected}
                  progress={progress}
                  onSelect={this.onSelect.bind(this)}
                  onSelectAll={this.onSelectAll.bind(this)}
                  onCollapse={this.onCollapse.bind(this)}
                  excludeFields={Object.keys(schema.fields).filter(
                    (key) => !this.presenter.fieldsToDisplay.includes(key)
                  )}
                />
              </InfiniteScroll>
            </div>
            <FloatingButton onClick={this.onClickAdd.bind(this)} />
          </InfiniteScroll> */}
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <p className="fs-3 fw-bold">
              {schema.collection.charAt(0).toUpperCase() +
                schema.collection.slice(1)}
            </p>
            <SearchFilter
              filter={schema.filters.fullName}
              onChangeFilter={this.onChangeFilter.bind(this)}
            />
            <p className="mt-3 mb-3">Filter:</p>
            <div className="row gx-2">
              <DropdownFilters
                schema={schema}
                keys={["status", "roles"]}
                onChangeFilter={this.onChangeFilter.bind(this)}
              />
              <DeleteButton
                selected={selected}
                onDelete={this.onClickDeleteSelected.bind(this)}
              />
            </div>
            <div className="mt-3">
              <CliTable
                fields={this.formatTableFieldsLabel(schema.fields)}
                objects={objects}
                collapsable
                selectable
                tableHeadBackground="#DAB44E"
                shadow={false}
                striped={false}
                selected={selected}
                progress={progress}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                excludeFields={Object.keys(schema.fields).filter(
                  (key) => !this.presenter.fieldsToDisplay.includes(key)
                )}
              />
            </div>
            <FloatingButton onClick={this.onClickAdd.bind(this)} />
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(UserListPage);
