const defaultProps = {
  keys: [],
};

function getColorBasedOnValue(value) {
  switch (value) {
    case "Disapproved":
      return "#B20001";
    case "Done":
      return "#3FC65C";
    case "Pending":
      return "#D8AE3F";
    case "Liquidation":
      return "#9747FF";
    case "Paid":
      return "#CF6F00";
    case "Approved":
      return "#3FC65C";
    case "Partially Paid":
      return "#CF6F00";
    default:
      return "inherit";
  }
}

function getColorBasedOnType(object) {
  //console.log("obj tble", object);
  const type = object.types;
  //console.log("GreenType", type);
  if (type?.type === "Money In" || object.type === "Money In") {
    return "#3FC65C";
  } else if (type?.type === "Money Out" || object.type === "Money Out") {
    return "#B20001";
  }
  return "inherit";
}

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(amount);
};

const formatCurrencyString = (amounts) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(amounts.replace(/,/g, ""));
};

function OutputString({ field, object, keys, role }) {
  const value = object[field];
  //console.log("getegete", field);
  const color =
    getColorBasedOnValue(value) !== "inherit"
      ? getColorBasedOnValue(value)
      : getColorBasedOnType(object);

  let renderedValue;

  // if (value && typeof value !== "object") {
  //   renderedValue =
  //     // field === "amounts" ||
  //     field === "account_balance" || field === "totalAmount"
  //       ? formatCurrency(value)
  //       : field === "amounts"
  //       ? formatCurrencyString(value)
  //       : field === "amount"
  //       ? formatCurrency(value)
  //       : value;
  // }

  if (value && typeof value !== "object") {
    renderedValue =
      // field === "amounts" ||
      field === "account_balance" || field === "totalAmount"
        ? formatCurrency(value)
        : field === "amounts"
        ? formatCurrencyString(value)
        : field === "deduction"
        ? formatCurrencyString(value)
        : field === "amount"
        ? formatCurrency(value)
        : field === "received"
        ? formatCurrency(value)
        : field === "collectibles"
        ? formatCurrency(value)
        : field === "pending_balance"
        ? formatCurrency(value)
        : value;

    if (!role?.includes("") && field === "account_balance")
      renderedValue = "****";
  } else {
    const values = [];
    keys.forEach((key) => {
      const v = object[key];
      if (v && typeof v !== "object") {
        values.push(v);
      }
    });
    renderedValue = values.join(" ");
  }

  return <span style={{ color }}>{renderedValue}</span>;
}

OutputString.defaultProps = defaultProps;

export default OutputString;
