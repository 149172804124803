import { dialog } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";

class LeaveCreditsFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async submit() {
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    try {
      this.view.submitting();

      if (!this.change.name) {
        throw new Error("User not selected.");
      }
      console.log("first", this.change.credits, this.object.credits);

      const query = {
        where: {
          id: this.change.name.id,
        },
      };

      const user = await findObjectUseCase().execute("users", query);
      console.log("QWEQWE", user[0]);

      console.log(this.view.state.object);
      if (!user[0]) {
        throw new Error("User not found.");
      }
      if (this.change.type === "Accrued") {
        // Convert both credits to numbers before adding
        const creditsWithoutCommas = user[0].credits.replace(/,/g, "");
        console.log(creditsWithoutCommas);
        const newCredits =
          parseFloat(creditsWithoutCommas) +
          parseFloat(this.change.credits.replace(/,/g, ""));
        user[0].credits = newCredits.toLocaleString();
      } else if (this.change.type === "Diminished") {
        // Convert both credits to numbers before subtracting
        const creditsWithoutCommas = user[0].credits.replace(/,/g, "");
        const newCredits =
          parseFloat(creditsWithoutCommas) -
          parseFloat(this.change.credits.replace(/,/g, ""));
        user[0].credits = newCredits.toLocaleString();
      }

      console.log("User object before upsert:", user[0]);
      console.log("Credits", user[0].credits, user[0].fullName);

      await this.upsertUseCase.execute("users", user[0]);
      await this.save();

      this.view.submissionSuccess();
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Data has been successfully saved.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo("/leave-credits");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default LeaveCreditsFormPresenter;
