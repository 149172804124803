import React from "react";
import Checkbox from "../Checkbox";
import Accordion from "../Accordion";
import OutputFactory from "../OutputFactory";
import CollapseView from "./CollapseView";
import AccordionProvider from "../Accordion/AccordionProvider";
import AccordionCollapse from "../Accordion/AccordionCollapse";

let timeoutId;

// const formatDate = (dateString) => {
//   const providedDate = new Date(dateString);
//   providedDate.setHours(0, 0, 0, 0);

//   const options = { year: "numeric", month: "short", day: "numeric" };
//   return providedDate.toLocaleDateString("en-US", options);
// };

const getColorForEndDate = (endDate) => {
  const currentDate = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  const differenceInDays = Math.round(
    (new Date(endDate) - currentDate) / oneDay
  );

  if (differenceInDays <= 1) {
    return "red";
  } else if (differenceInDays <= 3) {
    return "orange";
  } else {
    return "green";
  }
};

function Body({
  objects,
  fields,
  excludeFields,
  selected,
  selectable,
  onSelect,
  onLongPress,
  actionsList,
  collapsable,
  onCollapse,
  toggleable,
  actions,
  onClickItem,
  fieldImage,
}) {
  const onPointerDown = () => {
    timeoutId = setTimeout(onLongPress, 500);
  };

  const onPointerUp = () => {
    clearTimeout(timeoutId);
  };

  return objects.map((object, index) => {
    const id = object.id || object._id;
    const checked = selected.some((item) => item.id === id);

    return (
      <AccordionProvider key={id}>
        <tr>
          {Object.keys(fields).map((field, i) => {
            const options = fields[field];
            if (excludeFields.includes(field)) return null;

            return (
              <Accordion
                as="td"
                eventKey={`_${id}`}
                key={field}
                className="fs-sm "
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
                style={{
                  cursor: "pointer",
                  maxWidth: "15em",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
              >
                {field === "time" ? (
                  <span
                    style={{ color: getColorForEndDate(object["endDate"]) }}
                  >
                    {object[field]}
                  </span>
                ) : field === "statuses" &&
                  (object[field] === "In Progress" ||
                    object[field] === "Done") ? (
                  <span
                    style={{
                      color:
                        object[field] === "In Progress" ? "#CF6F00" : "green",
                    }}
                  >
                    {object[field]}
                  </span>
                ) : (
                  <OutputFactory field={field} object={object} {...options} />
                )}
              </Accordion>
            );
          })}
          {actionsList.map((action) => {
            return (
              <td
                className=""
                style={{
                  backgroundColor: "rgba(0,0,0,0)",
                  position: "relative",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.querySelector("button").style.opacity = 1)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.querySelector("button").style.opacity = 0)
                }
              >
                <button
                  onClick={action.onClick && action.onClick.bind(this, index)}
                  className="btn btn-outline-primary btn-sm fs-xs shadow-none"
                  style={{
                    display: "inline-block",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)", // Center the button horizontally and vertically
                    transition: "opacity 0.3s ease",
                    opacity: 0,
                  }}
                >
                  <i className={action.icon} /> {action.label}
                </button>
              </td>
            );
          })}
        </tr>
        {collapsable && (
          <tr>
            <td
              className="p-0"
              colSpan={Object.keys(fields).length + 1}
              style={{ backgroundColor: "rgba(0,0,0,0)" }}
            >
              <AccordionCollapse
                toggleable={toggleable}
                className="collapse"
                eventKey={`_${id}`}
              >
                <div className="p-3">
                  {onCollapse(index, object, excludeFields) ||
                    CollapseView(
                      index,
                      object,
                      excludeFields,
                      fields,
                      actions,
                      onClickItem,
                      fieldImage
                    )}
                </div>
              </AccordionCollapse>
            </td>
          </tr>
        )}
      </AccordionProvider>
    );
  });
}

export default Body;
