import React from "react";
import Modal from "./";

function noop() {}

const defaultProps = {
  positiveButton: "YES",
  negativeButton: "CANCEL",
  onPositiveClick: noop,
  onNegativeClick: noop,
  footer: true,
};

function ModalContent({
  children,
  isOpen,
  onClosed,
  size,
  positiveButton,
  positiveButtonBg,
  positiveButtonColor,
  negativeButton,
  onPositiveClick,
  onNegativeClick,
  footer,
  className,
}) {
  const [open, setOpen] = React.useState(isOpen);
  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const style = {
    minWidth: "6em",
  };
  return (
    <Modal isOpen={open} onClosed={onClosed} size={size}>
      <Modal.Body className="pb-0 ps-4 pe-4 pt-4">{children}</Modal.Body>
      {footer && (
        <div className="row justify-content-center gap-2 modal-footer border-0 p-4">
          {negativeButton && (
            <button
              style={{ ...style, width: "8rem", height: "2.75rem" }}
              onClick={() => {
                setOpen(false);
                onNegativeClick();
              }}
              type="button"
              className="btn btn-light fs-sm ms-2 col-auto fw-semibold"
            >
              {negativeButton}
            </button>
          )}
          <button
            style={{
              ...style,
              background: positiveButtonBg || "#DAB451",
              width: "8rem",
              height: "2.75rem",
              color: positiveButtonColor,
            }}
            onClick={() => {
              setOpen(false);
              onPositiveClick();
            }}
            type="submit"
            className="btn fs-sm col-auto fw-semibold"
          >
            {positiveButton}
          </button>
        </div>
      )}
    </Modal>
  );
}

ModalContent.defaultProps = defaultProps;
export default ModalContent;
