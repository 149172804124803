import React, { useState } from "react";
import dialog from "nq-component/dist/Modal/dialog";
import SelectSearch from "../SelectSearch";
import GetOption from './GetOption';
import getIndexes from "nq-component/dist/getIndexes";
import objectToOption from "./objectToOption";
import ModdedSelectSearch from "../ModdedSelectSearch"
import ModdedRelationDialog from "../ModdedRelationDialog";
import ModdedServices from "../ModdedServices"

function ModdedInputServices({
    schema,
    object,
    pick,
    target,
    onChange,
    isMulti,
    where,
    indexes,
    field,
    defaultValue,
    findObject,
    saveObject,
    saveFile,
    saveImage,
    schemas,
    ...props
}) {
    const [value, setValue] = useState(isMulti ? [] : { label: '', value: '' });
    const [options, setOptions] = useState([]);
    const [focus, setFocus] = useState(false);

    function onClickAdd() {
        const onClickItem = (object) => {
            _onChange(objectToOption(object, _indexes));
            dialog.close();
        }
        dialog.fire({
            html: (
                <ModdedRelationDialog
                    onClickItem={onClickItem}
                    schema={schema}
                    objects={object}
                    onCancel={() => dialog.close()}
                    findObject={findObject}
                    saveObject={saveObject}
                    saveFile={saveFile}
                    saveImage={saveImage}
                    schemas={schemas} />
            ),
            footer: false,
        });
    }


    const _indexes = React.useMemo(() => {
        const items = indexes || getIndexes(schema.fields);
        // if no index use name
        return items.length > 0 ? items : ['name'];
    }, [schema]);

    // set default value
    React.useEffect(() => {
        if (isMulti) {
            defaultValue && setValue(defaultValue.map(obj => objectToOption(obj, _indexes)));
        } else {
            defaultValue && setValue(objectToOption(defaultValue, _indexes));
        }
    }, [_indexes, defaultValue, isMulti]);

    function callback(options) {
        setOptions(options);
        // find default value
        if (defaultValue) {
            const option = options.find(o => o.value === defaultValue.id);
            option && setValue(option);
        }
    }

    // load the initial select
    React.useEffect(() => {
        // if (isMulti) {
        //     setValue([]);
        // } else {
        //     setValue({label: '', value: ''}); // reset
        // }
        new GetOption(target, '', _indexes, findObject, where, callback);
    }, [target, _indexes]);


    function _onChange(option) {
        if (isMulti) {
            const newValue = [...value, option];
            setValue(newValue);
            onChange(newValue.map((o) => o.object));
        } else {
            setValue(option);
            onChange(option.object);
        }
    }

    function onClickClear() {
        setValue(isMulti ? [] : { label: "", value: "" });
        onChange(isMulti ? [] : {});
    }

    function onSearch(word) {
        new GetOption(target, word, _indexes, findObject, where, setOptions);
    }

    function getValue() {
        if (isMulti && !focus) {
            const values = value.map(v => v.label).reverse();
            return { label: values.join(', ') };
        }
        return value;
    }

    function getOptions() {
        // remove the selected from the option
        if (isMulti) {
            return options.filter(o => !value.includes(o));
        }
        return options.filter(o => o !== value);
    }

    return (
        <ModdedServices
            label={`Select ${target}`}
            value={getValue()}
            onChange={_onChange}
            onSearch={onSearch}
            options={getOptions()}
            onFocus={setFocus}
            onClickClear={onClickClear}
            onClickAdd={onClickAdd}
            focus
            dynamic
            {...props} />
    );
}

export default ModdedInputServices;
