import React from "react";
import NavBar from "../../../components/navbar";
import { Button, InfiniteScroll, Progress } from "nq-component";
import BaseListPage from "../../../base/BaseListPage";
import {
  countObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import InputFactory from "../../../components/InputFactory";
import DateRange from "../../../components/DateRange";

import Table from "../../../components/Table";
import CashPaidOutPresenter from "./CashPaidOutPresenter";
import { browseFile } from "nq";

class CashPaidOutPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new CashPaidOutPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (
      role.some((role) => role.name === "admin" || role.name === "masterAdmin")
    ) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({ created_by: { id: user?.id } });
    }
  }
  getCollectionName() {
    return "expense";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeEmployee(where) {
    this.presenter.onChangeEmployee(where);
  }

  filterSubmit2(where) {
    this.presenter.filterSubmit2(where);
  }

  filterSubmit3(where) {
    this.presenter.filterSubmit3(where);
  }

  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit2(where);
  }

  onChangeFilter3(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit3(where);
  }

  onClick() {
    this.presenter.onClick();
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  onClickExport() {
    const objectCSV = this.state.objects;
    this.presenter.exportToCSV(objectCSV);
  }

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, count, progress } = this.state;

    const cashPaidOut = "CPO -";
    const cashPaidOutObjects = objects.filter((item) =>
      item.chart_accounts?.name
        .toLowerCase()
        .includes(cashPaidOut.toLowerCase())
    );

    const cashPaidOutData = cashPaidOutObjects.reduce((result, obj) => {
      const name = obj.chart_accounts?.name;
      const displayName =
        name && name.startsWith("CPO -") ? name.slice(5) : name;

      if (!result[displayName]) {
        result[displayName] = { amount: obj.amount };
      } else {
        result[displayName].amount += obj.amount;
      }
      return result;
    }, {});

    if (!schema) return <Progress />;
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="p-lg-4 d-flex">
                  <h1 className="fw-bold text-capitalize">
                    <span onClick={this.onClick.bind(this)}>
                      <i class="bi bi-chevron-left"></i>
                    </span>
                    Total Cash Paid Out
                  </h1>
                </div>

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#EBBD2F" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                    <div className="dropdown">
                      <button
                        className="border-0"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-three-dots"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          className="dropdown-item"
                          role="button"
                          onClick={this.onClickExport.bind(this)}
                        >
                          <i className="bi bi-filetype-csv"></i> Export CSV
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters2 || {}).map((field) => {
                          let { type, ...options } = schema.filters2[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter2.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters5 || {}).map((field) => {
                          let { type, ...options } = schema.filters5[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter5.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters3 || {}).map((field) => {
                          let { type, ...options } = schema.filters3[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1 "
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter3.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="createdAt"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none my-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters2 || {}).map((field) => {
                    let { type, ...options } = schema.filters2[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter2.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  {Object.keys(schema.filters3 || {}).map((field) => {
                    let { type, ...options } = schema.filters3[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1 "
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter3.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>

                <div className="dropdown">
                  <button
                    className="border-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-three-dots"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.onClickExport.bind(this)}
                    >
                      <i className="bi bi-filetype-pdf"></i> Export CSV
                    </li>
                  </ul>
                </div>
              </div>

              <table className="table">
                <thead
                  className="bg-warning"
                  style={{ backgroundColor: "#FFFF00" }}
                >
                  <tr>
                    <th scope="col" style={{ backgroundColor: "#DAB451" }}></th>
                    <th scope="col" style={{ backgroundColor: "#DAB451" }}>
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(cashPaidOutData).map(([name, data]) => (
                    <tr key={name}>
                      <th scope="row" style={{ fontWeight: "normal" }}>
                        {name}
                      </th>
                      <td>{formatCurrency(data.amount)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default CashPaidOutPage;
