import React, { useState } from "react";
import classNames from "../../../../classNames";

// const ConfirmDialogContent = ({ onConfirm, onCancel, cashAmount }) => (
//   <div className="text-end">
//     <div className="text-center">
//       <i
//         className={classNames("bi bi-file-earmark-check", "text-success")}
//         style={{ fontSize: "5rem" }}
//       ></i>
//       <h4 className="fw-bold">Confirm Cash Advance</h4>
//       <p className="m-0">
//         I hereby authorize the company to deduct the amount of cash ₱
//         {cashAmount} from my wages every cutoff.
//       </p>
//       <button
//         className="btn text-white mb-2 mt-2 me-2"
//         style={{ backgroundColor: "#4aab73" }}
//         onClick={onConfirm}
//       >
//         Confirm
//       </button>
//       <button className="btn mb-2 mt-2 btn-light" onClick={onCancel}>
//         Cancel
//       </button>
//     </div>
//   </div>
// );
const ConfirmDialogContent = ({ onConfirm, onCancel }) => {
  const [deduction, setDeduction] = useState("");

  const handleChange = (event) => {
    setDeduction(event.target.value);
  };

  return (
    <div className="text-end">
      <div className="text-center">
        <h4 className="fw-bold">Confirm Cash Advance</h4>
        <p className="m-0">
          I hereby authorize the company to deduct the amount of cash ₱
          <input
            type="number"
            value={deduction}
            onChange={handleChange}
            style={{
              width: "180px",
              textAlign: "center",
              marginLeft: "10px",
              marginRight: "10px",
              appearance: "none",
              MozAppearance: "textfield",
              WebkitAppearance: "none",
            }}
            placeholder="Enter Deduction"
          />
          from my wages every cutoff.
        </p>
        <button
          className="btn text-white mb-2 mt-2 me-2"
          style={{ backgroundColor: "#4aab73" }}
          onClick={() => onConfirm(deduction)}
        >
          Confirm
        </button>
        <button className="btn mb-2 mt-2 btn-light" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ConfirmDialogContent;
