import React from "react";
import Checkbox from "../Checkbox";

function Head({
  selectable,
  fields,
  excludeFields,
  actionsList,
  selected,
  onSelectAll,
  objects,
}) {
  return (
    <tr>
      {Object.keys(fields).map((field, index) => {
        const { type, ...options } = fields[field];
        if (excludeFields.includes(field)) return null;
        const label = options.label || field;
        return (
          <th
            key={field}
            className="border-start border-bottom fs-xs align-middle text-nowrap"
            style={{ backgroundColor: "rgba(0,0,0,0)", border: "#e5e5e5" }}
          >
            {label}
          </th>
        );
      })}
      {actionsList.length > 0 && (
        <>
          <th
            className="border-start border-bottom text-center fs-xs align-middle text-nowrap"
            colSpan={actionsList.length}
            style={{ backgroundColor: "rgba(0,0,0,0)", border: "#e5e5e5" }}
          >
            Actions
          </th>
        </>
      )}
    </tr>
  );
}

export default Head;
